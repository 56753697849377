import { Button, DatePicker } from 'antd';
import styled from 'styled-components/macro';
import React, { useState } from 'react';
import { getPreviousThreeMonths, getLastYear } from '../utils.js';

const { RangePicker } = DatePicker;

function DateFilterDropdown(props) {
  const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props;
  const [dateRange, setDateRange] = useState(selectedKeys);
  const [selectedMonth, setSelectedMonth] = useState(null);

  return (
    <div style={{ padding: 8 }}>
      <div style={{ marginBottom: 4 }}>
        <span style={{ marginLeft: 10, marginRight: 3 }}>Filtros rápidos:</span>
        {getPreviousThreeMonths().map((month) => (
          <Button
            key={month}
            type={month === selectedMonth ? 'primary' : 'secondary'}
            onClick={() => {
              setSelectedKeys([month]);
              setSelectedMonth(month);
              setDateRange([]);
              confirm();
            }}
            style={{ margin: 3 }}
          >
            {month}
          </Button>
        ))}
        <Button
          type={'lastYear' === selectedMonth ? 'primary' : 'secondary'}
          onClick={() => {
            const lastYearRange = getLastYear();
            const formattedRange = [
              lastYearRange[0].startOf('day'),
              lastYearRange[1].endOf('day'),
            ];
            setDateRange(formattedRange);
            setSelectedKeys([formattedRange]);
            setSelectedMonth('lastYear');
            confirm();
          }}
          style={{ margin: 3 }}
        >
          Último año
        </Button>
      </div>
      <RangePicker
        value={dateRange}
        onChange={(val) => {
          val[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          val[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          // val[1].set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
          setSelectedKeys([val]);
          setDateRange(val);
          setSelectedMonth(null);
        }}
      />
      <FilterButton
        type='primary'
        onClick={() => confirm()}
        size='small'
        style={{ width: 90 }}
      >
        Filtrar
      </FilterButton>
      <FilterButton
        onClick={() => {
          clearFilters();
          confirm();
          setSelectedKeys(null);
          setDateRange([]);
          setSelectedMonth(null);
        }}
        size='small'
        style={{ width: 90 }}
      >
        Reset
      </FilterButton>
    </div>
  );
}

const FilterButton = styled(Button)`
  margin-left: 7px;
`;

export { DateFilterDropdown };
