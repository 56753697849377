import { Button, Form, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { ConfigPaymentMethodModal } from './ConfigPaymentMethodModal.js';
import { ConfigSinglePaymentMethod } from './ConfigSinglePaymentMethod.js';

function ConfigPaymentMethodManager(props) {
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodChanged, setPaymentMethodChanged] = useState(false);
  const [form] = Form.useForm();

  function fetchPaymentMethods() {
    axios({
      method: 'post',
      url: props.API_domain + 'getPaymentMethods',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then(function (response) {
        setPaymentMethods(response.data);
        setPaymentMethodChanged(false);
        form.resetFields();
      })
      .catch(function (response) {
        console.log(response);
      });
  }
  useEffect(() => {
    fetchPaymentMethods();
  }, [paymentMethodChanged]);

  return (
    <div>
      <div>
        {/* if there are no payment methods, render a Empty from antd with text "helloo wold" */}
        {paymentMethods && paymentMethods.length === 0 && (
          <>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <div>
                    Apenas crees formas de pago, el usuario podrá seleccionarlas
                    al crear gastos mediante el bot de WhatsApp
                  </div>
                </>
              }
              style={{ marginTop: 0 }}
            />
          </>
        )}
        {paymentMethods.map((paymentMethod) => (
          <ConfigSinglePaymentMethod
            key={paymentMethod.id}
            API_domain={props.API_domain}
            paymentMethod={paymentMethod}
            setPaymentMethodChanged={setPaymentMethodChanged}
          />
        ))}
      </div>
      <div
        style={{
          paddingTop: paymentMethods && paymentMethods.length === 0 ? 0 : 16,
        }}
      >
        <Button type='primary' onClick={() => setVisible(true)}>
          <PlusOutlined /> Crear Forma de Pago
        </Button>
      </div>

      <ConfigPaymentMethodModal
        visible={visible}
        title={'Crear forma de pago'}
        handleCancel={() => setVisible(false)}
        handleOk={() => setVisible(false)}
        API_domain={props.API_domain}
        team={props.team}
        setCategoryCreated={setPaymentMethodChanged}
      />
    </div>
  );
}

export { ConfigPaymentMethodManager };
