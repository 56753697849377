import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { Button, Col, message, Modal, Popconfirm, Row } from 'antd';
import { ImgOrPdf } from './../ImgOrPdf.js';
import { InvoiceStateSelect } from './InvoiceStateSelect.js';

const downloadImage = (event, url) => {
  event.preventDefault();
  const link = document.createElement('a');
  link.download = 'image.jpg';
  link.href = url;
  link.click();
};

function SecondaryDocModal(props) {
  const auth = useContext(authContext);

  const deleteSecondaryDoc = (value) => {
    axios({
      method: 'post',
      url: props.API_domain + 'deleteSecondaryDoc',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        secondary_doc_id: props.selectedSecondaryDoc.id,
      },
    })
      .then((response) => {
        props.onSubmit();
      })
      .catch(function (response) {
        console.log('Error is ', response);
      });
  };

  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={props.handleCancel}
      centered
    >
      <div>
        <ImgOrPdf
          src={{ imageSource: props.selectedSecondaryDoc.file_url }}
          height={'800'}
        />
      </div>
      <Row>
        <Col span={8}>
          {/* {props.selectedSecondaryDoc.is_invoice ? null : (
            <Button
              type='secondary'
              onClick={() => {
                convertSecondaryDocToInvoice(props.selectedSecondaryDoc.id);
              }}
            >
              <FileTextOutlined />
              Convertir en factura
            </Button>
          )} */}
        </Col>
        <Col span={8}>
          {props.selectedSecondaryDoc.is_invoice ? (
            <InvoiceStateSelect
              API_domain={props.API_domain}
              secondaryDoc={props.selectedSecondaryDoc}
              invoiceStateMapper={props.invoiceStateMapper}
              onSubmit={props.onSubmit}
            />
          ) : null}
        </Col>
        <Col span={8} align='right'>
          <Popconfirm
            title='Estás seguro de que quieres borrar este documento?'
            onConfirm={(event) => {
              deleteSecondaryDoc();
              event.stopPropagation();
            }}
            onCancel={(event) => event.stopPropagation()}
            okText='Borrar'
            cancelText='Cancelar'
            placement='topRight'
          >
            <Button
              type='secondary'
              style={{ marginRight: 6 }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          <Button
            buttontype='secondary'
            onClick={(e) =>
              downloadImage(e, props.selectedSecondaryDoc.file_url)
            }
          >
            <DownloadOutlined />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
export { SecondaryDocModal };
