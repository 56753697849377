import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { FormattedUSD } from './FormattedUSD.js';

function VisualizationsTable(props) {
  const { chartObj } = props;
  const [hoveredCell, setHoveredCell] = useState(null);

  const currentYear = new Date().getFullYear();
  const allLabels = chartObj?.labels || [];

  // Filter labels for the current year
  const currentYearLabels = allLabels.filter((label) =>
    label.endsWith(currentYear.toString())
  );

  const dataSource = useMemo(() => {
    if (!chartObj) return [];

    const totals = {
      key: 'totals',
      dataset: 'Total',
      monthlyAverage: 0,
      ytd: 0,
    };

    allLabels.forEach((label) => {
      totals[label] = 0;
    });

    const data = chartObj.datasets.map((dataset, index) => {
      const row = {
        key: index,
        dataset: dataset.label,
      };

      let sumForYear = 0;
      let countForYear = 0;

      allLabels.forEach((label, labelIndex) => {
        const value = dataset.data[labelIndex];
        row[label] = value;
        totals[label] += value;

        // Include only current year data for monthlyAverage and ytd
        if (currentYearLabels.includes(label)) {
          sumForYear += value;
          countForYear++;
        }
      });

      row['monthlyAverage'] = countForYear > 0 ? sumForYear / countForYear : 0;
      row['ytd'] = sumForYear;

      // Update totals only for current year
      totals['monthlyAverage'] +=
        countForYear > 0 ? sumForYear / countForYear : 0;
      totals['ytd'] += sumForYear;

      return row;
    });

    data.unshift(totals); // Add totals as the first row
    return data;
  }, [chartObj, allLabels, currentYearLabels]);

  const percentageChanges = useMemo(() => {
    const changes = {};
    dataSource.forEach((row) => {
      changes[row.key] = {};
      let prevValue = null;
      allLabels.forEach((month) => {
        if (prevValue !== null) {
          changes[row.key][month] =
            prevValue === 0
              ? Infinity
              : ((row[month] - prevValue) / prevValue) * 100;
        }
        prevValue = row[month];
      });
    });
    return changes;
  }, [dataSource, allLabels]);

  const cellStyle = (percentageChange, isHovered, prevValue, currentValue) => {
    const style = {};

    if (isHovered) return style;

    const nominalChange = Math.abs(currentValue - prevValue);

    if (nominalChange < 30 || Math.abs(percentageChange) < 50) {
      return style;
    }

    if (nominalChange > 1000 && Math.abs(percentageChange) > 10) {
      style.fontWeight = Math.min(800, 400 + Math.abs(percentageChange) * 0.75);
    }

    const greenIntensity =
      100 + ((215 - 100) * (Math.abs(percentageChange) - 50)) / 50;
    const redIntensity =
      100 + ((255 - 100) * (Math.abs(percentageChange) - 50)) / 50;

    if (percentageChange > 0) {
      style.color = `rgb(${Math.min(255, redIntensity)}, 0, 0)`;
    } else {
      style.color = `rgb(0, ${Math.min(215, greenIntensity)}, 0)`;
    }

    return style;
  };

  const onCell = (record, rowIndex, colIndex) => {
    if (record.key === 'totals') {
      return {};
    }

    const isHovered =
      hoveredCell?.row === rowIndex && hoveredCell?.col === colIndex;

    const hoverStyle = isHovered
      ? { color: 'rgb(116, 116, 205)', cursor: 'pointer' }
      : {};

    return {
      onClick: () => onCellClick(record, rowIndex, colIndex),
      onMouseEnter: () => setHoveredCell({ row: rowIndex, col: colIndex }),
      onMouseLeave: () => setHoveredCell(null),
      style: hoverStyle,
    };
  };

  const onCellClick = (record, rowIndex, colIndex) => {
    const datasetLabel = record.dataset;
    const monthLabel = allLabels[colIndex - 1]; // -1 because the first column is 'dataset'
    props.showModal(datasetLabel, monthLabel);
  };

  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'dataset',
      key: 'dataset',
      render: (text, row) => {
        return row.key === 'totals' ? (
          <div style={{ fontWeight: 'bold' }}>{text}</div>
        ) : (
          text
        );
      },
    },
    ...allLabels.map((month, index) => ({
      title: month,
      dataIndex: month,
      key: month,
      render: (text, row, rowIndex) => {
        const isHovered =
          hoveredCell?.row === rowIndex && hoveredCell?.col === month;

        // Get the previous month's value and the current value
        const prevMonthIndex = index - 1;
        const prevMonth = allLabels[prevMonthIndex];
        const prevValue = row[prevMonth];
        const currentValue = row[month];

        // Call cellStyle with additional parameters
        const style = cellStyle(
          percentageChanges[row.key]?.[month] || 0,
          isHovered,
          prevValue,
          currentValue
        );

        const cellOnHover = onCell(row, rowIndex, month);
        const mergedStyle = { ...style, ...cellOnHover.style };

        if (isHovered) {
          mergedStyle.color = 'rgb(116, 116, 205)';
        }

        return row.key === 'totals' ? (
          <div style={{ fontWeight: 'bold' }}>
            {<FormattedUSD total={text} showCents={false} />}
          </div>
        ) : (
          <div style={mergedStyle}>
            {<FormattedUSD total={text} showCents={false} />}
          </div>
        );
      },
      onCell: (record, rowIndex) => onCell(record, rowIndex, index + 1),
    })),
    {
      title: 'Promedio Mensual este año',
      dataIndex: 'monthlyAverage',
      key: 'monthlyAverage',
      render: (text, row) => {
        const content = <FormattedUSD total={Number(text)} showCents={false} />;
        return {
          props: {
            style: {
              background: 'rgb(240,242,250)',
              borderRight: 'none',
              borderLeft: '1px solid rgb(235,235,235)',
              borderBottom: '1px solid rgb(235,235,235)',
            },
          },
          children: <div>{content}</div>,
        };
      },
      onHeaderCell: () => {
        return {
          style: {
            background: 'rgb(240,242,250)',
            borderRight: 'none',
            borderLeft: '1px solid rgb(235,235,235)',
            borderBottom: '1px solid rgb(235,235,235)',
            fontWeight: '700',
          },
        };
      },
    },
    {
      title: 'Año a la fecha',
      dataIndex: 'ytd',
      key: 'ytd',
      render: (text, row) => {
        const content = <FormattedUSD total={Number(text)} showCents={false} />;
        return {
          props: {
            style: {
              background: 'rgb(240,242,250)',
              borderRight: 'none',
              borderLeft: '1px solid rgb(235,235,235)',
              borderBottom: '1px solid rgb(235,235,235)',
            },
          },
          children: <div>{content}</div>,
        };
      },
      onHeaderCell: () => {
        return {
          style: {
            background: 'rgb(240,242,250)',
            borderRight: 'none',
            borderLeft: '1px solid rgb(235,235,235)',
            borderBottom: '1px solid rgb(235,235,235)',
            fontWeight: '700',
          },
        };
      },
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <Table
        size='small'
        columns={columns}
        dataSource={dataSource}
        style={{ height: '100%', width: '100%' }}
        pagination={false}
      />
    </div>
  );
}

export { VisualizationsTable };
