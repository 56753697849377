import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components/macro';
import { LoadingOutlined } from '@ant-design/icons';
import { authContext } from '../../ProvideAuth.js';
import { FormattedUSD } from '../FormattedUSD.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const obligationOptions = [
  { label: 'ITBMS', value: 202 },
  { label: 'TASA UNICA', value: 315 },
  { label: 'IMPUESTO COMPLEMENTARIO', value: 111 },
  { label: 'JURIDICA: IMPUESTO SOBRE LA RENTA', value: 102 },
  { label: 'PERSONAL: IMPUESTO SOBRE LA RENTA', value: 101 },
];

function AdminPayDGI(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [cardOptions, setCardOptions] = useState([]);
  const [amount, setAmount] = useState(0);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    axios({
      method: 'post',
      url: props.API_domain + 'adminPayDGI',
      data: values,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function adminFetchCardOptions() {
    axios({
      method: 'post',
      url: props.API_domain + 'adminFetchCardOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setCardOptions(
          response.data.map((card) => ({
            label: card[0],
            value: card[1],
          }))
        );
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    if (visible) {
      adminFetchCardOptions();
    }
  }, [visible]);

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Pagar DGI</ButtonSpan>
      </Button>
      <Modal
        title='Pagar DGI'
        width={800}
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>
                Pagar DGI <FormattedUSD total={amount} />
              </>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='db_card_id'
            label='Cardholder'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Select
              showSearch
              options={cardOptions}
              placeholder='Tarjetahabiente'
              filterOption={(input, option) =>
                option.props.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            name='ruc'
            label='RUC'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el RUC',
              },
            ]}
          >
            <Input placeholder='RUC' />
          </Form.Item>
          <Form.Item
            name='nit'
            label='NIT'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el NIT',
              },
            ]}
          >
            <Input placeholder='NIT' />
          </Form.Item>
          <Form.Item
            name='amount'
            label='Amount'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el monto en centavos',
              },
            ]}
          >
            <Input
              placeholder='100.00'
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name='tax_type_id'
            label='Obligación'
            rules={[
              {
                required: true,
                message: 'Seleccione una obligación',
              },
            ]}
          >
            <Select
              options={obligationOptions}
              placeholder='Seleccione una obligación'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminPayDGI };
