import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { downloadBlob, dateTransformer } from '../utils.js'; // Assuming you have a utility for date transformation

const BankSimpleDownloader = (props) => {
  const [transformedData, setTransformedData] = useState([]);

  // Transform the data to match the desired format
  useEffect(() => {
    setTransformedData(
      props.data.map((row) => {
        return [
          dateTransformer(row.Fecha) || row.fechaFormatted, // Apply dateTransformer function for Fecha column
          row.descripcion || row.description, // Descripción column
          row.Credito ? `${row.Credito.toFixed(2)}` : '', // Credito column
          row.Debito ? `${row.Debito.toFixed(2)}` : '', // Debito column
          `${row.balance.toFixed(2)}`, // Saldo column
        ];
      })
    );
  }, [props.data]);

  // Function to export the transformed data to CSV
  const exportToCSV = () => {
    const header = [['Fecha', 'Descripción', 'Crédito', 'Débito', 'Saldo']];
    const csvData = header.concat(transformedData);
    const ws = XLSX.utils.aoa_to_sheet(csvData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const csvBlob = new Blob(
      [XLSX.write(wb, { bookType: 'csv', type: 'array' })],
      { type: 'text/csv;charset=utf-8;' }
    );
    downloadBlob(csvBlob, 'bank_transactions.csv');
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={exportToCSV}>
      Excel
    </Button>
  );
};

export { BankSimpleDownloader };
