import {
  CreditCardOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedUSD } from './FormattedUSD.js';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function parseFloatOrNull(number) {
  const parsedFloat = parseFloat(number);
  if (!isNaN(parsedFloat)) {
    return parsedFloat.toFixed(2);
  } else {
    return null;
  }
}
function ReportedExpenseForm(props) {
  const [amountWithoutItbms, setAmountWithoutItbms] = useState(0);
  const [itbms, setItbms] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    setAmountWithoutItbms(
      parseFloatOrNull(
        parseFloat(props.expense.total) - parseFloat(props.expense.itbms)
      )
    );
    setItbms(parseFloatOrNull(props.expense.itbms));
  }, [props.expense]);

  return (
    <Form {...layout} name='basic' form={form}>
      <Form.Item label='Descripción' name='expenseName'>
        <span>
          <b>{props.expense.descripcion ? props.expense.descripcion : ''}</b>
        </span>
      </Form.Item>
      <Form.Item label='Fecha:'>
        <b>
          {new Intl.DateTimeFormat('es-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }).format(
            new Date(
              props.expense.fecha.split('/')[0],
              props.expense.fecha.split('/')[1] - 1,
              props.expense.fecha.split('/')[2]
            )
          )}
        </b>
      </Form.Item>
      <Form.Item label='Proveedor'>
        <span>
          <b>{props.expense.provider ? props.expense.provider : ''}</b>
        </span>
      </Form.Item>
      <Form.Item label='RUC'>
        <span>
          <b>{props.expense.ruc ? props.expense.ruc : ''}</b>
        </span>
      </Form.Item>
      <Form.Item label='Número de Factura'>
        <span>
          <b>
            {props.expense.receipt_number ? props.expense.receipt_number : ''}
          </b>
        </span>
      </Form.Item>
      <Form.Item label='Categoría'>
        <span>
          <b>{props.expense.category ? props.expense.category : ''}</b>
        </span>
      </Form.Item>
      <Form.Item label='Monto sin impuestos'>
        <b>
          <FormattedUSD total={parseFloatOrNull(+amountWithoutItbms)} />
        </b>
      </Form.Item>
      <Form.Item label='Impuestos'>
        <b>
          <FormattedUSD total={parseFloatOrNull(+itbms)} />
        </b>
      </Form.Item>
      <Form.Item label='Total'>
        <b>
          <FormattedUSD
            total={parseFloatOrNull(+amountWithoutItbms + +itbms)}
          />
        </b>
        {props.expense.card_atm_withdrawal ? (
          <span style={{ marginLeft: 6 }}>🏧</span>
        ) : props.expense.card_transaction ? (
          <span style={{ marginLeft: 6 }}>💳</span>
        ) : (
          ''
        )}
      </Form.Item>
      {/* <Form.Item {...tailLayout}>
        <Row>
          <Col span={20}>
            <Tooltip
              placement='topRight'
              title='No puedes editar un gasto reportado'
            >
              <Button
                type='primary'
                htmlType='submit'
                disabled
                block
                style={{ fontWeight: 500 }}
              >
                Actualizar gasto
              </Button>
            </Tooltip>
          </Col>
          <Col span={4}>
            <Tooltip
              placement='topRight'
              title='No puedes borrar un gasto reportado'
            >
              <Button
                danger
                style={{ margin: '0 4px' }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                block
                disabled={true}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Form.Item> */}
    </Form>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ReportedExpenseForm };
