// AccountingAccountsTable.js

import React, { useState, useEffect, useContext } from 'react';
import { Table, message, Button, Modal, Form, Input, Select } from 'antd';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined, // Added import for delete icon
} from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios.js';
import { authContext } from '../../ProvideAuth.js';
import { AccountingFinancialStatementsModal } from './AccountingFinancialStatementsModal.js';

function AccountingAccountsTable({ API_domain, accountingClientCompany }) {
  const auth = useContext(authContext);
  const [accountsData, setAccountsData] = useState([]);
  const [flattenedAccounts, setFlattenedAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newAccountName, setNewAccountName] = useState('');
  const [selectedParentAccountId, setSelectedParentAccountId] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [parentAccountType, setParentAccountType] = useState('');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editedAccountId, setEditedAccountId] = useState(null);
  const [editedAccountName, setEditedAccountName] = useState('');
  const [editedAccountType, setEditedAccountType] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false); // New state for delete modal
  const [accountToDelete, setAccountToDelete] = useState(null); // Account selected for deletion

  const [isEntriesModalVisible, setIsEntriesModalVisible] = useState(false);
  const [entriesModalContent, setEntriesModalContent] = useState({});

  const accountTypeLabels = {
    bank: { label: 'Banco', category: 'Activo' },
    cash: { label: 'Efectivo', category: 'Activo' },
    current_asset: { label: 'Activo Corriente', category: 'Activo' },
    other_current_asset: { label: 'Otro Activo Corriente', category: 'Activo' },
    other_asset: { label: 'Otro Activo', category: 'Activo' },
    fixed_asset: { label: 'Activo Fijo', category: 'Activo' },
    credit_card: { label: 'Tarjeta de Crédito', category: 'Pasivo' },
    current_liability: { label: 'Pasivo Corriente', category: 'Pasivo' },
    other_current_liability: {
      label: 'Otro Pasivo Corriente',
      category: 'Pasivo',
    },
    long_term_liability: { label: 'Pasivo a Largo Plazo', category: 'Pasivo' },
    other_liability: { label: 'Otro Pasivo', category: 'Pasivo' },
    equity: { label: 'Capital', category: 'Capital' },
    income: { label: 'Ingreso', category: 'Ingresos' },
    sales: { label: 'Ventas', category: 'Ingresos' },
    other_income: { label: 'Otros Ingresos', category: 'Ingresos' },
    expense: { label: 'Gastos', category: 'Gastos' },
    cost_of_goods_sold: {
      label: 'Costo de Mercancías Vendidas',
      category: 'Gastos',
    },
    other_expense: { label: 'Otros Gastos', category: 'Gastos' },
  };

  const accountTypes = Object.keys(accountTypeLabels);

  // Define the accounting categories in order
  const accountCategoryOrder = [
    'Assets',
    'Liabilities',
    'Equity',
    'Income',
    'Expense',
  ];

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await HeroAxios.post('getAccountHierarchy', {
        client_id: accountingClientCompany.accounting_client_id,
      });
      const sortedAccounts = sortAccounts(response.data.accounts);
      setAccountsData(sortedAccounts);
      setFlattenedAccounts(flattenAccounts(sortedAccounts));
    } catch (error) {
      console.error('Error fetching account hierarchy:', error);
      message.error('Error al obtener la jerarquía de cuentas');
    }
    setLoading(false);
  };
  const handleAccountNameClick = (record) => {
    setEntriesModalContent({
      account_id: record.id,
      subcategory: record.zoho_account_name,
      // We don't provide 'month', so the modal shows all entries
    });
    setIsEntriesModalVisible(true);
  };

  const handleCreateAccount = async () => {
    if (!newAccountName) {
      message.error('Por favor ingrese el nombre de la cuenta');
      return;
    }
    if (!selectedAccountType) {
      message.error('Por favor seleccione el tipo de cuenta');
      return;
    }
    setLoading(true);
    try {
      await HeroAxios.post('createAccount', {
        client_id: accountingClientCompany.accounting_client_id,
        zoho_account_name: newAccountName,
        parent_account_id: selectedParentAccountId,
        zoho_account_type: selectedAccountType,
      });
      message.success('Cuenta creada exitosamente');
      setIsModalVisible(false);
      setNewAccountName('');
      setSelectedParentAccountId(null);
      setSelectedAccountType('');
      setParentAccountType('');
      fetchAccounts(); // refresh accounts data
    } catch (error) {
      console.error('Error creating account:', error);
      message.error('Error al crear la cuenta');
    }
    setLoading(false);
  };

  const handleEditAccount = async () => {
    if (!editedAccountName) {
      message.error('Por favor ingrese el nombre de la cuenta');
      return;
    }
    if (!editedAccountType) {
      message.error('Por favor seleccione el tipo de cuenta');
      return;
    }
    try {
      await HeroAxios.post('editAccount', {
        account_id: editedAccountId,
        zoho_account_name: editedAccountName,
        zoho_account_type: editedAccountType,
      });
      message.success('Cuenta actualizada exitosamente');
      setIsEditModalVisible(false);
      setEditedAccountId(null);
      setEditedAccountName('');
      setEditedAccountType('');
      fetchAccounts(); // refresh accounts data
    } catch (error) {
      console.error('Error updating account:', error);
      message.error('Error al actualizar la cuenta');
    }
  };

  const handleDeleteAccount = async () => {
    if (!accountToDelete) {
      message.error('No hay cuenta seleccionada para eliminar');
      return;
    }
    try {
      await HeroAxios.post('deleteAccount', {
        account_id: accountToDelete.id,
      });
      message.success('Cuenta eliminada exitosamente');
      setIsDeleteModalVisible(false);
      setAccountToDelete(null);
      fetchAccounts(); // refresh accounts data
    } catch (error) {
      console.error('Error deleting account:', error);
      message.error('Error al eliminar la cuenta');
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [API_domain, accountingClientCompany, auth]);

  const handleExpand = (expanded, record) => {
    setExpandedKeys((prevKeys) => {
      if (expanded) {
        return [...prevKeys, record.id];
      } else {
        return prevKeys.filter((key) => key !== record.id);
      }
    });
  };

  // Expand all rows
  const handleExpandAll = () => {
    const getAllKeys = (nodes) => {
      let keys = [];
      nodes.forEach((node) => {
        keys.push(node.id);
        if (node.children) {
          keys = keys.concat(getAllKeys(node.children));
        }
      });
      return keys;
    };
    setExpandedKeys(getAllKeys(accountsData));
  };

  // Collapse all rows
  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  // Define table columns
  const columns = [
    {
      title: 'Nombre de la Cuenta',
      dataIndex: 'zoho_account_name',
      key: 'zoho_account_name',
      render: (text, record) =>
        record.is_category ? (
          <strong>{text}</strong>
        ) : (
          <a onClick={() => handleAccountNameClick(record)}>{text}</a>
        ),
    },
    {
      title: 'Tipo de Cuenta',
      dataIndex: 'zoho_account_type',
      key: 'zoho_account_type',
      render: (text) =>
        accountTypeLabels[text]
          ? `${accountTypeLabels[text].label} - ${accountTypeLabels[text].category}`
          : text,
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => {
        // Accounts with children or root accounts must not be editable or deletable
        if (
          (record.children && record.children.length > 0) ||
          rootAccountNames.includes(record.zoho_account_name)
        ) {
          return null;
        }
        return (
          <span style={{ display: 'flex', gap: '8px' }}>
            <Button
              onClick={() => showEditAccountModal(record)}
              style={{ border: 'none' }}
            >
              <EditOutlined /> Editar
            </Button>
            <Button
              onClick={() => showDeleteAccountModal(record)}
              danger
              style={{ border: 'none' }}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const handleParentAccountChange = (value) => {
    setSelectedParentAccountId(value);
    const parentAccount = flattenedAccounts.find(
      (account) => account.id === value
    );
    if (parentAccount) {
      setParentAccountType(parentAccount.zoho_account_type);
      setSelectedAccountType(parentAccount.zoho_account_type);
    }
  };

  const rootAccountNames = [
    'Assets',
    'Liabilities',
    'Equity',
    'Income',
    'Expense',
  ];

  const flattenAccounts = (accounts) => {
    let flatList = [];
    accounts.forEach((account) => {
      // Skip root categories
      if (!rootAccountNames.includes(account.zoho_account_name)) {
        flatList.push(account);
      }
      if (account.children) {
        flatList = flatList.concat(flattenAccounts(account.children));
      }
    });
    return flatList;
  };

  // Function to sort accounts based on accounting principles
  const sortAccounts = (accounts) => {
    if (!accounts || accounts.length === 0) return [];

    // Sort root accounts based on predefined order
    accounts.sort((a, b) => {
      const indexA = accountCategoryOrder.indexOf(a.zoho_account_name);
      const indexB = accountCategoryOrder.indexOf(b.zoho_account_name);
      if (indexA === -1 && indexB === -1) {
        return a.zoho_account_name.localeCompare(b.zoho_account_name);
      }
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });

    // Recursively sort child accounts
    accounts.forEach((account) => {
      if (account.children && account.children.length > 0) {
        account.children = sortAccounts(account.children);
      }
    });

    return accounts;
  };

  const showCreateAccountModal = () => {
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setNewAccountName('');
    setSelectedParentAccountId(null);
    setSelectedAccountType('');
    setParentAccountType('');
  };

  const showEditAccountModal = (record) => {
    setEditedAccountId(record.id);
    setEditedAccountName(record.zoho_account_name);
    setEditedAccountType(record.zoho_account_type);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setEditedAccountId(null);
    setEditedAccountName('');
    setEditedAccountType('');
  };

  const showDeleteAccountModal = (record) => {
    setAccountToDelete(record);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setAccountToDelete(null);
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Button style={{ marginRight: 8 }} onClick={handleCollapseAll}>
          <FullscreenExitOutlined /> Colapsar
        </Button>
        <Button style={{ marginRight: 8 }} onClick={handleExpandAll}>
          <FullscreenOutlined /> Expandir
        </Button>
        <Button
          type='primary'
          style={{ marginRight: 8 }}
          onClick={showCreateAccountModal}
        >
          <PlusOutlined /> Crear nueva cuenta
        </Button>
      </div>
      <Modal
        title='Crear nueva cuenta'
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleCreateAccount}
        okText='Crear'
        cancelText='Cancelar'
      >
        <Form layout='vertical'>
          <Form.Item label='Nombre de la cuenta' required>
            <Input
              value={newAccountName}
              onChange={(e) => setNewAccountName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Cuenta padre'>
            <Select
              value={selectedParentAccountId}
              onChange={handleParentAccountChange}
              placeholder='Seleccione una cuenta padre (opcional)'
            >
              {flattenedAccounts.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.zoho_account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Tipo de cuenta' required>
            <Select
              value={selectedAccountType}
              onChange={(value) => setSelectedAccountType(value)}
              placeholder='Seleccione un tipo de cuenta'
            >
              {accountTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {`${accountTypeLabels[type].label} - ${accountTypeLabels[type].category}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='Editar cuenta'
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        onOk={handleEditAccount}
        okText='Guardar cambios'
        cancelText='Cancelar'
      >
        <Form layout='vertical'>
          <Form.Item label='Nombre de la cuenta' required>
            <Input
              value={editedAccountName}
              onChange={(e) => setEditedAccountName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Tipo de cuenta' required>
            <Select
              value={editedAccountType}
              onChange={(value) => setEditedAccountType(value)}
              placeholder='Seleccione un tipo de cuenta'
              disabled={true}
            >
              {accountTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {`${accountTypeLabels[type].label} - ${accountTypeLabels[type].category}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='Eliminar cuenta'
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        onOk={handleDeleteAccount}
        okText='Eliminar'
        cancelText='Cancelar'
      >
        <p>
          ¿Está seguro de que desea eliminar la cuenta{' '}
          <strong>{accountToDelete?.zoho_account_name}</strong>?
        </p>
      </Modal>
      <AccountingFinancialStatementsModal
        API_domain={API_domain}
        accountingClientCompany={accountingClientCompany}
        modalContent={entriesModalContent}
        isModalVisible={isEntriesModalVisible}
        onCancel={() => setIsEntriesModalVisible(false)}
        onOk={() => setIsEntriesModalVisible(false)}
      />
      <Table
        dataSource={accountsData}
        columns={columns}
        loading={loading}
        pagination={false}
        style={{ width: '800px' }}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpand: handleExpand,
          rowExpandable: (record) =>
            record.children && record.children.length > 0,
          // Custom expand icon to hide for records without children
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record.children && record.children.length > 0) {
              return (
                <span
                  onClick={(e) => onExpand(record, e)}
                  style={{ marginRight: 8, cursor: 'pointer' }}
                >
                  {expanded ? '-' : '+'}
                </span>
              );
            } else {
              return <span style={{ marginRight: 8 }} />;
            }
          },
        }}
        rowKey={(record) => record.id}
      />
    </div>
  );
}

export { AccountingAccountsTable };
