import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Spin, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { ConfigAddExistingMemberSelect } from './ConfigAddExistingMemberSelect.js';

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

function ConfigAddUserToTeam(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [selectedMemberObject, setSelectedMemberObject] = useState(null);
  const auth = useContext(authContext);
  const [form] = Form.useForm();

  let initialValues;

  function formatPhoneNumber(input) {
    let countryFlag;
    let phoneNumber;

    if (input.startsWith('whatsapp:+507')) {
      countryFlag = '🇵🇦';
      phoneNumber = input.replace('whatsapp:+507', '');
    } else if (input.startsWith('whatsapp:+57')) {
      countryFlag = '🇨🇴';
      phoneNumber = input.replace('whatsapp:+57', '');
    } else if (input.startsWith('whatsapp:+1')) {
      countryFlag = '🇺🇸';
      phoneNumber = input.replace('whatsapp:+1', '');
    } else if (input.startsWith('whatsapp:+34')) {
      countryFlag = '🇪🇸';
      phoneNumber = input.replace('whatsapp:+34', '');
    } else if (input.startsWith('whatsapp:+55')) {
      countryFlag = '🇧🇷';
      phoneNumber = input.replace('whatsapp:+55', '');
    } else if (input.startsWith('whatsapp:+971')) {
      countryFlag = '🇦🇪';
      phoneNumber = input.replace('whatsapp:+971', '');
    } else if (input.startsWith('whatsapp:+58')) {
      countryFlag = '🇻🇪';
      phoneNumber = input.replace('whatsapp:+58', '');
    } else if (input.startsWith('whatsapp:+39')) {
      countryFlag = '🇮🇹';
      phoneNumber = input.replace('whatsapp:+39', '');
    } else if (input.startsWith('whatsapp:+44')) {
      countryFlag = '🇬🇧';
      phoneNumber = input.replace('whatsapp:+44', '');
    } else {
      return 'Número inválido';
    }

    return countryFlag + ' ' + phoneNumber;
  }

  function getUserObject(userId) {
    axios({
      method: 'post',
      url: props.API_domain + 'getUserObject',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        user_id: selectedMemberId,
      },
    })
      .then((response) => {
        setSelectedMemberObject(response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    if (selectedMemberId) {
      getUserObject(selectedMemberId);
    }
  }, [selectedMemberId]);

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      team: props.selectedTeam,
      user_id: selectedMemberId,
      expense_creator: values.expense_creator,
      approver: values.approver,
      email_on_creation: values.email_on_creation,
      email_on_approval: values.email_on_approval,
      email_on_reimbursement: values.email_on_reimbursement,
    };
    axios({
      method: 'post',
      url: props.API_domain + props.API_endPoint,
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.onSubmit();
        props.handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <h4>Selecciona un usuario existente para agregar al equipo:</h4>
      <div style={{ marginBottom: 10 }}>
        <ConfigAddExistingMemberSelect
          API_domain={props.API_domain}
          setSelectedMemberId={setSelectedMemberId}
          team={props.selectedTeam}
        />
      </div>
      <div
        style={selectedMemberId ? {} : { opacity: 0.5, pointerEvents: 'none' }}
      >
        <Form
          {...layout}
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          style={{ paddingTop: '20px' }}
        >
          <Form.Item label='Número de celular'>
            <Tooltip
              title='Un admin de tu compañía puede editar el número desde la pestaña de Usuarios'
              placement='right'
            >
              {selectedMemberObject
                ? formatPhoneNumber(selectedMemberObject.phone)
                : ''}
            </Tooltip>
          </Form.Item>
          <Form.Item label='Email'>
            <Tooltip
              title='Un admin de tu compañía puede editar el correo desde la pestaña de Usuarios'
              placement='right'
            >
              {selectedMemberObject ? selectedMemberObject.email : ''}
            </Tooltip>
          </Form.Item>
          <Form.Item
            name='expense_creator'
            valuePropName='checked'
            label='Creador de gastos'
          >
            <Checkbox
              style={{
                lineHeight: '32px',
              }}
            />
          </Form.Item>
          <Form.Item
            name='approver'
            valuePropName='checked'
            label='Aprobador de reportes'
          >
            <Checkbox
              style={{
                lineHeight: '32px',
              }}
            />
          </Form.Item>
          <Form.Item
            name='email_on_creation'
            valuePropName='checked'
            label='Notificar creación'
          >
            <Checkbox
              style={{
                lineHeight: '32px',
              }}
            />
          </Form.Item>
          <Form.Item
            name='email_on_approval'
            valuePropName='checked'
            label='Notificar aprobación'
          >
            <Checkbox
              style={{
                lineHeight: '32px',
              }}
            />
          </Form.Item>
          <Form.Item
            name='email_on_reimbursement'
            valuePropName='checked'
            label='Notificar reembolso'
          >
            <Checkbox
              style={{
                lineHeight: '32px',
              }}
            />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : 'Agregar a equipo'}
          </Button>
        </Form>
      </div>
    </>
  );
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigAddUserToTeam };
