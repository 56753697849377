import {
  CreditCardOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Empty, Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { authContext } from '../ProvideAuth.js';
import { CardsTableBalanceComponent } from './CardsTableBalanceComponent.js';
import { CardsTableSpentThisMonthComponent } from './CardsTableSpentThisMonthComponent.js';
import { CardStatus } from './CardStatus.js';
import { CardsTableNewCardModal } from './CardsTableNewCardModal.js';
import { FormattedUSD } from './FormattedUSD.js';

function CardsTable(props) {
  const [error, setError] = useState(null);
  const [basicCardDetails, setBasicCardDetails] = useState([]);
  const [fullCardDetails, setFullCardDetails] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totals, setTotals] = useState({ totalBalance: 0, totalSpent: 0 });
  const [balances, setBalances] = useState({});
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const auth = useContext(authContext);

  const fetchBasicDetails = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getBasicCardDetails',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setBasicCardDetails(response.data);
        props.setLoaded(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const fetchFinancialDetails = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getFullCardDetails',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        const fullCardDetails = {};
        response.data.forEach((card) => {
          fullCardDetails[card.id] = card;
        });
        setFullCardDetails(fullCardDetails);
      })
      .catch((error) => {
        console.error('Failed to fetch financial details', error);
      });
  };

  useEffect(() => {
    props.setLoaded(false);
    fetchBasicDetails();
    fetchFinancialDetails();
  }, [props.team, props.submittedToggle]);

  useEffect(() => {
    if (Object.keys(fullCardDetails).length > 0) {
      const totalSpent = Object.values(fullCardDetails).reduce(
        (acc, card) => acc + card.spent_this_month,
        0
      );
      setTotals((prevTotals) => ({ ...prevTotals, totalSpent }));
    }
  }, [fullCardDetails]);

  const handleBalanceLoad = (cardId, balance) => {
    setBalances((prevBalances) => {
      const newBalances = { ...prevBalances, [cardId]: balance };
      const totalBalance = Object.values(newBalances).reduce(
        (acc, balance) => acc + balance,
        0
      );
      setTotals((prevTotals) => ({ ...prevTotals, totalBalance }));
      return newBalances;
    });
  };

  let columns = [];
  {
    props.mobile
      ? (columns = [
          // MOBILE
        ])
      : (columns = [
          {
            title: 'Tarjetahabiente',
            key: 'name',
            dataIndex: 'name',
            width: 240,
            render: (name, card) => {
              return (
                <>
                  <div>{name}</div>
                  <div style={{ color: 'grey' }}>
                    {card.id !== 'totals' && (
                      <Tooltip
                        placement='left'
                        title='Equipo predeterminado al que entran los gastos de esta tarjeta.'
                      >
                        {card.default_team}
                      </Tooltip>
                    )}
                  </div>
                </>
              );
            },
          },
          {
            title: 'Número de tarjeta',
            dataIndex: 'pan_last_four',
            key: 'pan_last_four',
            width: 240,
            render: (pan_last_four, record) => {
              return record.id !== 'totals' ? (
                <>
                  <span style={{ color: 'grey', marginRight: 8 }}>
                    <CreditCardOutlined />
                  </span>
                  <span
                    style={{ fontSize: 16, fontWeight: 'bold', color: 'grey' }}
                  >
                    ••••{' '}
                  </span>
                  {pan_last_four}
                </>
              ) : null;
            },
          },
          {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            width: 200,
            render: (balance, item) => {
              return item.id !== 'totals' ? (
                <CardsTableBalanceComponent
                  API_domain={props.API_domain}
                  card_id={item.id}
                  onLoad={(balance) => handleBalanceLoad(item.id, balance)}
                />
              ) : (
                <FormattedUSD total={totals.totalBalance} />
              );
            },
          },
          {
            title: 'Gastado este mes',
            dataIndex: 'spent_this_month',
            key: 'spent_this_month',
            width: 200,
            render: (text, record) => {
              return record.id !== 'totals' ? (
                <FormattedUSD
                  total={fullCardDetails[record.id]?.spent_this_month}
                />
              ) : (
                <FormattedUSD total={totals.totalSpent} />
              );
            },
          },
          {
            title: 'Estado',
            dataIndex: 'card_blocked',
            key: 'card_blocked',
            render: (card_blocked, item) => {
              return item.id !== 'totals' ? (
                <CardStatus
                  API_domain={props.API_domain}
                  card_id={item.id}
                  cardBlocked={card_blocked}
                  shouldRefresh={props.refreshCardStatusForCardId == item.id}
                />
              ) : null;
            },
          },
        ]);
  }

  const dataSource = [
    ...basicCardDetails,
    {
      id: 'totals',
      name: 'Totales',
      pan_last_four: null,
      balance: totals.totalBalance,
      spent_this_month: totals.totalSpent,
      card_blocked: null,
    },
  ];

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!props.isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
        pagination={false}
        onRow={(card, item) => {
          return {
            onClick: () => {
              if (card.id !== 'totals') {
                props.setSelectedCard(card);
              }
            },
          };
        }}
        rowClassName={(record, index) => {
          return 'cursor-pointer';
        }}
        locale={{
          emptyText: (
            <Empty
              style={{ paddingBottom: '20vh' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <div style={{ marginBottom: 14 }}>
                    Aún no tienes tarjetas.
                  </div>
                  {props.isAdmin && (
                    <>
                      <Button
                        type='primary'
                        style={{
                          fontWeight: 500,
                          marginBottom: 10,
                        }}
                        onClick={showModal}
                      >
                        <PlusOutlined /> Crear tarjeta
                      </Button>
                      <CardsTableNewCardModal
                        API_domain={props.API_domain}
                        visible={isModalVisible}
                        handleCancel={handleCancel}
                        handleOk={handleOk}
                        team={props.team}
                      />
                      <br></br>
                      <br></br>
                    </>
                  )}
                  <img
                    src='Tarjeta Hero.png'
                    style={{ width: '40%', borderRadius: '10px' }}
                  ></img>
                </>
              }
            />
          ),
        }}
      />
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { CardsTable };
