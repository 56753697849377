import React, { useContext } from 'react';
import { authContext } from '../ProvideAuth.js';
import { HomeSiderExpMan } from './HomeSiderExpMan.js';
import { HomeSiderAccountant } from './HomeSiderAccountant.js';
import { HomeSiderAccountingClient } from './HomeSiderAccountingClient.js';

const HomeSiderTriage = (props) => {
  const auth = useContext(authContext);
  console.log(
    'auth.adminToken',
    auth.adminToken ? auth.adminToken.slice(0, 10) + '...' : 'NA'
  );
  console.log(
    'auth.showAccountingFeatures',
    auth.showAccountingFeatures ? auth.showAccountingFeatures : 'NA'
  );
  if (auth.adminToken) {
    return <HomeSiderAccountant {...props} />;
  } else if (auth.showAccountingFeatures) {
    return <HomeSiderAccountingClient {...props} />;
  } else {
    return <HomeSiderExpMan {...props} />;
  }
};

export { HomeSiderTriage };
