import React, { useState, useEffect } from 'react';
import { Table, Button, message, Spin, Input, Typography, Select } from 'antd';
import HeroAxios from '../helpers/HeroAxios.js';
import styled from 'styled-components/macro';

const { Text } = Typography;
const { Option } = Select;

function ConfigCompaniesTable() {
  const [companies, setCompanies] = useState([]);
  const [accountingClients, setAccountingClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch Accounting Clients
        const clientsResponse = await HeroAxios.get('getAccountingClients');
        setAccountingClients(clientsResponse.data);

        // Fetch Companies with Accounting Clients
        const companiesResponse = await HeroAxios.get('getAccountingClientCompanies');
        setCompanies(companiesResponse.data);
      } catch (error) {
        message.error('Error fetching data');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (value, recordKey, fieldName) => {
    setCompanies(prevCompanies =>
      prevCompanies.map(company => {
        if (company.id === recordKey) {
          return { ...company, [fieldName]: value };
        }
        return company;
      })
    );
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await HeroAxios.post('saveCompaniesBulk', { payload: companies });
      message.success('Companies updated successfully');
    } catch (error) {
      message.error('Error saving companies data');
      console.error('Error saving companies data:', error);
    } finally {
      setSaving(false);
    }
  };

  const columns = [
    {
      title: 'Company ID',
      dataIndex: 'id',
      key: 'company_id',
      width: 70,
      sorter: (a, b) => a.id - b.id,
      render: (text) => <Text style={{ fontSize: '11px' }}>{text}</Text>,
    },
    {
      title: 'RUC',
      dataIndex: 'ruc',
      key: 'ruc',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.id, 'ruc')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
    {
      title: 'Accounting Client',
      dataIndex: 'accounting_client_name',
      key: 'accounting_client_name',
      render: (text, record) => (
        <Select
          value={record.accounting_client_id}
          onChange={(value) => handleInputChange(value, record.id, 'accounting_client_id')}
          style={{ width: '100%', fontSize: '11px' }}
        >
          {accountingClients.map(client => (
            <Option key={client.id} value={client.id}>{client.name}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.id, 'name')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
    {
      title: 'PayCaddy Wallet ID',
      dataIndex: 'paycaddy_wallet_id',
      key: 'paycaddy_wallet_id',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.id, 'paycaddy_wallet_id')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
    {
      title: 'PayCaddy User ID',
      dataIndex: 'paycaddy_user_id',
      key: 'paycaddy_user_id',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.id, 'paycaddy_user_id')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
    {
      title: 'Logo Image URL',
      dataIndex: 'logo_image_url',
      key: 'logo_image_url',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.id, 'logo_image_url')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
    {
      title: 'Default PayCaddy Card ID',
      dataIndex: 'default_paycaddy_card_id',
      key: 'default_paycaddy_card_id',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.id, 'default_paycaddy_card_id')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
  ];

  // Filter data based on search term
  const filteredData = companies.filter(company => {
    const term = searchTerm.toLowerCase();
    return (
      company.id.toString().includes(term) ||
      company.name?.toLowerCase().includes(term) ||
      company.ruc?.toLowerCase().includes(term)
    );
  });

  if (loading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  return (
    <>
      <SearchContainer>
        <Input.Search
          placeholder="Search by ID, Name, or RUC"
          onSearch={(value) => setSearchTerm(value)}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
      </SearchContainer>
      <StyledTableContainer>
        <div style={{ overflowX: 'auto' }}>
          <StyledText>
            Edit company information
          </StyledText>
          <StyledTable
            columns={columns}
            dataSource={filteredData}
            rowKey="id"
            pagination={{
              ...pagination,
              total: filteredData.length,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} records`,
              onChange: (page, pageSize) =>
                setPagination({ current: page, pageSize }),
            }}
            bordered
            scroll={{ x: 'max-content', y: 400 }}
          />
          <br />
          <StyledText>
            Press "Save Changes" to save this configuration
          </StyledText>
        </div>
        <SaveButtonContainer>
          <Button type="primary" onClick={handleSave} loading={saving}>
            Save Changes
          </Button>
        </SaveButtonContainer>
      </StyledTableContainer>
    </>
  );
}

// Styled components
const SearchContainer = styled.div`
  text-align: left;
  margin-bottom: 16px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 100px;
`;

const StyledTableContainer = styled.div`
  margin: 20px 0;
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 4px;
    font-size: 11px;
  }
`;

const StyledText = styled(Text)`
  display: block;
  text-align: left;
  margin-bottom: 12px;
  font-size: 12px;
`;

const SaveButtonContainer = styled.div`
  text-align: right;
  margin-top: 10px;
`;

export { ConfigCompaniesTable };
