import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal, Popconfirm, Spin, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { NewReportFormExpensesTable } from './NewReportFormExpensesTable.js';

function NewReportForm(props) {
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);
  const [showPopConfirmAmount0, setShowPopConfirmAmount0] = useState(false);
  const [showPopConfirmMixedExpenses, setShowPopConfirmMixedExpenses] =
    useState(false);
  const [comments, setComments] = useState('');
  const [title, setTitle] = useState('');

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    let totals = selectedExpenses.map((a) => Math.abs(parseFloat(a.total)));
    setShowPopConfirmAmount0(totals.includes(0));

    let card_transaction_types = selectedExpenses.map(
      (x) => x.card_transaction
    );
    setShowPopConfirmMixedExpenses(
      card_transaction_types.includes(true) &&
        card_transaction_types.includes(false)
    );
  }, [selectedExpenses]);

  useEffect(() => {
    props.selectedExpensesInMassEdit &&
      setSelectedExpenses(props.selectedExpensesInMassEdit);
  }, [props.selectedExpensesInMassEdit]);

  const onFinish = (values) => {
    if (showPopConfirmAmount0 || showPopConfirmMixedExpenses) {
      return;
    }
    if (selectedExpenses.length == 0) {
      message.error('Selecciona al menos un gasto para crear un reporte');
      return;
    }
    for (let i = 0; i < selectedExpenses.length; i++) {
      if (selectedExpenses[i].processing == true) {
        message.error(
          'No puedes crear un reporte con un gasto que esté en proceso'
        );
        return;
      }
    }
    setSubmitting(true);
    const data = {
      comments: comments,
      title: title,
      expenses_ids: selectedExpenses.map((a) => a.id),
      team: props.team,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'createReport',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.onSubmit();
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const onPopconfirm = () => {
    setShowPopConfirmAmount0(false);
    setShowPopConfirmMixedExpenses(false);

    if (selectedExpenses.length == 0) {
      message.error('Selecciona al menos un gasto para crear un reporte');
      return;
    }
    for (let i = 0; i < selectedExpenses.length; i++) {
      if (selectedExpenses[i].processing == true) {
        message.error(
          'No puedes crear un reporte con un gasto que esté en proceso'
        );
        return;
      }
    }
    setSubmitting(true);
    const data = {
      comments: comments,
      title: title,
      expenses_ids: selectedExpenses.map((a) => a.id),
      totals: selectedExpenses.map((a) => a.total),
      team: props.team,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'createReport',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.onSubmit();
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <>
      {props.team && props.team != 'all' ? (
        <Button type='primary' onClick={showModal}>
          <ButtonSpan>
            <>
              <PlusOutlined /> Crear Reporte
            </>
          </ButtonSpan>
        </Button>
      ) : (
        <Tooltip
          placement='topRight'
          title='Por favor selecciona un equipo antes de crear un reporte'
        >
          <Button
            type='primary'
            onClick={showModal}
            disabled={true}
            // onMouseEnter={() => {props.setHighilightedColor('red')}}
            // onMouseLeave={() => {props.setHighilightedColor('transparent');}}
          >
            <ButtonSpan>
              <>
                <PlusOutlined /> Crear Reporte
              </>
            </ButtonSpan>
          </Button>
        </Tooltip>
      )}
      <Modal
        title='Crear Reporte'
        visible={visible}
        footer={
          <>
            <Input
              placeholder='Comentarios del reporte'
              onChange={(event) => setComments(event.target.value)}
            />
            <Popconfirm
              title={
                <span>
                  {showPopConfirmAmount0 ? (
                    <div style={{ marginBottom: 10 }}>
                      Tienes al menos 1 gasto con monto $0.00.
                    </div>
                  ) : (
                    ''
                  )}
                  {showPopConfirmMixedExpenses ? (
                    <div>
                      Seleccionaste gastos de <b>tarjeta</b> y de{' '}
                      <b>efectivo</b>.
                    </div>
                  ) : (
                    ''
                  )}
                  <div style={{ marginTop: 30 }}>
                    ¿Quieres crear el reporte de todos modos?
                  </div>
                </span>
              }
              onConfirm={onPopconfirm}
              onCancel={(event) => event.stopPropagation()}
              okText='Crear'
              cancelText='No crear'
              placement='top'
              disabled={!showPopConfirmAmount0 && !showPopConfirmMixedExpenses} // enabled if showPopConfirmAmout0 or showPopConfirmMixedExpenses
            >
              <Button
                type='primary'
                htmlType='submit'
                block
                style={{
                  fontWeight: 500,
                  marginTop: 10,
                  marginBottom: 10,
                }}
                onClick={onFinish} //TODO
              >
                {isSubmitting ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <>Crear reporte</>
                )}
              </Button>
            </Popconfirm>
          </>
        }
        onCancel={handleCancel}
        width={1400}
        centered
        bodyStyle={{ height: '60vh' }}
      >
        <Input
          placeholder='Título del reporte'
          onChange={(event) => setTitle(event.target.value)}
          style={{ width: '100%' }}
        />
        <br />
        <br />
        <h4>Selecciona gastos:</h4>
        <NewReportFormExpensesTable
          API_domain={props.API_domain}
          submittedToggle={props.submittedToggle}
          setSelectedExpenses={setSelectedExpenses}
          selectedExpenses={selectedExpenses}
          team={props.team}
          mobile={props.mobile}
          filters={props.filters}
          selectedExpensesInMassEdit={props.selectedExpensesInMassEdit}
        />
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { NewReportForm };
