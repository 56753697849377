import {
  CreditCardOutlined,
  LoadingOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Image, Table, Tag, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { FormattedUSD } from './FormattedUSD.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { get_moments_from_month_name } from '../utils.js';

function ImgOrPDF(props) {
  const [isImage, setIsImage] = useState(true);

  if (isImage) {
    return (
      <Image
        width={16}
        height={24}
        src={props.record.factura}
        onError={(event) => setIsImage(false)}
        preview={false}
      />
    );
  } else {
    return (
      <PdfDiv>
        <AWithAlign href={props.record} target='_blank'>
          Link
        </AWithAlign>
      </PdfDiv>
    );
  }
}

const colors = ['orange', 'green', 'cyan', 'blue', 'purple'];

function FinishedReportTable(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const auth = useContext(authContext);

  let category_to_color = {};
  let counter = 0;
  let index = 1;
  items.forEach(function (item) {
    item.index = index;
    index += 1;
    if (!(item.category in category_to_color)) {
      category_to_color[item.category] = colors[counter];
      counter += 1;
    }
    if (item.fecha) {
      item.fechaFormatted = new Intl.DateTimeFormat('es-US', {
        dateStyle: 'medium',
      }).format(
        new Date(
          item.fecha.split('/')[0],
          item.fecha.split('/')[1] - 1,
          item.fecha.split('/')[2]
        )
      );
    }
    item.fechaFormattedShort = new Intl.DateTimeFormat('es-US', {
      day: 'numeric',
      month: 'short',
    }).format(
      new Date(
        item.fecha.split('/')[0],
        item.fecha.split('/')[1] - 1,
        item.fecha.split('/')[2]
      )
    );
  });
  let gastosColumns = [];
  {
    props.mobile
      ? (gastosColumns = [
          {
            title: '',
            dataIndex: 'index',
            key: 'id',
            width: 35,
          },
          {
            dataIndex: ['descripcion', 'category', 'gastante'],
            key: 'descripcion',
            ellipsis: {
              showTitle: false,
            },
            render: (text, record) => (
              <div>
                <div>
                  <Tooltip placement='topLeft' title={record.descripcion}>
                    {record.descripcion}
                  </Tooltip>
                </div>
                <div style={{ paddingTop: 6 }}>
                  {record.category ? (
                    <Tag color={category_to_color[record.category]}>
                      {record.category}
                    </Tag>
                  ) : (
                    <Tag>N/A</Tag>
                  )}
                </div>
                <div style={{ paddingTop: 6 }}>
                  <b>{record.gastante}</b>
                </div>
              </div>
            ),
          },
          {
            title: 'Fecha',
            dataIndex: 'fechaFormattedShort',
            key: 'fecha',
            width: 80,
          },
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 75,
            sorter: (a, b) => a.total - b.total,
            sortDirections: ['ascend', 'descend'],
            render: (total) => {
              return <FormattedUSD total={total} />;
            },
          },
        ])
      : (gastosColumns = [
          // DESKTOP)
          {
            title: '',
            dataIndex: 'index',
            key: 'id',
            width: 35,
          },
          {
            dataIndex: 'card',
            key: 'card',
            width: 30,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record) => {
              if (record.card_atm_withdrawal) {
                return (
                  <Tooltip placement='right' title='Retiro en cajero'>
                    🏧
                  </Tooltip>
                );
              } else if (record.card_transaction) {
                return (
                  <Tooltip placement='right' title='Pagado con tarjeta'>
                    💳
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip placement='right' title='Sin tarjeta'></Tooltip>
                );
              }
            },
          },
          {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            ellipsis: {
              showTitle: false,
            },
            render: (expenseName, record) =>
              expenseName == 'SIN DESCRIPCION' ? (
                <Tooltip placement='topLeft' title={'SIN DESCRIPCION'}>
                  <span style={{ color: 'gray' }}>
                    {'SIN DESCRIPCION'}
                    <i>{record.provider ? ' - ' + record.provider : ''} </i>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip placement='topLeft' title={expenseName}>
                  {expenseName}
                </Tooltip>
              ),
          },
          {
            title: 'Categoría',
            dataIndex: 'category',
            key: 'category',
            width: 180,
            ellipsis: true,
            render: (category) => {
              if (category == null) {
                return <Tag>N/A</Tag>;
              }
              return <Tag color={category_to_color[category]}>{category}</Tag>;
            },
          },
          {
            title: 'Gastante',
            dataIndex: 'gastante',
            key: 'gastante',
            width: 160,
          },
          {
            title: 'Fecha',
            dataIndex: 'fechaFormatted',
            key: 'fecha',
            width: 120,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <DateFilterDropdown
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
              />
            ),
            onFilter: (value, record) => {
              const current_date = new Date(record.fecha);
              if (typeof value == 'string') {
                let filter_dates = get_moments_from_month_name(value);
                return (
                  current_date >= filter_dates[0] &&
                  current_date <= filter_dates[1]
                );
              }
              return current_date >= value[0] && current_date <= value[1];
            },
          },
          {
            title: 'Impuesto',
            dataIndex: 'itbms',
            key: 'itbms',
            align: 'right',
            width: 100,
            render: (itbms) => {
              return <FormattedUSD total={itbms} />;
            },
          },
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 120,
            render: (total) => {
              return <FormattedUSD total={total} />;
            },
          },
          {
            title: 'Factura',
            dataIndex: 'factura',
            key: 'factura',
            align: 'center',
            width: 80,
            render: (blank, record) => {
              return <ImgOrPDF record={record} />;
            },
          },
          {
            dataIndex: ['duplicate'],
            key: 'duplicate',
            align: 'center',
            width: 25,
            render: (id, expense) => {
              return expense.duplicate ? (
                <Tooltip
                  placement='topLeft'
                  title={
                    <>
                      {'Posible factura duplicada. Presente en:'}
                      {expense.duplicate_locations_list.map(
                        (location, index) => (
                          <div key={index}>
                            {index + 1}. {location}
                          </div>
                        )
                      )}
                    </>
                  }
                >
                  <WarningTwoTone twoToneColor='#ffcc00' />
                </Tooltip>
              ) : (
                ''
              );
            },
          },
        ]);
  }

  if (auth.personal) {
    gastosColumns.splice(2, 1);
  }

  useEffect(() => {
    getExpenses();
  }, [props.submittedToggle]);

  const getExpenses = () => {
    axios({
      method: 'get',
      url: props.API_domain + 'getReportExpenses?id=' + props.report_id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setLoaded(true);
        setItems(response.data);
        // console.log(response.data);
      })
      .catch((response) => {
        setLoaded(true);
        setError(true);
      });
  };
  props.setReportExpensesList(items);
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div style={{ paddingTop: '25vh' }}>
        <LoadingDiv>{antIcon}</LoadingDiv>
      </div>
    );
  } else {
    return (
      <Table
        columns={gastosColumns}
        dataSource={items}
        pagination={false}
        size='middle'
        scroll={{ y: 'overflow' }}
        rowClassName={(record, index) => {
          return 'cursor-pointer';
        }}
        id='table-to-print'
        onRow={(expense, rowIndex) => {
          return {
            onClick: () => {
              props.openModal(expense);
            },
          };
        }}
        footer={() => {
          return (
            <FooterDiv>
              <b>
                Total:
                <FormattedUSD
                  total={items.reduce(
                    (prev, cur) => prev + parseFloat(cur.total),
                    0
                  )}
                  // total={12}
                ></FormattedUSD>
              </b>
            </FooterDiv>
          );
        }}
      />
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  text-align: center;
`;

const AWithAlign = styled.a`
  vertical-align: middle;
`;

const PdfDiv = styled.div`
  height: 30px;
`;

const FooterDiv = styled.div`
  padding: 4px;
  text-align: right;
  padding-right: 80px;
`;

export { FinishedReportTable };
