import React, { useContext, useState, useEffect } from 'react';
import { Empty, Table } from 'antd';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import { FormattedUSD } from './../FormattedUSD.js';
import { CreditCardOutlined } from '@ant-design/icons';

function ConciliationTransactionsTable(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = useContext(authContext);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('es-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(new Date(date));
  };

  const getTransactions = (setCancel) => {
    setLoading(true);
    const source = axios.CancelToken.source();

    setCancel(source.cancel);
    axios({
      method: 'post',
      url: props.API_domain + 'getCardTransactions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        card_id: props.card,
        processingOnly: false,
        exclude_financial_transactions: true,
      },
      cancelToken: source.token,
    })
      .then((response) => {
        // console.log(response.data);
        props.setTransactions(
          response.data.map((transaction) => ({
            ...transaction,
            totalFormatted: <FormattedUSD total={transaction.total} />,
            fechaFormatted: formatDate(transaction.fecha),
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(error);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    let cancel;
    getTransactions((c) => (cancel = c));
    return () =>
      cancel && cancel('Canceled due to new request or component unmounting');
  }, [props.card]);

  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      render: (descripcion, transaction) => {
        return (
          <>
            <div>{transaction.descripcion}</div>
            <div style={{ color: 'grey' }}>{transaction.provider}</div>
          </>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'totalFormatted',
      key: 'total',
      sorter: (a, b) => a.total - b.total, // Assuming `total` is a numeric property
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fecha',
      sorter: (a, b) => new Date(a.fecha) - new Date(b.fecha), // Assuming `fecha` is the raw date
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const rowSelection = {
    type: 'radio',
    selectedRowKeys: props.selectedTransaction.map(
      (transaction) => transaction.paycaddy_transactions_id
    ),
    onChange: (_, selectedRows) => {
      props.setSelectedTransaction(selectedRows);
    },
    columnTitle: '',
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <Table
      columns={columns}
      dataSource={props.transactions}
      loading={loading}
      rowKey='paycaddy_transactions_id'
      pagination={{ defaultPageSize: 20 }}
      rowSelection={rowSelection}
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
      locale={{
        emptyText: (
          <Empty
            style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
            // image={Empty.PRESENTED_IMAGE_SIMPLE}
            image={<CreditCardOutlined style={{ fontSize: '64px' }} />}
            description={
              <>
                Selecciona una <b>tarjeta para ver las transacciones</b>{' '}
                pendientes por conciliar conciliar
              </>
            }
          />
        ),
      }}
    />
  );
}

export { ConciliationTransactionsTable };
