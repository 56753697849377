import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { ConfigAddExistingMemberSelect } from '../ConfigAddExistingMemberSelect.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const { TextArea } = Input;

function AdminSendMessage(props) {
  const auth = useContext(authContext);
  const [selectedMemberId, setSelectedMemberId] = useState();
  const [textToSend, setTextToSend] = useState('');

  function sendMessage() {
    axios({
      method: 'post',
      url: props.API_domain + 'adminSendWhatsappMessage',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        user_id: selectedMemberId,
        message: textToSend,
      },
    })
      .then(function (response) {
        message('Enviado!');
      })
      .catch(function (response) {
        console.log(response);
      });
  }
  return (
    <>
      {selectedMemberId > 0 && (
        <>
          <Modal
            visible={true}
            title='Mensaje a mandar'
            onOk={sendMessage}
            onCancel={() => setSelectedMemberId(null)}
            okText='Enviar'
          >
            <TextArea
              onChange={(event) => {
                setTextToSend(event.target.value);
              }}
            />
          </Modal>
        </>
      )}
      <ConfigAddExistingMemberSelect
        API_domain={props.API_domain}
        setSelectedMemberId={setSelectedMemberId}
        team={'all'}
      />
    </>
  );
}
export { AdminSendMessage };
