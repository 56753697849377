import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Empty, Image, Row, Table, Tooltip } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import { FormattedUSD } from './../FormattedUSD.js';
import {
  EyeOutlined,
  InboxOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

function ConciliationExpensesTable(props) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);
  const auth = useContext(authContext);

  const formatDate = (date) =>
    new Intl.DateTimeFormat('es-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(new Date(date));

  const getExpenses = (setCancel) => {
    setLoading(true);
    const source = axios.CancelToken.source();
    setCancel(source.cancel);
    axios({
      method: 'post',
      url: props.API_domain + 'getUnassignedExpenses',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
        processingOnly: false,
      },
      cancelToken: source.token,
    })
      .then((response) => {
        const filteredExpenses = response.data.filter(
          (expense) => !expense.card_transaction
        );
        props.setExpenses(
          filteredExpenses.map((expense) => ({
            ...expense,
            totalFormatted: <FormattedUSD total={expense.total} />,
            fechaFormatted: formatDate(expense.fecha),
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(error);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    let cancel;
    if (props.team) {
      getExpenses((c) => (cancel = c));
    }
    return () =>
      cancel && cancel('Canceled due to new request or component unmounting');
  }, [props.team]);

  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      render: (text, record) => (
        <Tooltip
          title={
            <>
              <strong>Proveedor:</strong> {record.provider}
              <br />
              <strong>Gastante:</strong> {record.gastante}
            </>
          }
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'totalFormatted',
      key: 'total',
      sorter: (a, b) => a.total - b.total, // Ensure 'total' is a numeric field in the expense data
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fecha',
      sorter: (a, b) => new Date(a.fecha) - new Date(b.fecha), // Ensure 'fecha' is the raw date field
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '',
      dataIndex: 'expand',
      key: 'expand',
      render: () => null,
    },
  ];

  const rowSelection = {
    type: 'radio', // just delete this to be able to select multiple expenses
    selectedRowKeys: props.selectedExpenses.map((expense) => expense.key),
    onChange: (_, selectedRows) => props.setSelectedExpenses(selectedRows),
    columnTitle: '',
  };

  const customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <EyeOutlined
          onClick={(e) => props.onExpand(props.record, e)}
          style={{ transform: 'rotate(180deg)' }}
        />
      );
    }
    return <EyeOutlined onClick={(e) => props.onExpand(props.record, e)} />;
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <Table
      columns={columns}
      dataSource={props.expenses}
      loading={loading}
      rowKey='id'
      pagination={{ defaultPageSize: 20 }}
      rowSelection={rowSelection}
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
      expandable={{
        expandedRowRender: (record) => (
          <Row>
            <Col span={12}>
              <p>
                <strong>Categoría:</strong> {record.category}
              </p>
              <p>
                <strong>Proveedor:</strong> {record.provider}
              </p>
              <p>
                <strong>RUC:</strong> {record.ruc}
              </p>
              <p>
                <strong>Fecha de creación:</strong> {record.created_at}
              </p>
              <p>
                <strong>Gastante:</strong> {record.gastante}
              </p>
            </Col>
            <Col span={12}>
              <Image width={200} src={record.factura} />
            </Col>
          </Row>
        ),
        expandIcon: customExpandIcon,
      }}
      locale={{
        emptyText: (
          <Empty
            style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
            image={<InboxOutlined style={{ fontSize: '64px' }} />}
            description={
              <>
                Selecciona un <b>equipo para ver los gastos</b> pendientes por
                conciliar
              </>
            }
          />
        ),
      }}
    />
  );
}

function ImgOrPDF(props) {
  const [isImage, setIsImage] = useState(true);
  if (!props.record) {
    return (
      <Tooltip placement='topLeft' title={'Foto de factura pendiente!'}>
        <QuestionCircleOutlined />
      </Tooltip>
    );
  }
  if (isImage) {
    return (
      <Image
        width={16}
        height={24}
        src={props.record}
        onError={(event) => setIsImage(false)}
        preview={false}
      />
    );
  } else {
    return (
      <Button
        type='text'
        style={{
          height: '24px',
          'text-align': 'center',
          border: '0px',
          'padding-top': '3px',
          'padding-bottom': '3px',
          'vertical-align': 'middle',
        }}
      >
        <AWithAlign href={props.record} target='_blank'>
          PDF
        </AWithAlign>
      </Button>
    );
  }
}

const FooterDiv = styled.div`
  padding: 20px;
  border-top: 1px solid #e8e8e8;
  text-align: left;
  background-color: white;
`;
const AWithAlign = styled.a``;

export { ConciliationExpensesTable };
