import {
  CreditCardOutlined,
  LoadingOutlined,
  WarningTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import { Image, Table, Tag, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { DescriptionSearchDropdown } from './DescriptionSearchDropdown.js';
import { FormattedUSD } from './FormattedUSD.js';
import { get_moments_from_month_name } from '../utils.js';
import _ from 'lodash';

function ImgOrPDF(props) {
  const [isImage, setIsImage] = useState(true);

  if (isImage) {
    return (
      <Image
        width={16}
        height={24}
        src={props.record}
        onError={(event) => setIsImage(false)}
      />
    );
  } else {
    return (
      <PdfDiv>
        <AWithAlign href={props.record} target='_blank'>
          Link
        </AWithAlign>
      </PdfDiv>
    );
  }
}

const colors = ['orange', 'green', 'cyan', 'blue', 'purple'];

function NewReportFormExpensesTable(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [selectionChanged, setSelectionChanged] = useState(false);
  const auth = useContext(authContext);

  let category_to_color = {};
  let counter = 0;
  items.forEach(function (item) {
    if (!(item.category in category_to_color)) {
      category_to_color[item.category] = colors[counter];
      counter += 1;
    }
    if (item.fecha) {
      item.fechaFormatted = new Intl.DateTimeFormat('es-US', {
        dateStyle: 'medium',
      }).format(
        new Date(
          item.fecha.split('/')[0],
          item.fecha.split('/')[1] - 1,
          item.fecha.split('/')[2]
        )
      );
    }
    item.fechaFormattedShort = new Intl.DateTimeFormat('es-US', {
      day: 'numeric',
      month: 'short',
    }).format(
      new Date(
        item.fecha.split('/')[0],
        item.fecha.split('/')[1] - 1,
        item.fecha.split('/')[2]
      )
    );
  });
  function filterCreator(data, column) {
    const columnSet = Array.from(new Set(data.map((item) => item[column])));
    return columnSet.map((item) => ({
      text: item,
      value: item,
    }));
  }

  function CardFilterCreator(data, column) {
    const columnSet = Array.from(new Set(data.map((item) => item[column])));
    return columnSet.map((item) => ({
      text: item ? 'Tarjeta' : 'No Tarjeta',
      value: item,
    }));
  }

  const selectRow = (record) => {
    const selectedRows = [...props.selectedExpenses];
    if (selectedRows.indexOf(record) >= 0) {
      selectedRows.splice(selectedRows.indexOf(record), 1);
    } else {
      selectedRows.push(record);
    }
    setSelectionChanged(!selectionChanged);
    props.setSelectedExpenses(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys: props.selectedExpenses.map((expense) => expense.key),
    onChange: (selectedRowKeys, selectedRows) => {
      props.setSelectedExpenses(selectedRows);
    },
  };

  let gastosColumns = [];
  {
    props.mobile
      ? (gastosColumns = [
          {
            title: '',
            dataIndex: 'index',
            key: 'id',
            width: 35,
          },
          {
            dataIndex: ['descripcion', 'category', 'gastante'],
            key: 'descripcion',
            ellipsis: {
              showTitle: false,
            },
            render: (text, record) => (
              <div>
                <div>
                  <Tooltip placement='topLeft' title={record.descripcion}>
                    {record.descripcion}
                  </Tooltip>
                </div>
                <div style={{ paddingTop: 6 }}>
                  {record.category ? (
                    <Tag color={category_to_color[record.category]}>
                      {record.category}
                    </Tag>
                  ) : (
                    <Tag>N/A</Tag>
                  )}
                </div>
                <div style={{ paddingTop: 6 }}>
                  <b>{record.gastante}</b>
                </div>
              </div>
            ),
          },
          {
            title: 'Fecha',
            dataIndex: 'fechaFormattedShort',
            key: 'fecha',
            width: 80,
          },
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 75,
            sorter: (a, b) => a.total - b.total,
            sortDirections: ['ascend', 'descend'],
            render: (total) => {
              return <FormattedUSD total={total} />;
            },
          },
        ])
      : (gastosColumns = [
          // DESKTOP)
          {
            dataIndex: 'card',
            key: 'card',
            width: 30,
            ellipsis: {
              showTitle: false,
            },
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            // filteredValue: props.filters?.card ? props.filters.card : null,
            filters: CardFilterCreator(items, 'card_transaction'), // TODO: render something different than 1 or 0
            onFilter: (value, record) => {
              return record.card_transaction == value;
            },
            render: (text, record) =>
              record.card_transaction ? (
                <Tooltip placement='right' title='Pagado con tarjeta'>
                  <CreditCardOutlined
                    style={{ fontSize: '12px', color: 'grey' }}
                  />
                </Tooltip>
              ) : (
                ' '
              ),
          },
          {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            ellipsis: {
              showTitle: false,
            },
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            render: (expenseName, record) =>
              expenseName == 'SIN DESCRIPCION' ? (
                <Tooltip placement='topLeft' title={'SIN DESCRIPCION'}>
                  <span style={{ color: 'gray' }}>
                    {'SIN DESCRIPCION'}
                    <i>{record.provider ? ' - ' + record.provider : ''} </i>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip placement='topLeft' title={expenseName}>
                  {expenseName}
                </Tooltip>
              ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <DescriptionSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
              />
            ),
            // filteredValue: props.filters?.descripcion
            // ? [props.filters.descripcion]
            // : null,
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            onFilter: (value, record) => {
              return record['descripcion']
                .concat(record['provider'])
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            },
          },
          {
            title: 'Categoría',
            dataIndex: 'category',
            key: 'category',
            width: 200,
            // filteredValue: props.filters?.category
            // ? props.filters.category
            // : null,
            filters: filterCreator(items, 'category'),
            ellipsis: true,
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            onFilter: (value, record) => {
              if (record.category) {
                return record.category.indexOf(value) === 0;
              } else {
                if (value == null) {
                  return true;
                }
                return false;
              }
            },

            render: (category) => {
              if (category == null) {
                return <Tag>N/A</Tag>;
              }
              return <Tag color={category_to_color[category]}>{category}</Tag>;
            },
          },
          {
            title: 'Gastante',
            dataIndex: 'gastante',
            key: 'gastante',
            width: 120,
            // filteredValue: props.filters?.gastante
            // ? props.filters.gastante
            // : null,
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            filters: filterCreator(items, 'gastante'),
            onFilter: (value, record) => {
              return record.gastante
                ? record.gastante.indexOf(value) === 0
                : false;
            },
          },
          {
            title: 'Fecha',
            dataIndex: 'fechaFormatted',
            key: 'fecha',
            width: 120,
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            // filteredValue: props.filters?.fechaFormatted
            // ? props.filters.fechaFormatted
            // : null,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <DateFilterDropdown
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
              />
            ),
            onFilter: (value, record) => {
              const current_date = new Date(record.fecha);
              if (typeof value == 'string') {
                let filter_dates = get_moments_from_month_name(value);
                return (
                  current_date >= filter_dates[0] &&
                  current_date <= filter_dates[1]
                );
              }
              return current_date >= value[0] && current_date <= value[1];
            },
          },
          {
            title: 'Impuesto',
            dataIndex: 'itbms',
            key: 'itbms',
            align: 'right',
            width: 100,
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            render: (itbms) => {
              return <FormattedUSD total={itbms} />;
            },
          },
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 120,
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            sorter: (a, b) => a.total - b.total,
            sortDirections: ['ascend', 'descend'],
            render: (total) => {
              return <FormattedUSD total={total} />;
            },
          },
          {
            title: 'Factura',
            dataIndex: 'factura',
            key: 'factura',
            align: 'center',
            width: 80,
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            render: (record) => {
              return <ImgOrPDF record={record} />;
            },
          },
          {
            dataIndex: ['duplicate'],
            key: 'duplicate',
            align: 'center',
            width: 25,
            shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
            render: (id, expense) => {
              return expense.processing ? (
                <Tooltip
                  placement='topRight'
                  title={'Estamos extrayendo los datos de tu factura'}
                >
                  <img src='processing.png' width='18px'></img>
                </Tooltip>
              ) : expense.duplicate ? (
                <Tooltip
                  placement='topLeft'
                  title={
                    <>
                      {'Posible factura duplicada. Presente en:'}
                      {expense.duplicate_locations_list.map(
                        (location, index) => (
                          <div key={index}>
                            {index + 1}. {location}
                          </div>
                        )
                      )}
                    </>
                  }
                >
                  <WarningTwoTone twoToneColor='#ffcc00' />
                </Tooltip>
              ) : (
                ''
              );
            },
          },
        ]);
  }

  if (auth.personal) {
    gastosColumns.splice(2, 1);
  }

  useEffect(() => {
    getExpenses();
  }, [props.submittedToggle, props.team]);

  const getExpenses = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getUnassignedExpenses',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then((response) => {
        setLoaded(true);
        setItems(response.data);
      })
      .catch((response) => {
        setLoaded(true);
        setError(true);
      });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div style={{ paddingTop: '25vh' }}>
        <LoadingDiv>{antIcon}</LoadingDiv>
      </div>
    );
  } else {
    return (
      <>
        <Table
          rowSelection={rowSelection}
          columns={gastosColumns}
          dataSource={items}
          footer={() => {
            if (props.selectedExpenses.length > 0) {
              return (
                <FooterDiv>
                  <b>
                    Total:
                    <FormattedUSD
                      total={props.selectedExpenses.reduce(
                        (prev, cur) => prev + parseFloat(cur.total),
                        0
                      )}
                    ></FormattedUSD>
                  </b>
                </FooterDiv>
              );
            }
          }}
          pagination={false}
          size='middle'
          scroll={{ y: 'auto' }}
          rowClassName={(record, index) => {
            return 'cursor-pointer';
          }}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
        />
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  text-align: center;
`;

const AWithAlign = styled.a`
  vertical-align: middle;
`;

const PdfDiv = styled.div`
  height: 30px;
`;

const FooterDiv = styled.div`
  padding: 4px;
  text-align: right;
  padding-right: 80px;
`;

export { NewReportFormExpensesTable };
