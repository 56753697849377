import React, { useContext, useEffect, useState } from 'react';
import { Table, Avatar, Tag, Button, Tooltip } from 'antd'; // Import Button component
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';

const getRandomColor = () => {
  const colors = [
    '#87d068',
    '#f56a00',
    '#ffbf00',
    '#00a2ae',
    '#f5222d',
    '#fa541c',
    '#fa8c16',
    '#faad14',
    '#fadb14',
    '#a0d911',
    '#52c41a',
    '#13c2c2',
    '#1890ff',
    '#fa541c',
    '#fa8c16',
    '#faad14',
    '#fadb14',
    '#a0d911',
    '#52c41a',
    '#13c2c2',
    '#1890ff',
  ];

  return colors[Math.floor(Math.random() * colors.length)];
};

function EmployeesTable(props) {
  const auth = useContext(authContext);
  const [employeeData, setEmployeeData] = useState([]); // State to hold employee data
  const [loading, setLoading] = useState(
    props.selectedAccountingClientId != null ? true : false
  );
  const [showExEmployees, setShowExEmployees] = useState(false); // State to toggle ex-employees visibility

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const selectedAccountingClientId = props.selectedAccountingClientId;
      if (!selectedAccountingClientId) {
        return;
      }
      setLoading(true);
      try {
        const response = await axios({
          method: 'post',
          url: props.API_domain + 'getAccountingClientEmployees',
          data: {
            accountingClientsId: selectedAccountingClientId,
          },
          auth: {
            username: auth.email,
            password: auth.token,
          },
        });
        setEmployeeData(
          response.data.map((emp, index) => ({
            ...emp,
            key: emp.employeeId,
            color: getRandomColor(), // Assign random color for the avatar
            index: index + 1,
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchEmployeeData();
  }, [props.selectedAccountingClientId, props.employeeModalVisible]);

  const columns = [
    {
      // title: 'No.',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Imagen',
      dataIndex: 'color',
      key: 'color',
      render: (color) => (
        <Avatar
          style={{
            backgroundColor: color,
          }}
          icon={<UserOutlined />}
        />
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <div>
          {name}{' '}
          {record.end_date ? (
            <Tooltip
              title={
                'Desactivado el ' + moment(record.end_date).format('DD-MM-YYYY')
              }
            >
              <Tag color='red'>Desactivado</Tag>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'gov_id',
      key: 'gov_id',
    },
    {
      title: 'Tipo de Empleado',
      dataIndex: 'type_of_employee',
      key: 'type_of_employee',
      render: (type_of_employee) => (
        <Tag color={props.employeeTypeColors[type_of_employee]}>
          {type_of_employee}
        </Tag>
      ),
    },
  ];

  // Filter employees based on the `showExEmployees` flag
  const filteredEmployeeData = employeeData.filter(
    (employee) => (showExEmployees ? true : employee.end_date === null) // Show all if toggled, else hide ex-employees
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={filteredEmployeeData} // Use the filtered employee data here
        onRow={(record, rowIndex) => {
          return {
            onClick: () => (
              props.setSelectedEmployee(record),
              props.setEmployeeModalVisible(true)
            ),
          };
        }}
        rowClassName={(record, index) => {
          return 'cursor-pointer';
        }}
        pagination={false}
        loading={loading}
      />
      {employeeData.some((employee) => employee.end_date !== null) && (
        <Button
          type='secondary'
          style={{ marginTop: '16px' }} // Adds some spacing from the table
          onClick={() => setShowExEmployees((prevState) => !prevState)}
        >
          {showExEmployees ? 'Ocultar ex-empleados' : 'Mostrar ex-empleados'}
        </Button>
      )}
    </>
  );
}

export { EmployeesTable };
