import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin, Upload } from 'antd';
import { useState } from 'react';
import { read, utils } from 'xlsx';
import { downloadBlob, ECBGtoZohoCovertorInput } from '../../utils.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CsvUploader(props) {
  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      props.setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={uploadChanged}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

function AdminECBGtoZohoConvertorInput(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [bankName, setBankName] = useState('');

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    const zohoCsvBlob = ECBGtoZohoCovertorInput(csvData, bankName);
    downloadBlob(zohoCsvBlob, 'input_for_zoho_converter.csv');
  };

  return (
    <>
      <Button type='secondary' onClick={showModal} style={{ fontWeight: 500 }}>
        ECBG a ZOHO Converter
      </Button>
      <Modal
        title='ECBG a ZOHO Converter'
        visible={visible}
        footer={
          <Button type='primary' htmlType='submit' block onClick={onFinish}>
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Convertir</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <p>- acepta xls, xlsx, csv</p>
        <p>
          - el input debe tener *Fecha Referencia Transacción Descripción Débito
          Crédito Saldo total Cuenta*
        </p>
        <p>
          - en el input, excel debe mostrar las fechas como 9/1/2023 y deben ser
          Date. si ves 9/1/2023, lo más probable es que funcione
        </p>
        <p>- no importa el orden de las columnas del input</p>
        <p>
          - el output es un csv con *FECHA CREDIT_ACCOUNT DEBIT ACCOUNT NOTAS
          MONTO ITBMS*
        </p>
        <Form {...layout} onFinish={onFinish}>
          <Form.Item
            label='Bank Name:'
            rules={[{ required: true, message: 'Please enter the bank name' }]}
          >
            <Input
              placeholder='Enter Bank Name'
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Upload CSV:'>
            <CsvUploader setCsvData={setCsvData} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminECBGtoZohoConvertorInput };
