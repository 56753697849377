import { formatDateSpanishLong } from '../../utils';

export const createCoverPage = async (
  doc,
  accountingClientCompany,
  incomeStatementRecentTimePeriods
) => {
  if (!accountingClientCompany) {
    console.warn('No accounting client company data available.');
    return;
  }

  console.log(
    'incomeStatementRecentTimePeriods:',
    incomeStatementRecentTimePeriods
  );

  const heroLogoSrc = '/heroLogoSmall.png'; // Adjust path as needed
  const title = `${accountingClientCompany.name}`;

  try {
    // Load both images concurrently
    const [logo1, logo2] = await Promise.all([
      loadImageBase64(accountingClientCompany.logo_base64),
      loadImage(heroLogoSrc),
    ]);

    // Function to calculate logo dimensions while maintaining aspect ratio
    const calculateLogoDimensions = (img) => {
      if (!img) return { width: 0, height: 0 };
      const aspectRatio = img.width / img.height;
      const maxLogoHeight = 50;
      const maxLogoWidth = 100;

      let width = maxLogoHeight * aspectRatio;
      let height = maxLogoHeight;

      if (width > maxLogoWidth) {
        width = maxLogoWidth;
        height = maxLogoWidth / aspectRatio;
      }

      return { width, height };
    };

    // Calculate dimensions for both logos
    const logo1Dimensions = calculateLogoDimensions(logo1);
    const logo2Dimensions = calculateLogoDimensions(logo2);

    // Set initial positions
    const xOffset1 = 10;
    const yOffset = 10;

    let currentX = xOffset1;
    let maxHeight = 0;

    // Add first logo if loaded
    if (logo1) {
      doc.addImage(
        logo1.src,
        'PNG',
        currentX,
        yOffset,
        logo1Dimensions.width,
        logo1Dimensions.height
      );
      currentX += logo1Dimensions.width + 10; // Add spacing between logos
      maxHeight = Math.max(maxHeight, logo1Dimensions.height);
    } else {
      console.warn('Accounting client logo failed to load.');
    }

    // Add second logo if loaded
    if (logo2) {
      doc.addImage(
        logo2.src,
        'PNG',
        currentX,
        yOffset,
        logo2Dimensions.width,
        logo2Dimensions.height
      );
      maxHeight = Math.max(maxHeight, logo2Dimensions.height);
    } else {
      console.warn('Hero logo failed to load.');
    }

    // Add title and report date below the logos
    doc.setFont('Georgia');
    doc.setFontSize(20);
    doc.text(title, 20, maxHeight + 30);

    const reportDate = formatDateSpanishLong(
      getLastDayOfMonth(
        incomeStatementRecentTimePeriods[
          incomeStatementRecentTimePeriods.length - 1
        ]
      )
    );

    doc.text(`Reporte contable al ${reportDate}`, 20, maxHeight + 48);
    doc.setFontSize(12);

    // Optionally, you can add more content or styling here
  } catch (error) {
    console.error('Error adding cover page:', error);
    // Handle errors as needed, possibly by adding default text or images
  }
};

const loadImageBase64 = (base64, mimeType = 'image/png') => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => {
      console.warn(`Failed to load base64 image`);
      resolve(null); // Resolve with null if image fails to load
    };
    // Set the src with the appropriate base64 data URL format
    img.src = `data:${mimeType};base64,${base64}`;
  });
};

const loadImage = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => {
      console.warn(`Failed to load image: ${src}`);
      resolve(null); // Resolve with null if image fails to load
    };
    img.src = src;
  });
};

const getLastDayOfMonth = (yearMonth) => {
  const [year, month] = yearMonth.split('-').map(Number);
  const date = new Date(year, month, 1);
  date.setDate(date.getDate() - 1);
  return date.toISOString().split('T')[0];
};
