import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { SharedReportModal } from './SharedReportModal';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function SharedReportView(props) {
  const location = useLocation();
  const reportId = new URLSearchParams(location.search).get('reportId', '');
  const token = new URLSearchParams(location.search).get('token', '');
  const [selectedReport, setSelectedReport] = useState();
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);

  const getSharedReportInfo = () => {
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getSharedReport?report_id=' +
        reportId +
        '&token=' +
        token,
    })
      .then((response) => {
        setSelectedReport(response.data);
        setLoaded(true);
      })
      .catch((response) => {
        setError(response);
      });
  };
  useEffect(() => {
    getSharedReportInfo();
    // props.setLoaded(false);
  }, []);
  return (
    <>
      {isLoaded ? (
        <SharedReportModal
          report_id={selectedReport.id}
          token={token}
          report_comments={selectedReport.comments}
          report_title={selectedReport.title}
          API_domain={props.API_domain}
          // onSubmit={onSubmit}
          handleCancel={() =>
            (window.location.href = 'https://www.herofacturas.com')
          }
          // handleOk={() => setSelectedReport(null)}
          team={selectedReport.team}
          mobile={props.mobile}
        />
      ) : (
        <LoadingDiv>{antIcon}</LoadingDiv>
      )}
    </>
  );
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { SharedReportView };
