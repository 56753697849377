import React, { useContext, useEffect, useState } from 'react';
import { message, Select } from 'antd';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';

const { Option } = Select;

const SOTSelect = (props) => {
  const [options, setOptions] = useState([]);
  const auth = useContext(authContext);

  const fetchSourcesOfTruth = () => {
    axios({
      method: 'get',
      url: props.API_domain + 'getSourcesOfTruth',
      auth: {
        username: auth.email, // Ensure 'auth' object is available in this context
        password: auth.token,
      },
    })
      .then((response) => {
        let options = response.data;
        // **Filter options based on 'type_of_mm' prop**
        options = options.filter((opt) => opt.type_of_mm === props.type_of_mm);

        // Sort options
        options.sort((a, b) =>
          a.accounting_client_name.localeCompare(b.accounting_client_name)
        );

        setOptions(options);

        // **If only one option after filtering, set it as the default selected option**
        if (options.length == 1) {
          props.setSelectedSot(options[0]);
        } else {
          props.setSelectedSot({});
        }
      })
      .catch((error) => {
        console.error('Error fetching sources of truth:', error);
      });
  };

  useEffect(() => {
    fetchSourcesOfTruth();
  }, [props.type_of_mm]);

  const handleChange = (value) => {
    props.setSelectedSot(
      options.find((opt) => opt.sources_of_truth_id === value)
    );
  };

  return (
    <Select
      style={{ width: 320, marginLeft: 10 }}
      placeholder='Seleccionar'
      onChange={handleChange}
      value={props.selectedSot.sources_of_truth_id}
      showSearch
      filterOption={(input, option) => {
        const opt = options.find(
          (opt) => opt.sources_of_truth_id === option.value
        );
        if (!opt) return false;
        return (
          opt.accounting_client_name
            .toLowerCase()
            .includes(input.toLowerCase()) ||
          opt.source_name.toLowerCase().includes(input.toLowerCase()) ||
          opt.source_zoho_name.toLowerCase().includes(input.toLowerCase()) ||
          opt.client_facing_name.toLowerCase().includes(input.toLowerCase())
        );
      }}
    >
      {options.map((opt) => (
        <Option key={opt.sources_of_truth_id} value={opt.sources_of_truth_id}>
          {auth.adminEmail ? (
            <>
              <b>{opt.accounting_client_name}</b>{' '}
              <span style={{ color: 'grey', marginLeft: 4, marginRight: 4 }}>
                |
              </span>{' '}
              {opt.source_zoho_name}{' '}
              <span style={{ color: 'grey', marginLeft: 4, marginRight: 8 }}>
                |
              </span>{' '}
              {opt.source_name} 🦸‍♂️
            </>
          ) : (
            opt.client_facing_name
          )}
        </Option>
      ))}
    </Select>
  );
};

export { SOTSelect };
