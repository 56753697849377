import {
  DiffOutlined,
  LoadingOutlined,
  FileTextOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import { Affix, Badge, Button, Col, Layout, Row, Empty } from 'antd';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from '../TeamSelect.js';
import { Refresh } from '../Refresh.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { useHistory } from 'react-router-dom';

const { Content } = Layout;

function DashboardView(props) {
  const auth = useContext(authContext);
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [requistionsCount, setRequistionsCount] = useState(0);
  const [purchaseOrdersCount, setPurchaseOrdersCount] = useState(0);
  const [invoicesCount, setInvoicesCount] = useState(0);
  const history = useHistory();

  const fetchTeams = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getInbox',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then((response) => {
        setRequistionsCount(response.data.requisitions);
        setPurchaseOrdersCount(response.data.purchaseOrders);
        setInvoicesCount(response.data.invoices);
        setLoaded(true);
      })
      .catch((response) => {
        setError(response);
        setLoaded(true);
      });
  };

  useEffect(() => {
    setLoaded(false);
    fetchTeams();
  }, [props.team, submittedToggle]);

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Refresh spin={!isLoaded} />
              <TeamSelect
                API_domain={props.API_domain}
                onTeamSelect={props.onTeamSelect}
                team={props.team}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}></Col>
          </Row>
        </AffixDiv>
      </Affix>
      <BeautifulDiv>
      {requistionsCount === 0 && purchaseOrdersCount === 0 && invoicesCount === 0 && isLoaded ? (<Empty description={<span>Estas al día! No hay documentos pendientes.</span>}></Empty>) : null}
        {/* <Row>
          <div
            style={{
              textAlign: 'left',
              paddingTop: 40,
              paddingBottom: 10,
              fontSize: 32,
              fontWeight: 600,
            }}
          >
            Bienvenido
          </div>
        </Row> */}
        <div
          style={{
            minHeight: 300,
            // backgroundColor: 'rgba(116, 116, 205)',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'left',
          }}
        >
          {/* <div style={{ padding: 8 }}>
            <Badge style={{fontSize:1}} count={1} color={'grey'}>
              <Button
                style={{
                  padding: '20px',
                  height: 'auto',
                  minWidth: '280px',
                  fontSize: 16,
                  // textAlign:'left'
                }}
                type='dashed'
                disabled
              >
                <b>Requisiciones </b> por aprobar
              </Button>
            </Badge>
            <div style={{ marginLeft: 130, marginTop: 10 }}>
              <ArrowDownOutlined style={{ color: 'white', fontSize: 22 }} />
            </div>
          </div>

          <div style={{ padding: 8 }}>
            <Badge style={{fontSize:1}} count={3} color={'grey'}>
              <Button
                style={{
                  padding: '20px',
                  height: 'auto',
                  minWidth: '280px',
                  fontSize: 16,
                  // textAlign:'left'
                }}
                type='secondary'
                disabled
              >
                <b>Órdenes de Compra </b> por crear
              </Button>
            </Badge>
            <div style={{ marginLeft: 130, marginTop: 10 }}>
              <ArrowDownOutlined style={{ color: 'white', fontSize: 22 }} />
            </div>
          </div> */}
          {!isLoaded && <LoadingDiv>{antIcon}</LoadingDiv>}
          {isLoaded && (
            <Row>
              {requistionsCount > 0 ? (
                <Col span={8} style={{ textAlign: 'right', padding: 30 }}>
                  <div style={{ textAlign: 'center', width: '200px' }}>
                    <div style={{ padding: 8 }}>
                      <Badge style={{ fontSize: 14 }} count={requistionsCount}>
                        <Button
                          style={{
                            padding: '20px',
                            fontSize: 16,
                            height: '125px',
                            width: '125px',
                            borderRadius: 14,
                            backgroundColor: 'rgba(116, 116, 205)',
                            color: 'white',
                          }}
                          onClick={() => {
                            history.push(`/requisitions?assigneeFilter=true`);
                          }}
                          type='secondary'
                        >
                          <DiffOutlined style={{ fontSize: 40 }} />
                        </Button>
                      </Badge>
                    </div>
                    <div style={{ marginBottom: 25, fontSize: 18 }}>
                      <b>Requisiciones </b> pendientes
                    </div>
                  </div>
                </Col>
              ) : null}
              {purchaseOrdersCount > 0 ? (
                <Col span={8} style={{ padding: 30 }}>
                  <div style={{ textAlign: 'center', width: '200px' }}>
                    <div style={{ padding: 8 }}>
                      <Badge
                        style={{ fontSize: 14 }}
                        count={purchaseOrdersCount}
                      >
                        <Button
                          style={{
                            padding: '20px',
                            fontSize: 16,
                            height: '125px',
                            width: '125px',
                            borderRadius: 14,
                            backgroundColor: 'rgba(116, 116, 205)',
                            color: 'white',
                          }}
                          type='secondary'
                          onClick={() => {
                            history.push(`/ordenes?assigneeFilter=true`);
                          }}
                        >
                          <ContainerOutlined style={{ fontSize: 40 }} />
                        </Button>
                      </Badge>
                    </div>
                    <div style={{ marginBottom: 25, fontSize: 18 }}>
                      <b>Ordenes </b> pendientes
                    </div>
                  </div>
                </Col>
              ) : null}
              {invoicesCount > 0 ? (
                <Col span={8} style={{ textAlign: 'left', padding: 30 }}>
                  <div style={{ textAlign: 'center', width: '200px' }}>
                    <div style={{ padding: 8 }}>
                      <Badge style={{ fontSize: 14 }} count={invoicesCount}>
                        <Button
                          style={{
                            padding: '20px',
                            fontSize: 16,
                            height: '125px',
                            width: '125px',
                            borderRadius: 14,
                            backgroundColor: 'rgba(116, 116, 205)',
                            color: 'white',
                          }}
                          type='secondary'
                          onClick={() => {
                            history.push(`/invoices?assigneeFilter=true`);
                          }}
                        >
                          <FileTextOutlined style={{ fontSize: 40 }} />
                        </Button>
                      </Badge>
                    </div>
                    <div style={{ marginBottom: 25, fontSize: 18 }}>
                      <b>Facturas </b> pendientes
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          )}
        </div>
      </BeautifulDiv>
    </Content>
  );
}
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;
const BeautifulDiv = styled.div`
  width: min(calc(100vw - 300px), 1200px);
  margin: auto;
`;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 50, color: 'white' }} spin />
);
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
  text-align: center;
`;
export { DashboardView };
