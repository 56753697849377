import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components/macro';
import { LoadingOutlined } from '@ant-design/icons';
import { authContext } from '../../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};


function AdminFetchDGIFE(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    axios({
      method: 'post',
      url: props.API_domain + 'adminFetchDGIFE',
      data: values,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
    .then((response) => {
      handleOk();
      setTimeout(() => setSubmitting(false), 2000);
    })
    .catch(function (response) {
      alert('No tienes este permiso habilitado');
      setSubmitting(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


//   useEffect(() => {
//     if(visible){
//       adminFetchCardOptions();
//     }
//   }, [visible]);

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Buscar FE DGI</ButtonSpan>
      </Button>
      <Modal
        title='Buscar FE DGI'
        width={800}
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Buscar FE DGI</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='team'
            label='Equipo'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Input placeholder='Hero' />
          </Form.Item>
          <Form.Item
            name='ruc'
            label='RUC'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el RUC',
              },
            ]}
          >
            <Input placeholder='RUC' />
          </Form.Item>
          <Form.Item
            name='nit'
            label='NIT'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el NIT',
              },
            ]}
          >
            <Input placeholder='NIT' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminFetchDGIFE };
