import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { ConfigCreateTeamFromExistingSelector } from './ConfigCreateTeamFromExistingSelector.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function ConfigCreateTeamFromExisting(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSelect = (value) => {
    form.setFieldsValue({
      category: value,
    });
    return;
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      existing_team_name: values.existing_team_name,
      new_team_name: values.new_team_name,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'createTeamFromExisting',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('An Error ocurred hehee');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal} style={{ marginLeft: 8 }}>
        <ButtonSpan>Clonar equipo</ButtonSpan>
      </Button>
      <Modal
        title='Clonar equipo'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Crear Equipo</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='existing_team_name'
            label='Equipo existente:'
            rules={[
              {
                required: true,
                message: 'Por favor selecciona',
              },
            ]}
          >
            <ConfigCreateTeamFromExistingSelector
              API_domain={props.API_domain}
              onChange={onSelect}
            />
          </Form.Item>
          <Form.Item
            name='new_team_name'
            label='Nuevo equipo:'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Input placeholder='Job 554 Caja Menuda' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigCreateTeamFromExisting };
