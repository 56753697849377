import Analytics from 'analytics';
import axios from 'axios';
import React, { useContext } from 'react';
import { authContext } from './ProvideAuth.js';

function PostEventTracker(payload) {
  axios({
    method: 'post',
    url: payload.properties.API_domain + 'postEventTracker',
    data: {
      email: payload.properties.email,
      event: payload.event,
      misc: payload.properties.misc,
    },
  })
    .then((response) => {
      // console.log('Tracked event!');
    })
    .catch(function (response) {
      console.log('Error is ', response);
    });
  // console.log(payload);
}

const analytics = Analytics({
  app: 'herofacturas',
  plugins: [
    {
      name: 'hero facturas custom plugin',
      track: ({ payload }) => {
        PostEventTracker(payload);
      },
    },
  ],
});

export default analytics;
