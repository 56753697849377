import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';

function AccountingClientSelect(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  function fetchAccountingClientOptions() {
    axios({
      method: 'get',
      url: props.API_domain + 'getAccountingClients',
      auth: {
        username: auth.adminEmail ? auth.adminEmail : auth.email,
        password: auth.adminToken ? auth.adminToken : auth.token,
      },
    })
      .then(function (response) {
        let selectOptions = response.data
          .map((accountingClient) => ({
            label: accountingClient.name.toUpperCase(),
            value: accountingClient.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setOptions(selectOptions);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchAccountingClientOptions();
  }, []);

  useEffect(() => {
    if (options.length === 1) {
      const singleOption = options[0];
      setSelectedValue(singleOption.value);
      props.onAccountingClient(singleOption.value);
    }
  }, [options]);

  const handleFilterOption = (input, option) => {
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  const handleChange = (value) => {
    setSelectedValue(value);
    props.onAccountingClient(value);
  };

  return (
    <>
      <span
        style={{
          backgroundColor: props.highlightedColor,
          padding: props.noPadding ? 0 : 12,
          borderRadius: 4,
        }}
      >
        <Select
          showSearch
          options={options}
          filterOption={handleFilterOption}
          disabled={props.disabled ? props.disabled : false}
          onChange={handleChange}
          value={selectedValue}
          placeholder='Compañía ABC'
          style={{ width: props.noWidth ? 'auto' : '240px' }}
          placement= { props.placement ? props.placement : null }
        />
      </span>
    </>
  );
}

export { AccountingClientSelect };
