import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin, Input, message, Select } from 'antd';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { AdminCompanySelect } from './AdminCompanySelect.js';
import { ConfigAddExistingMemberSelect } from '../ConfigAddExistingMemberSelect.js';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminJoinAccountingClientWithCompany(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible){
      getAccountingClientOptions();
    }
  }, [visible]);

  const getAccountingClientOptions = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingClients',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.name}
        </Option>
      ));
      setClientOptions(options);
    } catch (error) {
      console.error(
        'There was an error fetching the accounting clients',
        error
      );
    }
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      accounting_client_id: values.client_id,
      company_id: values.company_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminJoinAccountingClientWithCompany',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success('Cliente juntao a compañia');
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        message.error('Error al juntar cliente a compañia');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }
  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Join Client w/ Company</ButtonSpan>
      </Button>
      <Modal
        title='Join Client w/ Company'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Join Client w/ Company</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='client_id'
            label='Client ID'
            rules={[{ required: true, message: 'Please select the client' }]}
          >
            <Select
              showSearch
              placeholder='Select a client'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="company_id"
            label="Compañía"
            rules={[{ required: true, message: 'Please select the company' }]}
          >
            <AdminCompanySelect 
              API_domain={props.API_domain}
              onCompanySelect={(company_id) => form.setFieldsValue({ company_id })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminJoinAccountingClientWithCompany };
