import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin, Upload } from 'antd';
import { useState } from 'react';
import { read, utils } from 'xlsx';
import {
  downloadBlob,
  removeBlankRowsFromVentasZohoUploader,
} from '../../utils.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CsvUploader(props) {
  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      props.setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={uploadChanged}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

function AdminZohoVentasRemoveBlankRows(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    const zohoCsvBlob = removeBlankRowsFromVentasZohoUploader(csvData);
    downloadBlob(zohoCsvBlob, 'ventas_wout_empty_rows_for_zoho.csv');
  };

  return (
    <>
      <Button type='secondary' onClick={showModal} style={{ fontWeight: 500 }}>
        Zoho Ventas quitar filas vacías
      </Button>
      <Modal
        title='Zoho Ventas quitar filas vacías'
        visible={visible}
        footer={
          <Button type='primary' htmlType='submit' block onClick={onFinish}>
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Quitar filas vacías</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <p>- acepta xls, xlsx, csv</p>
        <p>
          - el input debe tener *Journal Date Reference Number Journal Number
          Prefix Journal Number Suffix Notes Journal Type Currency Account
          Description Contact Name Debit Credit*
        </p>
        <p>
          - en el input, excel debe mostrar las fechas como 9/1/2023 y deben ser
          Date. si ves 9/1/2023, lo más probable es que funcione
        </p>
        <p>- no importa el orden de las columnas del input</p>
        <p>
          - el output es un csv igualito al input, excepto que no tendrás los
          rows vacíos ni los que tienen monto 0*
        </p>
        <Form {...layout} onFinish={onFinish}>
          <Form.Item label='Upload CSV:'>
            <CsvUploader setCsvData={setCsvData} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminZohoVentasRemoveBlankRows };
