import React, { useContext, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Checkbox } from 'antd'; // Import Checkbox from Ant Design
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { AccountingInboxTable } from './AccountingInboxTable.js';
import '../styles.css';

const convertToIntDateFormat = (dateString) => {
  return new Intl.DateTimeFormat('es-US', {
    dateStyle: 'medium',
  }).format(
    new Date(
      dateString.split('-')[0],
      dateString.split('-')[1] - 1,
      dateString.split('-')[2]
    )
  );
};

const transformAndSortData = (transactions) => {
  return transactions
    .map((item) => ({
      ...item,
      key: item.id,
      Descripcion: item.description,
      Fecha: item.txn_date,
      Date: new Date(item.txn_date),
      fechaFormatted: convertToIntDateFormat(item.txn_date),
      Credito: item.amount > 0 ? Math.abs(item.amount) : null,
      Debito: item.amount < 0 ? Math.abs(item.amount) : null,
      balance: item.extra_info.balance,
      sotTxnId: item.id,
      flagged: item.flagged,
      comments: item.comments, // Include the comments in each transaction
    }))
    .sort((a, b) => {
      const dateDifference = b.Date - a.Date;
      if (dateDifference !== 0) {
        return dateDifference;
      }
      return b.sotTxnId - a.sotTxnId;
    });
};

const AccountingInboxFetcher = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [previousDoubtsData, setPreviousDoubtsData] = useState([]);
  const [commentedData, setCommentedData] = useState([]); // To store commented transactions
  const auth = useContext(authContext);
  const [clientNames, setClientNames] = useState({});
  const [showPreviousDoubts, setShowPreviousDoubts] = useState({}); // Checkbox states for each client
// Function to fetch flagged transactions
const getFlaggedUncommentedSotTxns = () => {
  setLoading(true);
  axios({
    method: 'get',
    url: props.API_domain + 'getFlaggedUncommentedSotTxns',
    auth: {
      username: auth.email,
      password: auth.token,
    },
  }).then((response) => {
    const transformedData = {};
    const transformedPreviousDoubts = {};
    Object.keys(response.data.txns).forEach((clientId) => {
      const clientTxns = response.data.txns[clientId];
      if (clientTxns.length > 0) {
        const sortedClientTxns = transformAndSortData(clientTxns);
        // Separate items into two categories
        transformedData[clientId] = sortedClientTxns.filter(
          (item) => item.comments == null
        );
        transformedPreviousDoubts[clientId] = sortedClientTxns.filter(
          (item) => item.comments != null
        );
      } else {
        // Handle empty transaction arrays
        transformedData[clientId] = [];
        transformedPreviousDoubts[clientId] = [];
      }
    });
    setData(transformedData);
    setPreviousDoubtsData(transformedPreviousDoubts);
    setClientNames(response.data.names || {});
    setLoading(false);
  });
};

// Function to fetch commented transactions
const getCommentedTransactions = () => {
  console.log('Fetching commented transactions');
  setLoading(true);
  axios({
    method: 'get',
    url: props.API_domain + 'getCommentedSotTxns',
    auth: {
      username: auth.email,
      password: auth.token,
    },
  }).then((response) => {
    const transformedCommentedData = {};
    Object.keys(response.data.txns).forEach((clientId) => {
      const clientTxns = response.data.txns[clientId];
      if (clientTxns.length > 0) {
        transformedCommentedData[clientId] = transformAndSortData(clientTxns);
      } else {
        // Handle empty transaction arrays
        transformedCommentedData[clientId] = [];
      }
    });
    console.log('Commented data:', transformedCommentedData);
    setCommentedData(transformedCommentedData);
    setLoading(false);
  });
};

  useEffect(() => {
    // Fetch both flagged and commented transactions
    getFlaggedUncommentedSotTxns();
    getCommentedTransactions();
    props.setRefresh(false);
  }, [props.refresh]);

  // Function to handle checkbox state change for each client
  const handleCheckboxChange = (clientId) => (e) => {
    setShowPreviousDoubts((prevState) => ({
      ...prevState,
      [clientId]: e.target.checked, // Toggle the checkbox state for the specific client
    }));

    // Scroll down slightly if checkbox is checked
    if (e.target.checked) {
      setTimeout(() => {
        window.scrollBy({
          top: 300, // Adjust this value as needed
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const renderData = () => {
    return Object.keys(data).map((clientId) => {
      const clientTxns = data[clientId];
      const showDoubtsForClient = showPreviousDoubts[clientId]; // Whether to show previous doubts for this client
      const commentedTxns = commentedData[clientId] || []; // Get commented transactions for this client
      const clientName = clientNames[clientId] ? clientNames[clientId].toUpperCase() : '';

      return (
        <div key={clientId}>
          {Object.keys(data).length > 1 && (
            <h3>
              Transacciones de <b>{clientName}</b>
            </h3>
          )}
          <h3 style={{ textAlign: 'left', marginLeft: 2, marginTop: 10 }}>
            Ayúdanos con estas dudas:
          </h3>
          <AccountingInboxTable
            API_domain={props.API_domain}
            loading={loading}
            data={clientTxns}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            clientId={clientId}
            setShowCommentModal={props.setShowCommentModal}
            setCommentModalSotTxn={props.setCommentModalSotTxn}
          />
          <div style={{ textAlign: 'left' }}>
            <Checkbox onChange={handleCheckboxChange(clientId)}>
              Ver dudas anteriores
            </Checkbox>
          </div>

          {showDoubtsForClient && previousDoubtsData[clientId] && (
            <div>
              <h3 style={{ textAlign: 'left', marginLeft: 2, marginTop: 10 }}>
                Dudas anteriores:
              </h3>
              <AccountingInboxTable
                API_domain={props.API_domain}
                loading={loading}
                data={commentedTxns}
                setRefresh={props.setRefresh}
                refresh={props.refresh}
                clientId={clientId}
                setShowCommentModal={props.setShowCommentModal}
                setCommentModalSotTxn={props.setCommentModalSotTxn}
                previousFlagged={true}
              />
            </div>
          )}
        </div>
      );
    });
  };

  if (loading) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  }

  return <>{renderData()}</>;
};

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { AccountingInboxFetcher };
