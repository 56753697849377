import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Popconfirm,
  Row,
  Spin,
  message,
  Tooltip,
} from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

function ConfigEditUserTeamRoles(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  function formatPhoneNumber(input) {
    let countryFlag;
    let phoneNumber;

    if (input.startsWith('whatsapp:+507')) {
      countryFlag = '🇵🇦';
      phoneNumber = input.replace('whatsapp:+507', '');
    } else if (input.startsWith('whatsapp:+57')) {
      countryFlag = '🇨🇴';
      phoneNumber = input.replace('whatsapp:+57', '');
    } else if (input.startsWith('whatsapp:+1')) {
      countryFlag = '🇺🇸';
      phoneNumber = input.replace('whatsapp:+1', '');
    } else if (input.startsWith('whatsapp:+34')) {
      countryFlag = '🇪🇸';
      phoneNumber = input.replace('whatsapp:+34', '');
    } else if (input.startsWith('whatsapp:+55')) {
      countryFlag = '🇧🇷';
      phoneNumber = input.replace('whatsapp:+55', '');
    } else if (input.startsWith('whatsapp:+971')) {
      countryFlag = '🇦🇪';
      phoneNumber = input.replace('whatsapp:+971', '');
    } else if (input.startsWith('whatsapp:+58')) {
      countryFlag = '🇻🇪';
      phoneNumber = input.replace('whatsapp:+58', '');
    } else if (input.startsWith('whatsapp:+39')) {
      countryFlag = '🇮🇹';
      phoneNumber = input.replace('whatsapp:+39', '');
    } else if (input.startsWith('whatsapp:+44')) {
      countryFlag = '🇬🇧';
      phoneNumber = input.replace('whatsapp:+44', '');
    } else {
      return 'Número inválido';
    }

    return countryFlag + ' ' + phoneNumber;
  }

  const CopyToClipboardButton = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Reset the copied state after a delay
    };

    return (
      <Button onClick={handleCopy} style={{ marginLeft: 4 }}>
        {copied ? 'Copiado!' : 'Copiar'}
      </Button>
    );
  };

  let initialValues = {
    id: props.member.id,
    name: props.member.name,
    phone: props.member.phone, // Set phone without the countryCode
    email: props.member.email,
    expense_creator: props.member.expense_creator,
    approver: props.member.approver,
    email_on_creation: props.member.email_on_creation,
    email_on_approval: props.member.email_on_approval,
    email_on_reimbursement: props.member.email_on_reimbursement,
  };
  // console.log(initialValues);
  const onFinish = (values) => {
    setSubmitting(true);

    const data = {
      id: props.member.id,
      team: props.selectedTeam,
      expense_creator: values.expense_creator,
      approver: values.approver,
      email_on_creation: values.email_on_creation,
      email_on_approval: values.email_on_approval,
      email_on_reimbursement: values.email_on_reimbursement,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'updateUserRoles',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.onSubmit();
        props.handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (error) {
        message.error(error.response.data);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const removeMemberFromTeam = (user_email) => {
    axios({
      method: 'post',
      url: props.API_domain + 'removeMemberFromTeam',
      data: {
        user_email: user_email,
        team: props.selectedTeam,
      },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.handleOk();
        props.onSubmit();
        message.success('Usuario removido exitosamente');
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <Form
      {...layout}
      name='basic'
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item label='Nombre:'>
        <Tooltip
          title='Un admin de tu compañía puede editar el nombre desde la pestaña de Usuarios'
          placement='right'
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flex: 1 }}>
              {initialValues ? initialValues.name : ''}
            </span>
            {initialValues && (
              <CopyToClipboardButton text={initialValues.name} />
            )}
          </div>
        </Tooltip>
      </Form.Item>

      <Form.Item label='Número de celular'>
        <Tooltip
          title='Un admin de tu compañía puede editar el número desde la pestaña de Usuarios'
          placement='right'
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flex: 1 }}>
              {initialValues ? formatPhoneNumber(initialValues.phone) : ''}
            </span>
            {initialValues && (
              <CopyToClipboardButton
                text={formatPhoneNumber(initialValues.phone).split(' ')[1]}
              />
            )}
          </div>
        </Tooltip>
      </Form.Item>

      <Form.Item label='Email'>
        <Tooltip
          title='Un admin de tu compañía puede editar el correo desde la pestaña de Usuarios'
          placement='right'
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flex: 1 }}>
              {initialValues ? initialValues.email : ''}
            </span>
            {initialValues && (
              <CopyToClipboardButton text={initialValues.email} />
            )}
          </div>
        </Tooltip>
      </Form.Item>
      <Form.Item
        name='expense_creator'
        valuePropName='checked'
        label={
          <>
            Creador de gastos
            <Tooltip title='Si el usuario puede ingresar gastos por WhatsApp'>
              <InfoCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          </>
        }
      >
        <Checkbox
          style={{
            lineHeight: '32px',
          }}
        />
      </Form.Item>
      <Form.Item
        name='approver'
        valuePropName='checked'
        label={
          <>
            Aprobador de reportes
            <Tooltip title='Si el usuario puede aprobar reportes'>
              <InfoCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          </>
        }
      >
        <Checkbox
          style={{
            lineHeight: '32px',
          }}
        />
      </Form.Item>
      <Form.Item
        name='email_on_creation'
        valuePropName='checked'
        label={
          <>
            Notificar creación
            <Tooltip title='Si el usuario quiere recibir emails cuando reportes son creados'>
              <InfoCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          </>
        }
      >
        <Checkbox
          style={{
            lineHeight: '32px',
          }}
        />
      </Form.Item>
      <Form.Item
        name='email_on_approval'
        valuePropName='checked'
        label={
          <>
            Notificar aprobación
            <Tooltip title='Si el usuario quiere recibir emails cuando reportes son aprobados'>
              <InfoCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          </>
        }
      >
        <Checkbox
          style={{
            lineHeight: '32px',
          }}
        />
      </Form.Item>
      <Form.Item
        name='email_on_reimbursement'
        valuePropName='checked'
        label={
          <>
            Notificar reembolso
            <Tooltip title='Si el usuario quiere recibir emails cuando reportes son reembolsados'>
              <InfoCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          </>
        }
      >
        <Checkbox
          style={{
            lineHeight: '32px',
          }}
        />
      </Form.Item>
      <Row>
        <Col span={16}>
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{ fontWeight: 500 }}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : 'Actualizar Usuario'}
          </Button>
        </Col>
        <Col span={8}>
          <Popconfirm
            title='Estás seguro de que quieres remover a este usuario?'
            onConfirm={(event) => {
              removeMemberFromTeam(props.member.email);
              event.stopPropagation();
            }}
            onCancel={(event) => event.stopPropagation()}
            okText='Remover'
            cancelText='Cancelar'
            placement='top'
          >
            <Button
              block
              danger
              style={{ fontWeight: 500, margin: '0 4px' }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Tooltip title='No tendrá acceso a este equipo'>
                Remover usuario
              </Tooltip>
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Form>
  );
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigEditUserTeamRoles };
