import React, { useContext } from 'react';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import styled from 'styled-components/macro';

function ImageLoader(props) {
  const auth = useContext(authContext);
  const { onImageSubmit, urlToUpload } = props;
  function onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      const formData = new FormData();
      formData.append('expenseImage', img);
      axios({
        method: 'post',
        url: urlToUpload,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        auth: {
          username: auth.email,
          password: auth.token,
        },
      })
        .then(function (response) {
          console.log(response.data.imageUrl);
          onImageSubmit(response.data.imageUrl);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }

  return (
    <ImageInput
      type='file'
      name='expenseImage'
      onChange={onImageChange}
      disabled={props.disabledBoolean}
    />
  );
}

const ImageInput = styled.input`
  color: rgb(70, 70, 70);
  display: inline;
`;

export { ImageLoader };
