import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminGetCardInfo(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [cardOptions, setCardOptions] = useState();
  const [cardPan, setCardPan] = useState();
  const [cardCvv, setCardCvv] = useState();
  const [cardExp, setCardExp] = useState();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      db_card_id: values.db_card_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminGetCardInfo',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        // handleOk();
        setCardPan(response.data.pan);
        setCardCvv(response.data.cvv);
        setCardExp(response.data.expiration);
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function adminFetchCardOptions() {
    axios({
      method: 'post',
      url: props.API_domain + 'adminFetchCardOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setCardOptions(
          response.data.map((card) => ({
            label: card[0],
            value: card[1],
          }))
        );
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    if (visible) {
      adminFetchCardOptions();
    }
  }, [visible]);

  const CopyToClipboardButton = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      setCopied(true);
    };

    return (
      <Button onClick={handleCopy}>{copied ? 'Copiado!' : 'Copiar'}</Button>
    );
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Get Card Info</ButtonSpan>
      </Button>
      <Modal
        title='Get Card Info'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Get Card Info</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='db_card_id'
            label='Cardholder'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Select
              showSearch
              options={cardOptions}
              placeholder='Tarjetahabiente'
              filterOption={(input, option) =>
                option.props.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Form>
        <>
          <div style={{ textAlign: 'center' }}>
            {cardPan && (
              <Row style={{ padding: 4 }}>
                <Col style={{ textAlign: 'left' }} span={12}>
                  <div>PAN: {cardPan}</div>
                </Col>
                <Col style={{ textAlign: 'left' }} span={12}>
                  <div>
                    <CopyToClipboardButton text={cardPan} />
                  </div>
                </Col>
              </Row>
            )}

            {cardCvv && (
              <Row style={{ padding: 4 }}>
                <Col style={{ textAlign: 'left' }} span={12}>
                  <div>CVV: {cardCvv}</div>
                </Col>
                <Col style={{ textAlign: 'left' }} span={12}>
                  <div>
                    <CopyToClipboardButton text={cardCvv} />
                  </div>
                </Col>
              </Row>
            )}

            {cardExp && (
              <Row style={{ padding: 4 }}>
                <Col style={{ textAlign: 'left' }} span={12}>
                  <div>Expiration: {cardExp}</div>
                </Col>
                <Col style={{ textAlign: 'left' }} span={12}>
                  <div>
                    <CopyToClipboardButton text={cardExp} />
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminGetCardInfo };
