import { FolderViewOutlined, LoadingOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
// import { DateFilterDropdown } from './../Compras/DateFilterDropdown.js';
// import { DescriptionSearchDropdown } from './../Compras/DescriptionSearchDropdown.js';
import { useLocation } from 'react-router-dom';
import { filterCreator } from '../../utils.js';

function OrdenesTable(props) {
  const auth = useContext(authContext);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openModalOrder = queryParams.get('openModalOrder');

  useEffect(() => {
    props.setLoaded(false);
    getOrdersAllProviders();
  }, [props.submittedToggle, props.team]);

  useEffect(() => {
    if (openModalOrder && items.length > 0) {
      props.openModal(items.find((item) => item.id == openModalOrder));
    }
  }, [openModalOrder, items, props.openModal]);

  const getOrdersAllProviders = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getAllPurchaseOrders',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        assignee_filter: props.assigneeFilter,
        team: props.team,
      },
    })
      .then((response) => {
        props.setLoaded(true);
        const data = response.data;
        data.forEach(function (item) {
          if (item.created_at) {
            item.createdAtFormatted = new Intl.DateTimeFormat('es-US', {
              dateStyle: 'medium',
            }).format(
              new Date(
                item.created_at.split('/')[0],
                item.created_at.split('/')[1] - 1,
                item.created_at.split('/')[2]
              )
            );
            item.createdAtFormattedShort = new Intl.DateTimeFormat('es-US', {
              day: 'numeric',
              month: 'short',
            }).format(
              new Date(
                item.created_at.split('/')[0],
                item.created_at.split('/')[1] - 1,
                item.created_at.split('/')[2]
              )
            );
          }
        });
        data.sort((a, b) => {
          if (a.state > b.state) {
            return -1;
          } else if (a.state < b.state) {
            return 1;
          } else {
            return new Date(b.created) - new Date(a.created);
          }
        });
        setItems(data);
      })
      .catch((response) => {
        props.setLoaded(true);
        setError(response);
      });
  };

  const comprasColumns = [
    // {
    //   title: 'Estado',
    //   key: 'state',
    //   dataIndex: 'state',
    //   width: 120,
    //   sorter: (a, b) => a.state.localeCompare(b.state),
    //   sortDirections: ['ascend', 'descend'],
    //   filters: stateFilterCreator(items, 'state'),
    //   onFilter: (value, record) => {
    //     if (record.state) {
    //       return record.state.indexOf(value) === 0;
    //     } else {
    //       if (value == null) {
    //         return true;
    //       }
    //       return false;
    //     }
    //   },
    //   render: (tag, record) => {
    //     return (
    //       <Tag color={record.state_color} key={tag}>
    //         {record.state_title}
    //       </Tag>
    //     );
    //   },
    // },

    {
      title: '# Orden',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortDirections: ['ascend', 'descend'],
      ellipsis: {
        showTitle: false,
      },
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //   }) => (
      //     <DescriptionSearchDropdown
      //       setSelectedKeys={setSelectedKeys}
      //       selectedKeys={selectedKeys}
      //       confirm={confirm}
      //       clearFilters={clearFilters}
      //     />
      //   ),
      //   filterIcon: (filtered) => (
      //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      //   ),
      //   onFilter: (value, record) => {
      //     return record['number']
      //       .toString()
      //       .toLowerCase()
      //       .includes(value.toLowerCase());
      //   },
    },
    {
      title: 'Creada',
      dataIndex: 'created_at_with_hour',
      key: 'created_at_with_hour',
      width: 160,
      sorter: (a, b) => new Date(b.created) - new Date(a.created),
      sortDirections: ['ascend', 'descend'],
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //   }) => (
      //     <DateFilterDropdown
      //       setSelectedKeys={setSelectedKeys}
      //       confirm={confirm}
      //       clearFilters={clearFilters}
      //     />
      //   ),
      //   onFilter: (value, record) => {
      //     const current_date = new Date(record.created);
      //     return current_date >= value[0] && current_date <= value[1];
      //   },
    },
    {
      title: 'Asignado',
      dataIndex: 'assignee_name',
      key: 'assignee_name',
      width: 160,
      render: (text, record) => {
        return (
          // <Tag
          //   color={requisicionStateMapper.find((x) => x.value === text).color}
          // >
          //   {requisicionStateMapper.find((x) => x.value === text).title}
          // </Tag>
          text ? <Tag color='blue'>{text}</Tag> : <></>
        );
      },
      filters: filterCreator(items, 'assignee_name'),
      onFilter: (value, record) => {
        return record.assignee_name
          ? record.assignee_name.indexOf(value) === 0
          : false;
      },
    },
    {
      title: 'Equipo',
      dataIndex: 'team',
      key: 'team',
      width: 200,
      render: (team, record) => (
        <>
          <b>{record.creator_name}</b>
          <br></br>
          <span style={{ color: 'grey', size: '10px' }}>{team}</span>
        </>
      ),
    },
    {
      title: '# Requisición',
      dataIndex: 'requisition_id',
      key: 'requisition_id',
      width: 110,
      render: (text, record) => {
        return (
          <a
            href={'/requisitions?openModalRequisition=' + text}
            style={{
              textDecoration: 'underline',
            }}
          >
            {/* {Math.floor(Math.random() * 10) + 1} */}
            {text}
          </a>
        );
      },
    },
    // {
    //   title: 'Recibidos',
    //   dataIndex: 'recibidos',
    //   key: 'recibidos',
    //   width: 80,
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {text > 0 ? <FolderViewOutlined style={{ color: 'grey' }} /> : null}
    //       </>
    //     );
    //   },
    // },
    {
      title: 'Facturas',
      dataIndex: 'invoices',
      key: 'invoices',
      width: 160,
      render: (text, record) => {
        return (
          <div>
            {record.invoice_ids.map((invoiceId, index) => (
              <>
                <a
                  key={index}
                  href={'/invoices?openModalInvoice=' + invoiceId}
                  style={{ textDecoration: 'underline' }}
                >
                  {invoiceId}
                </a>{' '}
              </>
            ))}
          </div>
        );
      },
    },
  ];
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!props.isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <>
        <Table
          columns={comprasColumns}
          dataSource={items}
          bordered={false}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          }}
          // pagination={{ defaultPageSize: 50 }}
          pagination={false}
          sticky
          size='middle'
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (event.target.tagName !== 'A') {
                  props.openModal(record);
                }
              },
            };
          }}
          rowClassName={(record, index) => {
            return 'cursor-pointer';
          }}
        />
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { OrdenesTable };
