import { LoadingOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Button, Empty, Table, Tag, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { FormattedUSD } from './FormattedUSD.js';

function ReportsTable(props) {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const auth = useContext(authContext);

  items.forEach(function (item) {
    if (item.inserted) {
      item.insertedFormatted = new Intl.DateTimeFormat('es-US', {
        dateStyle: 'medium',
      }).format(
        new Date(
          item.inserted.split('/')[0],
          item.inserted.split('/')[1] - 1,
          item.inserted.split('/')[2]
        )
      );
      item.insertedFormattedShort = new Intl.DateTimeFormat('es-US', {
        day: 'numeric',
        month: 'short',
      }).format(
        new Date(
          item.inserted.split('/')[0],
          item.inserted.split('/')[1] - 1,
          item.inserted.split('/')[2]
        )
      );
    }
  });

  function filterCreator(data, column) {
    const columnSet = Array.from(new Set(data.map((item) => item[column])));
    return columnSet.map((item) => ({
      text: item,
      value: item,
    }));
  }

  let columns = [];
  {
    props.mobile
      ? (columns = [
          // MOBILE
          {
            key: 'status',
            dataIndex: ['id', 'status', 'name'],
            render: (text, record) => {
              let color = record.status.length > 10 ? 'green' : 'geekblue';
              if (record.status === 'creado') {
                color = 'volcano';
              }
              return (
                <div>
                  <div>
                    <Tooltip placement='topLeft' title={record.descripcion}>
                      {record.title}
                    </Tooltip>
                  </div>
                  <div style={{ paddingTop: 6 }}>
                    <Tooltip placement='topLeft' title={record.status}>
                      <Tag color={color} key={record.status.toUpperCase()}>
                        {record.status.toUpperCase()}
                      </Tag>
                    </Tooltip>
                  </div>
                  <div style={{ paddingTop: 6 }}>
                    <b>{record.name}</b>
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Fecha',
            dataIndex: 'insertedFormattedShort',
            key: 'fecha',
            width: 80,
          },
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 75,
            render: (total) => {
              return <FormattedUSD total={total} />;
            },
          },
        ])
      : (columns = [
          // DESKTOP
          {
            title: 'Estado',
            key: 'status',
            dataIndex: 'status',
            width: 200,
            filters: filterCreator(items, 'status'),
            onFilter: (value, record) => {
              if (record.status) {
                return record.status.indexOf(value) === 0;
              } else {
                if (value == null) {
                  return true;
                }
                return false;
              }
            },
            render: (tag) => {
              // TODO: refactor, que nasty.
              let color = tag.length > 10 ? 'green' : 'geekblue';
              if (tag === 'creado') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            },
          },
          {
            title: 'Responsable de reporte',
            dataIndex: 'name',
            key: 'name',
            width: 240,
            filters: filterCreator(items, 'name'),
            onFilter: (value, report) => {
              if (report.name) {
                return report.name.indexOf(value) === 0;
              } else {
                if (value == null) {
                  return true;
                }
                return false;
              }
            },
            render: (name, report) => (
              <Tooltip placement='topLeft' title={report.team}>
                <b>{name}</b>
                <br></br>
                <span style={{ color: 'grey', size: '10px' }}>
                  {report.team}
                </span>
              </Tooltip>
            ),
          },
          {
            title: 'Título',
            dataIndex: 'id',
            key: 'titulo',
            render: (id, report) => {
              return <span>{report.title}</span>;
            },
          },
          {
            title: 'Monto total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 140,
            render: (total) => {
              return <FormattedUSD total={total} />;
            },
          },
          {
            title: 'Fecha de generación',
            dataIndex: 'insertedFormatted',
            key: 'inserted',
            width: 200,
          },
        ]);
  }

  useEffect(() => {
    props.setLoaded(false);
    getReports();
  }, [props.submittedToggle, props.team, props.filterPendingUserApproval]);

  const getReports = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getReports',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        filterPendingUserApproval: props.filterPendingUserApproval,
        team: props.team,
      },
    })
      .then((response) => {
        props.setLoaded(true);
        setItems(response.data);
      })
      .catch((response) => {
        props.setLoaded(true);
        setError(response);
      });
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!props.isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <Table
        dataSource={items}
        columns={columns}
        pagination={{ defaultPageSize: 50 }}
        style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              props.openModal(record);
            },
          };
        }}
        rowClassName={(record, index) => {
          return 'cursor-pointer';
        }}
        locale={{
          emptyText: (
            <Empty
              style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <div>
                    Puedes crear un reporte en el Botón de Crear Reporte
                  </div>
                </>
              }
            />
          ),
        }}
      />
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { ReportsTable };
