import { Button, Form, Input, Modal, Select, message } from 'antd';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminZohoFetchNewAccounts(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    if (visible) {
      getAccountingClientOptions();
    }
  }, [visible]);

  const getAccountingClientOptions = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingClients',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.name}
        </Option>
      ));
      setClientOptions(options);
    } catch (error) {
      console.error(
        'There was an error fetching the accounting clients',
        error
      );
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      client_id: values.client_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminFetchNewZohoAccounts',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setVisible(false);
        setSubmitting(false);
        message.success(
          `Se crearon ${response.data.amount_new_accounts} cuentas`
        );
        form.resetFields();
      })
      .catch((error) => {
        message.error('Error creando las cuentas');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Halar Cuentas Zoho</ButtonSpan>
      </Button>
      <Modal
        title='Fetch new Zoho accounts'
        visible={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        confirmLoading={isSubmitting}
        centered
      >
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name='client_id'
            label='Client ID'
            rules={[{ required: true, message: 'Please select the client' }]}
          >
            <Select
              showSearch
              placeholder='Select a client'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientOptions}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminZohoFetchNewAccounts };
