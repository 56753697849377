import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function EmployerContributionsChart({ contributions }) {
  const data = {
    labels: ['CSS', 'Seguro Educativo', 'Riesgo Profesional'],
    datasets: [
      {
        data: [
          contributions.monthlyEmployerCSS,
          contributions.monthlyEmployerSE,
          contributions.monthlyEmployerRiskPremiumBenefit,
        ],
        backgroundColor: ['#4A90E2', '#50A6E2', '#5AB3E2'], // css, se, rp
        hoverBackgroundColor: ['#4A90E2', '#50A6E2', '#5AB3E2'],
        borderColor: [
          // Employer Contributions
          '#357ABD',
          '#357ABD',
          '#357ABD',
        ],
      },
    ],
  };

  const totalContributions = contributions.monthlyTotalEmployerContributions;

  // Helper function to format numbers as USD
  const formatUSD = (value) =>
    '$' +
    value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  let isMobile = window.innerWidth <= 500;

  const options = {
    layout: {
      padding: {
        top: 8,
        bottom: 8,
        // left: 8,
        // right: 8,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        display: (context) => {
          if (isMobile) {
            return false;
          } else {
            return context.dataset.data[context.dataIndex] !== 0;
          }
        },
        formatter: (value) => {
          const percentage =
            ((value / totalContributions) * 100).toFixed(1) + '%';
          const amount = formatUSD(value);
          return `${amount}`;
        },
        color: '#fff',
        font: {
          size: 12,
        },
        // anchor: 'end',
        align: 'end',
        // offset: 8, // Increase this value to move labels further out
        padding: -6,
        clip: false,
      },
    },
  };

  return <Doughnut data={data} options={options} />;
}

export default EmployerContributionsChart;
