// axiosInterceptor.js
import axios from 'axios';
import Cookies from 'universal-cookie';

const API_domain =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/'
    : 'https://www.herofacturas.com/';

const HeroAxios = axios.create({
  baseURL: API_domain,
});

// Add the request interceptor
HeroAxios.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();

    // User credentials
    const email = cookies.get('email');
    const token = cookies.get('token');

    if (email && token) {
      config.auth = {
        username: email,
        password: token,
      };
    }

    // Admin credentials
    const adminEmail = cookies.get('adminEmail');
    const adminToken = cookies.get('adminToken');

    if (adminEmail && adminToken) {
      config.headers = {
        ...config.headers, // Preserve existing headers
        'X-Admin-Email': adminEmail,
        'X-Admin-Token': adminToken,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default HeroAxios;
