import React, { useContext, useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message, List } from 'antd';
import { FileTextOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import EmailInput from './EmailInput';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';

const EmailReviewModal = ({ visible, onCancel, initialValues = {}, API_domain }) => {
  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useContext(authContext);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        to: initialValues.to ? initialValues.to.split(',').map(email => email.trim()) : [],
        subject: initialValues.subject || '',
        body: initialValues.body || '',
      });
      
      if (initialValues.attachments && Array.isArray(initialValues.attachments)) {
        const attachmentsWithUrls = initialValues.attachments.map(attachment => ({
          ...attachment,
          url: URL.createObjectURL(attachment.content)
        }));
        setAttachments(attachmentsWithUrls);
      } else {
        setAttachments([]);
      }
    }

    return () => {
      attachments.forEach(attachment => {
        if (attachment.url) {
          URL.revokeObjectURL(attachment.url);
        }
      });
    };
  }, [initialValues, form]);

  const handleSend = (isTestMode = false) => {
    setLoading(true);
    form
      .validateFields()
      .then(values => {
        const formData = new FormData();
        
        formData.append('to', values.to.join(','));
        formData.append('subject', values.subject);
        
        // Format the email body with proper HTML and CSS, preserving multiple spaces
        const formattedBody = `
          <html>
            <head>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  font-size: 14px;
                  line-height: 1.6;
                  color: #333;
                }
                p {
                  margin-bottom: 10px;
                  white-space: pre-wrap;
                }
              </style>
            </head>
            <body>
              ${values.body.split('\n').map(line => `<p>${line.replace(/ /g, '&nbsp;')}</p>`).join('')}
            </body>
          </html>
        `;
        formData.append('body', formattedBody);
        formData.append('from_address', "contabilidad@herofacturas.com");
        
        attachments.forEach(attachment => {
          formData.append('attachments', attachment.content, attachment.name);
        });

        axios({
          method: 'post',
          url: `${API_domain}SendHeroEmail${isTestMode ? '?test_flag=true' : ''}`,
          data: formData,
          auth: {
            username: auth.email,
            password: auth.token,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            message.success(
              <>
                Planilla y comprobantes enviados a:{' '}
                <b>{response.data.join(', ')}.</b>
              </>,
              15
            );
            form.resetFields();
            setAttachments([]);
            onCancel();
          })
          .catch((error) => {
            console.error('Error:', error);
            message.error('Failed to send email. Please try again.');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  };

  const handleFileClick = (url, filename) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal
      visible={visible}
      title={<div className="text-lg font-semibold">Verificar Correo:</div>}
      onCancel={() => {
        onCancel();
        attachments.forEach(attachment => {
          if (attachment.url) {
            URL.revokeObjectURL(attachment.url);
          }
        });
      }}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="send"
          type="primary"
          onClick={() => {
            const istest = false;
            handleSend(istest);
          }}
          icon={<SendOutlined />}
          loading={loading}
        >
          Send
        </Button>
      ]}
      width={600}
    >
      <Form form={form} layout="vertical" className="mt-4">
        <Form.Item
          name="to"
          label="Para:"
          rules={[{ required: true, message: 'Por favor ingrese al menos un destinatario' }]}
        >
          <EmailInput />
        </Form.Item>
        <Form.Item
          name="subject"
          label="Asunto:"
          rules={[{ required: true, message: 'Por favor ingrese un asunto' }]}
        >
          <Input prefix={<FileTextOutlined className="text-gray-400" />} placeholder="Email subject" />
        </Form.Item>
        <Form.Item
          name="body"
          label="Cuerpo del Mensaje:"
          rules={[{ required: true, message: 'Please input the email body!' }]}
        >
          <Input.TextArea rows={9} placeholder="Email body" />
        </Form.Item>
        <Form.Item label="Adjuntos:">
          <List
            size="small"
            dataSource={attachments}
            renderItem={item => (
              <List.Item className="px-2 py-1 hover:bg-gray-100 rounded">
                <Button 
                  type="link" 
                  icon={<PaperClipOutlined className="text-gray-500" />}
                  onClick={() => handleFileClick(item.url, item.name)}
                  className="flex items-center text-blue-500 hover:text-blue-700"
                >
                  {item.name}
                </Button>
              </List.Item>
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmailReviewModal;