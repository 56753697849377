import React, { useState } from 'react';
import { Form, Input, message, Button, Row, Col } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const MunicipioIntegration = (props) => {
  const [usuario, setUsuario] = useState(
    props.municipioCredentials.login_info.usuario || ''
  );
  const [contrasena, setContrasena] = useState(
    props.municipioCredentials.login_info.contrasena || ''
  );
  const [loading, setLoading] = useState(false); // State to manage the loading status

  const handleSave = async () => {
    setLoading(true); // Start loading

    try {
      const updatedCredentials = {
        accounting_client_id: props.municipioCredentials.accounting_client_id,
        id: props.municipioCredentials.id, // Unique ID for the Municipio configuration
        site: 'municipio',
        login_info: {
          usuario,
          contrasena,
        },
      };

      // Send only the relevant credentials to the backend
      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Actualización exitosa');
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error actualizando');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <h3 style={{ marginLeft: 4, textAlign: 'left' }}>MUNICIPIO</h3>
      <Form.Item label='Usuario' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(usuario)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label='Contraseña' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={contrasena}
              onChange={(e) => setContrasena(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(contrasena)}
            />
          </Col>
        </Row>
      </Form.Item>
      <div style={{ textAlign: 'left', marginLeft: 96 }}>
        <Button
          type='primary'
          onClick={handleSave}
          loading={loading} // Apply loading state to the button
          style={{ marginRight: 16 }}
        >
          Guardar
        </Button>
        <Button
          type='secondary'
          onClick={() => console.log('validating')}
          style={{
            backgroundColor: 'hsl(0, 0%, 95%)',
            borderColor: 'hsl(0, 0%, 95%)',
          }}
          disabled
        >
          Validar Municipio
          {/* {props.renderValidationIcon(props.municipioCredentials.dgi_last_verify)} */}{' '}
          ✅
        </Button>
      </div>
    </div>
  );
};

export default MunicipioIntegration;
