import { SyncOutlined } from '@ant-design/icons';

function Refresh(props) {
  return (
    <div
      style={{
        display: 'inline',
        cursor: 'pointer',
        color: 'rgb(116, 116, 205)',
        marginRight: 4,
        height: 16,
        'vertical-align': 'middle',
      }}
    >
      <SyncOutlined
        onClick={props.onClick}
        style={{ fontSize: '24px' }}
        spin={props.spin}
      />
    </div>
  );
}
export { Refresh };
