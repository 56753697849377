import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

function ConfigRenameTeamForm(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [helpMessage, setHelpMessage] = useState(null);
  const [validateStatus, setValidateStatus] = useState(null);

  function refreshPage() {
    window.location.reload(false);
  }

  const showDeleteTeamConfirm = (record) => {
    Modal.confirm({
      title:
        'Estás seguro de que quieres eliminar este equipo?\n\n Se eliminarán todos los gastos del equipo.\n\n No podrás deshacer esta acción. \n\n Tip: si dejas de ser "Creador de gastos" en este equipo, no te aparecerá como opción en WhatsApp.',
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTeam();
        // console.log('delete team');
      },
    });
  };

  const deleteTeam = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'deleteTeam',
      data: { team: props.oldTeamName },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Equipo borrado exitosamente');
          props.onSubmit();
          props.handleOk();
          setSubmitting(!isSubmitting);
          refreshPage();
        } else {
          console.log('Failed to remove user');
        }
      })
      .catch((error) => {
        console.log('Error removing user:', error);
      });
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      old_team_name: props.oldTeamName,
      new_team_name: values.newTeamName,
    };

    axios({
      method: 'post',
      url: props.API_domain + props.API_endPoint,
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.onSubmit();
        props.handleOk();
        setTimeout(() => setSubmitting(false), 2000);
        refreshPage();
      })
      .catch(function (response) {
        setValidateStatus('error');
        const errorMessage =
          response.message == 'Network Error'
            ? 'Hay un problema con tu conexión'
            : 'Este correo ya existe en nuestra base de datos';
        setHelpMessage(errorMessage);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      {...layout}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={{ newTeamName: props.oldTeamName }}
    >
      <Form.Item
        name='newTeamName'
        label='Nuevo Nombre:'
        rules={[
          {
            required: true,
            message: 'Escriba el nuevo nombre para el equipo',
          },
        ]}
      >
        <Input placeholder='JOB 123 Compras' />
      </Form.Item>
      <Row>
        <Col span={16}>
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
            }}
          >
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Renombrar Equipo</>
            )}
          </Button>
        </Col>
        <Col span={8}>
          <Button
            block
            danger
            style={{ fontWeight: 500, margin: '0 4px' }}
            onClick={() => showDeleteTeamConfirm(props.member)}
          >
            Eliminar equipo
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigRenameTeamForm };
