import { Image } from 'antd';
import React, { useState, useEffect } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

function ImgOrPdf(props) {
  const [isImage, setIsImage] = useState(true);
  useEffect(() => {
    setIsImage(true);
  }, [props.src.imageSource]);
  if (isImage) {
    return (
      <>
        <Image
          style={{ borderRadius: '4px', display: 'none' }}
          onError={(event) => setIsImage(false)}
          src={props.src.imageSource}
        />
        <InnerImageZoom
          key={props.src.imageSource}
          src={props.src.imageSource}
          hideHint={true}
          zoomScale={1.25}
          moveType='drag'
        />
      </>
    );
  } else {
    if (props.expense && props.expense.descripcion?.includes('.xls')) {
      return (
        <iframe // This is the image that is rendered if it is not a thumbnail
          src={
            'https://docs.google.com/viewer?embedded=true&url=' +
            props.src.imageSource
          }
          height={props.height ? props.height : '100%'}
          // width='100%'
          width='350'
          setIsImage={setIsImage}
          toolbar='0'
        ></iframe>
      );
    } else {
      return (
        <embed
          src={props.src.imageSource}
          width='100%'
          height='100%'
          type='application/pdf'
        ></embed>
      );
    }
  }
}

export { ImgOrPdf };
