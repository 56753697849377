import { Modal } from 'antd';
import { ConfigAddUserToTeam } from './ConfigAddUserToTeam.js';
import { ConfigEditUserTeamRoles } from './ConfigEditUserTeamRoles.js';

function ConfigTeamMemberModal(props) {
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      footer={false}
      onCancel={props.handleCancel}
      width={550}
    >
      {props.API_endPoint === 'addUserToTeam' ? (
        <ConfigAddUserToTeam
          API_domain={props.API_domain}
          API_endPoint={props.API_endPoint}
          onSubmit={props.onSubmit}
          handleOk={props.handleOk}
          member={props.member}
          isOwner={props.isOwner}
          subscriptionId={props.subscriptionId}
          selectedTeam={props.selectedTeam}
        />
      ) : (
        <ConfigEditUserTeamRoles
          API_domain={props.API_domain}
          onSubmit={props.onSubmit}
          handleOk={props.handleOk}
          isOwner={props.isOwner}
          member={props.selectedMember}
          subscriptionId={props.subscriptionId}
          selectedTeam={props.selectedTeam}
        />
      )}
    </Modal>
  );
}

export { ConfigTeamMemberModal };
