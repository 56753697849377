import { Button, Form, Input, Modal, Select, message } from 'antd';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminPayrollToZoho(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [payrollOptions, setPayrollOptions] = useState([]);

  useEffect(() => {
    if (visible) {
      getApprovedPayrollOptions();
    }
  }, [visible]);

  const getApprovedPayrollOptions = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: props.API_domain + 'getApprovedPayrolls',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((payroll) => (
        <Option key={payroll.value} value={payroll.value}>
          {payroll.label}
        </Option>
      ));
      setPayrollOptions(options);
    } catch (error) {
      console.error('There was an error fetching the approved payrolls', error);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      payrollId: values.payroll_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'getCSVwithPayrollDetails',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        console.log(response.data);
        setVisible(false);
        setSubmitting(false);
        form.resetFields();
      })
      .catch((error) => {
        message.error('Error fetching payroll details');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Planilla pa Zoho</ButtonSpan>
      </Button>
      <Modal
        title='Planilla Pa Zoho'
        visible={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        confirmLoading={isSubmitting}
        centered
      >
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name='payroll_id'
            label='Planilla'
            rules={[{ required: true, message: 'Please select the payroll' }]}
          >
            <Select
              showSearch
              placeholder='Select a payroll'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {payrollOptions}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminPayrollToZoho };
