import {
  CheckCircleFilled,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Upload,
  Row,
  message,
} from 'antd';
import { React, useEffect, useState, useContext } from 'react';
import { ProviderSelect } from './../Compras/ProviderSelect.js';
import { OrdenesNewOrdenRequisitionSelect } from './OrdenesNewOrdenRequisitionSelect.js';
import { authContext } from '../../ProvideAuth.js';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const buttonItemLayout = {
  wrapperCol: { span: 14, offset: 8 },
};

function OrdenesNewOrdenModal(props) {
  const auth = useContext(authContext);
  const history = useHistory();
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [amountOfFiles, setAmountOfFiles] = useState(0);

  const [selectedTeam, setSelectedTeam] = useState(
    new URLSearchParams(location.search).get('Equipo')
      ? new URLSearchParams(location.search).get('Equipo')
      : []
  );
  const [selectedRequisition, setSelectedRequisition] = useState(
    new URLSearchParams(location.search).get('Requisition')
      ? new URLSearchParams(location.search).get('Requisition')
      : []
  );
  const [selectedProviderId, setSelectedProviderId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  function onFileChange(event) {
    if (event.file.status === 'uploading') {
      setUploading(true);
    }
    if (event.file.status !== 'uploading') {
      console.log(event.file, event.fileList);
    }
    if (event.file.status === 'done') {
      setImageUrl(event.file.response.file_url);
      message.success('Documento subido exitosamente');
      setAmountOfFiles(amountOfFiles + 1);
      setUploading(false);
    } else if (event.file.status === 'error') {
      setUploading(false);
      message.error(`${event.file.name} file upload failed.`);
    }
  }
  // form submission handler
  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      team: selectedTeam,
      legit_provider_id: selectedProviderId,
      image_url: imageUrl,
      requisition_id: selectedRequisition,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'createPurchaseOrder',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: data,
    })
      .then((response) => {
        // props.setLoaded(true);
        setSubmitting(false);
        props.handleOk();
      })
      .catch((response) => {
        setSubmitting(false);
        message.error(error.response.data);
        // props.setLoaded(true);
        // setError(response);
      });
  };

  return (
    <Modal
      visible={props.showNewOrdenModal}
      onCancel={() => {
        props.setShowNewOrdenModal(false);
        setSelectedRequisition([]);
        setSelectedTeam([]);

        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete('showNewOrdenModal');
        newUrl.searchParams.delete('Equipo');
        newUrl.searchParams.delete('Requisition');
        history.replace(newUrl.pathname + newUrl.search);
      }}
      footer={null}
      title={'Nueva Orden'}
      width={700}
    >
      <Upload.Dragger
        name='file'
        action={props.API_domain + 'fileUpload'}
        showUploadList={false}
        headers={{
          Authorization: `Basic ${btoa(`${auth.email}:${auth.token}`)}`,
        }}
        onChange={onFileChange}
        onDrop={(e) => {
          console.log('Dropped files', e.dataTransfer.files);
        }}
        style={{
          backgroundColor: 'rgba(116, 116, 205, 0.15)',
          width: 'block',
        }}
      >
        {uploading ? (
          <LoadingOutlined style={{ fontSize: 50 }} spin />
        ) : (
          <Row align='center'>
            <Col style={{ paddingRight: 5 }}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined style={{ fontSize: 50 }} />
              </p>
            </Col>
            <Col align='left' style={{ paddingLeft: 5 }}>
              <p className='ant-upload-text'>Nueva Orden</p>
              <p className='ant-upload-hint'>Clickea o arrastra documento</p>
            </Col>
          </Row>
        )}
      </Upload.Dragger>
      {amountOfFiles > 0 ? (
        <>
          <div style={{ textAlign: 'center', margin: 10, fontWeight: '200' }}>
            {amountOfFiles} documento subido exitosamente
            <CheckCircleFilled style={{ color: 'green', padding: 10 }} />
          </div>
        </>
      ) : (
        <br></br>
      )}
      <Form onFinish={onFinish} {...layout}>
        <Form.Item label='Requisición'>
          <OrdenesNewOrdenRequisitionSelect
            API_domain={props.API_domain}
            onRequisitionSelect={setSelectedRequisition}
            requisition={selectedRequisition}
            disabled={
              new URLSearchParams(location.search).get('Requisition')
                ? true
                : false
            }
            noPadding={true}
          />
        </Form.Item>
        {/* <Form.Item label='Equipo'>
          <TeamSelect
            defaultTeam={selectedTeam}
            API_domain={props.API_domain}
            onTeamSelect={setSelectedTeam}
            team={selectedTeam}
            disabled={
              new URLSearchParams(location.search).get('Equipo') ? true : false
            }
            noPadding={true}
          />
        </Form.Item> */}
        <Form.Item label='Proveedor'>
          <ProviderSelect
            API_domain={props.API_domain}
            onChange={setSelectedProviderId}
            onCancel={props.handleCancel}
          />
        </Form.Item>

        <Form.Item {...buttonItemLayout}>
          <Button type='primary' htmlType='submit' style={{ width: '100%' }} disabled={!imageUrl}>
            {submitting ? (
              <LoadingOutlined style={{ fontSize: 20 }} spin />
            ) : (
              <>Crear Orden</>
            )}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export { OrdenesNewOrdenModal };
