import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import { Button, Table, Tag, Tooltip } from 'antd';
import {
  CreditCardOutlined,
  EditOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components/macro';
import { formatPhoneNumber } from '../utils.js';
import { ConfigUserModal } from './ConfigUserModal.js';

const ConfigUserSettings = (props) => {
  const auth = useContext(authContext);
  const [subscriptionUsers, setSubscriptionUsers] = useState(0);
  const [visible, setVisible] = useState(false);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [API_endPoint, setAPI_endPoint] = useState('');
  const [
    subscriptionItemWithHighestQuantity,
    setSubscriptionItemWithHighestQuantity,
  ] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOk = () => {
    setVisible(false);
    setSubmittedToggle(!submittedToggle);
  };
  // call getTeams to get the team options for the form
  const getTeams = () => {
    axios({
      method: 'get',
      url: props.API_domain + 'getTeams',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setTeamOptions(response.data);
      })
      .catch((error) => {
        console.log('Error fetching teams:', error);
      });
  };

  const fetchSubscriptionUsers = () => {
    setIsLoaded(false);
    axios({
      method: 'get',
      url: props.API_domain + 'getSubscriptionUsers',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        if (response.data.subscriptionUsers) {
          setSubscriptionUsers(response.data.subscriptionUsers);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log('Error fetching subscription users:', error);
        setIsLoaded(true);
      });
  };

  const getSubscriptionItems = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getSubscriptionItems',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        subscriptionId: props.subscriptionId,
      },
    })
      .then((response) => {
        if (response.data.subscriptionItems) {
          let subscriptionItemWithHighestQuantity =
            response.data.subscriptionItems[0];

          response.data.subscriptionItems.forEach((subscriptionItem) => {
            if (
              subscriptionItem.quantity >
              subscriptionItemWithHighestQuantity.quantity
            ) {
              subscriptionItemWithHighestQuantity = subscriptionItem;
            }
          });
          setSubscriptionItemWithHighestQuantity(
            subscriptionItemWithHighestQuantity
          );
        }
      })
      .catch((error) => {
        console.log('Error fetching Stripe Subscription URL:', error);
      });
    // setSubscriptionItemWithHighestQuantity({ quantity: 5 });
  };

  const getStripeSubscriptionURL = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'stripeGetSubscriptionURL',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        subscriptionId: props.subscriptionId,
      },
    })
      .then((response) => {
        if (response.data.sessionURL) {
          window.open(response.data.sessionURL, '_blank');
        }
      })
      .catch((error) => {
        console.log('Error fetching Stripe Subscription URL:', error);
      });
  };
  let quantityOfAvailableUsers =
    subscriptionItemWithHighestQuantity.quantity - subscriptionUsers.length;
  useEffect(() => {
    fetchSubscriptionUsers();
    getTeams();
    getSubscriptionItems();
  }, [submittedToggle]);

  const columns = [
    {
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Celular',
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
      render: (phone) => {
        if (phone.includes('phone')) {
          return '';
        }
        return formatPhoneNumber(phone);
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Equipos',
      dataIndex: 'finca',
      key: 'finca',
      width: 160,
      render: (finca) => {
        if (finca.length > 0) {
          return (
            <Tooltip
              // title must be each finca name separated by a new line and each new line must have a number
              title={finca.map((finca, index) => {
                return (
                  <div>
                    {index + 1}. {finca}
                  </div>
                );
              })}
              placement='left'
            >
              <Tag color='orange'>{finca.length}</Tag>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              // title must be each finca name separated by a new line and each new line must have a number
              title='Desde la pestaña de Equipos podrás agregar a este usuario a un equipo'
              placement='left'
            >
              <span style={{ marginRight: 8 }}>{finca.length}</span>
              <Tag color='purple'>Pendiente</Tag>
            </Tooltip>
          );
        }
      },
    },
    {
      title: 'Admin',
      dataIndex: 'stripe_subscription_id',
      key: 'admin',
      width: 80,
      render: (stripe_subscription_id) => {
        if (stripe_subscription_id) {
          return (
            <Tooltip
              title={
                <>
                  Puede<div> - Habilitar/Deshabilitar a otros Admins</div>
                  <div>- Ver y editar todos los usuarios de la compañía</div>
                </>
              }
              placement='left'
            >
              <Tag color='blue'>Admin</Tag>
            </Tooltip>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button type='text' icon={<EditOutlined style={{ color: 'grey' }} />} />
      ),
    },
  ];
  if (!isLoaded) {
    return <LoadingDiv>{antLoadingIcon}</LoadingDiv>;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div style={{ width: '100%', maxWidth: '1000px' }}>
        {subscriptionUsers && (
          <>
            {selectedMember && (
              <ConfigUserModal
                visible={true}
                title={
                  API_endPoint === 'createNewUser'
                    ? 'Crear Usuario'
                    : 'Editar Usuario'
                }
                handleCancel={() => setSelectedMember(null)}
                handleOk={() => setSelectedMember(null)}
                onSubmit={handleOk}
                member={selectedMember}
                API_domain={props.API_domain}
                API_endPoint={API_endPoint}
                team={props.team}
                isOwner={props.isOwner}
                subscriptionId={props.subscriptionId}
                teamOptions={teamOptions}
                quantityOfAvailableUsers={quantityOfAvailableUsers}
              />
            )}
            <div style={{ marginTop: 20, textAlign: 'right' }}>
              <Button
                type='primary'
                onClick={() => {
                  setSelectedMember(true);
                  setAPI_endPoint('createNewUser');
                }}
                style={{ width: 200, margin: 4 }}
              >
                <PlusOutlined />
                Crear Usuario Nuevo
              </Button>
              <Button
                type='secondary'
                onClick={getStripeSubscriptionURL}
                style={{ width: 200, margin: 4 }}
              >
                Manejar Suscripción <CreditCardOutlined />
              </Button>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Table
                dataSource={subscriptionUsers}
                columns={columns}
                style={{
                  paddingTop: 10,
                  maxWidth: 1200,
                }}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setSelectedMember(record);
                      setAPI_endPoint('updateUserNamePhoneEmail');
                    },
                  };
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const antLoadingIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { ConfigUserSettings };
