import {
  Affix,
  Button,
  Col,
  Layout,
  Row,
  Table,
  Tooltip,
  Upload,
  message,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from '../TeamSelect.js';
import { authContext } from '../../ProvideAuth.js';
import { CardSelect } from '../CardSelect.js';
import { ConciliationExpensesTable } from './ConciliationExpensesTable.js';
import { ConciliationTransactionsTable } from './ConciliationTransactionsTable.js';
import { FormattedUSD } from './../FormattedUSD.js';
import axios from 'axios';

const { Content } = Layout;

function ConciliationView(props) {
  const auth = useContext(authContext);
  const [team, setTeam] = useState();
  const [card, setCard] = useState();
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const calculateTransactionTotal = () =>
    selectedTransaction.reduce(
      (total, transaction) => total + parseFloat(transaction.total),
      0
    );
  const calculateExpensesTotal = () =>
    selectedExpenses.reduce(
      (total, expense) => total + parseFloat(expense.total),
      0
    );
  const handleTransactionUnselect = () => {
    setSelectedTransaction([]);
  };
  const handleExpensesUnselect = () => {
    setSelectedExpenses([]);
  };

  const handleJoin = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'joinExpensesAndTransactions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        expenses: selectedExpenses,
        transactions: selectedTransaction,
      },
    })
      .then(function (response) {
        message.success('Unión exitosa');
        // Remove the selected expenses and transactions
        setTransactions(
          transactions.filter(
            (transaction) => !selectedTransaction.includes(transaction)
          )
        );
        setExpenses(
          expenses.filter((expense) => !selectedExpenses.includes(expense))
        );

        setSelectedExpenses([]);
        setSelectedTransaction([]);

        console.log(response);
      })
      .catch(function (response) {
        message.error('Ocurrío un error uniendo');
        console.log(response);
      });
  };

  return (
    <Content
      style={{
        margin: '0',
        overflow: 'initial',
        // border: 'solid rgb(235,235,235) 1px',
        height: '100vh', // Ensure the Content takes full viewport height
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Affix>
        <AffixDiv>
          <StyledRow>
            <StyledCol span={12}>
              <Row align='middle' justify='start'>
                <Col>
                  {/* <h2 style={{ marginBottom: 0 }}>💳 🏧 🏦 Transacciones</h2> */}
                  <h2 style={{ marginBottom: 0 }}>💳 Transacciones</h2>
                </Col>
                <Col flex='auto'>
                  <CardSelect
                    API_domain={props.API_domain}
                    onCardSelect={setCard}
                    selectedCard={card}
                  />
                </Col>
              </Row>
            </StyledCol>
            <StyledCol span={12}>
              <Row align='middle' justify='start'>
                <Col>
                  {/* <h2 style={{ marginBottom: 0 }}>📥 🧾 📲 Gastos</h2> */}
                  <h2 style={{ marginBottom: 0 }}>📲 Gastos</h2>
                </Col>
                <Col flex='auto'>
                  <TeamSelect
                    API_domain={props.API_domain}
                    onTeamSelect={setTeam}
                    team={team}
                    exclude_all_teams={true}
                  />
                </Col>
              </Row>
            </StyledCol>
          </StyledRow>
        </AffixDiv>
      </Affix>
      <StyledRow style={{ height: 'calc(100% - 105px)' }}>
        <StyledCol span={12}>
          <ScrollableTableContainer>
            <ConciliationTransactionsTable
              API_domain={props.API_domain}
              card={card}
              selectedTransaction={selectedTransaction}
              setSelectedTransaction={setSelectedTransaction}
              transactions={transactions}
              setTransactions={setTransactions}
            />
          </ScrollableTableContainer>
        </StyledCol>
        <StyledCol span={12}>
          <ScrollableTableContainer>
            <ConciliationExpensesTable
              API_domain={props.API_domain}
              team={team}
              selectedExpenses={selectedExpenses}
              setSelectedExpenses={setSelectedExpenses}
              expenses={expenses}
              setExpenses={setExpenses}
            />
          </ScrollableTableContainer>
        </StyledCol>
      </StyledRow>
      <Affix offsetBottom={0}>
        <AffixDivBottom>
          <Row>
            <Col span={10} style={{ textAlign: 'right' }}>
              {selectedTransaction.length > 0 && (
                <>
                  <Button
                    onClick={handleTransactionUnselect}
                    type='secondary'
                    style={{ marginRight: 10 }}
                  >
                    Deseleccionar
                  </Button>
                  <></>Total:{' '}
                  <b>
                    <FormattedUSD total={calculateTransactionTotal()} />
                  </b>
                </>
              )}
            </Col>
            <Col span={4} style={{ textAlign: 'center' }}>
              <Button
                type='primary'
                style={{ marginRight: 10 }}
                disabled={
                  selectedExpenses.length < 1 || selectedTransaction < 1
                }
                onClick={handleJoin}
              >
                <Tooltip
                  title={
                    selectedExpenses.length < 1 || selectedTransaction < 1
                      ? 'Selecciona al menos un gasto y una transacción'
                      : ''
                  }
                >
                  Unir
                </Tooltip>
              </Button>
            </Col>
            <Col span={10} style={{ textAlign: 'left' }}>
              {selectedExpenses.length > 0 && (
                <>
                  Total:{' '}
                  <b>
                    <FormattedUSD total={calculateExpensesTotal()} />
                  </b>
                  <Button
                    onClick={handleExpensesUnselect}
                    type='secondary'
                    style={{ marginLeft: 10 }}
                  >
                    Deseleccionar{' '}
                    {selectedExpenses.length > 1
                      ? '(' + selectedExpenses.length + ')'
                      : ''}
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </AffixDivBottom>
      </Affix>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;
const AffixDivBottom = styled.div`
  //   background-color: green;
  border-top: 1px solid #e8e8e8;
  background-color: white;
  padding-top: 6px;
  padding-left: 14px;
  padding-right: 14px;
  position: fixed; // Ensures that the div remains fixed at the bottom
  bottom: 0; // Anchors the div at the bottom of the page
  width: calc(100% - 225px); // Ensures the div stretches across the width
  padding-bottom: 8px;
`;
const ScrollableTableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  height: calc(
    100% - 105px
  ); // Ensure the container's height is 100% of its parent minus 105px
  min-height: 0; // This can help in flex contexts where content might otherwise force larger sizes
`;
const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  height: calc(100% - 105px); // Subtract 105px from the full height
`;
const StyledCol = styled(Col)`
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1; // This makes the column grow to use available space
  height: 100%; // This should ensure it respects the flex height
`;
export { ConciliationView };
