import React, { useContext, useState } from 'react';
import {
  Form,
  Input,
  DatePicker,
  message,
  Select,
  Upload,
  Tag,
  Button,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';

function NewEmployeeForm(props) {
  const auth = useContext(authContext);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm(); // Create the form instance

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
  );

  // Function to handle form submission
  const onFinish = (values) => {
    // console.log('Received values of form: ', values);
    handleFormSubmission(values);
  };
  const handleFormSubmission = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('accountingClientsId', props.selectedAccountingClientId);

    Object.keys(values).forEach((key) => {
      if (key === 'document' && values[key]) {
        values[key].forEach((file) => {
          formData.append('govIdImage', file.originFileObj);
        });
      } else if (key === 'startDate') {
        if (values[key]) {
          formData.append(key, values[key].format('YYYY-MM-DD'));
        } else {
          formData.append(key, '');
        }
      } else {
        formData.append(key, values[key]);
      }
    });

    axios({
      method: 'post',
      url: props.API_domain + 'createEmployee',
      data: formData,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        props.setEmployeeModalVisible(false);
        setLoading(false);
        form.resetFields(); // Reset the form fields
        message.success('Empleado creado exitosamente');
      })
      .catch((error) => {
        console.error('Error creating employee:', error);
        if (error.response && error.response.status === 403) {
          message.error(error.response.data);
        }
        props.onError && props.onError(error);
        setLoading(false);
      });
  };

  return (
    <div>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <Form.Item
          name='document'
          label='Documento (ID)'
          valuePropName='fileList'
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            name='logo'
            listType='picture'
            beforeUpload={() => false} // Prevent automatic upload
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name='name'
          label='Nombre'
          rules={[{ required: true, message: 'Por favor ingrese su nombre' }]}
        >
          <Input placeholder='Juan Pérez' />
        </Form.Item>

        <Form.Item
          name='idNumber'
          label='Número de Identidad'
          rules={[
            {
              required: true,
              message: 'Por favor ingrese su número de identidad',
            },
          ]}
        >
          <Input placeholder='8-123-456' />
        </Form.Item>

        <Form.Item name='startDate' label='Fecha de Inicio'>
          <DatePicker />
        </Form.Item>

        <Form.Item
          name='employeeType'
          label='Tipo de Empleado'
          rules={[
            {
              required: true,
              message: 'Por favor seleccione un tipo de empleado',
            },
          ]}
        >
          <Select placeholder='Seleccione uno'>
            <Option value='css'>CSS</Option>
            <Option value='mixto'>Mixto</Option>
            <Option value='honorarios_profesionales'>
              Honorarios Profesionales
            </Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
            loading={loading}
          >
            Crear empleado
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export { NewEmployeeForm };
