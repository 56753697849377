import { Modal } from 'antd';
import { ConfigRenamePaymentMethodForm } from './ConfigRenamePaymentMethodForm.js';

function ConfigRenamePaymentMethodModal(props) {
  console.log(props);
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      footer={false}
      onCancel={props.handleCancel}
    >
      <ConfigRenamePaymentMethodForm
        API_domain={props.API_domain}
        handleOk={props.handleOk}
        payment_method={props.payment_method}
      />
    </Modal>
  );
}

export { ConfigRenamePaymentMethodModal };
