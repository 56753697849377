import { Modal } from 'antd';
import { ConfigNewPaymentMethodForm } from './ConfigNewPaymentMethodForm.js';

function ConfigPaymentMethodModal(props) {
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      footer={false}
      onCancel={props.handleCancel}
      width={600}
    >
      <ConfigNewPaymentMethodForm
        API_domain={props.API_domain}
        handleOk={props.handleOk}
        team={props.team}
        setCategoryCreated={props.setCategoryCreated}
      />
    </Modal>
  );
}

export { ConfigPaymentMethodModal };
