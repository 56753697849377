import { Button, Modal, Input, message, Form, Radio } from 'antd';
import { useState } from 'react';
import styled from 'styled-components/macro';
import confetti from 'canvas-confetti';

function AdminOnboardingLinkCreator() {
  const [visible, setVisible] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(null);
  const [stripeLink, setStripeLink] = useState('');
  const [paymentLinkId, setPaymentLinkId] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('pendiente');
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('');

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    setGeneratedLink(null);
    setStripeLink('');
    setPaymentLinkId('');
    setPaymentStatus('pendiente');
    setStripeSubscriptionId('');
  };

  const handleCopy = () => {
    if (generatedLink) {
      navigator.clipboard.writeText(generatedLink);
      message.success('Link copied to clipboard!');
      triggerConfetti();
    }
  };

  const handleCreateLink = () => {
    let onboardingLink;

    if (paymentStatus === 'pendiente') {
      if (stripeLink && paymentLinkId) {
        onboardingLink = `https://www.herofacturas.com/onboarding?stripe_payment_link=${encodeURIComponent(
          stripeLink
        )}&plink=${encodeURIComponent(paymentLinkId)}`;
      } else {
        message.error(
          'Please enter both a Stripe payment link and a Payment Link ID.'
        );
        return;
      }
    } else if (paymentStatus === 'ya_pago') {
      if (stripeSubscriptionId) {
        onboardingLink = `https://www.herofacturas.com/onboarding?stripe_subscription_id=${encodeURIComponent(
          stripeSubscriptionId
        )}`;
      } else {
        message.error('Please enter a Stripe Subscription ID.');
        return;
      }
    }

    setGeneratedLink(onboardingLink);
  };

  const triggerConfetti = () => {
    const confettiSettings = {
      particleCount: 1500,
      spread: 360,
      startVelocity: 50,
      ticks: 200,
    };
    confetti(confettiSettings);
    message.success('GOOD LUCK!');
  };

  return (
    <>
      <Button onClick={showModal} type='primary'>
        <ButtonSpan>Onboard</ButtonSpan>
      </Button>
      <Modal
        title='Create Onboarding Link'
        visible={visible}
        footer={
          generatedLink ? (
            <>
              <Button
                onClick={() => setGeneratedLink('')}
                style={{ fontWeight: 500 }}
              >
                Reset
              </Button>
              <Button
                type='primary'
                onClick={handleCopy}
                style={{ fontWeight: 500, marginRight: 10 }}
              >
                Copiar Link
              </Button>
            </>
          ) : (
            <Button
              type='primary'
              onClick={handleCreateLink}
              style={{ fontWeight: 500 }}
            >
              Crear Onboarding Link
            </Button>
          )
        }
        onCancel={handleOk}
        centered
      >
        {!generatedLink ? (
          <Form layout='vertical'>
            <Form.Item label='Payment Status'>
              <Radio.Group
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <Radio value='pendiente'>Pendiente pago</Radio>
                <Radio value='ya_pago'>Ya pagó</Radio>
              </Radio.Group>
            </Form.Item>
            {paymentStatus === 'pendiente' ? (
              <>
                <Form.Item label='Stripe Payment Link'>
                  <Input
                    value={stripeLink}
                    onChange={(e) => setStripeLink(e.target.value)}
                    placeholder='https://buy.stripe.com/14k4iz4TpbRv5Us6q1'
                  />
                </Form.Item>
                <Form.Item label='Payment Link ID'>
                  <Input
                    value={paymentLinkId}
                    onChange={(e) => setPaymentLinkId(e.target.value)}
                    placeholder='plink_1PqhgFIfS9uNiBtDFoNW2umB'
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item label='Stripe Subscription ID'>
                <Input
                  value={stripeSubscriptionId}
                  onChange={(e) => setStripeSubscriptionId(e.target.value)}
                  placeholder='sub_1PqhgFIfS9uNiBtDFoNW2umB'
                />
              </Form.Item>
            )}
          </Form>
        ) : (
          <Input value={generatedLink} readOnly />
        )}
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminOnboardingLinkCreator };
