import { PlusOutlined } from '@ant-design/icons';
import { Button, Layout, Image } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { CardModal } from './CardModal.js';
import { CardsTable } from './CardsTable.js';
import { CardsCompanyWallet } from './CardsCompanyWallet.js';
import { CardsTableNewCardModal } from './CardsTableNewCardModal.js';
import { authContext } from '../ProvideAuth.js';
import axios from 'axios';

const { Content } = Layout;

function CardsView(props) {
  const auth = useContext(authContext);
  const [error, setError] = useState(null);
  const [selectedCard, setSelectedCard] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasWallet, setHasWallet] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [refreshingBalance, setRefreshingBalance] = useState(true);
  const [companyWalletBalance, setCompanyWalletBalance] = useState(0);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [refreshCardStatusForCardId, setRefreshCardStatusForCardId] = useState(
    []
  );
  const [imgVisible, setImgVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const onSubmit = () => {
    setSubmittedToggle(!submittedToggle);
  };
  const getWalletBalance = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getWalletBalance',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then((response) => {
      setIsAdmin(response.data.isAdmin);
      setHasWallet(response.data.hasWallet);
      setCompanyWalletBalance(response.data.walletBallance);
      setRefreshingBalance(false);
    });
  };

  useEffect(() => {
    getWalletBalance();
  }, [refreshingBalance]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Content
        style={{
          margin: '0 0 0',
          overflow: 'initial',
          // borderLeft: 'solid rgb(235,235,235) 1px',
          borderRight: 'solid rgb(235,235,235) 1px',
          borderTop: 'solid rgb(235,235,235) 1px',
        }}
      >
        <AffixDiv>
          <div
            style={{
              textAlign: 'left',
              paddingTop: 40,
              paddingLeft: 25,
              fontSize: 36,
              fontWeight: 'bold',
            }}
          >
            Tarjetas
            {isAdmin && (
              <>
                <Button
                  type='secondary'
                  style={{
                    fontWeight: 500,
                    marginBottom: 10,
                    marginLeft: 20,
                    verticalAlign: 'middle',
                  }}
                  onClick={showModal}
                >
                  <PlusOutlined /> Crear tarjeta
                </Button>
                <CardsTableNewCardModal
                  API_domain={props.API_domain}
                  visible={isModalVisible}
                  handleCancel={handleCancel}
                  handleOk={handleOk}
                  team={props.team}
                />
              </>
            )}
            <Button
              type='secondary'
              style={{
                fontWeight: 500,
                marginTop: 10,
                float: 'right',
              }}
              onClick={() => {
                setImgVisible(true);
                // Logic to show the image goes here
              }}
            >
              Cómo depositar?
            </Button>
            <Image
              width={200}
              style={{ display: 'none' }}
              src='Como recargar CORRIENTE.png'
              preview={{
                visible: imgVisible,
                scaleStep: 0.5,
                src: 'Como recargar CORRIENTE.png',
                onVisibleChange: (value) => {
                  setImgVisible(value);
                },
              }}
            />
          </div>
          {isAdmin && hasWallet && (
            <CardsCompanyWallet
              API_domain={props.API_domain}
              amount={companyWalletBalance}
              refreshingBalance={refreshingBalance}
            />
          )}

          {selectedCard && (
            <CardModal
              API_domain={props.API_domain}
              selectedCard={selectedCard}
              handleCancel={() => {
                setRefreshCardStatusForCardId(selectedCard.id);
                setSelectedCard(null);
              }}
              isAdmin={isAdmin}
              setRefreshingBalance={setRefreshingBalance}
              companyWalletBalance={companyWalletBalance}
              onSubmit={onSubmit}
            />
          )}

          <CardsTable
            API_domain={props.API_domain}
            team={props.team}
            isAdmin={isAdmin}
            isLoaded={isLoaded}
            setLoaded={setLoaded}
            setSelectedCard={setSelectedCard}
            submittedToggle={submittedToggle}
            refreshCardStatusForCardId={refreshCardStatusForCardId}
          />
        </AffixDiv>
      </Content>
    );
  }
}

const AffixDiv = styled.div`
  max-width: 1200px;
  margin: auto;
`;

export { CardsView };
