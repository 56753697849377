import { Affix, Col, Layout, Row, Switch, Button, Tag } from 'antd';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from './TeamSelect.js';
import { ExpenseModal } from './ExpenseModal.js';
import { ExpenseModalMobile } from './ExpenseModalMobile.js';
import { ExpenseReportDownloader } from './ExpenseReportDownloader';
import { MassEditButtonAndModalForExpenses } from './MassEditButtonAndModalForExpenses.js';
import { ExpensesTable } from './ExpensesTable.js';
import { NewReportForm } from './NewReportForm.js';
import { Refresh } from './Refresh.js';
import { useHistory, useLocation } from 'react-router-dom';
import { ReportedExpenseModal } from './ReportedExpenseModal.js';
import { CreateExpenseButton } from './CreateExpenseButton.js';

const { Content } = Layout;

function ExpensesView(props) {
  const history = useHistory();
  const location = useLocation();
  const viewAll = new URLSearchParams(location.search).get('all', false);
  const anyParam = new URLSearchParams(location.search).toString().length > 0;

  const [onlyNewExpenses, setOnlyNewExpenses] = useState(!viewAll);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState();
  const [expensesList, setExpensesList] = useState([]);
  const [selectedExpensesInMassEdit, setSelectedExpensesInMassEdit] = useState(
    []
  );
  const [tableExpenses, setTableExpenses] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [highlightedColor, setHighilightedColor] = useState('transparent');

  const catInParams = new URLSearchParams(location.search).get('category', '');
  const monthInParams = new URLSearchParams(location.search).get('month', '');
  const cardInParams = new URLSearchParams(location.search).get('card', '');
  const gastanteInParams = new URLSearchParams(location.search).get('user', '');

  const [filters, setFilters] = useState({
    card: cardInParams ? [cardInParams] : null,
    descripcion: null,
    category: catInParams ? [catInParams] : null,
    gastante: gastanteInParams ? [gastanteInParams] : null,
    fechaFormatted: monthInParams ? [monthInParams] : null,
    // total: null, this is a sorter. not a filter
  });

  const resetFilters = () => {
    setFilters({
      card: null,
      descripcion: null,
      category: null,
      gastante: null,
      fechaFormatted: null,
      // total: null, this is a sorter, not a filter
    });
  };

  const updateFilters = (newFilters) => {
    setFilters({ ...filters, ...newFilters });
  };

  const isAnyFilterActive = (filters) => {
    return Object.values(filters).some((value) => value !== null);
  };

  const viewReports = function () {
    history.push('/reports');
  };
  const onSubmit = () => {
    setSubmittedToggle(!submittedToggle);
  };

  function onSwitch(checked) {
    if (checked == false) {
      setOnlyNewExpenses(false);
    } else {
      setOnlyNewExpenses(true);
    }
  }

  function getIndex(expenseObject) {
    return expensesList.findIndex((obj) => expenseObject === obj);
  }

  function getExpenseObject(index) {
    return expensesList[index];
  }

  function previousExpense() {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex > 0) {
      setSelectedExpense(getExpenseObject(currentIndex - 1));
    }
  }
  function nextExpense() {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex < expensesList.length - 1) {
      setSelectedExpense(getExpenseObject(currentIndex + 1));
    } else {
    }
  }

  function clearFilters() {
    history.push('/expenses');
  }

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      {props.mobile ? (
        <Affix>
          <AffixDiv>
            <Row>
              <Col span={12} style={{ textAlign: 'left' }}>
                <TeamSelect
                  API_domain={props.API_domain}
                  // onTeamSelect will do props.onTeamSelect, and will also resetFilters
                  onTeamSelect={(team) => {
                    props.onTeamSelect(team);
                    resetFilters();
                  }}
                  team={props.team}
                  highlightedColor={highlightedColor}
                />
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}></Col>
            </Row>
          </AffixDiv>
        </Affix>
      ) : (
        <Affix>
          <AffixDiv>
            {/* <Tag color='red'>
              BOT DE WHATSAPP ESTÁ MALFUNCIONANDO TEMPORALMENTE
            </Tag> */}
            <Row>
              <Col span={12} style={{ textAlign: 'left' }}>
                <Refresh onClick={onSubmit} spin={!isLoaded} />

                <TeamSelect
                  API_domain={props.API_domain}
                  onTeamSelect={(team) => {
                    props.onTeamSelect(team);
                    resetFilters();
                  }}
                  team={props.team}
                  highlightedColor={highlightedColor}
                />

                <SwitchSpan style={{ marginLeft: 12 }}>Todos </SwitchSpan>
                <Switch
                  defaultChecked={onlyNewExpenses}
                  size='small'
                  onChange={onSwitch}
                />
                <SwitchSpan> Nuevos </SwitchSpan>
                {/* if anyParam or isAnyFilterActive(filters), show button */}
                {anyParam || isAnyFilterActive(filters) ? (
                  <Button
                    onClick={() => {
                      clearFilters();
                      onSubmit();
                      resetFilters();
                    }}
                  >
                    Limpiar Filtros
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <ButtonDiv>
                  <MassEditButtonAndModalForExpenses
                    API_domain={props.API_domain}
                    selectedExpensesInMassEdit={selectedExpensesInMassEdit}
                    setSelectedExpensesInMassEdit={
                      setSelectedExpensesInMassEdit
                    }
                    submittedToggle={submittedToggle}
                    setSubmittedToggle={setSubmittedToggle}
                    team={props.team}
                  />
                </ButtonDiv>
                <ButtonDiv>
                  <ExpenseReportDownloader
                    API_domain={props.API_domain}
                    tableExpenses={tableExpenses}
                  />
                </ButtonDiv>

                <ButtonDiv>
                  <CreateExpenseButton
                    API_domain={props.API_domain}
                    team={props.team}
                    onSubmit={onSubmit}
                  />
                </ButtonDiv>

                <ButtonDiv
                  className='secondStep'
                  onMouseEnter={() => {
                    if (props.team == 'all' || !props.team) {
                      setHighilightedColor('red');
                    }
                  }}
                  onMouseLeave={() => {
                    setHighilightedColor('transparent');
                  }}
                >
                  <NewReportForm
                    API_domain={props.API_domain}
                    onSubmit={viewReports}
                    submittedToggle={submittedToggle}
                    team={props.team}
                    filters={filters}
                    selectedExpensesInMassEdit={selectedExpensesInMassEdit}
                  />
                </ButtonDiv>
                {/* <ButtonDiv>
                  <NewBoxExpense
                    API_domain={props.API_domain}
                    onSubmit={viewReports}
                    submittedToggle={submittedToggle}
                    team={props.team}
                  />
                </ButtonDiv> */}
              </Col>
            </Row>
          </AffixDiv>
        </Affix>
      )}

      {selectedExpense && (
        <>
          {props.mobile ? (
            <ExpenseModalMobile
              record={selectedExpense}
              visible={true}
              title={'Editar Gasto'}
              imageSource={selectedExpense.factura}
              handleCancel={() => setSelectedExpense(null)}
              handleOk={() => setSelectedExpense(null)}
              onSubmit={onSubmit}
              expense={selectedExpense}
              API_domain={props.API_domain}
              team={props.team}
              nextExpense={nextExpense}
              previousExpense={previousExpense}
              expensesList={expensesList}
            />
          ) : selectedExpense.report_id ? (
            <ReportedExpenseModal
              record={selectedExpense}
              visible={true}
              title={'Editar Gasto'}
              imageSource={selectedExpense.factura}
              handleCancel={() => setSelectedExpense(null)}
              handleOk={() => setSelectedExpense(null)}
              // onSubmit={onSubmit}
              expense={selectedExpense}
              API_domain={props.API_domain}
              team={props.team}
              previous={previousExpense}
              next={nextExpense}
            />
          ) : (
            <ExpenseModal
              record={selectedExpense}
              visible={true}
              title={'Editar Gasto'}
              imageSource={selectedExpense.factura}
              handleCancel={() => setSelectedExpense(null)}
              handleOk={() => setSelectedExpense(null)}
              onSubmit={onSubmit}
              expense={selectedExpense}
              API_domain={props.API_domain}
              team={props.team}
              nextExpense={nextExpense}
              previousExpense={previousExpense}
              expensesList={expensesList}
              gastante={selectedExpense.gastante}
              tableExpenses={tableExpenses}
            />
          )}
        </>
      )}
      <ExpensesTable
        API_domain={props.API_domain}
        route={onlyNewExpenses ? 'getUnassignedExpenses' : 'getExpenses'}
        submittedToggle={submittedToggle}
        onSubmit={onSubmit}
        openModal={setSelectedExpense}
        team={props.team}
        setExpensesList={setExpensesList}
        mobile={props.mobile}
        setTableExpenses={setTableExpenses}
        isLoaded={isLoaded}
        setLoaded={setLoaded}
        filters={filters}
        updateFilters={updateFilters}
        setSelectedExpensesInMassEdit={setSelectedExpensesInMassEdit}
      />
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const SwitchSpan = styled.span`
  color: grey;
  font-size: small;
`;

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 10px;
`;

export { ExpensesView };
