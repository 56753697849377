import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Spin, InputNumber, Checkbox } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminInvuToZoho(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      row_id: values.client,
      month: values.month,
      itbms: values.itbms
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminFetchInvuData',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      responseType: 'blob' // Important for handling binary data
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invu_zoho_mes_${values.month}_cliente_${values.client}.csv`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function adminGetAccountingClientOptionsGivenSite() {
    axios({
      method: 'post',
      url: props.API_domain + 'adminGetAccountingClientOptionsGivenSite',
      data: { site: 'invu' },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setClientOptions(response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    if (visible) {
      adminGetAccountingClientOptionsGivenSite();
    }
  }, [visible]);

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Invu a Zoho</ButtonSpan>
      </Button>
      <Modal
        title='Invu a Zoho'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Descargar</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='client'
            label='Cliente'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Select
              showSearch
              options={clientOptions}
              placeholder='Cliente'
              filterOption={(input, option) =>
                option.props.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            name='month'
            label='Mes'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el mes',
              },
            ]}
          >
            <InputNumber min={1} max={12} placeholder='12' />
          </Form.Item>
          <Form.Item name='itbms' valuePropName='checked' wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>ITBMS</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminInvuToZoho };
