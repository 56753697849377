import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  message,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { CategorySelect } from './CategorySelect.js';
import { FormattedUSD } from './FormattedUSD.js';
import { ImageLoader } from './ImageLoader';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function parseFloatOrNull(number) {
  const parsedFloat = parseFloat(number);
  if (!isNaN(parsedFloat)) {
    return parsedFloat.toFixed(2);
  } else {
    return null;
  }
}
function NewExpenseFormMobile(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [amountWithoutItbms, setAmountWithoutItbms] = useState(0);
  const [itbms, setItbms] = useState(0);
  const [commandPressed, setCommandPressed] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    props.expense ? props.expense.factura : ''
  );
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  let expenseId, defaultCategory;
  let initialValues;
  if (props.API_endPoint == 'updateExpense') {
    const expense_date = moment(props.expense.fecha, 'YYYY/MM/DD');
    initialValues = {
      expenseName: props.expense.descripcion,
      date: expense_date.isValid() ? expense_date : '',
      itbms: parseFloatOrNull(props.expense.itbms),
      amount: parseFloatOrNull(
        parseFloat(props.expense.total) - parseFloat(props.expense.itbms)
      ),
      ruc: props.expense.ruc,
      provider: props.expense.provider,
      receipt_number: props.expense.receipt_number,
    };
    defaultCategory = props.expense.category_id;
    expenseId = props.expense.id;
  }

  useEffect(() => {
    form.resetFields(); //Necessary to show prefilled inputs (when updating and parsing)
    if (props.API_endPoint == 'updateExpense') {
      setImageUrl(props.expense.factura);
      setAmountWithoutItbms(
        parseFloatOrNull(
          parseFloat(props.expense.total) - parseFloat(props.expense.itbms)
        )
      );
      setItbms(parseFloatOrNull(props.expense.itbms));
    }
  }, [props.expense]);

  const onFinish = (values) => {
    setSubmitting(true);
    var expense_date = values.date;
    const data = {
      amount: -1 * values.amount - values.itbms,
      date: expense_date,
      receipt_number: values.receipt_number,
      imageUrl: imageUrl,
      expenseName: values.expenseName,
      category: values.category,
      id: expenseId,
      itbms: -1 * values.itbms,
      ruc: values.ruc,
      provider: values.provider,
    };
    axios({
      method: 'post',
      url: props.API_domain + props.API_endPoint,
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        if (commandPressed) {
          props.nextExpense();
          setSubmitting(false);
        } else {
          props.handleOk();
          props.onSubmit();
          setTimeout(() => setSubmitting(false), 2000);
        }
      })
      .catch(function (response) {
        console.log(response);
        if (response.response.status == 403) {
          message.error('No puedes editar un gasto que ya está en un reporte');
        }
        setSubmitting(false);
      });
  };

  const handleKey = (event) => {
    setCommandPressed(event.metaKey);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onSelect = (value) => {
    form.setFieldsValue({
      category: value,
    });
    return;
  };
  const disabledBoolean =
    props.expense && props.expense.report_id ? true : false;

  return (
    <Form
      {...layout}
      name='basic'
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      onKeyDown={handleKey}
      onKeyUp={handleKey}
    >
      <Form.Item
        label='Factura:'
        style={{
          display: '',
        }}
      >
        <Row>
          <Col span={20}>
            <ImageLoader
              onImageSubmit={setImageUrl}
              urlToUpload={props.API_domain + 'uploadImages'}
              disabledBoolean={disabledBoolean}
            />
          </Col>
          <Col span={4}>
            {imageUrl ? <Image width={24} height={24} src={imageUrl} /> : <></>}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label='Descripción'
        name='expenseName'
        rules={[
          {
            required: true,
            message: 'Por favor escriba el nombre del gasto',
          },
        ]}
      >
        <Input
          placeholder='Gasolina'
          disabled={disabledBoolean}
          style={{ color: 'black' }}
        />
      </Form.Item>
      <Form.Item
        name='date'
        label='Fecha:'
        rules={[
          {
            required: true,
            message: 'Por favor escriba el nombre del gasto',
          },
        ]}
      >
        <DatePicker
          locale={locale}
          style={{ width: 180 }}
          disabled={disabledBoolean}
        />
      </Form.Item>
      <Form.Item label='Proveedor' name='provider'>
        <Input
          placeholder='Farmacias Arrocha'
          disabled={disabledBoolean}
          style={{ color: 'black' }}
        />
      </Form.Item>
      <Form.Item label='RUC' name='ruc'>
        <Input
          placeholder='12345-123-45678'
          disabled={disabledBoolean}
          style={{ color: 'black' }}
        />
      </Form.Item>
      <Form.Item label='Número de Factura' name='receipt_number'>
        <Input
          placeholder='012345'
          disabled={disabledBoolean}
          style={{ color: 'black' }}
        />
      </Form.Item>
      <Form.Item
        label='Categoría'
        name='category'
        initialValue={defaultCategory}
      >
        <CategorySelect
          API_domain={props.API_domain}
          onChange={onSelect}
          defaultCategory={defaultCategory}
          disabledBoolean={disabledBoolean}
          team={props.team}
          expenseId={props.expense.id}
        />
      </Form.Item>
      <Form.Item
        label='Monto sin ITBMS'
        name='amount'
        rules={[
          {
            required: true,
            message: 'Por favor escriba el monto (número) sin ITBMS',
          },
        ]}
      >
        <InputNumber
          style={{ width: 80, color: 'black' }}
          placeholder='10.00'
          step='0.01'
          onChange={(value) => setAmountWithoutItbms(value)}
          disabled={disabledBoolean}
        />
      </Form.Item>
      <Form.Item
        label='ITBMS'
        name='itbms'
        rules={[
          {
            required: true,
            message: 'Por favor escriba la cantidad (número) del ITBMS',
          },
        ]}
      >
        <InputNumber
          style={{ width: 80, color: 'black' }}
          placeholder='0.70'
          step='0.01'
          onChange={(value) => setItbms(value)}
          disabled={disabledBoolean}
        />
      </Form.Item>
      <Form.Item label='Total'>
        <FormattedUSD total={parseFloatOrNull(+amountWithoutItbms + +itbms)} />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col span={12}>
            {props.expense && props.expense.report_id ? (
              <Tooltip
                placement='topRight'
                title='No puedes editar un gasto reportado'
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled
                  block
                  style={{ fontWeight: 500 }}
                >
                  Actualizar gasto
                </Button>
              </Tooltip>
            ) : (
              <Button
                type='primary'
                htmlType='submit'
                block
                style={{ fontWeight: 500 }}
              >
                {isSubmitting ? (
                  <Spin indicator={antIcon} />
                ) : props.API_endPoint == 'updateExpense' ? (
                  <>Actualizar gasto</>
                ) : (
                  <>Crear gasto</>
                )}
              </Button>
            )}
          </Col>
          <Col span={12}>
            {props.expense.report_id ? (
              <>
                <Tooltip
                  placement='topRight'
                  title='No puedes borrar un gasto reportado'
                >
                  <Button
                    danger
                    style={{ margin: '4px 4px' }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    block
                    disabled={true}
                  >
                    Borrar gasto <DeleteOutlined />
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Popconfirm
                title='Estás seguro de que quieres borrar este gasto?'
                onConfirm={(event) => {
                  props.deleteExpense(props.expense.id);
                  event.stopPropagation();
                }}
                onCancel={(event) => event.stopPropagation()}
                okText='Borrar'
                cancelText='Cancelar'
                placement='left'
              >
                <Button
                  danger
                  style={{ margin: '0 4px' }}
                  block
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  Borrar gasto <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { NewExpenseFormMobile };
