import { Button, Typography, Space, Row, Col, Progress, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';

const { Title, Text } = Typography;

const NewCardPackageView = () => {
  const [showContent, setShowContent] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [imageLoaded, setImageLoaded] = useState([false, false, false, false]);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    const initialDelay = 700; // Delay before confetti starts in milliseconds
    const duration = 2750; // Duration of confetti animation in milliseconds

    const confettiSettings = {
      particleCount: 1500, // Number of confetti particles
      spread: 360, // Spread angle of confetti particles, 360 for the entire page
      startVelocity: 50, // Initial velocity of confetti particles
      ticks: 200, // Number of animation frames
    };

    const startConfetti = setTimeout(() => {
      animateConfetti();
    }, initialDelay);

    const stopConfetti = setTimeout(() => {
      confetti.reset(); // Stop the confetti after 2 seconds
      setShowContent(true);
    }, initialDelay + duration);

    const animateConfetti = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      confetti(
        Object.assign({}, confettiSettings, {
          origin: { x: 0.5, y: (screenHeight - 1) / screenHeight },
        })
      );
    };

    return () => {
      clearTimeout(startConfetti);
      clearTimeout(stopConfetti);
      confetti.reset();
    };
  }, []);

  const renderStepContent = (step) => {
    const handleImageLoad = (index) => {
      const updatedImageLoadedStatus = [...imageLoaded];
      updatedImageLoadedStatus[index] = true;
      setImageLoaded(updatedImageLoadedStatus);
    };

    const imageUrls = [
      'https://www.dropbox.com/scl/fi/sb3rxu93v5lo2p7le6v17/C-mo-recargar-CORRIENTE.png?rlkey=e7p3xsb0fe1moy4ubqbf3vxkv&raw=1',
      'https://www.dropbox.com/s/p15ye40s3rxvaqo/deposito%20tarjeta.png?raw=1',
      'https://www.dropbox.com/scl/fi/nq5oire43bi2o1fhg4q6z/bot-still-card-transaction-2024.png?rlkey=iorqkvjfwe884anv70zsgkkt3&raw=1',
      'https://www.dropbox.com/s/bxkjsom9imdc4o1/online.png?raw=1',
    ];
    const stepDescriptions = [
      <>
        <span style={{ fontSize: 16 }}>
          <b>Paso 1</b>: depositar saldo a tu tarjeta
        </span>
      </>,
      <>
        <span style={{ fontSize: 16 }}>
          <b>Paso 2</b>: recibirás un mensaje notificando
        </span>{' '}
        <span style={{ fontSize: 16 }}>el depósito</span>
      </>,
      <>
        <span style={{ fontSize: 16 }}>
          <b>Paso 3</b>: pagar con tu tarjeta
        </span>
      </>,
      <>
        <span style={{ fontSize: 16 }}>
          <b>Paso 4</b>: configura tus aplicaciones
        </span>{' '}
        <span style={{ fontSize: 16 }}>
          favoritas online para usar tu nueva tarjeta
        </span>
      </>,
    ];

    if (step >= 1 && step <= 4) {
      return (
        <>
          {!imageLoaded[step - 1] && (
            <div
              style={{
                height: '350px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin />
            </div>
          )}
          <img
            src={imageUrls[step - 1]}
            alt={`Step ${step}`}
            style={{
              width: '100%',
              height: 'auto',
              display: imageLoaded[step - 1] ? 'block' : 'none',
              maxHeight: 'calc(70vh - 100px)', // Adjust the value as needed based on your layout
              objectFit: 'contain', // To maintain the aspect ratio while resizing the image
            }}
            onLoad={() => handleImageLoad(step - 1)}
          />
          <Text>{stepDescriptions[step - 1]}</Text>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Title level={2} style={{ paddingTop: 40 }}>
        Felicidades por sacar tu tarjeta de Hero!
      </Title>
      {showContent && (
        <Row
          justify='center'
          align='middle'
          style={{ minHeight: 'calc(100vh - 200px)' }}
        >
          <Col xs={22} sm={20} md={16} lg={12} xl={10}>
            <Space direction='vertical' size='large' style={{ width: '100%' }}>
              {renderStepContent(currentStep)}
              <Progress
                percent={(currentStep / 4) * 100}
                format={() => `${currentStep}/4`}
                style={{ alignSelf: 'center' }}
                strokeColor='rgb(116, 116, 205)'
              />
              <div style={{ textAlign: 'center' }}>
                {currentStep > 1 && (
                  <Button onClick={handleBack} style={{ marginRight: '1rem' }}>
                    Atrás
                  </Button>
                )}
                {currentStep < 4 ? (
                  <Button type='primary' onClick={handleNext}>
                    Continuar
                  </Button>
                ) : (
                  // button that links to herofacturas.com/login
                  <Button
                    type='primary'
                    onClick={() =>
                      (window.location.href =
                        'https://www.herofacturas.com/login')
                    }
                  >
                    Continuar a Hero
                  </Button>
                )}
              </div>
            </Space>
          </Col>
        </Row>
      )}
    </>
  );
};

export { NewCardPackageView };
