import { Button, Card, Col, Divider, Row } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import {
  PlusOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';
import { Collapse } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;
const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

// const data = [{"Whatsapp": []},
//               {"Gastos": []}, 
//               {"Reportes": []}, 
//               {"Configuración": []}, 
//               {"Misc": []}]

function HelpCard(props) {
  return (
    <Card
      style={{
        margin: '16px',
        borderRadius: '10px',
        boxShadow: '2px 2px 15px 8px rgb(240,240,240)',
      }}

    >
      <Collapse ghost expandIconPosition={"right"}>
        <Panel header={<PanelHeader>Whatsapp</PanelHeader>} key="1">
          <Collapse ghost expandIconPosition={"right"}>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>¿Cómo registro un gasto por Whatsapp?</SubPanelHeader>} key="1">
                <p>Guarda el número +1 248-609-4376 como Hero Facturas en Whatsapp y envíale una foto de una factura. Luego responde las preguntas que te hace y listo!</p>
            </Panel>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>Le envié mi primera foto y no me responde. ¿Qué hago?</SubPanelHeader>} key="2">
                <p>Asegúrate que el número que tiene registrado Hero Facturas (dentro de Configuración) es el correcto.</p>
            </Panel>
          </Collapse>
        </Panel>
        <Divider />
        <Panel header={<PanelHeader>Gastos</PanelHeader>} key="2">
          <Collapse ghost expandIconPosition={"right"}>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>¿Cómo edito un gasto?</SubPanelHeader>} key="1">
                <p>Presiona en la fila del gasto y se abrirá la factura junto con los datos. Cambia cualquier dato y presiona “Actualizar gasto”</p>
            </Panel>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>¿Por qué le faltan datos a mi gasto?</SubPanelHeader>} key="2">
                <p>A veces el interpretador no logra interpretar todos los datos. En ese caso, puedes editar todos los datos del gasto que gustes.</p>
            </Panel>
          </Collapse>
        </Panel>
        <Divider />
        <Panel header={<PanelHeader>Reportes</PanelHeader>} key="3">
          <Collapse ghost expandIconPosition={"right"}>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>¿Si borro un reporte, se borran las facturas?</SubPanelHeader>} key="1">
                <p>No. Las facturas vuelven a la sección de "Nuevas", con el resto de las facturas no reportadas.</p>
            </Panel>
          </Collapse>
        </Panel>
        <Divider />
        <Panel header={<PanelHeader>Configuración</PanelHeader>} key="4">
          <Collapse ghost expandIconPosition={"right"}>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>¿Cómo agrego a otro gastante a mi equipo?</SubPanelHeader>} key="1">
                <p>En la sección de configuración puedes presionar "Agregar usuario" para agregar a alguien a tu equipo.</p>
            </Panel>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>¿Por qué no puedo editar casillas de un usuario?</SubPanelHeader>} key="2">
                <p>Solo el creador del equipo podrá editar ciertas cualidades de sus integrantes.</p>
            </Panel>
          </Collapse>
        </Panel>
        {/* <Divider />
        <Panel header={<PanelHeader>Misc</PanelHeader>} key="5">
          <Collapse ghost expandIconPosition={"right"}>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>Pregunta 1</SubPanelHeader>} key="1">
                <p>{text}</p>
            </Panel>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>Pregunta 2</SubPanelHeader>} key="2">
                <p>{text}</p>
            </Panel>
            <Divider style = {{margin: 0}}/>
            <Panel header={<SubPanelHeader>Pregunta 3</SubPanelHeader>} key="3">
                <p>{text}</p>
            </Panel>
          </Collapse>
        </Panel> */}
      </Collapse>
    </Card>
  );
}
const PanelHeader = styled.p`
  text-align: left;
  margin: 0px;
  font-size:24px;
  font-weight:600;
`;

const SubPanelHeader = styled.p`
text-align: left;
color: grey;
margin:0px;
font-size:20px;
font-weight:600;
`;

export { HelpCard };
