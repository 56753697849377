import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';

function ProviderSelect(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState();
  function fetchProviderOptions() {
    axios({
      method: 'post',
      url: props.API_domain + 'providerOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then(function (response) {
        setOptions(
          response.data.map((provider) => ({
            label: provider.name,
            value: provider.id,
          }))
        );
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchProviderOptions();
  }, []);
  return (
    <Select
      allowClear
      filterOption={(input, option) =>
        option.props.label
          .toString()
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      options={options}
      onChange={props.onChange}
      placeholder='Selecciona proveedor'
      style={{ width: '240px' }}
    />
  );
}

export { ProviderSelect };
