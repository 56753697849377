import { message, Select, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function ExpenseModalNewTeamSelector(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState([]);

  const changeExpenseTeam = (newTeam) => {
    axios({
      method: 'post',
      url: props.API_domain + 'changeExpenseTeam',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        expenseId: props.expense.id,
        newTeam: newTeam,
      },
    })
      .then((response) => {
        props.handleOk();
        props.onSubmit();
        message.success('Gasto movido a ' + newTeam);
      })
      .catch(function (response) {
        console.log('Error is ', response);
      });
  };

  function fetchTeamOptions() {
    axios({
      method: 'get',
      url: props.API_domain + 'getTeams',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        let selectOptions = response.data.map((team) => ({
          label: team,
          value: team,
        }));
        setOptions(selectOptions);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchTeamOptions();
  }, [props.expense]);
  return (
    <>
      <span
        style={{
          padding: 12,
          borderRadius: 4,
        }}
      >
        {options.length > 1 ? (
          <Tooltip
            placement='left'
            title={'Para mover este gasto a otro equipo'}
          >
            <Select
              showSearch
              options={options}
              value={props.expense.team}
              onChange={changeExpenseTeam}
              placeholder='Selecciona Equipo'
              style={{ width: '240px' }}
            />
          </Tooltip>
        ) : (
          <>{props.expense.team}</>
        )}
      </span>
    </>
  );
}

export { ExpenseModalNewTeamSelector };
