import { Modal } from 'antd';
import { ExistingEmployeeForm } from './ExistingEmployeeForm';
import { NewEmployeeForm } from './NewEmployeeForm';

function EmployeeModal(props) {
  return (
    <Modal
      title={
        props.selectedEmployee.employee_id
          ? 'Editar empleado existente'
          : 'Crear nuevo empleado'
      }
      visible={props.employeeModalVisible}
      onCancel={props.onCancel}
      footer={null}
      style={{ top: 20 }} // This is the top margin of the modal
    >
      {props.selectedEmployee.employee_id ? (
        <ExistingEmployeeForm
          API_domain={props.API_domain}
          selectedEmployee={props.selectedEmployee}
          employeeTypeColors={props.employeeTypeColors}
          selectedAccountingClientId={props.selectedAccountingClientId}
          setEmployeeModalVisible={props.setEmployeeModalVisible}
        />
      ) : (
        <NewEmployeeForm
          API_domain={props.API_domain}
          employeeTypeColors={props.employeeTypeColors}
          selectedAccountingClientId={props.selectedAccountingClientId}
          setEmployeeModalVisible={props.setEmployeeModalVisible}
        />
      )}
    </Modal>
  );
}

export { EmployeeModal };
