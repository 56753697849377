import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Affix, Avatar, Button, Col, Layout, Modal, Row, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { BoxUploader } from './../Compras/BoxUploader.js';
import { OrdenesTable } from './OrdenesTable.js';
import { OrdenModal } from './OrdenModal.js';
import { StateFilterSelect } from './../Compras/StateFilterSelect.js';
import { OrdenesNewOrdenModal } from './OrdenesNewOrdenModal.js';
import { TeamSelect } from '../TeamSelect.js';
import { Refresh } from '../Refresh.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

function OrdenesView(props) {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [submittedToggle, setSubmittedToggle] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState();
  const [showNewOrdenModal, setShowNewOrdenModal] = useState();
  const [data, setData] = useState(null);
  const assigneeFilter =new URLSearchParams(location.search).get('assigneeFilter')
      ? new URLSearchParams(location.search).get('assigneeFilter')
      : [];

  const onSubmit = () => {
    setSubmittedToggle(!submittedToggle);
    setShowNewOrdenModal(false);
  };

  let invoiceStateMapper = [
    { value: 'pending', title: 'PENDIENTE', color: 'yellow' },
    { value: 'approved', title: 'APROBADO', color: 'blue' },
    { value: 'registered', title: 'REGISTRADO', color: 'purple' },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const showModalParam = params.get('showNewOrdenModal');
    setShowNewOrdenModal(showModalParam === 'true');
  }, [location.search]);

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <OrdenesNewOrdenModal
        showNewOrdenModal={showNewOrdenModal}
        setShowNewOrdenModal={setShowNewOrdenModal}
        handleOk={onSubmit}
        // selectedTeam={new URLSearchParams(location.search).get('Equipo')}
        // selectedRequisicion={new URLSearchParams(location.search).get(
        //   'Requisicion'
        // )}
        API_domain={props.API_domain}
      />
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Refresh onClick={onSubmit} spin={!isLoaded} />
              <TeamSelect
                API_domain={props.API_domain}
                onTeamSelect={props.onTeamSelect}
                team={props.team}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <ButtonDiv>
                <Button
                  type='primary'
                  onClick={() => setShowNewOrdenModal(true)}
                >
                  <ButtonSpan>
                    <>
                      <PlusOutlined /> Crear Orden
                    </>
                  </ButtonSpan>
                </Button>
              </ButtonDiv>
            </Col>
          </Row>
        </AffixDiv>
      </Affix>
      {selectedOrder && (
        <OrdenModal
          API_domain={props.API_domain}
          order={selectedOrder}
          submittedToggle={submittedToggle}
          setSubmittedToggle={setSubmittedToggle}
          setLoaded={setLoaded}
          setSelectedOrder={setSelectedOrder}
          handleCancel={() => {
            setSelectedOrder(null);
          }}
          handleOk={() => {
            setSelectedOrder(null);
            setLoaded(false);
            setSubmittedToggle(!submittedToggle);
          }}
          invoiceStateMapper={invoiceStateMapper}
        />
      )}

      <BeautifulDiv>
        <OrdenesTable
          API_domain={props.API_domain}
          openModal={setSelectedOrder}
          team={props.team}
          order_list={data}
          submittedToggle={submittedToggle}
          assigneeFilter={assigneeFilter}
          isLoaded={isLoaded}
          setLoaded={setLoaded}
        />
      </BeautifulDiv>
    </Content>
  );
}

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 15px;
`;
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const BeautifulDiv = styled.div`
  width: min(calc(100vw - 300px), 1200px);
  margin: auto;
`;

export { OrdenesView };
