import {
  CaretLeftOutlined,
  CaretRightOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import { useEffect, useRef } from 'react';
import { ReportedExpenseForm } from './ReportedExpenseForm.js';
import { ReportedExpenseImgOrPdf } from './ReportedExpenseImgOrPdf.js';

function ReportedExpenseModal(props) {
  useEffect(() => {
    window.addEventListener('keydown', handleKey);
  }, []);
  const nextButton = useRef();
  const prevButton = useRef();

  const handleKey = (event) => {
    if (event.keyCode == '37') {
      prevButton.current.click();
    }
    if (event.keyCode == '39') {
      nextButton.current.click();
    }
  };
  console.log('cashflow id:', props.expense.id);
  console.log(
    'paycaddy_transactions_id',
    props.expense.paycaddy_transactions_id
  );
  return (
    <>
      <Modal
        title={
          <Row>
            <Col span={12}>
              <>
                <span style={{ marginRight: 6 }}>
                  {props.expense.index ? 'Gasto #' + props.expense.index : ''}
                </span>
                {props.title}
                {props.expense.duplicate ? (
                  <Tooltip
                    placement='topLeft'
                    title={
                      <>
                        {'Posible factura duplicada. Presente en:'}
                        {props.expense.duplicate_locations_list.map(
                          (location, index) => (
                            <div key={index}>
                              {index + 1}. {location}
                            </div>
                          )
                        )}
                      </>
                    }
                  >
                    <WarningTwoTone twoToneColor='#ffcc00' />
                  </Tooltip>
                ) : (
                  ''
                )}
              </>
            </Col>
            <Col span={12} style={{ textAlign: 'right', paddingRight: 25 }}>
              <span style={{ paddingRight: 12 }}>
                <Tooltip placement='left' title={props.team}>
                  {props.expense.gastante}
                </Tooltip>
              </span>
              <Tooltip
                placement='bottomRight'
                title={[
                  'Presiona ',
                  <kbd
                    style={{
                      backgroundColor: 'grey',
                      padding: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      borderRadius: 4,
                    }}
                  >
                    ←
                  </kbd>,
                ]}
              >
                <Button
                  type='secondary'
                  style={{ marginRight: 3 }}
                  ref={prevButton}
                  onClick={props.previousExpense}
                >
                  <CaretLeftOutlined />
                </Button>
              </Tooltip>
              <Tooltip
                placement='bottomLeft'
                title={[
                  'Presiona ',
                  <kbd
                    style={{
                      backgroundColor: 'grey',
                      padding: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      borderRadius: 4,
                    }}
                  >
                    →
                  </kbd>,
                ]}
              >
                <Button
                  type='secondary'
                  style={{ marginRight: 3 }}
                  ref={nextButton}
                  onClick={props.nextExpense}
                >
                  <CaretRightOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        }
        visible={true}
        style={{ top: 20 }}
        footer={null}
        onCancel={props.handleCancel}
        width={1000}
      >
        <Row gutter={13}>
          <Col span={12}>
            <ReportedExpenseImgOrPdf src={props} expense={props.expense} />
          </Col>
          <Col span={12}>
            <ReportedExpenseForm expense={props.expense} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export { ReportedExpenseModal };
