import { Button, Col, Modal, Row } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
import { AssigneeSelect } from './AssigneeSelect.js';
import { BoxUploader } from '../Compras/BoxUploader.js';
import { ChatBox } from './ChatBox.js';
import { DeleteDocumentButton } from './DeleteDocumentButton.js';
import { MarkAsCompletedButton } from './MarkAsCompletedButton.js';

import { useHistory } from 'react-router-dom';
import { ImgOrPdfProcurement } from './ImgOrPdfProcurement.js';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function RequisitionModal(props) {
  const auth = useContext(authContext);
  const history = useHistory();
  const [renameOrder, setRenameOrder] = useState();
  const [selectedSecondaryDoc, setSelectedSecondaryDoc] = useState();
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [assignee, setAssignee] = useState(props.requisition.assignee);
  const [imageUrls, setImageUrls] = useState([]);
  const onSecondaryDocChange = () => {
    setSubmittedToggle(!submittedToggle);
  };

  useEffect(() => {
    axios({
      method: 'post',
      url: props.API_domain + 'getAllQuotes',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        requisition_id: props.requisition.id,
      },
    }).then(function (response) {
      let urls = response.data.map((item) => item.image_url);
      setImageUrls([props.requisition.image_url].concat(urls));
    });
  }, [props.requisition, submittedToggle]);

  const onFileSubmit = (file_url) => {
    axios({
      method: 'post',
      url: props.API_domain + 'createQuote',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        requisition_id: props.requisition.id,
        image_url: file_url,
        team: props.requisition.team,
      },
    }).then(function (response) {
      onSecondaryDocChange();
    });
  };

  return (
    <>
      <Modal
        visible={true}
        onCancel={() => {
          if (refreshToggle) {
            props.setSelectedRequisition(null);
            props.setLoaded(false);
            props.setSubmittedToggle(!props.submittedToggle);
          } else {
            props.setSelectedRequisition(null);
          }
          history.push({
            search: '',
          });
        }}
        onOk={props.handleOk}
        width={1200}
        centered
        // style={{ paddingBottom: 0 }}
        bodyStyle={{
          height: 'calc(100vh - 320px)',
          overflow: 'auto',
          paddingTop: 5,
          paddingBottom: 5,
        }}
        title={
          <Row>
            <Col span={12}>
              <div>
                Requisición {props.requisition.id}
                {/* <Button
                  style={{ border: 'none', color: 'grey' }}
                  onClick={() => {
                    setRenameOrder(true);
                  }}
                >
                  <EditOutlined />
                </Button> */}
              </div>

              <span style={{ color: 'grey', fontSize: '14px' }}>
                {props.requisition.team} -{' '}
                {props.requisition.createdAtFormatted}
              </span>
            </Col>

            <Col span={12}>
              <div
                style={{
                  paddingLeft: 20,
                  textAlign: 'right',
                  paddingRight: 40,
                }}
              >
                <>
                  Orden de Compra: &nbsp;
                  {props.requisition.order_ids.length > 0 ? (
                    props.requisition.order_ids.map((orderId, index) => (
                      <>
                        <a
                          key={index}
                          href={'/ordenes?openModalOrder=' + orderId}
                          style={{ textDecoration: 'underline' }}
                        >
                          {orderId}
                        </a>{' '}
                      </>
                    ))
                  ) : (
                    <span
                      style={{ color: 'grey', marginLeft: 5, fontWeight: 300 }}
                    >
                      PENDIENTE
                    </span>
                  )}
                </>
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row>
              <Col
                span={8}
                style={{
                  textAlign: 'center',
                  padding: '100 100 100 100',
                  marginTop: 15,
                }}
              >
                <MarkAsCompletedButton
                  API_domain={props.API_domain}
                  API_endPoint={'markRequisitionAsCompleted'}
                  document={props.requisition}
                  onSubmit={props.handleOk}
                />
                <DeleteDocumentButton
                  API_domain={props.API_domain}
                  API_endPoint={'deleteRequisition'}
                  document={props.requisition}
                  onSubmit={props.handleOk}
                />
                Asignado a: &nbsp;
                <AssigneeSelect
                  API_domain={props.API_domain}
                  API_endPoint={'setRequisitionAssignee'}
                  document={props.requisition}
                  onSubmit={onSecondaryDocChange}
                  setAssignee={setAssignee}
                  setRefreshToggle={setRefreshToggle}
                />
              </Col>

              <Col
                span={8}
                style={{
                  padding: '100 100 100 100',
                  marginTop: 15,
                  textAlign: 'center',
                }}
              >
                <Button
                  type='primary'
                  //   style={{ marginRight: 6 }}
                  onClick={() => {
                    history.push(
                      `/ordenes?showNewOrdenModal=true&Equipo=${props.requisition.team}&Requisition=${props.requisition.id}`
                    );
                  }}
                >
                  <PlusOutlined />
                  Crear Orden de Requisición
                </Button>
              </Col>

              <Col span={8}>
                {/* {orderState !== 'closed' && ( */}
                <BoxUploader
                  API_domain={props.API_domain}
                  endpointToUpload={'fileUpload'}
                  onFileSubmit={onFileSubmit}
                  width={'100%'}
                  uploaderTitle='Adjuntar documento a requisición'
                />
                {/* )} */}
              </Col>
            </Row>
          </>
        }
      >
        <Row style={{ height: '100%' }}>
          <Col
            span={16}
            style={{
              overflow: 'auto',
              height: '100%',
              // display: 'flex',
              // alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Carousel
              useKeyboardArrows={true}
              dynamicHeight={false}
              height='100%'
              showStatus={false}
              style={{ color: 'red' }}
              renderThumbs={() =>
                imageUrls.map((image, index) => (
                  <ImgOrPdfProcurement
                    key={index}
                    src={{
                      imageSource: image,
                    }}
                    isThumbnail={true}
                  />
                ))
              }
            >
              {imageUrls.map((image, index) => (
                <ImgOrPdfProcurement
                  src={{
                    imageSource: image,
                  }}
                  isThumbnail={false}
                />
              ))}
            </Carousel>
          </Col>

          <Col
            span={8}
            style={{
              overflow: 'auto',
              padding: 8,
              height: '100%',
              backgroundColor: 'rgb(245, 245, 245)',
              borderRadius: 8,
            }}
          >
            <h3 style={{ color: 'grey', padding: 3, paddingBottom: 6 }}>
              Chat
            </h3>
            <ChatBox
              API_domain={props.API_domain}
              document={props.requisition}
              documentType='requisition'
              API_endPointCreateChat='createRequisitionChat'
              API_endPointGetChats='getRequisitionChats'
              submittedToggle={submittedToggle}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export { RequisitionModal };
