import { Button, Form, Input, Modal, Spin } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import styled from "styled-components/macro";
import { authContext } from "../../ProvideAuth.js";
import { LoadingOutlined } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminCreateAccountingObligationEnum(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      name: values.name,
    };
    axios({
      method: "post",
      url: props.API_domain + "adminCreateAccountingObligationEnum", // Update to your new route
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert("An Error occurred hehee");
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Button type="secondary" onClick={showModal}>
        <ButtonSpan>Crear Tipo de Obligación</ButtonSpan>
      </Button>
      <Modal
        title="Crear Obligación"
        visible={visible}
        onOk={form.submit} // Changed to onOk to submit form on modal confirmation
        onCancel={handleCancel}
        centered
        confirmLoading={isSubmitting}
      >
        <Form
          {...layout}
          form={form}
          name="adminCreateAccountingObligationEnum"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="name"
            label="Nombre:"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el nombre de la obligación",
              },
            ]}
          >
            <Input placeholder="Nombre de la obligación" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export { AdminCreateAccountingObligationEnum };
