import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { AdminCompanySelect } from './AdminCompanySelect.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminNewUserForm(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      name: values.name,
      phone: values.phone,
      finca: values.finca,
      email: values.email,
      company_id: values.company_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminCreateUser',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Agregar Usuario</ButtonSpan>
      </Button>
      <Modal
        title='Agregar Usuario'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Agregar usuario</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='name'
            label='Nombre:'
            rules={[
              {
                required: true,
                message: 'Por favor escriba el nombre del colaborador',
              },
            ]}
          >
            <Input placeholder='Juan Pérez' />
          </Form.Item>
          <Form.Item
            name='finca'
            label='Finca:'
            rules={[
              {
                required: true,
                message: 'Por favor escriba el nombre de la finca',
              },
            ]}
          >
            <Input placeholder='fincatest' />
          </Form.Item>
          <Form.Item name='phone' label='Número de celular'>
            <Input type='number' placeholder='66123476' />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                required: true,
                message: 'Por favor escriba el correo del colaborador',
              },
            ]}
          >
            <Input placeholder='juanperez@gmail.com' />
          </Form.Item>
          <Form.Item
            name="company_id"
            label="Compañía"
          >
            <AdminCompanySelect 
              API_domain={props.API_domain}
              onCompanySelect={(company_id) => form.setFieldsValue({ company_id })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminNewUserForm };
