import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';

function OrdenesNewOrdenRequisitionSelect(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState();
  function fetchRequisitionOptions() {
    axios({
      method: 'post',
      url: props.API_domain + 'getAllRequisitions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data:{
        team: props.team,
      }
    })
      .then(function (response) {
        let selectOptions = response.data.map((requisition) => ({
          label: requisition.id,
          value: requisition.id,
        }));
        setOptions(selectOptions);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchRequisitionOptions();
  }, []);
  return (
    <>
      <span
        style={{
          backgroundColor: props.highlightedColor,
          padding: props.noPadding ? 0 : 12,
          borderRadius: 4,
        }}
      >
        <Select
          showSearch
          defaultValue={props.requisition ? props.requisition : null}
          disabled={props.disabled}
          options={options}
          onChange={props.onRequisitionSelect}
          placeholder='Selecciona Requsición'
          style={{ width: '240px' }}
        />
      </span>
    </>
  );
}

export { OrdenesNewOrdenRequisitionSelect };
