import React, { useState } from 'react';
import { Form, Input, message, Button, Row, Col } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const PedidosYaIntegration = (props) => {
  console.log('props', props);
  const [username, setusername] = useState(
    props.pedidosYaCredentials.login_info.username || ''
  );
  const [password, setPassword] = useState(
    props.pedidosYaCredentials.login_info.password || ''
  );
  const [loading, setLoading] = useState(false); // State to manage the loading status

  const handleSave = async () => {
    setLoading(true); // Start loading

    try {
      const updatedCredentials = {
        accounting_client_id: props.pedidosYaCredentials.accounting_client_id,
        id: props.pedidosYaCredentials.id, // Unique ID for the PedidosYa configuration
        site: 'pedidosya',
        login_info: {
          username,
          password,
        },
      };

      // Send only the relevant credentials to the backend
      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Actualización exitosa');
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error actualizando');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <h3 style={{ marginLeft: 4, textAlign: 'left' }}>PEDIDOS YA</h3>
      <Form.Item label='Username' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={username}
              onChange={(e) => setusername(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(username)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label='Password' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(password)}
            />
          </Col>
        </Row>
      </Form.Item>
      <div style={{ textAlign: 'left', marginLeft: 96 }}>
        <Button
          type='primary'
          onClick={handleSave}
          loading={loading} // Apply loading state to the button
          style={{ marginRight: 16 }}
        >
          Guardar
        </Button>
        <Button
          type='secondary'
          onClick={() => console.log('validating')}
          style={{
            backgroundColor: 'hsl(0, 0%, 95%)',
            borderColor: 'hsl(0, 0%, 95%)',
          }}
          disabled
        >
          Validar Pedidos Ya
          {/* {props.renderValidationIcon(props.pedidosYaCredentials.dgi_last_verify)} */}{' '}
          ✅
        </Button>
      </div>
    </div>
  );
};

export default PedidosYaIntegration;
