import React, { useState, useContext } from 'react';
import { authContext } from '../ProvideAuth.js';
import { ConfigTeamMemberModal } from './ConfigTeamMemberModal.js';

import { ConfigTeamCard } from './ConfigTeamCard.js';

const ConfigTeamSettings = (props) => {
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [API_endPoint, setAPI_endPoint] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const handleOk = () => {
    setVisible(false);
    setSubmittedToggle(!submittedToggle);
  };
  return (
    <>
      {selectedMember && (
        <ConfigTeamMemberModal
          visible={true}
          title={
            API_endPoint === 'addUserToTeam'
              ? 'Agregar Usuario a Equipo'
              : 'Editar Usuario'
          }
          handleCancel={() => {
            setSelectedMember(null);
            setSelectedTeam(null);
            setAPI_endPoint('');
          }}
          handleOk={() => {
            setSelectedMember(null);
            setSelectedTeam(null);
            setSubmittedToggle(!submittedToggle);
          }}
          onSubmit={handleOk}
          member={selectedMember}
          API_domain={props.API_domain}
          API_endPoint={API_endPoint}
          isOwner={props.isOwner}
          subscriptionId={props.subscriptionId}
          selectedMember={selectedMember}
          teamOptions={props.teams}
          setSelectedMember={setSelectedMember}
          selectedTeam={selectedTeam}
        />
      )}
      <div style={{ maxWidth: 1200, margin: 'auto' }}>
        {props.teams.map((team_and_owner, index) => {
          return (
            <ConfigTeamCard
              key={index}
              API_domain={props.API_domain}
              team={team_and_owner.team}
              setSelectedTeam={setSelectedTeam}
              ownerEmail={team_and_owner.ownerEmail}
              fullCard={props.fullCard}
              isOwner={props.isOwner}
              setSelectedMember={setSelectedMember}
              setAPI_endPoint={setAPI_endPoint}
              submittedToggle={submittedToggle}
              setSubmittedToggle={setSubmittedToggle}
            />
          );
        })}
      </div>
    </>
  );
};

export { ConfigTeamSettings };
