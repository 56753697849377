import React from 'react';
import Joyride from 'react-joyride';

export default function PageGuide(props) {
  return (
    <Joyride
      run={props.onboarding}
      callback={(data) => {
        if (data.action === 'reset') {
          window.history.replaceState(null, null, window.location.pathname);
        }
      }}
      hideBackButton={true}
      showProgress={true}
      continuous={true}
      disableScrolling={true}
      locale={{ next: 'Continuar', last: 'Terminar' }}
      steps={[
        {
          content: '¡Aquí viven tus gastos!',
          target: '.firstStep',
        },
        {
          content: 'Aquí puedes crear reportes.',
          target: '.secondStep',
        },
        {
          content: 'Aquí puedes ver reportes creados.',
          target: '.thirdStep',
        },
        {
          content: 'Aquí puedes configurar tu equipo.',
          target: '.fourthStep',
        },
        // {
        //   content: 'Aquí puedes agregar usuarios a tu equipo',
        //   target: '.fifthStep',
        // },
        // {
        //   content: 'Aquí puedes crear categorías',
        //   target: '.sixthStep',
        // },
      ]}
    />
  );
}
