import {
  Button,
  Divider,
  Form,
  InputNumber,
  Modal,
  Table,
  message,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';
import { FormattedUSD } from '../FormattedUSD';
import axios from 'axios';

function PayrollAdvanceCreationModal(props) {
  const auth = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const [payrollHistoricalAdvances, setPayrollHistoricalAdvances] = useState(
    []
  );

  const handleFormSubmit = async (values) => {
    const { advanceAmount } = values;
    setLoading(true);
    try {
      await axios({
        method: 'post',
        url: props.API_domain + 'createPayrollAdvance',
        data: {
          accounting_clients_id: props.accountingClientsId,
          comments: 'ADELANTO',
          name: props.employee.name,
          gov_id: props.employee.gov_id,
          advance_payments: advanceAmount,
          employee_id: props.employee.employee_id,
        },
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      message.success('Adelanto creado exitosamente');
      setLoading(false);
      getPayrollHistoricalAdvances();
    } catch (error) {
      console.error('Error:', error);
      message.error('Error al crear adelanto');
      setLoading(false);
    }
  };

  const getPayrollHistoricalAdvances = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: props.API_domain + 'getPayrollHistoricalAdvances',
        data: {
          accountingClientsId: props.accountingClientsId,
          employeeId: props.employee.employee_id,
        },
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      // Sort data by date in descending order
      const sortedData = response.data.sort(
        (a, b) => new Date(b.payable_date) - new Date(a.payable_date)
      );
      setPayrollHistoricalAdvances(sortedData);
    } catch (error) {
      console.error('Error:', error);
      setPayrollHistoricalAdvances([]); // Ensure the state is always an array
    }
  };

  useEffect(() => {
    getPayrollHistoricalAdvances();
  }, [props.employee.employee_id]);

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'payable_date',
      key: 'payable_date',
    },
    {
      title: 'Adelanto',
      dataIndex: 'advance_payment',
      key: 'advance_payment',
      align: 'right',
      render: (text) => (
        <div style={{ fontWeight: parseFloat(text) > 0 ? 'bold' : 'normal' }}>
          <FormattedUSD total={parseFloat(text).toFixed(2)} />
        </div>
      ),
    },
    {
      title: 'Descuento',
      dataIndex: 'advance_deduction',
      key: 'advance_deduction',
      align: 'right',
      render: (text) => (
        <div style={{ fontWeight: parseFloat(text) > 0 ? 'bold' : 'normal' }}>
          <FormattedUSD total={parseFloat(text).toFixed(2)} />
        </div>
      ),
    },
  ];

  const totalAdvancePayments = (payrollHistoricalAdvances || []).reduce(
    (sum, record) => sum + (parseFloat(record.advance_payment) || 0),
    0
  );

  const totalAdvanceDeductions = (payrollHistoricalAdvances || []).reduce(
    (sum, record) => sum + (parseFloat(record.advance_deduction) || 0),
    0
  );

  const pendingDeduction = totalAdvancePayments - totalAdvanceDeductions;

  return (
    <Modal
      title={'Crear adelanto para ' + props.employee.name}
      visible={props.visible}
      onCancel={props.onCancel}
      footer={null}
      width={600}
    >
      <div>
        <Form layout='vertical' onFinish={handleFormSubmit}>
          <Form.Item
            name='advanceAmount'
            label='Monto de adelanto'
            rules={[
              {
                required: true,
                message: 'Por favor introduce un monto de adelanto',
              },
            ]}
          >
            <InputNumber type='number' min={0} step={0.01} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={loading}>
              Crear adelanto
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Divider />
      <div>
        <h3>Adelantos y descuentos anteriores</h3>
        <h4 style={{ marginTop: 30 }}>
          Pendiente por descontar: ${pendingDeduction.toFixed(2)}
        </h4>
        <Table
          dataSource={payrollHistoricalAdvances}
          columns={columns}
          pagination={false}
        />
      </div>
    </Modal>
  );
}

export { PayrollAdvanceCreationModal };
