import React, { useContext, useState } from 'react';
import { Divider, Button, Tag, message } from 'antd';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';

const CreateAccountingClientConfigSite = (props) => {
  const auth = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const createSite = (props) => {
    setLoading(true);
    // console.log('API domain:', props.API_domain);
    try {
      //   make an axios call to createAccountingClientConfig. the data must be site: props.site, accounting_client_id: props.accounting_client_id, login_info: {}
      axios({
        method: 'post',
        url: props.API_domain + 'createAccountingClientConfig',
        data: {
          site: props.site,
          accounting_client_id: props.accountingClientId,
          login_info: {},
        },
        auth: {
          username: auth.email,
          password: auth.token,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        props.setRefresh(!props.refresh);
        message.success('Creación exitosa');
      });
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error creando');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h3 style={{ marginLeft: 4, textAlign: 'left', marginTop: 30 }}>
        {props.site.toUpperCase()}
        <Tag color='yellow' style={{ marginLeft: 8 }}>
          NO CONFIGURADO
        </Tag>
      </h3>
      <div style={{ textAlign: 'left', marginLeft: 96, marginTop: 24 }}>
        <div style={{ marginBottom: 8, color: 'grey' }}></div>
        <Button
          style={{
            backgroundColor: 'hsl(0, 0%, 95%)',
            borderColor: 'hsl(0, 0%, 95%)',
          }}
          loading={loading}
          onClick={() => createSite(props)}
        >
          Configurar acceso a {props.site.toUpperCase()}
        </Button>
      </div>
      <Divider />
    </>
  );
};
export default CreateAccountingClientConfigSite;
