import React, { useContext, useEffect, useRef, useState } from 'react';
import { PrinterOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, message, Space, Switch, DatePicker, Checkbox } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import { jsPDF } from 'jspdf';
import { formatDateSpanishLong } from '../../utils';
import NewPayrollTable from './NewPayrollTable';

const calculateNextReasonablePayableDate = () => {
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  // Calculate the 15th of the current month
  let payableDate = new Date(currentYear, currentMonth, 15);

  // Check if the 15th is a Sunday, adjust to the 14th if necessary
  if (payableDate.getDay() === 0) {
    payableDate.setDate(14);
  }

  // If today is past the 15th, set the payable date to the 30th of the month
  if (currentDay >= 15) {
    payableDate = new Date(currentYear, currentMonth, 30);
    // Adjust for months with less than 31 days
    if (payableDate.getDate() !== 30) {
      payableDate.setDate(0); // Sets to the last day of the current month
    }
  }

  let formattedDate = moment(payableDate); // Use moment directly on the Date object
  return formattedDate;
};

function NewPayrollTableContainer(props) {
  const auth = useContext(authContext);
  const [isCondensed, setIsCondensed] = useState(true);
  const [payableDate, setPayableDate] = useState(
    calculateNextReasonablePayableDate
  );
  const [decimoTercerMesBoolean, setDecimoTercerMesBoolean] = useState(false);
  const [vacacionesBoolean, setVacacionesBoolean] = useState(false);
  const [hourBreakdownBoolean, setHourBreakdownBoolean] = useState(false);
  const childRef = useRef();

  function toggleView() {
    setIsCondensed(!isCondensed);
  }

  const handleCreatePayroll = async () => {
    if (childRef.current) {
      const payrollData = childRef.current.getPayrollData(); // Get payrollData from the child component

      const payrollDataWithPayableDate = payrollData.map((emp) => {
        return {
          ...emp,
          accounting_clients_id: props.selectedAccountingClientId,
          payable_date: payableDate,
          comments: decimoTercerMesBoolean
            ? 'DECIMO'
            : vacacionesBoolean
            ? 'VACACIONES'
            : '',
          employee_id: emp.employee_id,
        };
      });
      //   console.log('payrollDataWithPayableDate:', payrollDataWithPayableDate);
      try {
        if (!payableDate) {
          message.error('Por favor seleccione una fecha de pago');
          return;
        }
        const response = await axios.post(
          `${props.API_domain}createPayroll`,
          { payrollDataWithPayableDate },
          { auth: { username: auth.email, password: auth.token } }
        );
        message.success('Planilla creada exitosamente');
        props.onCancel();
        props.setSelectedPayroll(response.data.selectedDate);
        props.setSendApprovedPayroll(false); // change to false to avoid sending the payroll after approval
        props.setPayrollModalContent('old');
      } catch (error) {
        console.error('Error creating payroll:', error);
      }
    }
  };

  const handleSendForApproval = async () => {
    const payrollPDF = await exportPDF(
      'payrollTable',
      payableDate + '_borrador_de_planilla' + '.pdf',
      true // Output only the PDF blob
    );
    const new_payroll_ids = await handleSavePayrollDraft();
    // console.log('new_payroll_ids:', new_payroll_ids);
    const formData = new FormData();
    formData.append(
      'file',
      payrollPDF,
      `${payableDate}_borrador_de_planilla.pdf`
    );
    formData.append('new_payroll_ids', JSON.stringify(new_payroll_ids));
    formData.append('payable_date', payableDate);
    formData.append('accounting_client_id', props.selectedAccountingClientId);
    axios({
      method: 'post',
      url: props.API_domain + 'sendPayrollDraftForApproval',
      data: formData,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // then message success
    })
      .then((response) => {
        // the backend will respond a list of email recipients. let's show them in a message success
        message.success(
          <>
            Planilla y comprobantes enviados a:{' '}
            <b>{response.data.join(', ')}.</b>
          </>,
          15
        );
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    return;
  };

  const handleSavePayrollDraft = async () => {
    if (!childRef.current) {
      console.error('Child component not found');
      return;
    }
    const payrollData = childRef.current.getPayrollData(); // Get payrollData from the child component

    const payrollDataWithPayableDate = payrollData.map((emp) => {
      const updatedEmp = {
        ...emp,
        accounting_clients_id: props.selectedAccountingClientId,
        payable_date: payableDate,
        comments: decimoTercerMesBoolean ? 'DECIMO' : '',
        employee_id: emp.employee_id,
      };

      if (!hourBreakdownBoolean) {
        updatedEmp.horas_dialibre = 0;
        updatedEmp.horas_domingos = 0;
        updatedEmp.horas_extradiurnas = 0;
        updatedEmp.horas_extranocturnas = 0;
        updatedEmp.horas_no_trabajadas = 0;
        updatedEmp.horas_recargo = 0;
        updatedEmp.pago_dialibre = 0;
        updatedEmp.pago_domingos = 0;
        updatedEmp.pago_extradiurnas = 0;
        updatedEmp.pago_extranocturnas = 0;
        updatedEmp.pago_recargo = 0;
        updatedEmp.propinas = 0;
        updatedEmp.pago_total_extras = 0;
        updatedEmp.salarioporhora = 0;
      }

      return updatedEmp;
    });

    if (!payableDate) {
      message.error('Por favor seleccione una fecha de pago');
      return;
    }
    try {
      const response = await axios.post(
        `${props.API_domain}savePayrollDraft`,
        { payrollDataWithPayableDate },
        { auth: { username: auth.email, password: auth.token } }
      );
      message.success('Planilla guardada exitosamente');
      return response.data.new_payroll_ids;
    } catch (error) {
      message.error('Error guardando la planilla');
      console.error('Error saving payroll draft:', error);
    }
  };

  const handleDecimoTercerMesChange = (e) => {
    const newDecimoTercerMesBoolean = e.target.checked;
    setDecimoTercerMesBoolean(newDecimoTercerMesBoolean);
  };

  const exportPDF = async (
    element,
    file_name = 'download_hero.pdf',
    outputOnlyBlob = false
  ) => {
    const doc = new jsPDF('landscape', 'pt', 'A4');
    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    const title =
      'Borrador de planilla' +
      ' del ' +
      formatDateSpanishLong(payableDate) +
      (decimoTercerMesBoolean ? ' - DECIMO' : '') +
      (vacacionesBoolean ? ' - Vacaciones' : '');
    doc.text(title, 40, 70);

    const logo1 = new Image();
    logo1.src = `accounting_client_logo_${props.selectedAccountingClientId}.png`;
    const logo2 = new Image();
    logo2.src = 'heroLogoSmall.png';

    const loadImageBase64 = (base64, mimeType = 'image/png') => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn(`Failed to load image`);
          resolve(null); // Resolve with null if image fails to load
        };
        // Set the src with the appropriate base64 data URL format
        img.src = `data:${mimeType};base64,${base64}`;
      });
    };
    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn(`Failed to load image: ${src}`);
          resolve(null); // Resolve with null if image fails to load
        };
        img.src = src;
      });
    };

    const generatePDF = async () => {
      // BEWARE COLUMNS IS NOW AN INPUT. MUST BE PASSED FROM CHILD COMPONENT (NEWPAYROLLTABLE)
      let columns = [];
      let payrollData = [];
      if (childRef.current) {
        columns = childRef.current.getColumns(); // Get columns from the child component
        payrollData = childRef.current.getPayrollData(); // Get payrollData from the child component
        // console.log('columns:', columns);
        // console.log('payrollData:', payrollData);
      }
      if (!columns || !payrollData) {
        console.error('Columns or payrollData are missing');
        return;
      }

      const [loadedLogo1, loadedLogo2] = await Promise.all([
        loadImage('heroLogoSmall.png'),
        loadImageBase64(props.logoBase64),
      ]);

      const pageWidth = doc.internal.pageSize.getWidth();
      const yOffset = 20;
      const logoHeight = 50;
      let logoWidth1 = 0;
      let logoWidth2 = 0;
      let xOffset1 = pageWidth - 30; // Start from the right margin
      let xOffset2 = xOffset1;

      if (loadedLogo1) {
        const aspectRatio1 = loadedLogo1.width / loadedLogo1.height;
        logoWidth1 = logoHeight * aspectRatio1;
        xOffset1 -= logoWidth1;
      }

      if (loadedLogo2) {
        const aspectRatio2 = loadedLogo2.width / loadedLogo2.height;
        logoWidth2 = logoHeight * aspectRatio2;
        xOffset2 = xOffset1 - logoWidth2 - 10; // Adjust spacing between logos
      }

      // Add images if they are loaded
      if (loadedLogo1) {
        doc.addImage(
          loadedLogo1.src,
          'PNG',
          xOffset1,
          yOffset,
          logoWidth1,
          logoHeight
        );
      }

      if (loadedLogo2) {
        doc.addImage(
          loadedLogo2.src,
          'PNG',
          xOffset2,
          yOffset,
          logoWidth2,
          logoHeight
        );
      }

      const tableElement = document.getElementById(element);

      // Columns to exclude in all cases
      const excludeColumns = [
        'Aplicar deducciones',
        'Acciones',
        'Adelantos pendientes por descontar',
      ];

      // Define horasColumnKeys to exclude from the main table and to include in the horas extra table
      const horasColumnKeys = [
        'salarioporhora',
        'horas_extradiurnas',
        'horas_extranocturnas',
        'horas_domingos',
        'horas_dialibre',
        'horas_no_trabajadas',
        'horas_recargo',
        'pago_extradiurnas',
        'pago_extranocturnas',
        'pago_domingos',
        'pago_dialibre',
        'pago_recargo',
        'propinas',
      ];

      // Filter columns based on isCondensed and always exclude specific columns
      const filteredColumns = columns.filter((col) => {
        if (isCondensed) {
          return [
            'name',
            // 'hasDeductions'
            'biweekly_salary',
            'benefit_production',
            'benefit_fuel',
            'overtime', // this column is useless when horas breakdown is active. the name might be confusing. overtime might be 0 when pago_total_extras is non-zero
            'pago_total_extras',
            'payroll_gross',
            'deduction_attendance',
            'advance_deductions',
            'deduction_total',
            'payroll_net',
            'notes',
          ].includes(col.key);
        }
        return (
          !excludeColumns.includes(col.title) &&
          !horasColumnKeys.includes(col.key)
        );
      });

      // Filter table headers to exclude specific columns
      const tableHeaders = filteredColumns.map((col) => col.title);

      // Build tableRows from payrollData and filteredColumns
      const tableRows = payrollData.map((dataRow, rowIndex) => {
        const rowData = filteredColumns.map((col, colIndex) => {
          const columnKey = col.dataIndex || col.key;
          let value = dataRow[columnKey];

          // Handle 'name' column to include 'gov_id'
          if (columnKey === 'name') {
            const govId = dataRow['gov_id'] || '';
            value = `${dataRow['name']}\n${govId}`;
          }

          // Handle 'notes' column
          if (columnKey === 'notes') {
            // Try to get the value from the input element in the DOM
            const row = tableElement.querySelectorAll('tbody tr')[rowIndex];
            const tds = row.querySelectorAll('td');

            // Need to find the td corresponding to the 'notes' column
            // Since tds may include excluded columns, we need to find the correct td index
            let tdIndex = -1;
            let tdCounter = 0;

            for (let i = 0; i < columns.length; i++) {
              const colKey = columns[i].dataIndex || columns[i].key;
              if (
                excludeColumns.includes(columns[i].title) ||
                horasColumnKeys.includes(colKey)
              ) {
                continue;
              }

              if (colKey === columnKey) {
                tdIndex = tdCounter;
                break;
              }
              tdCounter++;
            }

            if (tdIndex >= 0 && tdIndex < tds.length) {
              const td = tds[tdIndex];
              const inputElement = td.querySelector('input');
              if (inputElement) {
                value = inputElement.value;
              }
            }
          }

          // Format value
          if (typeof value === 'number') {
            value = parseFloat(value).toFixed(2);
            if (!value.toString().includes('$')) {
              value = '$' + value;
            }
          } else if (value == null || value === '') {
            value = '';
          }

          return value;
        });
        return rowData;
      });

      // Calculate totals
      const totals = tableHeaders.map((header, index) => {
        if (header !== 'Persona ' && header !== 'Fecha' && header !== 'Notas') {
          const total = tableRows
            .reduce((acc, row) => {
              let value = row[index];
              if (typeof value === 'string') {
                value = value.replace(/[^\d.-]/g, ''); // Remove non-numeric characters
              }
              return acc + (parseFloat(value) || 0);
            }, 0)
            .toFixed(2);
          return total === '0.00' ? '$0' : `$${total}`;
        }
        return '';
      });

      tableRows.push(totals);

      const tableStyles = {
        head: [tableHeaders],
        theme: 'plain',
        body: tableRows,
        startY: 100,
        styles: {
          fontSize: 10,
          cellPadding: { top: 5, right: 3, bottom: 5, left: 3 },
          overflow: 'linebreak',
          valign: 'middle',
          halign: 'right',
          fillColor: [255, 255, 255],
        },
        headStyles: {
          fillColor: [116, 116, 199],
          textColor: [255, 255, 255],
          fontSize: 8,
          halign: 'right',
        },
        bodyStyles: {
          halign: 'right',
          cellPadding: { top: 12, bottom: 12 },
        },
        columnStyles: {
          [tableHeaders.indexOf('Total de Ingresos')]: {
            textColor: [0, 128, 0],
            fontStyle: 'bold',
          },
          [tableHeaders.indexOf('Total de Deducciones')]: {
            textColor: [255, 0, 0],
            fontStyle: 'bold',
          },
          [tableHeaders.indexOf('Salario Neto a Recibir')]: {
            textColor: [65, 130, 239],
            fontStyle: 'bold',
          },
          [tableHeaders.indexOf('Persona ')]: {
            cellWidth: 'auto',
            halign: 'left',
          },
        },
        margin: { top: 150 },
      };

      doc.autoTable(tableStyles);

      // Check if we need to add the "reporte de horas" table
      if (
        // !isCondensed &&
        columns.some((col) => horasColumnKeys.includes(col.key))
      ) {
        // Build the "horas" table
        const horasColumns = columns.filter((col) =>
          horasColumnKeys.includes(col.key)
        );

        // Build headers for the "horas" table
        const horasTableHeaders = horasColumns.map((col) => col.title);
        horasTableHeaders.unshift('Persona '); // Add 'Persona' column
        horasTableHeaders.push('Total Horas Extra'); // Add 'Total Horas Extra' column

        // Build data for the "horas" table
        const horasTableRows = payrollData.map((data) => {
          const rowData = [];
          rowData.push(data['name']); // Add 'Persona' data
          horasColumns.forEach((col) => {
            let value = data[col.dataIndex] ?? '';
            if (typeof value === 'number') {
              value = value.toFixed(2);
            }
            // If the column is a 'pago_' column, format as dollars
            if (
              col.dataIndex.startsWith('pago_') ||
              col.dataIndex === 'salarioporhora' ||
              col.dataIndex === 'propinas' ||
              col.dataIndex === 'horas_no_trabajadas'
            ) {
              value = '$' + value;
            }
            rowData.push(value);
          });
          // Calculate 'Total Horas Extra' as the sum of 'pago_' columns
          const totalHorasExtra = horasColumnKeys
            .filter((key) => key.startsWith('pago_'))
            .reduce((sum, key) => sum + (parseFloat(data[key]) || 0), 0);
          const formattedTotalHorasExtra = '$' + totalHorasExtra.toFixed(2);
          rowData.push(formattedTotalHorasExtra);
          return rowData;
        });

        // Optionally, add a totals row
        const totalsRow = horasTableHeaders.map((header, index) => {
          if (header === 'Persona ') {
            return 'Totales';
          } else if (header !== 'Total Horas Extra') {
            const total = horasTableRows.reduce((acc, row) => {
              let value = row[index];
              if (typeof value === 'string') {
                value = value.replace(/[^\d.-]/g, '');
              }
              return acc + (parseFloat(value) || 0);
            }, 0);
            const correspondingCol = horasColumns[index - 1]; // Adjust for 'Persona' column
            if (
              (correspondingCol &&
                correspondingCol.dataIndex.startsWith('pago_')) ||
              correspondingCol.dataIndex === 'salarioporhora'
            ) {
              return '$' + total.toFixed(2);
            }
            return total.toFixed(2);
          } else {
            // Total Horas Extra column
            const total = horasTableRows.reduce((acc, row) => {
              let value = row[row.length - 1];
              if (typeof value === 'string') {
                value = value.replace(/[^\d.-]/g, '');
              }
              return acc + (parseFloat(value) || 0);
            }, 0);
            return '$' + total.toFixed(2);
          }
        });

        horasTableRows.push(totalsRow);

        // Add a new page for the "horas" table
        doc.addPage();
        doc.setFontSize(16);
        doc.setFont('Helvetica', 'bold');
        doc.text('Desglose de Horas Extra', 40, 50);

        // Generate the "horas" table
        doc.autoTable({
          head: [horasTableHeaders],
          body: horasTableRows,
          startY: 80,
          styles: {
            fontSize: 10,
            cellPadding: { top: 5, right: 3, bottom: 5, left: 3 },
            overflow: 'linebreak',
            valign: 'middle',
            halign: 'right',
            fillColor: [255, 255, 255],
          },
          headStyles: {
            fillColor: [116, 116, 199],
            textColor: [255, 255, 255],
            fontSize: 8,
            halign: 'right',
          },
          bodyStyles: {
            halign: 'right',
            cellPadding: { top: 12, bottom: 12 },
          },
          margin: { top: 50 },
        });
      }

      const pdfBlob = doc.output('blob');

      return pdfBlob;
    };

    // Use the function as per your existing code
    if (outputOnlyBlob) {
      return await generatePDF();
    } else {
      const pdfBlob = await generatePDF();
      doc.save(file_name);
      return pdfBlob; // Return PDF blob even if saving to disk
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky', // Ensures this part remains fixed while scrolling horizontally
          top: 0, // Adjust as needed if you want to fix it vertically as well
          zIndex: 1, // Ensures it stays above the table when scrolling horizontally
          background: 'white', // Prevent overlap issues
        }}
      >
        <div id='SelectDiv' style={{ flex: '0 1 auto', marginTop: 10 }}>
          <div>
            <span>Fecha a pagar:</span>
            <DatePicker
              style={{ marginLeft: 10 }}
              value={payableDate}
              onChange={(chosenDate) => {
                setPayableDate(chosenDate);
              }}
            />
          </div>
          <div>
            <Checkbox
              style={{ marginTop: 8 }}
              onChange={handleDecimoTercerMesChange}
            >
              Décimo Tercer Mes
            </Checkbox>
            <Checkbox
              style={{ marginTop: 8 }}
              onChange={(e) => setVacacionesBoolean(e.target.checked)}
            >
              Vacaciones
            </Checkbox>
            <Checkbox
              style={{ marginTop: 8 }}
              onChange={(e) => setHourBreakdownBoolean(e.target.checked)}
            >
              Desglosar horas 🕐
            </Checkbox>
          </div>
        </div>
        <div id='ActionsDiv' style={{ flex: '1 1 auto', textAlign: 'right' }}>
          <span style={{ marginRight: 8 }}>Vista simple:</span>
          <Switch checked={isCondensed} onChange={toggleView} />
          <Button
            type='secondary'
            onClick={() =>
              exportPDF('payrollTable', 'borrador_de_planilla' + '.pdf')
            }
            style={{ marginLeft: 10 }}
          >
            Exportar borrador a PDF
          </Button>
        </div>
      </div>

      {/* Scrollable container for the table */}
      <div
        style={{
          overflowX: 'auto', // Allows horizontal scrolling
          marginTop: 20, // Space between fixed section and table
        }}
      >
        <NewPayrollTable
          ref={childRef}
          API_domain={props.API_domain}
          selectedAccountingClientId={props.selectedAccountingClientId}
          decimoTercerMesBoolean={decimoTercerMesBoolean}
          isCondensed={isCondensed}
          payableDate={payableDate}
          hourBreakdownBoolean={hourBreakdownBoolean}
          style={{
            minWidth: '1000px', // Ensures the table is wide enough to scroll horizontally
          }}
        />
      </div>

      <div
        style={{
          width: '600px',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky', // Fixes the buttons horizontally
          bottom: 0, // If you want the buttons fixed at the bottom of the screen
          background: 'white', // Prevents overlap with content
          zIndex: 1, // Keeps the buttons above other content when scrolling
        }}
      >
        <Button
          type='secondary'
          style={{ width: '40%', margin: 10, height: 35 }}
          onClick={handleSavePayrollDraft}
        >
          <SaveOutlined />
          <b> Guardar </b> Borrador
        </Button>
        <Button
          type='secondary'
          style={{ margin: 10, height: 35 }}
          onClick={handleSendForApproval}
        >
          <SaveOutlined />
          <b> Enviar borrador </b> por correo pidiendo aprobación
        </Button>
        <Button
          type='primary'
          style={{ width: '60%', margin: 10, height: 35 }}
          onClick={handleCreatePayroll}
        >
          <PrinterOutlined />
          <b> Aprobar </b>Planilla
        </Button>
      </div>
    </>
  );
}

export { NewPayrollTableContainer };
