import React from 'react';
import { useLocation } from 'react-router-dom';
import { ImgOrPdf } from './ImgOrPdf.js';

function ImageHosterView(props) {
  const location = useLocation();
  const link = new URLSearchParams(location.search).get('link', '');
  console.log(link);

  if (link && link !== '') {
    return (
      <>
        <ImgOrPdf src={{ imageSource: link }} height={'850'} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <a href='https://www.herofacturas.com' target='_blank'>
            <img src='/heroLogoSharedReports.png' style={{ width: '250px' }} />
          </a>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <a href='https://www.herofacturas.com' target='_blank'>
          <img src='/heroLogoSharedReports.png' style={{ width: '250px' }} />
        </a>
      </div>
    );
  }
}

export { ImageHosterView };
