import {
  LoadingOutlined,
  SmileOutlined,
  UpOutlined,
  DownOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Spin, message, Table, Tag } from 'antd';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { FormattedUSD } from '../FormattedUSD.js';
import { AccountingAccountSelect } from '../AccountingAccountSelect.js';
import '../styles.css';

const CustomEmptyComponent = () => (
  <div style={{ textAlign: 'center', padding: 20 }}>
    <SmileOutlined style={{ fontSize: 56, color: '#7474cd' }} />
    <p style={{ marginTop: 16 }}>Todo claro por ahora!</p>
  </div>
);

const AccountingInboxTable = (props) => {
  const [flagging, setFlagging] = useState([]); // holds the key to the row that is flagging
  const [data, setData] = useState([]);
  const auth = useContext(authContext);
  const [accountOptions, setAccountOptions] = useState([]);
  const [showAccountSelect, setShowAccountSelect] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [sortedInfo, setSortedInfo] = useState({});
  const [sortDirection, setSortDirection] = useState({
    fecha: null,
    amount: null,
  }); // null: no sort, ascend: ascending, descend: descending

  const fetchAccountOptions = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountsOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { client_id: props.clientId },
    })
      .then((response) => {
        setAccountOptions(response.data);
      })
      .catch((error) => {
        console.error('Error fetching accounting accounts:', error);
      });
  };

  useEffect(() => {
    fetchAccountOptions();
  }, []);

  useEffect(() => {
    // Process data to add 'amount' field
    const processedData = props.data.map((item) => {
      const amount = parseFloat(item.Credito || item.Debito);
      const isIngreso = item.Credito !== undefined && item.Credito !== null;
      return {
        ...item,
        amount: isIngreso ? amount : -amount, // Negative amount for expenses
      };
    });
    setData(processedData);
  }, [props.data]);

  const updateSotTxnFlagged = (record) => {
    setFlagging([...flagging, record.key]);
    axios({
      method: 'post',
      url: props.API_domain + 'flagSotTxn',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { sot_txn_id: record.sotTxnId, add_comment: true },
    })
      .then((response) => {
        setFlagging(flagging.filter((key) => key !== record.key));
        // Update local state for the flagged status
        const updatedData = data.filter((item) => item.key !== record.key);
        setData(updatedData);
        props.setRefresh(!props.refresh);
      })
      .catch((error) => {
        console.error('Error flagging transaction: ', error);
      });
  };

  // Handle sorting for both date and amount
  const handleSort = (key) => {
    let newDirection = null;
    if (sortDirection[key] === null) {
      newDirection = 'ascend';
    } else if (sortDirection[key] === 'ascend') {
      newDirection = 'descend';
    } else if (sortDirection[key] === 'descend') {
      newDirection = null; // Turn off sorting
    }

    // Reset the sortDirection for the other key(s)
    const updatedSortDirection = {
      fecha: null,
      amount: null,
      [key]: newDirection,
    };

    setSortDirection(updatedSortDirection);
    setSortedInfo(newDirection ? { order: newDirection, columnKey: key } : {});
  };

  const getSortIcon = (key) => {
    if (sortDirection[key] === 'ascend') return <UpOutlined />;
    if (sortDirection[key] === 'descend') return <DownOutlined />;
    return <MinusOutlined />;
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: 'Detalles de Transacción',
      key: 'transaction',
      width: '60%',
      render: (text, record) => {
        const amount = Math.abs(record.amount);
        const isIngreso = record.amount >= 0;
        const amountDisplay = isIngreso ? (
          <Tag color='green'>
            <FormattedUSD total={amount} />
          </Tag>
        ) : (
          <Tag color='red'>
            -<FormattedUSD total={amount} />
          </Tag>
        );
        return (
          <div>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
              {record.sources_of_truth_zoho_name}
            </div>
            <div style={{ fontSize: '14px', color: '#555' }}>
              {record.Descripcion}
            </div>
            <div style={{ fontSize: '14px', color: '#888' }}>
              {record.fechaFormatted}
            </div>
            <div style={{ marginTop: 16 }}>{amountDisplay}</div>
          </div>
        );
      },
    },
    {
      title: 'Cuenta Contable',
      key: 'account',
      render: (text, record) => {
        return (
          <div style={{ marginTop: 8 }}>
            {props.previousFlagged ? (
              <div>
                {
                  accountOptions.find((acc) => acc.id === record.account_id)
                    ?.name
                }{' '}
                ✅
              </div>
            ) : (
              <>
                <AccountingAccountSelect
                  API_domain={props.API_domain}
                  accountOptions={accountOptions}
                  record={record}
                  allowClear={false}
                  onChange={() => {}}
                  displayMode={showAccountSelect}
                  createFlaggedComment={true}
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'comment',
      render: (text, record) => {
        return props.previousFlagged ? (
          <div>{record.comments}</div>
        ) : (
          <>
            {flagging.includes(record.key) ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    spin
                    style={{ color: 'black', marginLeft: 12 }}
                  />
                }
              />
            ) : (
              <>
                <Button
                  style={{
                    margin: 8,
                    border: 'none',
                  }}
                  onClick={() => {
                    updateSotTxnFlagged(record);
                    message.success('Categorización guardada');
                  }}
                >
                  Aprobar 👍
                </Button>
                <Button
                  className='comment-button'
                  onClick={() => {
                    props.setShowCommentModal(true);
                    props.setCommentModalSotTxn(record);
                  }}
                >
                  💬 Comentar
                </Button>
              </>
            )}
          </>
        );
      },
    },
    // Hidden columns for sorting
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fecha',
      sorter: (a, b) => new Date(a.fechaFormatted) - new Date(b.fechaFormatted),
      sortOrder: sortedInfo.columnKey === 'fecha' && sortedInfo.order,
      className: 'hidden-column',
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === 'amount' && sortedInfo.order,
      className: 'hidden-column',
    },
  ];

  const customLocale = {
    emptyText: <CustomEmptyComponent />,
  };

  return (
    <>
      {contextHolder} {/* For the message component */}
      <div style={{ marginBottom: '16px' }}>
        <Button
          onClick={() => handleSort('fecha')}
          style={{ marginRight: '8px' }}
        >
          Ordenar por fecha {getSortIcon('fecha')}
        </Button>
        <Button onClick={() => handleSort('amount')}>
          Ordenar por monto {getSortIcon('amount')}
        </Button>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={{ defaultPageSize: 50 }}
        style={{ width: '100%' }}
        loading={props.loading}
        locale={data.length === 0 ? customLocale : {}}
        rowClassName='table-row'
        onChange={handleChange}
      />
    </>
  );
};

export { AccountingInboxTable };
