import { Button, message, Popconfirm } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext } from 'react';
import { authContext } from '../../ProvideAuth.js';

function MarkAsCompletedButton(props) {
  const auth = useContext(authContext);

  const markAsCompleted = (values) => {
    axios({
      method: 'post',
      url: props.API_domain + props.API_endPoint,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        document_id: props.document.id,
      },
    })
      .then((response) => {
        message.success('Documento marcado como completo');
        props.onSubmit();
      })
      .catch((response) => {
        if (response.response.status === 400) {
          message.error('Error al marcar documento como completo');
        } else {
          message.error('Error al marcar documento como completo');
        }
      });
  };

  return (
      <Popconfirm
        title='Quieres marcar el documento como completo?'
        onConfirm={(event) => {
          markAsCompleted(props.document.id);
          event.stopPropagation();
        }}
        onCancel={(event) => event.stopPropagation()}
        okText='Marcar Completo'
        cancelText='Cancelar'
        placement='topRight'
      >
        <Button
          style={{ margin: '0 10px', color: '#25D366', borderColor: '#25D366' }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <CheckOutlined />
        </Button>
      </Popconfirm>
  );
}

export { MarkAsCompletedButton };







