import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin, Upload } from 'antd';
import { useContext, useState } from 'react';
import { read, utils } from 'xlsx';
import { downloadBlob, matchExpensesAndCSV } from '../../utils.js';
import { TeamSelect } from './../TeamSelect.js';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CsvUploader(props) {
  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      props.setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={uploadChanged}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

function AdminSOTandHeroGastosMatcher(props) {
  const auth = useContext(authContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [expenses, setExpenses] = useState([{}]);
  const [SOTcsv, setSOTcsv] = useState([]);
  const [teamForSelector, setTeamForSelector] = useState('');

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    const zohoCsvBlob = matchExpensesAndCSV(expenses, SOTcsv);
    downloadBlob(zohoCsvBlob, 'classifiedECBG.csv');
  };

  const fetchExpenses = (team) => {
    axios({
      method: 'post',
      url: props.API_domain + 'getExpenses',
      data: { team }, // passing the selected team as data
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setSubmitting(false);
        setExpenses(response.data); // Assuming response.data is the list of dictionaries
      })
      .catch((response) => {
        alert('An Error occurred. Contact Hero Team.');
      });
  };

  const onTeamSelected = (team) => {
    setTeamForSelector(team);
    setSubmitting(true);
    fetchExpenses(team);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal} style={{ fontWeight: 500 }}>
        SOT & Hero Gastos matcher
      </Button>
      <Modal
        title='SOT & Hero Gastos Matcher'
        visible={visible}
        footer={
          <Button type='primary' htmlType='submit' block onClick={onFinish}>
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Descargar matched</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <p>- acepta xls, xlsx, csv</p>
        <p>
          - asume que *SOT* tiene una columna que se llama debito y otra que se
          llama fecha
        </p>
        <p>- matchea en base a monto *y* fecha</p>
        <p>
          - regresa CSV con una columna adicional de Expense ID y categoría de
          Hero
        </p>

        <Form {...layout} onFinish={onFinish}>
          <Form.Item label='SOT:'>
            <CsvUploader setCsvData={setSOTcsv} />
          </Form.Item>
          <Form.Item label='Equipo: '>
            <TeamSelect
              API_domain={props.API_domain}
              onTeamSelect={onTeamSelected}
              team={teamForSelector}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminSOTandHeroGastosMatcher };
