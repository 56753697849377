import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { get_expenses_downloader_blob } from '../utils.js';
import { DownloadModal } from './DownloadModal.js';

function ExpenseReportDownloader(props) {
  const auth = useContext(authContext);
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [percentImagesDone, setPercentImagesDone] = useState(null);

  const { API_domain } = props;
  function download(with_images) {
    setLoading(true);
    axios({
      method: 'post',
      url: API_domain + 'getDVs',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        expenses: props.tableExpenses,
      },
    }).then((response) => {
      var zip = new JSZip();
      const gastos_blob = get_expenses_downloader_blob(response.data.expenses);
      zip.file('gastos.csv', gastos_blob);
      if (with_images) {
        var itemsProcessed = 0;
        response.data.expenses.forEach(function (item, index, array) {
          fetch(API_domain + 'downloadImage?url=' + item['factura']).then(
            (r) => {
              var file_ending =
                r.headers.get('content-type') == 'application/pdf'
                  ? '.pdf'
                  : '.jpg';
              let filename =
                item['fecha'].split('/')[0] +
                '-' +
                item['fecha'].split('/')[1] +
                '-' +
                item['fecha'].split('/')[2] +
                ' ' +
                item['descripcion'] +
                ' ' +
                item['id'] +
                file_ending;
              zip.file(filename, r.blob(), { binary: true });
              itemsProcessed++;
              setPercentImagesDone((itemsProcessed * 100) / array.length);
              if (itemsProcessed == array.length) {
                zip.generateAsync({ type: 'blob' }).then(
                  function (blob) {
                    saveAs(blob, 'heroFacturas.zip');
                    setLoading(false);
                    setPercentImagesDone(null);
                    callback_for_download();
                  },
                  function (err) {
                    console.log('Error is ', err);
                    setLoading(false);
                    setPercentImagesDone(null);
                    callback_for_download();
                  }
                );
              }
            }
          );
        });
      } else {
        zip.generateAsync({ type: 'blob' }).then(
          function (blob) {
            saveAs(blob, 'heroFacturas.zip');
            setLoading(false);
            callback_for_download();
          },
          function (err) {
            console.log('Error is ', err);
            setLoading(false);
            callback_for_download();
          }
        );
      }
    });
  }

  const onCreate = (values) => {
    download(values.with_images, callback_for_download);
  };

  const callback_for_download = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        buttontype='primary'
      >
        <DownloadOutlined />
        Descargar
      </Button>
      <DownloadModal
        isLoading={isLoading}
        visible={visible}
        percentImagesDone={percentImagesDone}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
}

export { ExpenseReportDownloader };
