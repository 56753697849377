import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Title } = Typography;

const UpdateObligationView = ({ API_domain }) => {
    const location = useLocation();
    const [updateSuccess, setUpdateSuccess] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const obligationId = queryParams.get('obligation_id');

        if (obligationId) {
            // Call the API endpoint
            fetch(`${API_domain}/markObligationAsPaid?obligation_id=${obligationId}`)
                .then(response => {
                    // Handle response
                    setUpdateSuccess(true);
                })
                .catch(error => {
                    // Handle error
                });
        }
    }, [location, API_domain]);

    if (updateSuccess) {
      return (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <CheckCircleOutlined style={{ color: 'green', fontSize: '48px' }} />
              <Title level={2}>Gracias por notificarnos</Title>
          </div>
      );
    }

    return (
        <div>Loading...</div>
    );
};

export {UpdateObligationView};