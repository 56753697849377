import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { downloadBlob, get_barrio_blob } from '../utils.js';

function ReportDownloaderBarrioPizza(props) {
  const auth = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);

  function download() {
    setIsLoading(true);
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getReportExpenses?id=' +
        props.report_id +
        '&download=Barrio',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const gastos_blob = get_barrio_blob(result.data, props.API_domain);
        downloadBlob(gastos_blob, 'reporte#' + props.report_id + '.csv');
        setIsLoading(false);
      },
      (error) => {
        console.log('Error is ', error);
        setIsLoading(false);
      }
    );
  }
  if (isLoading) {
    return <LoadingOutlined spin />;
  }
  return (
    <p onClick={download}>
      <DownloadOutlined />
      Barrio
    </p>
  );
}

export { ReportDownloaderBarrioPizza };
