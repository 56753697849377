import React, { useContext, useState } from 'react';
import { Layout, Row, Col } from 'antd';
import styled from 'styled-components/macro';
import { BankTable } from './BankTable.js';
import { SOTSelect } from './SOTSelect.js';
import { TableDataDownloader } from './TableDataDownloader.js';
import { SOTUploader } from './SOTUploader.js';
import { Refresh } from './Refresh.js';
import { MassEditButtonAndModalForSotTxns } from './MassEditButtonAndModalForSotTxns.js';
import { BankZohoDownloader } from './BankZohoDownloader.js';
import { BankMassAutoCategorizer } from './BankMassAutoCategorizer.js';
import { BankSimpleDownloader } from './BankSimpleDownloader.js';
import { authContext } from '../ProvideAuth.js';
import './styles.css';
import CommitSotTransactionsButton from './CommitSotTransactionsButton';

const { Content } = Layout;

function BankView(props) {
  const auth = useContext(authContext);
  const [currentData, setCurrentData] = useState([]);
  const [selectedSot, setSelectedSot] = useState(0);
  const [uploadChanged, setUploadChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedSotTxnsInMassEdit, setSelectedSotTxnsInMassEdit] = useState(
    []
  );
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  // console.log(selectedSotTxnsInMassEdit);
  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv>
        <Row>
          <Col span={9} style={{ textAlign: 'left' }}>
            {/* <div
              style={{
                textAlign: 'left',
                fontSize: 36,
                fontWeight: 'bold',
              }}
            >
              Banco
            </div> */}
            <Refresh
              onClick={() => setUploadChanged(!uploadChanged)}
              spin={loading}
            />
            <SOTSelect
              API_domain={props.API_domain}
              selectedSot={selectedSot}
              setSelectedSot={setSelectedSot}
              type_of_mm={props.type_of_mm}
            />
          </Col>
          <Col span={15} style={{ textAlign: 'right' }}>
            <ButtonDiv>
              <MassEditButtonAndModalForSotTxns
                API_domain={props.API_domain}
                selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
                submittedToggle={submittedToggle}
                setSubmittedToggle={setSubmittedToggle}
                accountOptions={accountOptions}
              />
            </ButtonDiv>
            <ButtonDiv>
              <BankMassAutoCategorizer
                API_domain={props.API_domain}
                selectedSot={selectedSot}
                selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
                submittedToggle={submittedToggle}
                setSubmittedToggle={setSubmittedToggle}
              />
            </ButtonDiv>
            {auth.adminEmail && (
              <>
                <ButtonDiv
                  style={{
                    visibility:
                      Object.keys(selectedSot).length === 0
                        ? 'hidden'
                        : 'visible',
                  }}
                >
                  <SOTUploader
                    API_domain={props.API_domain}
                    selectedSot={selectedSot}
                    uploadChanged={uploadChanged}
                    setUploadChanged={setUploadChanged}
                  />
                </ButtonDiv>
                <ButtonDiv>
                  <CommitSotTransactionsButton
                    selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                    API_domain={props.API_domain}
                    auth={auth}
                    setUploadChanged={setUploadChanged}
                    uploadChanged={uploadChanged}
                    setLoading={setLoading}
                    clientId={props.clientId}
                    clientAccounts={props.clientAccounts}
                  />
                </ButtonDiv>
                <ButtonDiv>
                  <BankZohoDownloader
                    data={currentData}
                    selectedSot={selectedSot}
                    accountOptions={accountOptions}
                    accountantOnly={true}
                  />
                </ButtonDiv>
                <ButtonDiv>
                  <TableDataDownloader
                    data={currentData}
                    accountantOnly={true}
                  />
                </ButtonDiv>
              </>
            )}
            <ButtonDiv>
              <BankSimpleDownloader
                data={currentData}
              />
            </ButtonDiv>
          </Col>
        </Row>
        <BankTable
          API_domain={props.API_domain}
          selectedSot={selectedSot}
          setCurrentData={setCurrentData}
          uploadChanged={uploadChanged}
          loading={loading}
          setLoading={setLoading}
          setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
          selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
          accountOptions={accountOptions}
          setAccountOptions={setAccountOptions}
          submittedToggle={submittedToggle}
        />
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  max-width: 1400px;
  margin: auto;
`;

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 10px;
`;

export { BankView };
