// V2ConciliationView.js

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Affix, Badge, Button, Col, Layout, Row, Tooltip, message } from 'antd';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { MovementSourceSelect } from './MovementSourceSelect.js';
import { ConciliationTable } from './ConciliationTable.js';
import { FormattedUSD } from './../FormattedUSD.js';
import HeroAxios from '../../helpers/HeroAxios.js';
import TransactionConciliationModal from './TransactionConciliationModal.js';
import moment from 'moment';

const { Content } = Layout;

function V2ConciliationView(props) {
  const auth = useContext(authContext);
  const [movementSources1, setMovementSources1] = useState([]);
  const [movementSources2, setMovementSources2] = useState([]); 
  const [selectedMovements1, setSelectedMovements1] = useState([]);
  const [selectedMovements2, setSelectedMovements2] = useState([]);
  const [movements1, setMovements1] = useState([]);
  const [movements2, setMovements2] = useState([]);
  const [highlightedMovements, setHighlightedMovements] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // New states for animating row removal
  const [rowsToFade1, setRowsToFade1] = useState([]);
  const [rowsToFade2, setRowsToFade2] = useState([]);

  // New state to control modal visibility and data
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);

  // State variables for counters and notifications
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [discardedCount, setDiscardedCount] = useState(0);
  const [notification, setNotification] = useState(null);

  const calculateTotal = (items) =>
    items.reduce((total, item) => total + parseFloat(item.total), 0);

  const handleUnselect = (setItems) => {
    setItems([]);
  };

  const isDateClose = (date1, date2, threshold = 1) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= threshold;
  };

  // Updated isAmountClose function to use absolute values and percentage difference
  const isAmountClose = (amount1, amount2, threshold = 0.1) => {
    const absAmount1 = Math.abs(amount1);
    const absAmount2 = Math.abs(amount2);
    const diff = Math.abs(absAmount1 - absAmount2);
    const maxAmount = Math.max(absAmount1, absAmount2);
    return diff <= threshold * maxAmount;
  };

  const stringSimilarity = (str1 = '', str2 = '') => {
    const s1 = str1.toLowerCase();
    const s2 = str2.toLowerCase();
    const pairs1 = new Set(
      s1
        .split('')
        .map((_, i) => s1.slice(i, i + 2))
        .filter((pair) => pair.length === 2)
    );
    const pairs2 = new Set(
      s2
        .split('')
        .map((_, i) => s2.slice(i, i + 2))
        .filter((pair) => pair.length === 2)
    );
    const union = new Set([...pairs1, ...pairs2]);
    const intersection = new Set(
      [...pairs1].filter((pair) => pairs2.has(pair))
    );
    return union.size === 0 ? 0 : intersection.size / union.size;
  };

  const getHighlightedMovements = (selectedMovements, allMovements) => {
    if (selectedMovements.length === 0) return [];

    const highlighted = [];
    const dateMatches = [];

    for (const selected of selectedMovements) {
      for (const movement of allMovements) {
        const isDateMatch = isDateClose(
          selected.movement_date,
          movement.movement_date
        );
        const isAmountMatch = isAmountClose(
          parseFloat(selected.total),
          parseFloat(movement.total)
        );
        const isNameMatch =
          stringSimilarity(
            selected.provider_or_consumer_name || '',
            movement.provider_or_consumer_name || ''
          ) > 0.5;

        if (isDateMatch && isAmountMatch) {
          highlighted.push(movement);
        } else if (isDateMatch && isNameMatch) {
          highlighted.push(movement);
        } else if (isDateMatch) {
          dateMatches.push(movement);
        }
      }
    }

    // If we haven't found enough matches, add the date matches
    if (highlighted.length === 0) {
      return dateMatches.slice(0, selectedMovements.length + 1);
    }

    return highlighted;
  };

  useEffect(() => {
    if (selectedMovements1.length > 0) {
      const highlighted = getHighlightedMovements(
        selectedMovements1,
        movements2
      );
      setHighlightedMovements(highlighted);
    } else {
      setHighlightedMovements([]);
    }
  }, [selectedMovements1, movements2]);

  const getMovements = (sources, setMovements, setLoading) => {
    if (sources.length === 0) {
      setMovements([]);
      return;
    }
    setLoading(true);
    const fetches = sources.map((source) => HeroAxios.post('getMovementsForConciliation', source));
  
    Promise.all(fetches)
      .then((responses) => {
        const allMovements = responses.reduce((acc, response) => {
          return acc.concat(response.data);
        }, []);
        setMovements(allMovements);
        setLoading(false);
      })
      .catch((error) => {
        message.error('Error al obtener movimientos');
        console.error('Error fetching movements:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (movementSources1.length > 0) {
      getMovements(movementSources1, setMovements1, setLoading1);
    } else {
      setMovements1([]);
    }
  }, [movementSources1]);
  
  useEffect(() => {
    if (movementSources2.length > 0) {
      getMovements(movementSources2, setMovements2, setLoading2);
    } else {
      setMovements2([]);
    }
  }, [movementSources2]);
  

  const handleJoin = useCallback(() => {
    const movements1Data = selectedMovements1.map((movement) => ({
      table_name: movement.source_table,
      id: movement.id,
    }));
  
    const movements2Data = selectedMovements2.map((movement) => ({
      table_name: movement.source_table,
      id: movement.id,
    }));
  
    HeroAxios.post('createPairwiseRelationship', {
      movements1: movements1Data,
      movements2: movements2Data,
    })
      .then((response) => {
        message.success('Unión exitosa');
        const trackingData = {
          event: 'movementsJoined',
          properties: {},
        };
        HeroAxios.post('trackFrontEndEvent', trackingData)
          .then((response) => {
            console.log('Event tracked:', response);
          })
          .catch((error) => {
            console.error('Error tracking event:', error);
          });
  
        // Start the fade-out animation by setting rowsToFade
        setRowsToFade1(selectedMovements1.map((m) => m.id));
        setRowsToFade2(selectedMovements2.map((m) => m.id));
  
        // Delay the actual removal to allow animation to complete
        setTimeout(() => {
          setMovements1((prevMovements) =>
            prevMovements.filter(
              (m) =>
                !selectedMovements1.some((selected) => selected.id === m.id)
            )
          );
          setMovements2((prevMovements) =>
            prevMovements.filter(
              (m) =>
                !selectedMovements2.some((selected) => selected.id === m.id)
            )
          );
          setRowsToFade1([]);
          setRowsToFade2([]);
        }, 300); // Duration of animation in milliseconds
  
        setSelectedMovements1([]);
        setSelectedMovements2([]);
      })
      .catch((error) => {
        message.error('Ocurrió un error uniendo');
        console.error(error);
      });
  }, [selectedMovements1, selectedMovements2]);
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        if (selectedMovements1.length >= 1 && selectedMovements2.length >= 1) {
          event.preventDefault();
          handleJoin();
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedMovements1.length, selectedMovements2.length, handleJoin]);

  // Function to find exact matches between movements
  const findMatchingPairs = (movements1, movements2) => {
    const pairs = [];
    const used1 = new Set();
    const used2 = new Set();

    // Sort both arrays by date for better matching
    const sorted1 = [...movements1].sort((a, b) => new Date(a.movement_date) - new Date(b.movement_date));
    const sorted2 = [...movements2].sort((a, b) => new Date(a.movement_date) - new Date(b.movement_date));

    // Find exact matches first (same date and amount)
    sorted1.forEach(mov1 => {
      if (used1.has(mov1.id)) return;
      
      const matchIndex = sorted2.findIndex(mov2 => 
        !used2.has(mov2.id) &&
        mov1.movement_date === mov2.movement_date &&
        Math.abs(parseFloat(mov1.total) - parseFloat(mov2.total)) < 0.01
      );

      if (matchIndex !== -1) {
        const mov2 = sorted2[matchIndex];
        pairs.push({
          key: pairs.length,
          left: mov1,
          right: mov2,
          estado: 'Pendiente',
          confianza: 100
        });
        used1.add(mov1.id);
        used2.add(mov2.id);
      }
    });

    return pairs;
  };

  // Function to open modal with matched pairs
  const openModal = () => {
    const matchedPairs = findMatchingPairs(movements1, movements2);
    setModalData(matchedPairs);
    setModalVisible(true);
  };

  // Updated handleModalUnir function
  const handleModalUnir = (pair) => {
    // Prepare movements data
    const movements1Data = [
      {
        table_name: pair.left.source_table,
        id: pair.left.id,
      },
    ];

    const movements2Data = [
      {
        table_name: pair.right.source_table,
        id: pair.right.id,
      },
    ];

    // Perform the merge operation
    return HeroAxios.post('createPairwiseRelationship', {
      movements1: movements1Data,
      movements2: movements2Data,
    })
      .then(() => {
        // Increment the accepted count
        setAcceptedCount(prevCount => prevCount + 1);

        // Show notification for 2 seconds
        setNotification('Unión exitosa');
        setTimeout(() => {
          setNotification(null);
        }, 2000);

        // Remove the pair from modalData
        setModalData(prevData =>
          prevData.filter(
            p => p.left.id !== pair.left.id && p.right.id !== pair.right.id
          )
        );
      })
      .catch((error) => {
        message.error('Ocurrió un error uniendo');
        console.error(error);
      });
  };

  // Function to handle discarding a pair
  const handleDiscardPair = (pair) => {
    return new Promise((resolve) => {
      // Increment the discarded count
      setDiscardedCount(prevCount => prevCount + 1);

      // Remove the pair from modalData
      setModalData(prevData =>
        prevData.filter(
          p => p.left.id !== pair.left.id && p.right.id !== pair.right.id
        )
      );

      resolve();
    });
  };

  // Add this function to get the count of matches
  const getMatchCount = useCallback(() => {
    if (!movements1?.length || !movements2?.length) return 0;
    const matchedPairs = findMatchingPairs(movements1, movements2);
    return matchedPairs.length;
  }, [movements1, movements2]);

  return (
    <Content
      style={{
        margin: '0',
        overflow: 'initial',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Affix>
        <AffixDiv>
          <StyledRow>
            <StyledCol span={12}>
              <Row align='middle' justify='start' gutter={8}>
                <Col>
                  <h2 style={{ marginBottom: 0 }}>💳 Movimientos 1</h2>
                </Col>
                <Col flex='auto'>
                  <MovementSourceSelect
                    API_domain={props.API_domain}
                    onSourceSelect={(sources) => {
                      setMovementSources1(sources);
                    }}
                  />
                  <Tooltip title={`${getMatchCount()} coincidencias exactas encontradas`}>
                    <Badge 
                      count={getMatchCount()} 
                      showZero
                      size="small" 
                      offset={[10, 0]}
                      style={{
                        backgroundColor: getMatchCount() === 0 ? '#f5f5f5' : 'white',
                        color: getMatchCount() === 0 ? '#d9d9d9' : '#722ed1',
                        border: `1px solid ${getMatchCount() === 0 ? '#d9d9d9' : '#722ed1'}`
                      }}
                    >
                      <AutoMatchButton 
                        onClick={openModal}
                        disabled={getMatchCount() === 0}
                      >
                        Boton🪄
                      </AutoMatchButton>
                    </Badge>
                  </Tooltip>
                </Col>
                <Col>
                  
                </Col>
              </Row>
            </StyledCol>
            <StyledCol span={12}>
              <Row align='middle' justify='start'>
                <Col>
                  <h2 style={{ marginBottom: 0 }}>💳 Movimientos 2</h2>
                </Col>
                <Col flex='auto'>
                  <MovementSourceSelect
                    API_domain={props.API_domain}
                    onSourceSelect={(sources) => {
                      setMovementSources2(sources);
                    }}
                  />
                </Col>
              </Row>
            </StyledCol>
          </StyledRow>
        </AffixDiv>
      </Affix>
      <StyledRow style={{ height: 'calc(100% - 105px)' }}>
        <StyledCol span={12}>
          <ScrollableTableContainer>
            <ConciliationTable
              data={movements1}
              loading={loading1}
              selectedItems={selectedMovements1}
              setSelectedItems={setSelectedMovements1}
              highlightedMovements={[]}
              rowsToFade={rowsToFade1}
              type='transaction'
              API_domain={props.API_domain}
            />
          </ScrollableTableContainer>
        </StyledCol>
        <StyledCol span={12}>
          <ScrollableTableContainer>
            <ConciliationTable
              data={movements2}
              loading={loading2}
              selectedItems={selectedMovements2}
              setSelectedItems={setSelectedMovements2}
              highlightedMovements={highlightedMovements}
              rowsToFade={rowsToFade2}
              type='transaction'
              API_domain={props.API_domain}
            />
          </ScrollableTableContainer>
        </StyledCol>
      </StyledRow>
      <Affix offsetBottom={0}>
        <AffixDivBottom>
          <Row justify='space-between' align='middle'>
            <Col span={10} style={{ textAlign: 'right' }}>
              {selectedMovements1.length > 0 && (
                <>
                  <Button
                    onClick={() => handleUnselect(setSelectedMovements1)}
                    style={{ marginRight: 10 }}
                  >
                    Deseleccionar
                  </Button>
                  Total ({selectedMovements1.length} movimientos):{' '}
                  <b>
                    <FormattedUSD total={calculateTotal(selectedMovements1)} />
                  </b>
                </>
              )}
            </Col>
            <Col span={4} style={{ textAlign: 'center' }}>
              <Tooltip
                title={
                  <>
                    {selectedMovements1.length < 1 || selectedMovements2.length < 1
                      ? 'Selecciona al menos un movimiento de cada tabla'
                      : 'Atajo: ⌘ / Alt + Enter'}
                  </>
                }
              >
                <Button
                  type='primary'
                  style={{ marginRight: 10 }}
                  disabled={
                    selectedMovements1.length < 1 || selectedMovements2.length < 1
                  }
                  onClick={handleJoin}
                >
                  Unir
                </Button>
              </Tooltip>
            </Col>
            <Col span={10} style={{ textAlign: 'left' }}>
              {selectedMovements2.length > 0 && (
                <>
                  Total ({selectedMovements2.length} movimientos):{' '}
                  <b>
                    <FormattedUSD total={calculateTotal(selectedMovements2)} />
                  </b>
                  <Button
                    onClick={() => handleUnselect(setSelectedMovements2)}
                    style={{ marginLeft: 10 }}
                  >
                    Deseleccionar
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </AffixDivBottom>
      </Affix>

      {/* Display the notification */}
      {notification && (
        <StyledNotification>{notification}</StyledNotification>
      )}

      {/* Display the accepted and discarded counts */}
      <CountersContainer>
        <span>Aceptados: {acceptedCount}</span>
        <span>Descartados: {discardedCount}</span>
      </CountersContainer>

      {/* Include the modal component */}
      <TransactionConciliationModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        data={modalData}
        onUnir={handleModalUnir}
        onDescartar={handleDiscardPair}
      />
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const AffixDivBottom = styled.div`
  border-top: 1px solid #e8e8e8;
  background-color: white;
  padding-top: 6px;
  padding-left: 14px;
  padding-right: 14px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 225px);
  padding-bottom: 8px;
`;

const ScrollableTableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  height: calc(100% - 105px);
  min-height: 0;
`;

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  height: calc(100% - 105px);
`;

const StyledCol = styled(Col)`
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const AutoMatchButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 14px;

  &:disabled {
    border: 1px solid #d9d9d9;
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &:not(:disabled) {
    border: 1px solid #722ed1;
    background: white;
    color: #722ed1;
    cursor: pointer;

    &:hover {
      background: #722ed1;
      color: white;
    }
  }
`;

// Styled components for notification and counters
const StyledNotification = styled.div`
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #52c41a;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1100;
`;

const CountersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  span {
    margin-left: 20px;
    font-weight: bold;
  }
`;

export { V2ConciliationView };
