import { PlusOutlined } from '@ant-design/icons';
import { Affix, Button, Col, Layout, Row } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from '../TeamSelect.js';
import { Refresh } from '../Refresh.js';
import { RequisitionModal } from './RequisitionModal.js';
import { RequisitionsTable } from './RequisitionsTable.js';
import { BoxUploader } from '../Compras/BoxUploader.js';
import { RequisitionsNewModal } from './RequisitionsNewModal.js';
import { useLocation } from 'react-router-dom';
const { Content } = Layout;

function RequisitionsView(props) {
  const [isLoaded, setLoaded] = useState(false);
  const [selectedRequisition, setSelectedRequisition] = useState();
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState();
  const location = useLocation();
  const [assigneeFilter, setAssigneeFilter] = useState(
    new URLSearchParams(location.search).get('assigneeFilter')
      ? new URLSearchParams(location.search).get('assigneeFilter')
      : []
  );

  const onSubmit = () => {
    setShowNewRequisitionModal(false);
    setSubmittedToggle(!submittedToggle);
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <RequisitionsNewModal
        handleCancel={() => {
          setShowNewRequisitionModal(false);
        }}
        showNewRequisitionModal={showNewRequisitionModal}
        setShowNewRequisitionModal={setShowNewRequisitionModal}
        handleOk={onSubmit}
        API_domain={props.API_domain}
      />
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Refresh onClick={onSubmit} spin={!isLoaded} />
              <TeamSelect
                API_domain={props.API_domain}
                onTeamSelect={props.onTeamSelect}
                team={props.team}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <ButtonDiv>
                <Button
                  type='primary'
                  onClick={() => setShowNewRequisitionModal(true)}
                >
                  <ButtonSpan>
                    <>
                      <PlusOutlined /> Crear Requisición
                    </>
                  </ButtonSpan>
                </Button>
              </ButtonDiv>
            </Col>
          </Row>
          {selectedRequisition && (
            <RequisitionModal
              API_domain={props.API_domain}
              requisition={selectedRequisition}
              submittedToggle={submittedToggle}
              setSubmittedToggle={setSubmittedToggle}
              setLoaded={setLoaded}
              setSelectedRequisition={setSelectedRequisition}
              handleOk={() => {
                setSelectedRequisition(null);
                setLoaded(false);
                setSubmittedToggle(!submittedToggle);
              }}
              //   invoiceStateMapper={invoiceStateMapper}
            />
          )}
        </AffixDiv>
      </Affix>
      <BeautifulDiv>
        <RequisitionsTable
          API_domain={props.API_domain}
          team={props.team}
          submittedToggle={submittedToggle}
          setSubmittedToggle={setSubmittedToggle}
          openModal={setSelectedRequisition}
          assigneeFilter={assigneeFilter}
          isLoaded={isLoaded}
          setLoaded={setLoaded}
        />
      </BeautifulDiv>
    </Content>
  );
}

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 15px;
`;
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const BeautifulDiv = styled.div`
  width: min(calc(100vw - 300px), 1200px);
  margin: auto;
`;

export { RequisitionsView };
