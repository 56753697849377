import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin, Input, message, Checkbox } from 'antd'; // Import Checkbox
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { SelectCompanyUsers } from './SelectCompanyUsers.js';
import { TeamSelect } from './TeamSelect.js';

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 16,
  },
};

function CardsTableNewCardModal(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(false);
  const [team, setTeam] = useState(false);
  const [isCompanyName, setIsCompanyName] = useState(false); // Add state for checkbox

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      userfacturas_id: selectedUser,
      team: values.team,
      is_company_name: isCompanyName, // Include checkbox value
    };
    axios({
      method: 'post',
      url: props.API_domain + 'CreatePaycaddyUserAndAskForKYC',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        if (isCompanyName){
          message.success('Emitimos una tarjeta a nombre de tu compañía!');
        }else{
          message.success('Ya le escribimos al usuario para validación de identidad');
        }
        props.handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        message.error('Ocurrió un error pidiendo tu tarjeta');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title='Pedir Tarjeta'
      visible={props.visible}
      footer={
        <Button
          type='primary'
          htmlType='submit'
          block
          style={{
            fontWeight: 500,
            marginTop: 10,
            marginBottom: 10,
          }}
          onClick={form.submit}
        >
          {isSubmitting ? (
            <Spin indicator={antIcon} />
          ) : (
            <>Pedir Tarjeta</>
          )}
        </Button>
      }
      onCancel={props.handleCancel}
      centered
    >
      <Form
        {...layout}
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item // Add a form item for the checkbox
          name='isCompanyName'
          valuePropName='checked' // Ensure the value is treated as a boolean
          label='A Nombre de compañía:'
        >
          <Checkbox onChange={(e) => setIsCompanyName(e.target.checked)}>
          </Checkbox>
        </Form.Item>
        <Form.Item
          name='usersfacturas_id'
          label='Usuario:'
          rules={[{ required: true, message: 'Por favor seleccionar usuario' }]}
        >
          <SelectCompanyUsers
            API_domain={props.API_domain}
            setSelectedMemberId={(value) => {
              form.setFieldsValue({ usersfacturas_id: value });
              setSelectedUser(value);
            }}
            team={'all'}
          />
        </Form.Item>
        <Form.Item
          name='team'
          label='Equipo'
          rules={[{ required: true, message: 'Por favor seleccionar equipo' }]}
        >
          <TeamSelect
            noPadding={true}
            team={team}
            API_domain={props.API_domain}
            onTeamSelect={(value) => {
              form.setFieldsValue({ team: value });
              setTeam(value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { CardsTableNewCardModal };
