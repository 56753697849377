// AccountingAccountSelect.js

import React, { useState, useEffect, useRef } from 'react';
import { Select, message } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import axios from 'axios';

function AccountingAccountSelect({
  value,
  onChange,
  clientId,
  API_domain,
  auth,
  ...restProps
}) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectRef = useRef(null);

  // Fetch accounts for the client
  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${API_domain}getClientAccounts`,
          {
            client_id: clientId,
          },
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        setAccounts(response.data.accounts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        message.error('Error al obtener las cuentas');
        setLoading(false);
      }
    };
    if (!restProps.accounts) {
      fetchAccounts();
    } else {
      setAccounts(restProps.accounts);
    }
  }, [API_domain, clientId, auth]);

    const calculateDropdownWidth = () => {
      if (selectRef.current) {
        const selectWidth = selectRef.current.offsetWidth;
        return selectWidth * 1.5; // Set to 1.5 times the select width, for example
      }
      return 300; // Fallback width
    };
  return (
    <Select
      showSearch
      value={value}
      loading={loading}
      style={{ width: '100%' }}
      onChange={onChange}
      optionLabelProp="label"
      dropdownStyle={{ width: `${calculateDropdownWidth()}px` }}
      dropdownMatchSelectWidth={false}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...restProps}
    >
      {accounts.map((account) => (
        <Select.Option
          key={account.account_id}
          value={account.account_id}
          label={account.zoho_account_name}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>{account.zoho_account_name}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ color: 'gray', marginLeft: 10 }}>
                <SwapOutlined style={{ marginLeft: 4 }} />
                {account.movement_count}
              </span>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default AccountingAccountSelect;
