import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './../styles.css';
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  LineElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartDataLabels
);

const getCSSVariable = (variable) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

function AvailableCash(props) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const balanceSheetData = props.balanceSheetData;
    const balanceSheetRecentTimePeriods = props.balanceSheetRecentTimePeriods;

    // Find the "Assets" category
    const assetsCategory = balanceSheetData.find(
      (item) => item.category === 'Assets'
    );

    if (
      !assetsCategory ||
      !assetsCategory.children ||
      !balanceSheetRecentTimePeriods
    ) {
      setChartData({
        labels: [],
        datasets: [],
      });
      return;
    }

    // Get the length of the 'values' arrays to match the recent time periods
    const valuesLength = balanceSheetRecentTimePeriods.length;

    // Initialize an array of zeros for totalCash over time
    const totalCashArray = Array(valuesLength).fill(0);

    // Iterate over assetsCategory.children to calculate total cash
    assetsCategory.children.forEach((child) => {
      const { category, subcategory, values } = child;

      if (
        category === 'bank' ||
        (category === 'other_current_asset' && subcategory === 'Efectivo') ||
        category === 'cash'
      ) {
        // Add each 'values' array to totalCashArray element-wise
        values.slice(-valuesLength).forEach((value, index) => {
          totalCashArray[index] += value || 0;
        });
      }
    });

    // Prepare the data for the chart
    const labels = balanceSheetRecentTimePeriods.map((dateString) => {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${String(date.getMonth() + 2).padStart(
        2,
        '0'
      )}`;
    });

    // Get CSS variables for consistent styling
    const borderColor = getCSSVariable('--purple-light');
    const backgroundColor = getCSSVariable('--purple-light');
    const pointBackgroundColor = getCSSVariable('--purple-normal');
    const pointBorderColor = getCSSVariable('--purple-normal');
    const hoverBackgroundColor = getCSSVariable('--purple-dark');

    setChartData({
      labels,
      datasets: [
        {
          data: totalCashArray,
          borderColor: borderColor,
          backgroundColor: backgroundColor,
          fill: true,
          tension: 0.4,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: pointBorderColor,
          hoverBackgroundColor: hoverBackgroundColor,
        },
      ],
    });
  }, [props.balanceSheetData, props.balanceSheetRecentTimePeriods]);

  return (
    <div className='infographic-card' style={{ position: 'relative' }}>
      <div style={{ opacity: 0.16 }}>
        <h2 style={{ color: 'var(--purple-darkest)' }}>Efectivo Disponible</h2>
        <Line
          data={chartData}
          options={{
            layout: {
              padding: {
                top: 8,
                bottom: 8,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                anchor: 'end',
                align: 'top',
                offset: 0,
                formatter: (value) => `$${value.toLocaleString()}`, // Format as USD with commas
                color: getCSSVariable('--purple-darkest'),
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.raw;
                    return `$${value.toLocaleString()}`; // Format as USD with commas
                  },
                },
              },
            },
            elements: {
              point: {
                radius: 5,
                hoverRadius: 7,
                pointStyle: 'circle',
                hitRadius: 10,
                hoverBorderWidth: 2,
              },
            },
            scales: {
              x: {
                beginAtZero: false,
                ticks: {
                  padding: 8,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  padding: 8,
                  callback: function (value) {
                    return `$${value.toLocaleString()}`; // Format y-axis labels as USD with commas
                  },
                },
              },
            },
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'rgba(128, 128, 128, 0.9)',
          fontSize: '18px',
          fontWeight: 'bold',
          pointerEvents: 'none',
        }}
      >
        Coming soon ⚡🙂
      </div>
    </div>
  );
}

export { AvailableCash };
