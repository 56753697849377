import React from 'react';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';

const InfoCard = ({ title, onClose, children }) => {
  const styles = {
    container: {
      width: '100%',
      maxWidth: '450px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow:
        '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    },
    header: {
      padding: '16px 16px 8px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #f0f0f0',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    title: {
      margin: 0,
      fontSize: '16px',
      fontWeight: 600,
      color: '#1f2937',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      padding: '4px',
      cursor: 'pointer',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    closeIcon: {
      fontSize: '16px',
      color: '#6b7280',
    },
    content: {
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      textAlign: 'left',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.titleWrapper}>
          <InfoCircleOutlined style={{ fontSize: 20, color: '#6b7280' }} />
          <h3 style={styles.title}>{title}</h3>
        </div>
        <button
          style={styles.closeButton}
          onClick={onClose}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = '#f3f4f6')
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = 'transparent')
          }
        >
          <CloseOutlined style={styles.closeIcon} />
        </button>
      </div>

      <div style={styles.content}>{children}</div>
    </div>
  );
};

export default InfoCard;
