import React, { useContext, useState } from 'react';
import { Switch, Button, message } from 'antd';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';

const CompanyWalletEnabler = (props) => {
  const auth = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(
    props.accountingClientCompany.wallet_enabled
  ); // Assuming `is_enabled` comes from props

  const handleToggleChange = (checked) => {
    setIsEnabled(checked);
    handleSave(checked);
  };

  const handleSave = (checked) => {
    setLoading(true);
    if (checked) {
      axios({
        method: 'post',
        url: props.API_domain + 'createCompanyWallet',
        data: {
          company_id:
            props.accountingClientCompany.accounting_client_company_id,
        },
        auth: {
          username: auth.email,
          password: auth.token,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          if (response.data) {
            message.success(
              'Wallet ' +
                (checked ? 'habilitado' : 'deshabilitado') +
                ' exitosamente.'
            );
          } else {
            message.error('Error updating the company status');
          }
        })
        .catch((error) => {
          console.error('Error updating company status:', error);
          message.error('Error updating the company status');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <div style={{ marginLeft: 96 }}>
        <Switch
          checked={isEnabled}
          onChange={handleToggleChange}
          checkedChildren='Habilitado'
          unCheckedChildren='Deshabilitado'
          style={{ fontSize: '20px', width: '160px' }}
          disabled={isEnabled}
        />
      </div>
    </div>
  );
};

export default CompanyWalletEnabler;
