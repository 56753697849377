import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext } from 'react';
import { authContext } from '../ProvideAuth.js';
import { useAnalytics } from 'use-analytics';
import { downloadBlob, get_quickbooks_blob } from '../utils.js';

function ReportDownloaderQuickbooks(props) {
  const auth = useContext(authContext);
  const { track } = useAnalytics();

  function download() {
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getReportExpenses?id=' +
        props.report_id +
        '&download=General',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const gastos_blob = get_quickbooks_blob(result.data);
        downloadBlob(
          gastos_blob,
          result.data[0]['title'] + 'Quickbooks_import.csv'
        );
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
  }

  return (
    <p onClick={download}>
      <DownloadOutlined />
      Quickbooks
    </p>
  );
}

export { ReportDownloaderQuickbooks };
