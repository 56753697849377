import { FilePdfOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import React, { useState, useEffect } from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

function getAspectRatio(imageSrc) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = (img.height / img.width) * 100;
      resolve(aspectRatio);
    };
    img.onerror = () => {
      reject(new Error('Failed to load image: ' + imageSrc));
    };
    img.src = imageSrc;
  });
}

function ImgOrPdfProcurement(props) {
  const [isImage, setIsImage] = useState(true);

  useEffect(() => {
    setIsImage(true);
  }, [props.src.imageSource]);
  if (isImage) {
    if (props.isThumbnail) {
      return (
        <>
          <Image // This is a hidden image that is used to check if the image is valid
            style={{
              borderRadius: '4px',
              width: props.width ? props.width : '100%',
              display: 'none',
            }}
            onError={(event) => setIsImage(false)}
            src={props.src.imageSource}
          />
          <div style={{ height: 50 }}>
            <img // This is the image that is displayed
              src={props.src.imageSource}
              style={{
                width: props.width ? props.width : '100%',
                maxWidth: '100%',
              }}
              alt='Image description'
            />
          </div>
        </>
      );
    } else {
      return (
        <Image // This is the image that is rendered if it not a thumbnail
          style={{
            borderRadius: '4px',
            height: 400,
          }}
          onError={(event) => setIsImage(false)}
          src={props.src.imageSource}
        />
      );
    }
  } else {
    return props.isThumbnail ? (
      <div // This is the image that is rendered if it is a thumbnail
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <FilePdfOutlined style={{ fontSize: 50, color: 'grey' }} />
      </div>
    ) : (
      <embed
        src={props.src.imageSource}
        width='100%'
        height='400px'
        type='application/pdf'
      ></embed>
    );
  }
}

export { ImgOrPdfProcurement };
