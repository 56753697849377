import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function CategorySelect(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState();
  const [value, setValue] = useState(
    props.defaultCategory ? props.defaultCategory : null
  );

  function fetchCategoryOptions() {
    axios({
      method: 'post',
      url: props.API_domain + 'categoryOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then(function (response) {
        setOptions(
          response.data.map((category) => ({
            label: category.category,
            value: category.id,
          }))
        );
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchCategoryOptions();
    setValue(props.defaultCategory);
  }, [props.defaultCategory, props.team, props.expenseId]);

  function handleChange(value) {
    setValue(value);
    props.onChange(value);
  }
  return (
    <Select
      allowClear
      filterOption={(input, option) =>
        option.props.label
          .toString()
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      options={options}
      onChange={handleChange}
      placeholder='Selecciona categoría'
      value={value}
      disabled={props.disabledBoolean}
    />
  );
}

export { CategorySelect };
