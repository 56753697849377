import { Button, Form, Select, Modal, Table, Typography } from 'antd';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const { Option } = Select;
const { Text } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminViewClientConfig(props) {
  const [isFetching, setFetching] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [clientConfigs, setClientConfigs] = useState([]);

  useEffect(() => {
    if (visible) {
      getAccountingClientOptions();
    }
  }, [visible]);

  const getAccountingClientOptions = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingClients',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.name}
        </Option>
      ));
      setClientOptions(options);
    } catch (error) {
      console.error(
        'There was an error fetching the accounting clients',
        error
      );
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setFetching(true);
    axios({
      method: 'post',
      url: props.API_domain + 'adminGetSingleClientConfigs',
      data: { client_id: values.client_id },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setClientConfigs(response.data);
        setFetching(false);
      })
      .catch((error) => {
        console.error('An error occurred while fetching client configs', error);
        setFetching(false);
      });
  };

  const renderLoginInfo = (login_info) => {
    const renderNestedObject = (obj, parentKey = '') => {
      return Object.entries(obj).map(([key, value]) => {
        const combinedKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof value === 'object' && value !== null) {
          return renderNestedObject(value, combinedKey);
        } else {
          return (
            <div key={combinedKey}>
              <Text strong>{combinedKey}: </Text>
              <Text copyable>{value}</Text>
            </div>
          );
        }
      });
    };

    return renderNestedObject(login_info);
  };


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Site',
      dataIndex: 'site',
      key: 'site',
    },
    {
      title: 'Login Info',
      dataIndex: 'login_info',
      key: 'login_info',
      render: (login_info) => renderLoginInfo(login_info),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>View Client Configs</ButtonSpan>
      </Button>
      <Modal
        title='View Client Configs'
        visible={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        confirmLoading={isFetching}
        centered
        width={800}  // Set the modal width to 800px
      >
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name='client_id'
            label='Client ID'
            rules={[{ required: true, message: 'Please select the client' }]}
          >
            <Select
              showSearch
              placeholder='Select a client'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientOptions}
            </Select>
          </Form.Item>
        </Form>
        {clientConfigs.length > 0 && (
          <Table
            dataSource={clientConfigs}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        )}
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminViewClientConfig };
