import { LoadingOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { useState } from 'react';
import styled from 'styled-components/macro';

const excelDateToJSDate = (serial) => {
  const epoch = new Date(1899, 11, 31);
  const excelDate = new Date(epoch.getTime() + (serial - 1) * 86400000);
  return excelDate;
};

function AdminZohoCorrectDateReturner(props) {
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState(
    Array.from({ length: 100 }, () => ({
      unformattedDate: '',
      formattedDate: '',
    }))
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleInputChange = (index, newValue) => {
    const newData = [...tableData];
    newData[index].unformattedDate = newValue;

    // Extract non-empty unformattedDates and format them.
    const nonEmptyDates = newData
      .map((row) => row.unformattedDate)
      .filter((date) => date.trim() !== '');

    const formattedDates = formatDatesList(nonEmptyDates);

    // Update formattedDates back into tableData.
    let j = 0;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].unformattedDate.trim() !== '') {
        newData[i].formattedDate = formattedDates[j] || '';
        j++;
      } else {
        newData[i].formattedDate = '';
      }
    }

    setTableData(newData);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const pastedRows = pastedData
      .split('\n')
      .map((row) => row.trim()) // Trim each row to remove carriage return or white spaces
      .map((row) => row.split('\t'));

    // Assuming you are pasting into a single column, the first one
    const updatedData = [...tableData];
    for (let i = 0, j = 0; i < pastedRows.length; i++, j++) {
      const [unformattedDate] = pastedRows[i];
      if (j < updatedData.length) {
        updatedData[j].unformattedDate = unformattedDate;
      } else {
        updatedData.push({ unformattedDate, formattedDate: '' });
      }
    }

    // Now you can run your formatting logic to populate formattedDate
    const nonEmptyDates = updatedData
      .map((row) => row.unformattedDate)
      .filter((date) => date.trim() !== '');
    const formattedDates = formatDatesList(nonEmptyDates);

    for (let i = 0, k = 0; i < updatedData.length; i++) {
      if (updatedData[i].unformattedDate.trim() !== '') {
        updatedData[i].formattedDate = formattedDates[k] || '';
        k++;
      } else {
        updatedData[i].formattedDate = '';
      }
    }

    setTableData(updatedData);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Correct date returner</ButtonSpan>
      </Button>
      <Modal
        title='Convertidor a ZOHO'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
      >
        <table>
          <thead>
            <tr>
              <th>Unformatted Date</th>
              <th>YYYY-MM-DD Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Button
                  type='primary'
                  onClick={() => {
                    const newData = [...tableData];
                    for (let i = 0; i < newData.length; i++) {
                      newData[i].unformattedDate = '';
                      newData[i].formattedDate = '';
                    }
                    setTableData(newData);
                  }}
                >
                  Clear all
                </Button>
              </td>
              <td>
                <Button
                  style={{ margin: 2 }}
                  type='primary'
                  onClick={() => {
                    const newData = [...tableData];
                    let formattedDates = [];
                    for (let i = 0; i < newData.length; i++) {
                      if (newData[i].unformattedDate.trim() !== '') {
                        formattedDates.push(newData[i].formattedDate);
                      }
                    }
                    navigator.clipboard.writeText(formattedDates.join('\n'));
                    message.success('Fechas copiadas');
                  }}
                >
                  Copy all as YYYY-MM-DD str
                </Button>
                <Button
                  style={{
                    margin: 2,
                    backgroundColor: 'green',
                    borderColor: 'green',
                    color: 'white',
                  }}
                  onClick={() => {
                    const newData = [...tableData];
                    let formattedDates = [];
                    for (let i = 0; i < newData.length; i++) {
                      if (
                        newData[i].unformattedDate &&
                        newData[i].unformattedDate.trim() !== ''
                      ) {
                        // Convert unformattedDate to Excel format and store in formattedDates array
                        const excelDate = convertToExcelDate(
                          newData[i].formattedDate
                        );
                        formattedDates.push(excelDate);
                      }
                    }
                    navigator.clipboard.writeText(formattedDates.join('\n'));
                    message.success('Fechas copiadas');
                  }}
                >
                  Copy all in Excel format ("44022")
                </Button>
                <Button
                  style={{
                    margin: 2,
                    backgroundColor: 'blue',
                    borderColor: 'blue',
                    color: 'white',
                  }}
                  onClick={() => {
                    const newData = [...tableData];
                    let formattedDates = [];
                    for (let i = 0; i < newData.length; i++) {
                      if (
                        newData[i].unformattedDate &&
                        newData[i].unformattedDate.trim() !== ''
                      ) {
                        // Convert unformattedDate to Excel format and store in formattedDates array
                        const desiredDateFormat = convertDateSlashOrDash(
                          newData[i].formattedDate,
                          'MM/DD/YY'
                        );
                        formattedDates.push(desiredDateFormat);
                      }
                    }
                    navigator.clipboard.writeText(formattedDates.join('\n'));
                    message.success('Fechas copiadas');
                  }}
                >
                  Copy all in MM/DD/YY format
                </Button>
                <Button
                  style={{
                    margin: 2,
                    backgroundColor: 'yellow',
                    borderColor: 'yellow',
                    color: 'black',
                  }}
                  onClick={() => {
                    const newData = [...tableData];
                    let formattedDates = [];
                    for (let i = 0; i < newData.length; i++) {
                      if (
                        newData[i].unformattedDate &&
                        newData[i].unformattedDate.trim() !== ''
                      ) {
                        // Convert unformattedDate to Excel format and store in formattedDates array
                        const desiredDateFormat = convertDateSlashOrDash(
                          newData[i].formattedDate,
                          'DD/MM/YY'
                        );
                        formattedDates.push(desiredDateFormat);
                      }
                    }
                    navigator.clipboard.writeText(formattedDates.join('\n'));
                    message.success('Fechas copiadas');
                  }}
                >
                  Copy all in DD/MM/YY format
                </Button>
                <Button
                  style={{
                    margin: 2,
                    backgroundColor: 'pink',
                    borderColor: 'pink',
                    color: 'black',
                  }}
                  onClick={() => {
                    const newData = [...tableData];
                    let formattedDates = [];
                    for (let i = 0; i < newData.length; i++) {
                      if (
                        newData[i].unformattedDate &&
                        newData[i].unformattedDate.trim() !== ''
                      ) {
                        // Convert unformattedDate to Excel format and store in formattedDates array
                        const desiredDateFormat = convertDateSlashOrDash(
                          newData[i].formattedDate,
                          'MM-DD-YY'
                        );
                        formattedDates.push(desiredDateFormat);
                      }
                    }
                    navigator.clipboard.writeText(formattedDates.join('\n'));
                    message.success('Fechas copiadas');
                  }}
                >
                  Copy all in MM-DD-YY format
                </Button>
                <Button
                  style={{
                    margin: 2,
                    backgroundColor: 'brown',
                    borderColor: 'brown',
                    color: 'white',
                  }}
                  onClick={() => {
                    const newData = [...tableData];
                    let formattedDates = [];
                    for (let i = 0; i < newData.length; i++) {
                      if (
                        newData[i].unformattedDate &&
                        newData[i].unformattedDate.trim() !== ''
                      ) {
                        // Convert unformattedDate to Excel format and store in formattedDates array
                        const desiredDateFormat = convertDateSlashOrDash(
                          newData[i].formattedDate,
                          'DD-MM-YY'
                        );
                        formattedDates.push(desiredDateFormat);
                      }
                    }
                    navigator.clipboard.writeText(formattedDates.join('\n'));
                    message.success('Fechas copiadas');
                  }}
                >
                  Copy all in DD-MM-YY format
                </Button>
              </td>
            </tr>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>
                  <input
                    type='text'
                    value={row.unformattedDate}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onBlur={() => handleInputChange(index, row.unformattedDate)}
                    onPaste={index === 0 ? handlePaste : null} // Attach only to the first input
                  />
                </td>
                <td>{row.formattedDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  );
}

////////////////////////////////////////////////////
// Function to convert a JavaScript Date object to YYYY-MM-DD format
function formatDateToISO(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function isExcelDate(num) {
  return num > 44000 && num < 46000;
}

function isDateStringFormat(str) {
  return (
    /^(\d{1,2})[/-](\d{1,2})[/-](\d{2}|\d{4})$/.test(str) ||
    /^(\d{2}|\d{4})[/-](\d{1,2})[/-](\d{1,2})$/.test(str)
  );
}

function breakDownDateStrInParts(str) {
  let parts, day, month, year;
  if (/^(\d{1,2})[/-](\d{1,2})[/-](\d{4})$/.test(str)) {
    parts = str.split(/[/-]/);
    [month, day, year] = parts;
  } else if (/^(\d{1,2})[/-](\d{1,2})[/-](\d{2})$/.test(str)) {
    parts = str.split(/[/-]/);
    [month, day, year] = parts;
    year = `20${year}`;
  } else if (/^(\d{4})[/-](\d{1,2})[/-](\d{1,2})$/.test(str)) {
    parts = str.split(/[/-]/);
    [year, month, day] = parts;
  } else if (/^(\d{2})[/-](\d{1,2})[/-](\d{1,2})$/.test(str)) {
    parts = str.split(/[/-]/);
    [year, month, day] = parts;
    year = `20${year}`;
  }
  return year ? [day, month, year] : null;
}

function convertToExcelDate(dateString) {
  // Parse the date string into a Date object
  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JavaScript's months are 0-indexed
  const day = parseInt(parts[2], 10);
  const date = new Date(year, month, day);

  // Initialize the Excel epoch as a JavaScript Date object
  const excelEpoch = new Date(1900, 0, 1);

  // Calculate the difference between the two dates
  const timeDiff = date - excelEpoch;

  // Calculate the number of days, rounding to the nearest whole number
  const days = Math.round(timeDiff / (1000 * 60 * 60 * 24));

  // Excel incorrectly treats 1900 as a leap year, so we need to adjust
  if (days >= 60) {
    return days + 2;
  } else {
    return days + 1;
  }
}
function convertDateSlashOrDash(dateString, format) {
  const parts = dateString.split('-');
  if (parts.length !== 3) {
    return null; // or you could return an error or throw an exception
  }

  const year = parts[0].slice(-2); // Take the last two digits of the year
  const month = parts[1];
  const day = parts[2];

  switch (format) {
    case 'MM/DD/YY':
      return `${month}/${day}/${year}`;
    case 'DD/MM/YY':
      return `${day}/${month}/${year}`;
    case 'MM-DD-YY':
      return `${month}-${day}-${year}`;
    case 'DD-MM-YY':
      return `${day}-${month}-${year}`;
    default:
      return null; // or you could return an error or throw an exception
  }
}

function getMostCommonMonth(columnData) {
  let monthCounts = {};

  for (let cell of columnData) {
    if (typeof cell === 'number' && isExcelDate(cell)) {
      const date = excelDateToJSDate(cell);
      const month = date.getMonth() + 1; // Months are 0-indexed
      monthCounts[month] = (monthCounts[month] || 0) + 1;
    } else if (typeof cell === 'string' && isDateStringFormat(cell)) {
      const [first, second, year] = breakDownDateStrInParts(cell).map(Number);
      if (first <= 12) {
        monthCounts[first] = (monthCounts[first] || 0) + 1;
      }
      if (second <= 12) {
        monthCounts[second] = (monthCounts[second] || 0) + 1;
      }
    }
  }
  let commonMonth = null;
  let maxCount = 0;
  for (let [month, count] of Object.entries(monthCounts)) {
    if (count > maxCount) {
      maxCount = count;
      commonMonth = parseInt(month, 10);
    }
  }
  return commonMonth;
}

// Function to format a list of unformatted date strings
export const formatDatesList = (unformattedDates) => {
  // First, calculate the common month using your existing function
  const commonMonth = getMostCommonMonth(unformattedDates);

  // Then, format the dates
  return unformattedDates.map((dateString) => {
    if (typeof dateString === 'number' && isExcelDate(dateString)) {
      const date = excelDateToJSDate(dateString);
      return formatDateToISO(date);
    } else if (
      typeof dateString === 'string' &&
      isDateStringFormat(dateString)
    ) {
      let [first, second, year] =
        breakDownDateStrInParts(dateString).map(Number);
      if (first <= 12 && second <= 12) {
        // Ambiguous date, choose the part that matches the most common month
        if (first !== commonMonth && second === commonMonth) {
          [first, second] = [second, first]; // Swap to interpret as dd/mm/yyyy
        }
      } else if (second <= 12) {
        // Only second part is a valid month, interpret as dd/mm/yyyy
        [first, second] = [second, first];
      }
      const date = new Date(year, first - 1, second);
      return formatDateToISO(date);
    }
    return dateString; // If not a recognized date, return as is
  });
};

// Example usage:
// const unformattedDates = ['1-5-2021', '12/11/2020','1/1/20','10-12-23','8/31/23','08/31/23', 44022, 45203];
// const formattedDates = formatDatesList(unformattedDates);
// console.log(formattedDates); // This should output the formatted dates

///////////////////////
const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminZohoCorrectDateReturner };
