import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Spin, Upload } from 'antd';
import { useContext, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';
import { read, utils } from 'xlsx';
import { downloadBlob } from '../../utils.js';
import axios from 'axios';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CsvUploader(props) {
  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      props.setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={uploadChanged}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

function AdminECBGclassifierWzohoAccounts(props) {
  const auth = useContext(authContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [unclassifiedCsv, setUnclassifiedCsv] = useState([]);
  const [classifiedCsv, setClassifiedCsv] = useState([]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'assignAccountBasedOnPreviousData',
      data: { data_existing: classifiedCsv, data_new: unclassifiedCsv },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setSubmitting(false);
        const csvContent = response.data.map((row) => row.join(',')).join('\n');
        const zohoCsvBlob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8;',
        });
        downloadBlob(zohoCsvBlob, 'classifiedSOT.csv');
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
        message.error('Se produjo un error al procesar el archivo CSV.');
      });
  };

  return (
    <>
      <Button type='secondary' onClick={showModal} style={{ fontWeight: 500 }}>
        Clasificar ECBG
      </Button>
      <Modal
        title='Clasificar ECBG con cuentas de Zoho'
        visible={visible}
        footer={
          <Button type='primary' htmlType='submit' block onClick={onFinish}>
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Descargar Clasificado</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <p>- acepta xls, xlsx, csv</p>
        <p>
          - asume que *clasificado* tiene una columna que se llama Descripción y
          otra que se llama Cuentas de ZOHO. tildes y mayúsculas NO importan
        </p>
        <p>
          - asume que *el NO clasificado* tiene una columna que se llama
          Descripción
        </p>
        <p>- clasifica puramente en base a Descripción</p>

        <Form {...layout} onFinish={onFinish}>
          <Form.Item label='Clasificado:'>
            <CsvUploader setCsvData={setClassifiedCsv} />
          </Form.Item>
          <Form.Item label='NO clasificado:'>
            <CsvUploader setCsvData={setUnclassifiedCsv} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminECBGclassifierWzohoAccounts };
