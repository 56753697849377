import React from 'react';

function FormattedUSD(props) {
  const total = props.total === '-0.00' ? 0 : props.total;
  const showCents = props.showCents !== false;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showCents ? 2 : 0,
    maximumFractionDigits: showCents ? 2 : 0,
  });

  return <span>{formatter.format(total)}</span>;
}

export { FormattedUSD };
