import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin, Input, message } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminCreateCompany(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      name: values.name,
      ruc: values.ruc,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminCreateCompany',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success('Compañia creada con exito');
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        message.error('Error al crear la compañia');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }
  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Create Company</ButtonSpan>
      </Button>
      <Modal
        title='Create Company'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Create Company</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='name'
            label='Name:'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Input placeholder='HERO' />
          </Form.Item>
          <Form.Item
            name='ruc'
            label='RUC:'
          >
            <Input placeholder='123-456-789' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminCreateCompany };
