import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Form, Button, Modal, Checkbox, DatePicker, Select, message } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { authContext } from '../../ProvideAuth.js';

const { RangePicker } = DatePicker;

function NewAdminInvuToZoho({ API_domain }) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();

  const showModal = () => setVisible(true);
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  function adminGetUniqueAccountingClientOptionsGivenSite() {
    axios({
      method: 'post',
      url: API_domain + 'adminGetUniqueAccountingClientOptionsGivenSite',
      data: { site: 'invu' },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setClientOptions(response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    if (visible) {
      adminGetUniqueAccountingClientOptionsGivenSite();
    }
  }, [visible]);

  const onFinish = (values) => {
    setSubmitting(true);
    const [startDate, endDate] = values.dateRange;
    const data = {
      accounting_client_id: values.client,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      itbms: values.itbms
    };

    axios({
      method: 'post',
      url: `${API_domain}adminInvuDataReporter`,
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      responseType: 'blob'
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invu_zoho_${startDate.format('YYYY-MM-DD')}_${endDate.format('YYYY-MM-DD')}_cliente_${values.client}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        message.success('File downloaded successfully');
      })
      .catch((error) => {
        console.error('Error:', error);
        message.error('An error occurred or you may not have the required permissions');
      })
      .finally(() => {
        setSubmitting(false);
        setVisible(false);
        form.resetFields();
      });
  };

  return (
    <>
      <Button type="secondary" onClick={showModal}>
        <ButtonSpan>New Invu to Zoho</ButtonSpan>
      </Button>
      <Modal
        title="New Invu to Zoho"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="client"
            label="Client"
            rules={[{ required: true, message: 'Please select a client' }]}
          >
            <Select
              showSearch
              options={clientOptions}
              placeholder="Select a client"
              loading={loading}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>

          <Form.Item
            name="dateRange"
            label="Date Range"
            rules={[{ required: true, message: 'Please select a date range' }]}
          >
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name="itbms" valuePropName="checked">
            <Checkbox>ITBMS</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={isSubmitting}
            >
              {isSubmitting ? 'Downloading...' : 'Download'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { NewAdminInvuToZoho };