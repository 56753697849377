import React from 'react';
import { Bar } from 'react-chartjs-2';

function VisualizationsBarChart(props) {
  const { chartObj } = props;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Presiona en una barra para explorar más!',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let index = context.dataIndex;
            let value = context.raw;
            let total = 0;
            var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            });
            context.chart.data.datasets.forEach((dataset) => {
              total += dataset.data[index];
            });
            let label = '';
            label +=
              context.dataset.label + ': ' + formatter.format(value) + '\n \n';
            label += ' Total: ' + formatter.format(total);
            return label;
          },
        },
      },
    },
    onClick: function (evt, element) {
      if (element.length > 0) {
        let datasetLabel = chartObj.datasets[element[0].datasetIndex]['label'];
        let monthLabel = chartObj.labels[element[0].index];
        props.showModal(datasetLabel, monthLabel);
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <Bar
      options={options}
      data={chartObj}
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export { VisualizationsBarChart };
