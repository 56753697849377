import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin, Input } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { ConfigAddExistingMemberSelect } from '../ConfigAddExistingMemberSelect.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminCreateCompanyCard(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      company_id: values.companyId,
      usersfacturas_id: selectedUser,
      team: values.team,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminCreateCompanyCard',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        alert('Card created successfully');
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }
  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Create Company Card</ButtonSpan>
      </Button>
      <Modal
        title='Create Company Card'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Create Company Card</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='companyId'
            label='Company id:'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Input placeholder='13' />
          </Form.Item>
          <Form.Item name='usersfacturas_id' label='New Cardholder:'>
            <ConfigAddExistingMemberSelect
              API_domain={props.API_domain}
              setSelectedMemberId={setSelectedUser}
              team={'all'}
            />
          </Form.Item>
          <Form.Item
            name='team'
            label='Team:'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Input placeholder='Hero Equipo de Prueba' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminCreateCompanyCard };
