import { Badge, Button } from 'antd';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';


function ReportsMyReportsButton(props) {
  const [count, setCount] = useState(0);
  const auth = useContext(authContext);

  useEffect(() => {
    const data = {
        team: props.team,
      };
    axios({
        method: 'post',
        url: props.API_domain + 'getInboxReportsIds',
        data: data,
        auth: {
          username: auth.email,
          password: auth.token,
        },
      })
        .then((response) => {
            setCount(response.data.length);
        })
        .catch(function (response) {
          console.log(response);
        });
  }, [props.team, props.submittedToggle]);

  return (
    <Badge count={count} size='default'>
        <Button disabled={count == 0} onClick={props.onClick}>Por Aprobar</Button>
    </Badge>
  );
}

export { ReportsMyReportsButton };
