import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Spin, Input } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminRunBGApifyActor(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      client_config_id: values.client,
      sot_id: values.sot_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminRunBGApifyActor',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        alert('Apify run started successfully');
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('Error running Apify actor');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function adminGetAccountingClientOptionsGivenSite() {
    axios({
      method: 'post',
      url: props.API_domain + 'adminGetAccountingClientOptionsGivenSite',
      data: { site: 'bg' },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setClientOptions(response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    if (visible) {
      adminGetAccountingClientOptionsGivenSite();
    }
  }, [visible]);

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Run BG Apify Actor</ButtonSpan>
      </Button>
      <Modal
        title='Run BG Apify Actor'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Run</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='client'
            label='Client'
            rules={[
              {
                required: true,
                message: 'Please select a client',
              },
            ]}
          >
            <Select
              showSearch
              options={clientOptions}
              placeholder='Client'
              filterOption={(input, option) =>
                option.props.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            name='sot_id'
            label='SOT ID'
            rules={[
              {
                required: true,
                message: 'Please enter the SOT ID',
              },
            ]}
          >
            <Input placeholder='SOT ID' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminRunBGApifyActor };
