import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext } from 'react';
import { authContext } from '../ProvideAuth.js';
import { downloadBlob, get_medicinas_generales_blob } from '../utils.js';

function ReportDownloaderMedicinasGenerales(props) {
  const auth = useContext(authContext);

  function download() {
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getReportExpenses?id=' +
        props.report_id +
        '&download=MedicinasGenerales',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const gastos_blob = get_medicinas_generales_blob(result.data);
        downloadBlob(gastos_blob, 'reporte#' + props.report_id + '.csv');
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
  }

  return (
    <p onClick={download}>
      <DownloadOutlined />
      Medicinas Generales
    </p>
  );
}

export { ReportDownloaderMedicinasGenerales };
