import React, { useContext, useState, useEffect } from 'react';
import { Modal, List, Spin, Button, Descriptions } from 'antd';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { HistoryOutlined } from '@ant-design/icons';

const PayrollHistory = (props) => {
  const auth = useContext(authContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  const eventNamesMap = {
    savePayrollDraft: 'Borrador de Planilla Guardado',
    createPayroll: 'Planilla Creada',
    sendPayrollDraftForApproval: 'Borrador de Planilla Enviado para Aprobación',
    markPayrollAsApproved: 'Planilla Aprobada',
    sendPayrollPDF: 'Planilla Enviada por Correo',
  };

  useEffect(() => {
    const getPayrollHistory = async () => {
      if (!props.accounting_clients_id) {
        return;
      }
      setLoading(true);
      try {
        const response = await axios({
          method: 'post',
          url: props.API_domain + 'getPayrollHistory',
          data: {
            accounting_clients_id: props.accounting_clients_id,
          },
          auth: {
            username: auth.email,
            password: auth.token,
          },
        });
        const sortedEvents = response.data.events.sort(
          (a, b) => new Date(b.inserted) - new Date(a.inserted)
        );
        setEvents(sortedEvents);
      } catch (error) {
        console.error('Error fetching payroll history:', error);
      } finally {
        setLoading(false);
      }
    };
    getPayrollHistory();
  }, [props.accounting_clients_id, isModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const convertToPanamaTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    dateTime.setHours(dateTime.getHours() - 5);
    return dateTime.toISOString().replace('T', ' ').substring(0, 19);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal} disabled={props.disabled}>
        <HistoryOutlined />
        Historial
      </Button>
      <Modal
        title='Payroll History'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {loading ? (
          <Spin tip='Loading...'>
            <div style={{ minHeight: '200px' }} />
          </Spin>
        ) : (
          <List
            itemLayout='vertical'
            dataSource={events}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <strong>{eventNamesMap[item.event] || item.event}</strong>
                  }
                  description={
                    <Descriptions bordered column={1} size='small'>
                      <Descriptions.Item label='Hecho por'>
                        {item.email}
                      </Descriptions.Item>
                      <Descriptions.Item label='Fecha de acción (Panamá)'>
                        {convertToPanamaTime(item.inserted)}
                      </Descriptions.Item>
                      {Object.keys(item.misc).map((key) => (
                        <Descriptions.Item key={key} label={key}>
                          {Array.isArray(item.misc[key])
                            ? item.misc[key].join(', ')
                            : item.misc[key].toString()}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </Modal>
    </>
  );
};

export { PayrollHistory };
