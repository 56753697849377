import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button, Col, Image, Modal, Row } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { NewExpenseFormMobile } from './NewExpenseFormMobile.js';
import { ImgOrPdf } from './ImgOrPdf.js';

function ExpenseModalMobile(props) {
  const auth = useContext(authContext);
  function deleteExpense(expenseId) {
    // track('deleteExpense', {
    //   API_domain: props.API_domain,
    //   email: auth.email,
    //   misc: { duplicate: props.expense.duplicate },
    // });
    axios({
      method: 'post',
      url:
        props.API_domain +
        'deleteExpense?id=' +
        expenseId +
        '&duplicate=' +
        props.expense.duplicate,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        // console.log('Calling get expenses after delete');
        props.handleOk();
        props.onSubmit();
      })
      .catch(function (response) {
        console.log('Error is ', response);
      });
  }
  let expenseIndex = props.expense.index ? ' #' + props.expense.index : '';
  return (
    <>
      <Modal
        title={
          <Row>
            <Col span={12}>{props.title + expenseIndex}</Col>
            <Col span={12} style={{ textAlign: 'right', paddingRight: 25 }}>
              <Button
                type='secondary'
                style={{ marginRight: 3 }}
                onClick={props.previousExpense}
              >
                <CaretLeftOutlined />
              </Button>
              <Button
                type='secondary'
                style={{ marginRight: 3 }}
                onClick={props.nextExpense}
              >
                <CaretRightOutlined />
              </Button>
            </Col>
          </Row>
        }
        visible={true}
        style={{ top: 20 }}
        footer={null}
        onCancel={props.handleCancel}
        width={850}
      >
        <ImgOrPdf src={props} />
        <NewExpenseFormMobile
          API_domain={props.API_domain}
          API_endPoint='updateExpense'
          onSubmit={props.onSubmit}
          handleOk={props.handleOk}
          expense={props.expense}
          deleteExpense={deleteExpense}
          team={props.team}
          nextExpense={props.nextExpense}
        />
      </Modal>
    </>
  );
}

export { ExpenseModalMobile };
