import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function CardStatementDownloaderPDF(props) {
  const auth = useContext(authContext);
  const [isLoading, setLoading] = useState(false);
  const [cardInfo, setCardInfo] = useState({});
  const [isCardInfoLoaded, setCardInfoLoaded] = useState(false); // New state variable

  const { API_domain } = props;

  const getPaycaddyCardInfos = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getPaycaddyCardInfos',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { card_id: props.selectedCard },
    })
      .then((response) => {
        setCardInfo(response.data);
        setCardInfoLoaded(true); // Set the flag to true when cardInfo is loaded
      })
      .catch((response) => {
        console.log('Error is ', response);
      });
  };

  useEffect(() => {
    if(props.selectedCard) {
      getPaycaddyCardInfos();
    }
  }, [props.selectedCard]);

  const download = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${API_domain}getDVs`,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        expenses: props.tableExpenses,
      },
    })
      .then((response) => {
        const doc = new jsPDF({ compress: true });

        const logoImg = new Image();
        logoImg.src = 'heroLogoTransparent.png'; // replace with the path to your optimized logo image
        logoImg.onload = function () {
          const imgWidth = 25; // Desired width in the PDF
          const aspectRatio = logoImg.width / logoImg.height;
          const imgHeight = imgWidth / aspectRatio;

          doc.addImage(logoImg, 'PNG', 10, 10, imgWidth, imgHeight);

          // Add cardholder info next to the logo
          doc.setFontSize(12);
          const cardholderInfo = [
            // 'Tarjetahabiente: ALVARO HEILBRON',
            `Número de tarjeta: **** **** **** ${cardInfo.pan.slice(-4)}`,
          ];
          const textStartY =
            10 + imgHeight / 2 - (cardholderInfo.length * 5) / 2; // Center the text vertically with the logo
          cardholderInfo.forEach((line, index) => {
            doc.text(line, 70, textStartY + index * 10); // Adjust the X and Y position as needed
          });

          const tableHeader = [
            'Fecha',
            'Descripción',
            'Débito',
            'Crédito',
            'Balance',
          ];
          const tableData = response.data.expenses.map((expense) => {
            let debito = '';
            let credito = '';
            const amount = Number(expense.total);

            if (amount < 0) {
              credito = Math.abs(amount).toFixed(2); // Remove negative sign for credito
            } else {
              debito = amount.toFixed(2);
            }

            return [
              expense.fecha,
              expense.descripcion + ' - ' + expense.provider,
              debito,
              credito,
              Number(expense.balance).toFixed(2),
            ];
          });

          doc.autoTable({
            head: [tableHeader],
            body: tableData,
            startY: 30 + imgHeight, // start after the logo
            headStyles: {
              fillColor: [116, 116, 199], // RGB color for header background
            },
          });

          doc.save('hero_estado_de_cuenta_tarjeta.pdf');
          setLoading(false);
        };
      })
      .catch((error) => {
        console.error('Error downloading the data:', error);
        setLoading(false);
      });
  };

  return (
    <div>
      <Button
        onClick={download}
        type='secondary'
        loading={isLoading}
        disabled={!isCardInfoLoaded}
      >
        <DownloadOutlined />
        Descargar PDF
      </Button>
    </div>
  );
}

export { CardStatementDownloaderPDF };
