import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components/macro';
import { LoadingOutlined } from '@ant-design/icons';
import { authContext } from '../../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const bankOptions = [ {'label': 'BAC INTERNATIONAL BANK', 'value': 'BAC INTERNATIONAL BANK'},
{'label': 'BANCO ALIADO', 'value': 'BANCO ALIADO'},
{'label': 'BANCO AZTECA', 'value': 'BANCO AZTECA'},
{'label': 'BANCO DEL PACIFICO PANAMA S.A.', 'value': 'BANCO DEL PACIFICO PANAMA S.A.'},
{'label': 'BANCO DELTA', 'value': 'BANCO DELTA'},
{'label': 'BANCO FICOHSA PANAMA', 'value': 'BANCO FICOHSA PANAMA'},
{'label': 'BANCO GENERAL', 'value': 'BANCO GENERAL'},
{'label': 'BANCO INTERNACIONAL DE COSTA RICA -BICSA', 'value': 'BANCO INTERNACIONAL DE COSTA RICA -BICSA'},
{'label': 'BANCO LA HIPOTECARIA', 'value': 'BANCO LA HIPOTECARIA'},
{'label': 'BANCO LAFISE PANAMA S.A.', 'value': 'BANCO LAFISE PANAMA S.A.'},
{'label': 'BANCO NACIONAL', 'value': 'BANCO NACIONAL'},
{'label': 'BANCO PICHINCHA PANAMA', 'value': 'BANCO PICHINCHA PANAMA'},
{'label': 'BANCOLOMBIA', 'value': 'BANCOLOMBIA'},
{'label': 'BANESCO', 'value': 'BANESCO'},
{'label': 'BANISI S.A.', 'value': 'BANISI S.A.'},
{'label': 'BANISTMO S.A.', 'value': 'BANISTMO S.A.'},
{'label': 'BANK OF CHINA', 'value': 'BANK OF CHINA'},
{'label': 'BBP BANK S.A.', 'value': 'BBP BANK S.A.'},
{'label': 'BCT BANK', 'value': 'BCT BANK'},
{'label': 'BI - BANK', 'value': 'BI - BANK'},
{'label': 'CACECHI', 'value': 'CACECHI'},
{'label': 'CACSA COOPERATIVA DE SERVICIOS MULTIPLES', 'value': 'CACSA COOPERATIVA DE SERVICIOS MULTIPLES'},
{'label': 'CAJA DE AHORROS', 'value': 'CAJA DE AHORROS'},
{'label': 'CANAL BANK', 'value': 'CANAL BANK'},
{'label': 'CITIBANK N.A.', 'value': 'CITIBANK N.A.'},
{'label': 'COEDUCO', 'value': 'COEDUCO'},
{'label': 'COOESAN', 'value': 'COOESAN'},
{'label': 'COOPEDUC', 'value': 'COOPEDUC'},
{'label': 'COOPERATIVA CRISTOBAL', 'value': 'COOPERATIVA CRISTOBAL'},
{'label': 'COOPERATIVA EDIOACC', 'value': 'COOPERATIVA EDIOACC'},
{'label': 'COOPERATIVA PROFESIONALES R.L.', 'value': 'COOPERATIVA PROFESIONALES R.L.'},
{'label': 'COOPEVE', 'value': 'COOPEVE'},
{'label': 'COOPRAC R.L.', 'value': 'COOPRAC R.L.'},
{'label': 'CREDICORP BANK', 'value': 'CREDICORP BANK'},
{'label': 'DAVIVIENDA', 'value': 'DAVIVIENDA'},
{'label': 'ECASESO', 'value': 'ECASESO'},
{'label': 'GLOBAL BANK', 'value': 'GLOBAL BANK'},
{'label': 'MERCANTIL BANCO', 'value': 'MERCANTIL BANCO'},
{'label': 'METROBANK', 'value': 'METROBANK'},
{'label': 'MMG BANK', 'value': 'MMG BANK'},
{'label': 'MULTIBANK', 'value': 'MULTIBANK'},
{'label': 'PRIVAL BANK', 'value': 'PRIVAL BANK'},
{'label': 'ST. GEORGES BANK', 'value': 'ST. GEORGES BANK'},
{'label': 'THE BANK OF NOVA SCOTIA', 'value': 'THE BANK OF NOVA SCOTIA'},
{'label': 'TOWERBANK INT.', 'value': 'TOWERBANK INT.'},
{'label': 'UNIBANK', 'value': 'UNIBANK'}
]


function AdminApifyPayBG(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    axios({
      method: 'post',
      url: props.API_domain + 'adminApifyPayBG',
      data: values,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
    .then((response) => {
      handleOk();
      setTimeout(() => setSubmitting(false), 2000);
    })
    .catch(function (response) {
      alert('No tienes este permiso habilitado');
      setSubmitting(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Pagar BG</ButtonSpan>
      </Button>
      <Modal
        title='Pagar BG'
        width={800}
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Pagar BG</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='bank'
            label='Bank'
            rules={[
              {
                required: true,
                message: 'Por favor, seleccione el banco',
              },
            ]}
          >
            <Select
              showSearch
              options={bankOptions}
              placeholder='Seleccione el banco'
              filterOption={(input, option) =>
                option.props.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            name='name'
            label='Name'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el nombre',
              },
            ]}
          >
            <Input placeholder='Nombre' />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el correo electrónico',
              },
              {
                type: 'email',
                message: 'Por favor, ingrese un correo electrónico válido',
              },
            ]}
          >
            <Input placeholder='Correo electrónico' />
          </Form.Item>
          <Form.Item
            name='account_number'
            label='Account Number'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el número de cuenta',
              },
            ]}
          >
            <Input placeholder='Número de cuenta' />
          </Form.Item>
          <Form.Item
            name='amount_string'
            label='Amount'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese el monto',
              },
            ]}
          >
            <Input placeholder='Monto' />
          </Form.Item>
          <Form.Item
            name='description'
            label='Description'
            rules={[
              {
                required: true,
                message: 'Por favor, ingrese la descripción',
              },
            ]}
          >
            <Input placeholder='Descripción' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminApifyPayBG };
