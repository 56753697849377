import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { FormattedUSD } from './FormattedUSD.js';

function CardsTableBalanceComponent(props) {
  const [response, setResponse] = useState(-1);
  const auth = useContext(authContext);
  const [isLoaded, setLoaded] = useState(false);

  const getPaycaddyCardBalance = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getPaycaddyCardBalance',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { card_id: props.card_id },
    })
      .then((response) => {
        setResponse(response.data.balance);
        setLoaded(true);
        if (props.onLoad) {
          props.onLoad(response.data.balance);
        }
      })
      .catch((response) => {
        console.log('error!');
        setLoaded(true);
        if (props.onLoad) {
          props.onLoad(0); // Assuming a failed fetch should result in a balance of 0
        }
      });
  };

  useEffect(() => {
    getPaycaddyCardBalance();
  }, []);

  if (isLoaded && response !== -1) {
    return <FormattedUSD total={response}></FormattedUSD>;
  } else {
    return <LoadingOutlined></LoadingOutlined>;
  }
}

export { CardsTableBalanceComponent };
