import React, { useContext, useState } from 'react';
import { Button, Modal, DatePicker, Spin, Divider, Row, Col, Form } from 'antd'; // import DatePicker from antd
import { TeamSelect } from './../TeamSelect.js';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { FormattedUSD } from './../FormattedUSD.js';
import {
  getPreviousFourMonths,
  get_moments_from_month_name,
} from '../../utils.js';

const AdminCalculateITBMS = (props) => {
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);
  const [dates, setDates] = useState([]);
  const [team, setTeam] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [backendData, setBackendData] = useState({
    itbms_from_transactions: null,
    implied_expense_total_based_on_itbms: null,
  });

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      start_date: dates[0].toISOString().split('T')[0], // extracting the YYYY-MM-DD format
      end_date: dates[1].toISOString().split('T')[0], // extracting the YYYY-MM-DD format
      team: team,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'calculateITBMS',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setBackendData(response.data);
        // setTimeout(() => setSubmitting(false), 2000);
        setSubmitting(false);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button style={{ fontWeight: 500 }} onClick={() => setVisible(true)}>
        Calcular ITBMS
      </Button>
      <Modal
        title='Calcular ITBMS'
        visible={visible}
        onCancel={handleCancel}
        width={600}
        style={{ textAlign: 'center' }}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={onFinish}
            disabled={!dates || dates.length !== 2 || team === ''}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Calcular</>}
          </Button>
        }
      >
        <div style={{ padding: 8, marginBottom: 20 }}>
          <span style={{ marginLeft: 10, marginRight: 3 }}>
            Filtros rápidos:
          </span>
          {getPreviousFourMonths().map((month) => (
            <Button
              key={month}
              type={month === selectedMonth ? 'primary' : 'default'}
              onClick={() => {
                const [startDateMoment, endDateMoment] =
                  get_moments_from_month_name(month);
                const startDate = new Date(startDateMoment.toISOString());
                const endDate = new Date(endDateMoment.toISOString());

                setDates([startDate, endDate]);
                setSelectedMonth(month);
              }}
              style={{ margin: 3 }}
            >
              {month}
            </Button>
          ))}
          <Button
            type='secondary'
            onClick={() => {
              setDates([]);
              setSelectedMonth(null);
            }}
            style={{ marginLeft: 25 }}
          >
            Reset
          </Button>
        </div>
        {/* <div>
          <DatePicker.RangePicker
            onChange={(dates) => setDates(dates)}
            style={{ marginBottom: '20px' }}
          />
        </div> */}
        <TeamSelect
          API_domain={props.API_domain}
          onTeamSelect={setTeam}
          team={team}
        />
        <Divider />
        <div style={{ marginTop: '20px', textAlign: 'left' }}>
          <h3>Resultados:</h3>
          <Row>
            {backendData.itbms_from_transactions !== null && (
              <>
                <Col span={18}>
                  <strong>ITBMS de transacciones:</strong>
                </Col>
                <Col span={6}>
                  <FormattedUSD total={backendData.itbms_from_transactions} />
                </Col>
              </>
            )}
          </Row>
          <Row>
            {backendData.implied_expense_total_based_on_itbms !== null && (
              <>
                <Col span={18}>
                  <strong>Gasto implícito (para Form. 430):</strong>
                </Col>
                <Col span={6}>
                  <FormattedUSD
                    total={backendData.implied_expense_total_based_on_itbms}
                  />
                </Col>
              </>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminCalculateITBMS };
