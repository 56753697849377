import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext } from 'react';
import { authContext } from '../ProvideAuth.js';
import { useAnalytics } from 'use-analytics';
import { downloadBlob, get_peachtree_blob } from '../utils.js';

function ReportDownloaderPeachtree(props) {
  const auth = useContext(authContext);
  const { track } = useAnalytics();

  function download() {
    // track('downloadPeachtree', {
    //   API_domain: props.API_domain,
    //   email: auth.email,
    //   misc: { report_id: props.report_id },
    // });
    axios({
      method: 'get',
      url: props.API_domain + 'getReportExpenses?id=' + props.report_id + "&download=Peachtree",
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        // calculate length of entries
        const gastos_blob = get_peachtree_blob(result.data);
        downloadBlob(gastos_blob, result.data[0]['title'] + 'Peachtree.csv');
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
  }

  return (
    <p onClick={download}>
      <DownloadOutlined />
      Peachtree
    </p>
  );
}

export { ReportDownloaderPeachtree };
