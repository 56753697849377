import { Tag, Button, message } from 'antd';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { ConfigRenameCategoryModal } from './ConfigRenameCategoryModal.js';

function ConfigSingleCategory(props) {
  const auth = useContext(authContext);
  const [selectedCategory, setSelectedCategory] = useState(null);

  function deleteCategory() {
    axios({
      method: 'post',
      url: props.API_domain + 'deleteCategory?id=' + props.category.value,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success('Categoría eliminada exitosamente');
        props.setCategoriesChanged(true);
      })
      .catch(function (response) {
        console.log('Error is ', response);
      });
  }
  return (
    <Tag style={{ margin: 5 }}>
      <b>{props.category.label}</b>{' '}
      <Button
        type='text'
        icon={<CloseCircleOutlined />}
        onClick={deleteCategory}
      ></Button>
      <Button
        type='text'
        icon={<EditOutlined />}
        onClick={(event) => {
          setSelectedCategory(props.category);
        }}
      ></Button>
      {selectedCategory && (
        <ConfigRenameCategoryModal
          visible={true}
          title={'Renombrar Categoría'}
          handleCancel={() => setSelectedCategory(null)}
          handleOk={() => {
            setSelectedCategory(null);
            props.setCategoriesChanged(true);
          }}
          category={selectedCategory}
          API_domain={props.API_domain}
          API_endPoint='renameCategory'
        />
      )}
    </Tag>
  );
}

export { ConfigSingleCategory };
