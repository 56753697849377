import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Affix,
  Badge,
  Button,
  Col,
  Input,
  Layout,
  Row,
  Table,
  Upload,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from '../TeamSelect.js';
import { Refresh } from '../Refresh.js';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { read, utils } from 'xlsx';
import { authContext } from '../../ProvideAuth.js';
import { excelDateToJSDate, filterCreator } from '../../utils.js';

const { Content } = Layout;

// type ReconciliationItem = {
//   id: number,
//   date: Date,
//   amount: number
// }

function transformJsonDataIntoParsedList(
  jsonData,
  dateColumnNumber,
  amountColumnNumber,
  descriptionColumnNumber
) {
  let parsedData = [];
  // we want a list of objects where each object has three keys: id (just a serial from 0 to n), date, and amount
  let currentCount = 0;
  for (let i = 0; i < jsonData.length; i++) {
    const row = jsonData[i];
    const date = row[dateColumnNumber];
    const amount = row[amountColumnNumber];
    const description = row[descriptionColumnNumber];
    // check if amount is a number
    if (!isNaN(amount) && amount != '' && date != '') {
      const currentDate = excelDateToJSDate(date);
      parsedData.push({
        id: currentCount,
        date:
          currentDate.toString() == 'Invalid Date' ? new Date() : currentDate,
        amount: Number(amount),
        description: description,
      });
      currentCount++;
    }
  }
  return parsedData;
}

function ReconciliationView(props) {
  const [isLoaded, setLoaded] = useState(false);
  const [submittedToggle, setSubmittedToggle] = useState(false);

  // return (
  //   <Upload beforeUpload={beforeUpload} onChange={(info) => handleFileUpload(info.file)}>
  //     <Button>Select File</Button>
  //   </Upload>
  // );

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              {/* <Refresh onClick={onSubmit} spin={!isLoaded} /> */}
              <TeamSelect
                API_domain={props.API_domain}
                onTeamSelect={props.onTeamSelect}
                team={props.team}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}></Col>
          </Row>
        </AffixDiv>
      </Affix>
      <ReconciliationMainComponent API_domain={props.API_domain} />
    </Content>
  );
}

function ReconciliationMainComponent(props) {
  const [extractedJsonData, setExtractedJsonData] = useState([]);
  const [secondExtractedJsonData, setSecondExtractedJsonData] = useState([]);
  const [columnNumber, setColumnNumber] = useState();
  const [dateColumnNumber, setDateColumnNumber] = useState();
  const [descriptionColumnNumber, setDescriptionColumnNumber] = useState();
  const [secondColumnNumber, setSecondColumnNumber] = useState();
  const [secondDateColumnNumber, setSecondDateColumnNumber] = useState();
  const [secondDescriptionColumnNumber, setSecondDescriptionColumnNumber] =
    useState();

  const [combinedList, setCombinedList] = useState([]);
  const [list1Only, setList1Only] = useState([]);
  const [list2Only, setList2Only] = useState([]);
  const [list1Reconciled, setList1Reconciled] = useState([]);
  const [list2Reconciled, setList2Reconciled] = useState([]);
  const auth = useContext(authContext);

  const [selectedRows1, setSelectedRows1] = useState([]);
  const [selectedRows2, setSelectedRows2] = useState([]);

  const joinSelectedRows = () => {
    const newReconciledRows1 = [...list1Reconciled, ...selectedRows1];
    const newOnlyList1 = list1Only.filter(
      (row) => !selectedRows1.map((item) => item.id).includes(row.id)
    );
    console.log(newReconciledRows1);
    setList1Reconciled(newReconciledRows1);
    setList1Only(newOnlyList1);

    const newReconciledRows2 = [...list2Reconciled, ...selectedRows2];
    const newOnlyList2 = list2Only.filter(
      (row) => !selectedRows2.map((item) => item.id).includes(row.id)
    );

    setList2Reconciled(newReconciledRows2);
    setList2Only(newOnlyList2);
    setSelectedRows1([]);
    setSelectedRows2([]);
  };
  function askBackendMostLikelyDateColumn(jsonData) {
    // ask the backend which column is most likely to be the date column
    // return the column number
    axios({
      method: 'post',
      url: props.API_domain + 'reconciliationFindMostLikelyDateColumn',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        list1: jsonData,
      },
    }).then(
      (result) => {
        console.log(result);
        // setLoading(false);
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
    return 0;
  }
  // write api call to server that calls the reconciliation endpoint with the two lists of data
  const onSubmit = () => {
    console.log('submitting');
    axios({
      method: 'post',
      url: props.API_domain + 'reconcileTwoLists',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        list1: transformJsonDataIntoParsedList(
          extractedJsonData,
          dateColumnNumber,
          columnNumber,
          descriptionColumnNumber
        ),
        list2: transformJsonDataIntoParsedList(
          secondExtractedJsonData,
          secondDateColumnNumber,
          secondColumnNumber,
          secondDescriptionColumnNumber
        ),
      },
    }).then(
      (result) => {
        const {
          results,
          list1_only,
          list2_only,
          list1_reconciled,
          list2_reconciled,
        } = result.data;
        setList1Only(list1_only);
        setList2Only(list2_only);
        setList1Reconciled(list1_reconciled);
        setList2Reconciled(list2_reconciled);
        setCombinedList(results);
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
  };

  return (
    <BeautifulDiv>
      <Row>
        <Col span={10} style={{ textAlign: 'center' }}>
          <ReconciliationListPicker
            setExtractedJsonData={setExtractedJsonData}
          />
          <br></br>
          <Input
            type='text'
            placeholder='Amount Column'
            onChange={(e) => setColumnNumber(e.currentTarget.value)}
          />
          <br></br>
          <br></br>
          <Input
            type='text'
            placeholder='Date Column'
            onChange={(e) => setDateColumnNumber(e.currentTarget.value)}
          />
          <br></br>
          <br></br>
          <Input
            type='text'
            placeholder='Description Column'
            onChange={(e) => setDescriptionColumnNumber(e.currentTarget.value)}
          />
          {columnNumber && extractedJsonData.length > 0 ? (
            <ReconciliationTable
              data={transformJsonDataIntoParsedList(
                extractedJsonData,
                dateColumnNumber,
                columnNumber,
                descriptionColumnNumber
              )}
              reconciledIds={list1Reconciled.map((item) => item.id)}
              selectedRows={selectedRows1}
              setSelectedRows={setSelectedRows1}
              onlyList={list1Only}
            />
          ) : (
            <></>
          )}
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}>
          <Button onClick={onSubmit}>Conciliar</Button>
          <Button onClick={joinSelectedRows}>Juntar</Button>
        </Col>
        <Col span={10} style={{ textAlign: 'center' }}>
          <ReconciliationListPicker
            setExtractedJsonData={setSecondExtractedJsonData}
          />
          <br></br>
          <Input
            type='text'
            placeholder='Amount Column'
            onChange={(e) => setSecondColumnNumber(e.currentTarget.value)}
          />
          <br></br>
          <br></br>
          <Input
            type='text'
            placeholder='Date Column'
            onChange={(e) => setSecondDateColumnNumber(e.currentTarget.value)}
          />
          <br></br>
          <br></br>
          <Input
            type='text'
            placeholder='Description Column'
            onChange={(e) =>
              setSecondDescriptionColumnNumber(e.currentTarget.value)
            }
          />
          {secondColumnNumber && secondExtractedJsonData.length > 0 ? (
            <ReconciliationTable
              data={transformJsonDataIntoParsedList(
                secondExtractedJsonData,
                secondDateColumnNumber,
                secondColumnNumber,
                secondDescriptionColumnNumber
              )}
              reconciledIds={list2Reconciled.map((item) => item.id)}
              selectedRows={selectedRows2}
              setSelectedRows={setSelectedRows2}
              onlyList={list2Only}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={10} style={{ textAlign: 'center' }}>
          {list1Only.length > 0 ? (
            <>
              Solo en lista 1 <br></br>
              {
                <ReconciliationTable
                  data={list1Only}
                  reconciledIds={list1Reconciled.map((item) => item.id)}
                  selectedRows={selectedRows1}
                  setSelectedRows={setSelectedRows1}
                  onlyList={list1Only}
                />
              }
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}></Col>
        <Col span={10} style={{ textAlign: 'center' }}>
          {list2Only.length > 0 ? (
            <>
              Solo en lista 2 <br></br>
              {
                <ReconciliationTable
                  data={list2Only}
                  reconciledIds={list2Reconciled.map((item) => item.id)}
                  selectedRows={selectedRows2}
                  setSelectedRows={setSelectedRows2}
                  onlyList={list2Only}
                />
              }
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </BeautifulDiv>
  );
}

function ReconciliationTable(props) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const total = props.selectedRows.reduce((sum, row) => sum + row.amount, 0);
    setTotal(total);
  }, [props.selectedRows]);

  const dataSource = props.data.map((row, index) => {
    let date;
    if (typeof row.date === 'string') {
      date = new Date(row.date);
    } else {
      // row.date is a Date object
      date = row.date;
    }
    const dateFormatted = new Intl.DateTimeFormat('es-US', {
      dateStyle: 'medium',
    }).format(date);
    return {
      key: index,
      status: props.reconciledIds.includes(row.id) ? 'RECONCILED' : '',
      id: row.id,
      date: dateFormatted,
      amount: row.amount,
      description: row.description,
    };
  });

  const rowSelection = {
    selectedRowKeys: props.selectedRows.map((item) => item.id),
    getCheckboxProps: (record) => ({
      disabled: props.reconciledIds.includes(record.id),
    }),
    onChange: (selectedRowKeys, selectedRows) => {
      props.setSelectedRows(selectedRows);
    },
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: filterCreator(dataSource, 'status'),
      onFilter: (value, record) => {
        if (record.status) {
          return record.status.indexOf(value) === 0;
        } else {
          if (value == null) {
            return true;
          }
          return false;
        }
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Desc',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  return (
    <>
      <div style={{ position: 'sticky', top: '50px', zIndex: 1000 }}>
        {props.selectedRows.length > 0 && (
          <div>
            <b>Total: {total}</b>
          </div>
        )}
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (props.reconciledIds.includes(record.id)) return;

              // check if row is already selected
              const alreadySelected = props.selectedRows.find(
                (row) => row.id === record.id
              );

              let newSelection;
              if (alreadySelected) {
                // row is already selected, so remove it from the selection
                newSelection = props.selectedRows.filter(
                  (row) => row.id !== record.id
                );
              } else {
                // row is not selected, so add it to the selection
                newSelection = [...props.selectedRows, record];
              }

              props.setSelectedRows(newSelection);
            },
          };
        }}
      />
    </>
  );
}

function ReconciliationListPicker(props) {
  const [files, setFiles] = useState([]);

  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      // Process the workbook data as needed
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      props.setExtractedJsonData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  const fileRemoved = (event) => {
    const filteredFiles = files.filter((file) => file !== event);
    setFiles(filteredFiles);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={(e) => uploadChanged(e)}
      onRemove={(e) => fileRemoved(e)}
      // capture={undefined}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;
const BeautifulDiv = styled.div`
  width: min(calc(100vw - 300px), 1200px);
  margin: auto;
`;

export { ReconciliationView };
