import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

function ConfigRenameCategoryForm(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [helpMessage, setHelpMessage] = useState(null);
  const [validateStatus, setValidateStatus] = useState(null);


  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      old_category_id : props.category.value,
      new_category_name: values.newCategoryName,
    };

    axios({
      method: 'post',
      url: props.API_domain + props.API_endPoint,
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        setValidateStatus('error');
        const errorMessage =
          response.message == 'Network Error'
            ? 'Hay un problema con tu conexión'
            : 'Esta categoría ya existe';
        setHelpMessage(errorMessage);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      {...layout}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={{ newCategoryName: props.category.label }}
    >
      <Form.Item
        name='newCategoryName'
        label='Nuevo Nombre:'
        rules={[
          {
            required: true,
            message: 'Escriba el nuevo nombre de la categoría',
          },
        ]}
      >
        <Input placeholder='Gasolina' />
      </Form.Item>

      <Button
        type='primary'
        htmlType='submit'
        block
        style={{
          fontWeight: 500,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {isSubmitting ? <Spin indicator={antIcon} /> : <>Renombrar Categoría</>}
      </Button>
    </Form>
  );
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigRenameCategoryForm };
