import { Button, message, Modal, Tooltip, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import axios from 'axios';
import confetti from 'canvas-confetti';
import { FormattedUSD } from './FormattedUSD.js';

const { Text } = Typography;

function ShareHeroButton(props) {
  const auth = useContext(authContext);
  const [hover, setHover] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [referralLink, setReferralLink] = useState('');

  function getUserName() {
    axios({
      method: 'get',
      url: props.API_domain + 'getUserName' + '?email=' + auth.email,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const name = result.data.name; // Adjust this based on your response object
        setReferralLink(`http://www.herofacturas.com/referral?user=${name}`);
      },
      (error) => {
        console.error("There was an error fetching the user's name:", error);
      }
    );
  }
  useEffect(() => {
    isModalVisible && getUserName();
  }, [isModalVisible]);

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const triggerConfetti = () => {
    confetti({
      particleCount: 250,
      spread: 95,
      origin: { x: 0.5, y: 1 },
    });
  };
  const handleCopy = () => {
    message.success('Link copiado');
    triggerConfetti();
  };

  // Inline styles for the heart animation
  const beatingHeartStyle = {
    display: 'inline-block',
    animation: 'beat 2.5s infinite',
    transformOrigin: 'center',
  };

  const keyframes = `
    @keyframes beat {
      0%, 100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
    }
  `;

  return (
    <>
      {/* Inject the keyframes directly into the component */}
      <style>{keyframes}</style>
      {props.mobile ? (
        <>❤️</>
      ) : (
        <>
          <Tooltip placement='right' title='Programa de Referencia'>
            <Button
              className={hover ? 'expanded-button' : 'normal-button'}
              onClick={showModal}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              type='secondary'
              style={{ width: '100%' }}
            >
              <b>
                <span style={beatingHeartStyle}>❤️ ${props.amount}</span>
              </b>
            </Button>
          </Tooltip>
          <Modal
            title='Programa de Referencia ❤️'
            visible={isModalVisible}
            onCancel={closeModal}
            footer={[
              <Button
                key='copy'
                type='primary'
                onClick={() => {
                  navigator.clipboard.writeText(referralLink);
                  handleCopy();
                }}
              >
                Copiar Link
              </Button>,
              <Button key='close' onClick={closeModal}>
                Cerrar
              </Button>,
            ]}
          >
            <h3>Así de fácil:</h3>
            <p>
              Mándale tu link a los negocios que quieras referir para que Hero
              les resuelva su <b>contabilidad</b>.
            </p>
            <p>
              Si se suscriben a Hero, te daremos un crédito de{' '}
              <b>
                <FormattedUSD total={props.amount} />
              </b>{' '}
              en tu suscripción.
            </p>
            {/* <span style={{ color: '#7474cd' }}>{referralLink}</span> */}
            <Text
              style={{ color: '#7474cd' }}
              copyable={{ onCopy: handleCopy }}
            >
              {referralLink}
            </Text>
          </Modal>
        </>
      )}
    </>
  );
}

export { ShareHeroButton };
