import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message, Radio, Space, Spin } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { ConfigTeamSettings } from './ConfigTeamSettings.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function ConfigNewTeamModal(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [newTeamName, setNewTeamName] = useState('');

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setCurrentStep(0);
  };

  const onFinish = (values) => {
    setNewTeamName(values.new_team_name);
    const data = {
      new_team_name: values.new_team_name,
    };

    setSubmitting(true);
    axios({
      method: 'post',
      url: props.API_domain + 'createNewTeam',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        // handleOk();
        // props.onSubmit();
        message.success('Equipo creado exitosamente');
        nextStep();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        message.error('Ese equipo ya existe');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Button type='primary' onClick={showModal}>
        <ButtonSpan>Crear Equipo</ButtonSpan>
      </Button>
      <Modal
        title={currentStep == 0 && 'Crear Equipo'}
        visible={visible}
        width={currentStep == 0 ? 600 : '75vw'}
        footer={
          <>
            {currentStep == 0 && (
              // <Button type='primary' block htmlType='submit' onClick={nextStep}>
              <Button
                type='primary'
                block
                htmlType='submit'
                onClick={form.submit}
              >
                {isSubmitting ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <>Crear Equipo</>
                )}
              </Button>
            )}
            {currentStep == 1 && (
              <>
                <Button
                  type='primary'
                  block
                  onClick={() => {
                    handleOk();
                    props.onSubmit();
                  }}
                >
                  Guardar configuración
                </Button>
              </>
            )}
          </>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {currentStep === 0 && (
            <Form.Item
              name='new_team_name'
              label='Nombre del Equipo:'
              rules={[
                {
                  required: true,
                  message: 'Por favor escribe el nombre del nuevo equipo',
                },
              ]}
            >
              <Input placeholder='Compañía ABC' />
            </Form.Item>
          )}
          {currentStep === 1 && (
            <div style={{ textAlign: 'center' }}>
              <ConfigTeamSettings
                API_domain={props.API_domain}
                teams={[{ team: newTeamName, owner: '' }]}
                isOwner={true}
                fullCard={true}
              />
            </div>
          )}
          {/* {currentStep === 2 && (
            <Form.Item name='existing_tseam_name' label='step 3'>
              goodbye world
            </Form.Item>
          )} */}
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigNewTeamModal };
