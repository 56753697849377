import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Table, Tag, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { ImgOrPdf } from './../ImgOrPdf.js';
import { useLocation } from 'react-router-dom';
import { filterCreator } from '../../utils.js';

function FacturasTable(props) {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const auth = useContext(authContext);
  const location = useLocation();
  const [selectedInvoice, setSelectedInvoice] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const openModalInvoice = queryParams.get('openModalInvoice');

  useEffect(() => {
    getAllInvoices();
  }, [props.submittedToggle, props.team, props.filterPendingUserInvoices]);

  if (items.length > 0) {
    items.forEach(function (item) {
      // item.order_ids = [1, 3, 4, 2];
      if (item.created_at) {
        item.createdAtFormatted = new Intl.DateTimeFormat('es-US', {
          dateStyle: 'medium',
        }).format(
          new Date(
            item.created_at.split('/')[0],
            item.created_at.split('/')[1] - 1,
            item.created_at.split('/')[2]
          )
        );
        item.createdAtFormattedShort = new Intl.DateTimeFormat('es-US', {
          day: 'numeric',
          month: 'short',
        }).format(
          new Date(
            item.created_at.split('/')[0],
            item.created_at.split('/')[1] - 1,
            item.created_at.split('/')[2]
          )
        );
      }
    });
  }

  const getAllInvoices = () => {
    props.setLoaded(false);
    axios({
      method: 'post',
      url: props.API_domain + 'getAllInvoices',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        assignee_filter: props.assigneeFilter,
        team: props.team,
      },
    })
      .then((response) => {
        props.setLoaded(true);
        setItems(response.data);

        const queryParams = new URLSearchParams(location.search);
        const openModalInvoice = queryParams.get('openModalInvoice');
        if (openModalInvoice) {
          props.openModal(response.data.find((x) => x.id == openModalInvoice));
        }
      })
      .catch((response) => {
        props.setLoaded(true);
        setError(response);
      });
  };

  const columns = [
    {
      title: 'Número',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Creada',
      dataIndex: 'created_at_with_hour',
      key: 'created_at_with_hour',
      width: 160,
    },
    {
      title: 'Asignado',
      dataIndex: 'assignee_name',
      key: 'assignee_name',
      width: 160,
      render: (text, record) => {
        return (
          // <Tag
          //   color={requisicionStateMapper.find((x) => x.value === text).color}
          // >
          //   {requisicionStateMapper.find((x) => x.value === text).title}
          // </Tag>
          text ? <Tag color='blue'>{text}</Tag> : <></>
        );
      },
      filters: filterCreator(items, 'assignee_name'),
      onFilter: (value, record) => {
        return record.assignee_name
          ? record.assignee_name.indexOf(value) === 0
          : false;
      },
    },
    {
      title: 'Orden',
      dataIndex: 'purchase_order_id',
      key: 'purchase_order_id',
      width: 160,
      render: (purchase_order_id, record) => {
        return <a 
                href={'/ordenes?openModalOrder=' + purchase_order_id}
                style={{ textDecoration: 'underline' }}>{purchase_order_id}
                </a>;
      },
    },
    {
      title: 'Equipo',
      dataIndex: 'team',
      key: 'team',
      render: (team, record) => (
        <>
          <b>{record.creator_name}</b>
          <br></br>
          <span style={{ color: 'grey', size: '10px' }}>{team}</span>
        </>
      ),
    },
    {
      title: 'Provedor',
      dataIndex: 'legit_provider',
      key: 'legit_provider',
      render: (text, record) => (
        <>
          {record.estado == 'completed' ? (
            <span style={{ fontWeight: 600 }}>{text}</span>
          ) : (
            // <span style={{ color: 'grey' }}>hi</span>
            <span style={{ color: 'grey' }}>{text}</span>
          )}
        </>
      ),
    },
  ];

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!props.isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <Table
        dataSource={items}
        columns={columns}
        bordered={false}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
        }}
        // pagination={{ defaultPageSize: 50 }}
        pagination={false}
        sticky
        size='middle'
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (event.target.tagName !== 'A') {
                props.openModal(record);
              }
            },
          };
        }}
        rowClassName={(record, index) => {
          return 'cursor-pointer';
        }}
      />
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { FacturasTable };
