import { Button, Form, Input, Modal, Spin, Select, DatePicker,Radio } from "antd";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { authContext } from "../../ProvideAuth.js";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminCreateAccountingObligation(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [obligationEnumsOptions, setObligationEnumsOptions] = useState([]);

  const getAccountingClientOptions = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingClients',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.name}
        </Option>
      ));
      setClientOptions(options);
    } catch (error) {
      console.error(
        'There was an error fetching the accounting clients',
        error
      );
    }
  };

  const getAccountingObligationEnumsOptions = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingObligationEnums',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((obligationEnum) => (
        <Option key={obligationEnum.id} value={obligationEnum.id}>
          {obligationEnum.name}
        </Option>
      ));
      setObligationEnumsOptions(options);
    } catch (error) {
      console.error(
        'There was an error fetching the accounting obligations',
        error
      );
    }
  };

  useEffect(() => {
    if (visible){
      getAccountingClientOptions();
      getAccountingObligationEnumsOptions();
    }
  }, [visible]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      client_id: values.client_id,
      obligation_enum_id: values.obligation_enum_id,
      amount: values.amount,
      due_date: values.due_date ? values.due_date.format('YYYY-MM-DD') : undefined,
      paid: values.paid,
    };
    axios({
      method: "post",
      url: props.API_domain + "createAccountingObligation", // Update to your new route
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert("An Error occurred hehee");
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Button type="secondary" onClick={showModal}>
        <ButtonSpan>Crear Obligación</ButtonSpan>
      </Button>
      <Modal
        title="Crear Obligación"
        visible={visible}
        onOk={form.submit} // Changed to onOk to submit form on modal confirmation
        onCancel={handleCancel}
        centered
        confirmLoading={isSubmitting}
      >
        <Form
          {...layout}
          form={form}
          name="adminCreateAccountingObligationEnum"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name='client_id'
            label='Client ID'
            rules={[{ required: true, message: 'Please select the client' }]}
          >
            <Select
              showSearch
              placeholder='Select a client'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name='obligation_enum_id'
            label='Tipo de obligación'
            rules={[{ required: true, message: 'Please select the obligation enum' }]}
          >
            <Select
              showSearch
              placeholder='Selecciona un tipo de obligación'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {obligationEnumsOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name='amount'
            label='Cantidad'
            rules={[{ required: true, message: 'Please input the amount' }]}
          >
            <Input placeholder="123.45"/>
          </Form.Item>
          <Form.Item
            name='due_date'
            label='Fecha'
            rules={[{ required: true, message: 'Please select the due date' }]}
          >
            <DatePicker
              format='YYYY-MM-DD'
              placeholder='Select date'
              style={{ width: '100%' }} // Ensures the date picker takes the full width of the form item
            />
          </Form.Item>
          <Form.Item
            name='paid'
            label='Hecho/Pagado:'
            rules={[{ required: true, message: 'Please select if it is paid' }]}
          >
            <Radio.Group>
              <Radio value={true}>Sí</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export { AdminCreateAccountingObligation };
