import { Tag, Button, message } from 'antd';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { ConfigRenamePaymentMethodModal } from './ConfigRenamePaymentMethodModal.js';

function ConfigSinglePaymentMethod(props) {
  const auth = useContext(authContext);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  function deletePaymentMethod() {
    axios({
      method: 'post',
      url:
        props.API_domain + 'deletePaymentMethod?id=' + props.paymentMethod.id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success('Forma de pago eliminada exitosamente');
        props.setPaymentMethodChanged(true);
      })
      .catch(function (response) {
        console.log('Error is ', response);
      });
  }

  return (
    <Tag style={{ margin: 5 }}>
      <b>{props.paymentMethod.payment_method}</b>
      <Button
        type='text'
        icon={<CloseCircleOutlined />}
        onClick={deletePaymentMethod}
      />
      <Button
        type='text'
        icon={<EditOutlined />}
        onClick={() => setSelectedPaymentMethod(props.paymentMethod)}
      />
      {selectedPaymentMethod && (
        <ConfigRenamePaymentMethodModal
          visible={true}
          title={'Renombrar Forma de Pago'}
          handleCancel={() => setSelectedPaymentMethod(null)}
          handleOk={() => {
            setSelectedPaymentMethod(null);
            props.setPaymentMethodChanged(true);
          }}
          payment_method={selectedPaymentMethod}
          API_domain={props.API_domain}
        />
      )}
    </Tag>
  );
}

export { ConfigSinglePaymentMethod };
