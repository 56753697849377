import React, { useState, useEffect } from 'react';
import { Table, Button, message, Spin, Input, Typography } from 'antd';
import HeroAxios from '../helpers/HeroAxios.js';
import styled from 'styled-components/macro';

const { Text } = Typography;

function ConfigAccountingClientsInfoTable() {
  const [clientsInfo, setClientsInfo] = useState([]);
  const [initialClientsInfo, setInitialClientsInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch Accounting Clients Info
        const clientsInfoResponse = await HeroAxios.get('getAccountingClientsInfo');
        // Fetch Accounting Clients for names
        const accountingClientsResponse = await HeroAxios.get('getAccountingClients');

        // Map client info with client names
        const accountingClientsMap = accountingClientsResponse.data.reduce((acc, client) => {
          acc[client.id] = client.name;
          return acc;
        }, {});

        const clientsInfoData = clientsInfoResponse.data.map(info => ({
          ...info,
          key: info.id,
          clientName: accountingClientsMap[info.accounting_client_id],
        }));

        setClientsInfo(clientsInfoData);
        // Deep copy to preserve initial state
        setInitialClientsInfo(JSON.parse(JSON.stringify(clientsInfoData)));
      } catch (error) {
        message.error('Error fetching accounting clients info data');
        console.error('Error fetching accounting clients info data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (value, recordKey, fieldName) => {
    setClientsInfo(prevData =>
      prevData.map(item => {
        if (item.key === recordKey) {
          return {
            ...item,
            [fieldName]: value,
          };
        }
        return item;
      })
    );
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      // Find changes
      const changedInfos = clientsInfo.filter(info => {
        const initialInfo = initialClientsInfo.find(
          initInfo => initInfo.key === info.key
        );
        return JSON.stringify(info) !== JSON.stringify(initialInfo);
      });

      if (changedInfos.length > 0) {
        await HeroAxios.post('saveAccountingClientsInfoBulk', { payload: changedInfos });
        message.success('Accounting clients info updated successfully');
        setInitialClientsInfo(JSON.parse(JSON.stringify(clientsInfo)));
      } else {
        message.info('No changes to save');
      }
    } catch (error) {
      message.error('Error saving accounting clients info data');
      console.error('Error saving accounting clients info data:', error);
    } finally {
      setSaving(false);
    }
  };

  // Prepare table columns
  const columns = [
    {
      title: 'ID',
      dataIndex: 'accounting_client_id',
      key: 'accounting_client_id',
      fixed: 'left',
      width: 60,
      sorter: (a, b) => a.accounting_client_id - b.accounting_client_id,
      render: (text) => (
        <Text style={{ fontSize: '11px', paddingLeft: '8px' }}>{text}</Text>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      fixed: 'left',
      width: 150,
      render: (text) => (
        <Text style={{ fontSize: '11px', paddingLeft: '8px' }}>{text}</Text>
      ),
    },
    // Add columns for all fields in the accounting clients info data
    {
      title: 'Info Field 1',
      dataIndex: 'info_field1',
      key: 'info_field1',
      width: 150,
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.key, 'info_field1')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
    // Repeat for other fields as necessary
    // Example:
    {
      title: 'Info Field 2',
      dataIndex: 'info_field2',
      key: 'info_field2',
      width: 150,
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.key, 'info_field2')}
          style={{ fontSize: '11px' }}
        />
      ),
    },
    // Add more fields based on your accounting clients info data structure
  ];

  // Filter data based on search term
  const filteredData = clientsInfo.filter(info => {
    const term = searchTerm.toLowerCase();
    return (
      info.accounting_client_id.toString().includes(term) ||
      (info.clientName && info.clientName.toLowerCase().includes(term))
    );
  });

  if (loading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  return (
    <>
      <SearchContainer>
        <Input.Search
          placeholder="Search by ID or Client Name"
          onSearch={(value) => setSearchTerm(value)}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
      </SearchContainer>
      <StyledTableContainer>
        <div style={{ overflowX: 'auto' }}>
          <StyledText>
            Edita la información de los clientes contables
          </StyledText>
          <StyledTable
            columns={columns}
            dataSource={filteredData}
            rowKey="key"
            pagination={{
              ...pagination,
              total: filteredData.length,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} registros`,
              onChange: (page, pageSize) =>
                setPagination({ current: page, pageSize }),
            }}
            bordered
            showHeader
            scroll={{ x: 'max-content', y: 400 }}
            sticky
          />
          <br />
          <StyledText>
            Al presionar "Guardar Cambios" se guardará esta configuración
          </StyledText>
        </div>
        <SaveButtonContainer>
          <Button type="primary" onClick={handleSave} loading={saving}>
            Guardar Cambios
          </Button>
        </SaveButtonContainer>
      </StyledTableContainer>
    </>
  );
}

// Styled components
const SearchContainer = styled.div`
  text-align: left;
  margin-bottom: 16px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 100px;
`;

const StyledTableContainer = styled.div`
  margin: 20px 0;
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 4px;
    font-size: 11px;
  }
`;

const StyledText = styled(Text)`
  display: block;
  text-align: left;
  margin-bottom: 12px;
  font-size: 12px;
`;

const SaveButtonContainer = styled.div`
  text-align: right;
  margin-top: 10px;
`;

export { ConfigAccountingClientsInfoTable };
