import { Empty, Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function ConfigAddExistingMemberSelect(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState();
  const [selectedMemberIdLocal, setSelectedMemberIdLocal] = useState(
    props.defaultSelectedMemberId
  );

  function fetchCompanyUsers() {
    const data = {
      team: props.team,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'getCompanyUsersExcludingInTeam',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        let selectOptions = response.data.map((user) => ({
          label: user.name,
          value: user.id,
        }));
        setOptions(selectOptions);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchCompanyUsers();
  }, []);

  return (
    <>
      <Select
        value={selectedMemberIdLocal}
        showSearch
        filterOption={(input, option) =>
          option.props.label
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => {
          props.setSelectedMemberId(value);
          setSelectedMemberIdLocal(value);
        }}
        options={options}
        placeholder='Selecciona Usuario'
        style={{
          width: '100%',
        }}
        notFoundContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <div>
                  Todos los usuarios de la empresa ya están en este equipo
                </div>
              </>
            }
          />
        }
      />
    </>
  );
}

export { ConfigAddExistingMemberSelect };
