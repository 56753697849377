import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  message,
  Row,
  Upload,
  Tag,
  Divider,
  Popconfirm,
  Tooltip,
} from 'antd';
import { ImgOrPdf } from './../ImgOrPdf.js';
import moment from 'moment';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import locale from 'antd/es/date-picker/locale/es_ES';
import {
  DownloadOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { PayrollAdvanceCreationModal } from './PayrollAdvanceCreationModal.js';

// Export PDF function
const exportPDF_for_employee = async (
  payrollData,
  file_name = payrollData.payroll[0].name + '_historial' + '.pdf'
) => {
  const doc = new jsPDF('landscape', 'pt', 'A4');
  doc.setFontSize(18);

  // Extract the payable date and collaborator name from the first payroll entry
  const collaboratorName = payrollData.payroll[0].name;

  const title = `Historial de planillas de ${collaboratorName}`;
  doc.text(title, 40, 70);

  const gov_id = `${payrollData.payroll[0].gov_id}`;
  doc.text(gov_id, 40, 100);

  // Define the table headers based on the provided columns
  const tableHeaders = [
    'Fecha de planilla', // payable_date
    'Salario Quincenal', // biweekly_salary
    'Prima de Prod.', // benefit_production
    'Prima de Comb.', // benefit_fuel
    'Gastos de Rep.', // benefit_representation_expenses
    'Tiempo Extra', // overtime
    'Total de Ingresos', // payroll_gross
    'Prima de Prod. sujeta a SS', // benefit_production_subject_to_social_security
    'Prima de Comb. sujeta a SS', // benefit_fuel_subject_to_social_security
    'Menos Ausencias', // deduction_attendance
    'Ingresos sujetos a SS', // payroll_subject_to_ss
    'Seguro Social 9.75%', // deduction_ss
    'Ingresos sujetos a SE', // payroll_subject_to_se
    'Seguro Educativo 1.25%', // deduction_se
    'Descuento Directo', // deduction_direct_discount
    'ISR', // deduction_isr
    'Adelantos',
    'Descuento de Adelantos', // advance_deductions
    'Total de Deducciones', // deduction_total
    'Salario Neto a Recibir', // payroll_net
  ];

  // Define the table rows based on payrollData, excluding the collaborator name and employee ID
  const tableRows = payrollData.payroll.map((entry) => [
    entry.payable_date,
    parseFloat(entry.biweekly_salary).toFixed(2),
    parseFloat(entry.benefit_production).toFixed(2),
    parseFloat(entry.benefit_fuel).toFixed(2),
    parseFloat(entry.benefit_representation_expenses).toFixed(2),
    parseFloat(entry.overtime).toFixed(2),
    parseFloat(entry.payroll_gross).toFixed(2),
    parseFloat(entry.benefit_production_subject_to_social_security).toFixed(2),
    parseFloat(entry.benefit_fuel_subject_to_social_security).toFixed(2),
    parseFloat(entry.deduction_attendance).toFixed(2),
    parseFloat(entry.payroll_subject_to_ss).toFixed(2),
    parseFloat(entry.deduction_ss).toFixed(2),
    parseFloat(entry.payroll_subject_to_se).toFixed(2),
    parseFloat(entry.deduction_se).toFixed(2),
    parseFloat(entry.deduction_direct_discount).toFixed(2),
    parseFloat(entry.deduction_isr).toFixed(2),
    parseFloat(entry.advance_payments).toFixed(2),
    parseFloat(entry.advance_deductions).toFixed(2),
    parseFloat(entry.deduction_total).toFixed(2),
    parseFloat(entry.payroll_net).toFixed(2),
  ]);

  // Calculate the totals for each column
  const totals = tableHeaders.map((header, index) => {
    if (index > 0) {
      // Skip the first column (date)
      return tableRows
        .reduce((acc, row) => acc + parseFloat(row[index]), 0)
        .toFixed(2);
    }
    return '';
  });

  // Add totals row
  tableRows.push(['Total', ...totals.slice(1).map((total) => `${total}`)]);

  // Convert dollar values to smaller font
  const tableRowsWithSmaller = tableRows.map((row) =>
    row.map((cell, index) => {
      if (index > 0 && typeof cell === 'string' && cell.startsWith('$')) {
        return { content: cell, styles: { fontSize: 7 } };
      }
      return cell;
    })
  );

  // Prepare styles
  const tableStyles = {
    head: [tableHeaders],
    theme: 'plain',
    body: tableRowsWithSmaller,
    startY: 130,
    styles: {
      fontSize: 10,
      cellPadding: { top: 5, right: 3, bottom: 5, left: 3 },
      overflow: 'linebreak',
      valign: 'middle',
      halign: 'center',
      fillColor: [255, 255, 255],
    },
    headStyles: {
      fillColor: [116, 116, 199],
      textColor: [255, 255, 255],
      fontSize: 8,
      halign: 'center',
    },
    bodyStyles: {
      halign: 'center',
      cellPadding: { top: 12, bottom: 12 },
    },
    columnStyles: {
      [tableHeaders.indexOf('Total de Ingresos')]: {
        textColor: [0, 128, 0],
        fontStyle: 'bold',
      },
      [tableHeaders.indexOf('Total de Deducciones')]: {
        textColor: [255, 0, 0],
        fontStyle: 'bold',
      },
      [tableHeaders.indexOf('Salario Neto a Recibir')]: {
        textColor: [65, 130, 239],
        fontStyle: 'bold',
      },
    },
    margin: { top: 150, left: 20, right: 20 }, // Shrink left and right margins
  };

  // Render the table
  doc.autoTable(tableStyles);

  // Save the PDF to a Blob
  const pdfBlob = doc.output('blob');

  // Save the PDF with the given file name
  doc.save(file_name);
};

function ExistingEmployeeForm(props) {
  const auth = useContext(authContext);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(moment());
  const [payrollAdvanceModalVisible, setPayrollAdvanceModalVisible] =
    useState(false);

  useEffect(() => {
    const startDate = props.selectedEmployee.start_date
      ? moment(props.selectedEmployee.start_date, 'YYYY/MM/DD')
      : null;

    form.setFieldsValue({
      name: props.selectedEmployee.name,
      idNumber: props.selectedEmployee.gov_id,
      startDate: startDate.isValid() ? startDate : '',
      employeeType: props.selectedEmployee.type_of_employee,
    });
  }, [props.selectedEmployee, form]);

  // Function to handle form submission
  const onFinish = (values) => {
    setSubmitting(true);
    handleFormSubmission(values);
  };

  // Function to get employee payment history
  const getEmployeePaymentHistory = async (employeeId) => {
    if (!employeeId) return;

    try {
      const response = await axios.post(
        `${props.API_domain}getPayroll`,
        {
          accountingClientsId: props.selectedAccountingClientId,
          employeeId,
        },
        { auth: { username: auth.email, password: auth.token } }
      );

      if (response.data && Array.isArray(response.data.payroll)) {
        // add index to each record
        response.data.payroll.forEach((record, index) => {
          record.index = index + 1;
        });
        // setPayrollData(response.data.payroll); // Uncomment this to set the payroll data
        exportPDF_for_employee(response.data);
      }
    } catch (error) {
      console.error('Error fetching payroll data:', error);
      message.error(
        'Error al obtener historial de planillas. Posiblemente no tiene planillas.'
      );
    }
  };

  // Function to handle form submission to the backend
  const handleFormSubmission = (values) => {
    const formData = new FormData();
    formData.append('employeeId', props.selectedEmployee.employee_id);

    Object.keys(values).forEach((key) => {
      if (key === 'document' && values[key]) {
        values[key].forEach((file) => {
          formData.append('govIdFile', file.originFileObj);
        });
      } else if (key === 'startDate') {
        formData.append(key, values[key].format('YYYY-MM-DD'));
      } else {
        formData.append(key, values[key]);
      }
    });

    axios({
      method: 'post',
      url: props.API_domain + 'editEmployee',
      data: formData,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        message.success('Empleado actualizado exitosamente');
        setSubmitting(false);
        props.setEmployeeModalVisible(false);
      })
      .catch((error) => {
        console.error('Error updating employee:', error);
        if (error.response && error.response.status === 403) {
          message.error(error.response.data);
        }
        setSubmitting(false);
        props.onError && props.onError(error);
      });
  };

  return (
    <>
      <div>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Form.Item name='gov_id_imageurl' label='Identificación'>
            {props.selectedEmployee.gov_id_imageurl ? (
              <ImgOrPdf
                src={{ imageSource: props.selectedEmployee.gov_id_imageurl }}
              />
            ) : (
              <div style={{ marginLeft: 20 }}>
                <UserOutlined style={{ fontSize: 45 }} />
              </div>
            )}
          </Form.Item>

          <Row gutter={16}>
            <Col>
              <Form.Item
                name='document'
                valuePropName='fileList'
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
              >
                <Upload
                  beforeUpload={() => false} // Prevent automatic upload
                >
                  <Button icon={<UploadOutlined />}>
                    {props.selectedEmployee.gov_id_imageurl ? (
                      <>Cambiar identificación</>
                    ) : (
                      <>Subir identificación</>
                    )}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            {props.selectedEmployee.gov_id_imageurl && (
              <Col>
                <Button
                  type='secondary'
                  style={{ marginBottom: '18px' }}
                  onClick={async (event) => {
                    event.stopPropagation();

                    const response = await fetch(
                      `${
                        props.API_domain
                      }/downloadImage?url=${encodeURIComponent(
                        props.selectedEmployee.gov_id_imageurl
                      )}`
                    );
                    const blob = await response.blob();

                    let fileExtension = 'jpg';
                    if (blob.type === 'application/pdf') {
                      fileExtension = 'pdf';
                    } else if (blob.type === 'image/png') {
                      fileExtension = 'png';
                    }

                    const objectUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = objectUrl;
                    a.download = `id_${props.selectedEmployee.name}.${fileExtension}`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(objectUrl);
                  }}
                >
                  <DownloadOutlined />
                  Descargar
                </Button>
              </Col>
            )}
          </Row>

          <Form.Item name='name' label='Nombre' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name='idNumber'
            label='Número de Identidad'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese su número de identidad',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name='startDate' label='Fecha de Inicio'>
            <DatePicker locale={locale} />
          </Form.Item>

          <Form.Item
            name='employeeType'
            label='Tipo de Empleado'
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un tipo de empleado',
              },
            ]}
          >
            <Select placeholder='Seleccione uno'>
              <Option value='css'>
                <Tag color={props.employeeTypeColors.css}>CSS</Tag>
              </Option>
              <Option value='mixto'>
                <Tag color={props.employeeTypeColors.mixto}>Mixto</Tag>
              </Option>
              <Option value='honorarios_profesionales'>
                <Tag color={props.employeeTypeColors.honorarios}>
                  Honorarios Profesionales
                </Tag>
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type='secondary'
              htmlType='submit'
              loading={submitting}
              style={{ width: '160px' }}
            >
              Actualizar
            </Button>

            {/* Popconfirm with correct endDate usage */}
            <Popconfirm
              title={
                <>
                  <div style={{ marginBottom: 16 }}>
                    Seleccione la fecha de finalización
                  </div>
                  <div style={{ marginBottom: 16 }}>
                    <DatePicker
                      locale={locale}
                      onChange={(date) => {
                        // Log and set endDate properly
                        console.log(date);
                        setEndDate(date);
                      }}
                    />
                  </div>
                </>
              }
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => {
                // Use endDate from state, ensure it's properly formatted
                if (!endDate) {
                  message.error(
                    'Por favor seleccione la fecha de finalización.'
                  );
                  return;
                }
                setLoading(true);
                axios({
                  method: 'post',
                  url: props.API_domain + 'deactivateEmployee',
                  data: {
                    employeeId: props.selectedEmployee.employee_id,
                    endDate: endDate.format('YYYY-MM-DD'), // Format endDate
                  },
                  auth: {
                    username: auth.email,
                    password: auth.token,
                  },
                })
                  .then((response) => {
                    message.success('Empleado desactivado exitosamente');
                    setLoading(false);
                    props.setEmployeeModalVisible(false);
                  })
                  .catch((error) => {
                    message.error(
                      'Error al desactivar empleado. Posiblemente porque el empleado tiene planillas asociadas.'
                    );
                    console.error('Error deactivating employee:', error);
                    if (error.response && error.response.status === 403) {
                      message.error(error.response.data);
                    }
                    setLoading(false);
                    props.onError && props.onError(error);
                  });
              }}
              okText='Confirmar'
              cancelText='Cancelar'
            >
              <Tooltip
                title='En el siguiente paso escogerás la fecha de salida del empleado'
                placement='right'
              >
                <Button
                  danger
                  style={{ margin: '0 4px', width: '120px' }}
                  loading={loading}
                >
                  Desactivar
                </Button>
              </Tooltip>
            </Popconfirm>
            <Button
              type='link'
              onClick={() =>
                getEmployeePaymentHistory(props.selectedEmployee.employee_id)
              }
              style={{ marginTop: 10 }}
            >
              Descargar historial de planillas de{' '}
              {props.selectedEmployee.name.split(' ')[0]}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Divider />
      <Button
        type='primary'
        onClick={() => setPayrollAdvanceModalVisible(true)}
        style={{ marginTop: 10 }}
        disabled={false}
      >
        Crear adelanto de planilla
      </Button>
      <PayrollAdvanceCreationModal
        API_domain={props.API_domain}
        visible={payrollAdvanceModalVisible}
        onCancel={() => setPayrollAdvanceModalVisible(false)}
        employee={props.selectedEmployee}
        accountingClientsId={props.selectedAccountingClientId}
      />
    </>
  );
}

export { ExistingEmployeeForm };
