import React from 'react';
import { Result, Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

const MeetingBookedView = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Result
        icon={<CalendarOutlined />}
        title='Reunión Agendada Exitosamente'
        // extra={[
        //   <Button type='primary' key='console'>
        //     Go to Dashboard
        //   </Button>,
        //   <Button key='buy'>View Calendar</Button>,
        // ]}
        extra={[
          <>
            <p>
              Hemos enviado un correo electrónico de confirmación a tu
              dirección. ¡Estamos emocionados de hablar contigo!
            </p>
            <p>Si estás apurado y quieres hablar ahora,</p>
            <Button
              type='primary'
              size='large'
              onClick={() => {
                const phoneNumbers = ['50766181519', '50766759599'];
                const selectedPhone =
                  phoneNumbers[Math.floor(Math.random() * phoneNumbers.length)];
                window.open(
                  `https://api.whatsapp.com/send?phone=${selectedPhone}&text=Agendé%20pero%20hablemos%20antes%20de%20Hero.`,
                  '_blank'
                );
              }}
            >
              Conversemos por WhatsApp
            </Button>
          </>,
        ]}
      />
    </div>
  );
};

export { MeetingBookedView };
