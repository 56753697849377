// BotExpenseModal.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Spin,
  Tooltip,
  message,
  Row,
  Col,
  Popconfirm,
  Space,
} from 'antd';
import {
  DeleteOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { authContext } from '../ProvideAuth.js';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import AccountingAccountSelect from './Accounting/AccountingAccountSelect';
import { FormattedUSD } from './FormattedUSD.js';
import { ImageLoader } from './ImageLoader';
import { ImgOrPdf } from './ImgOrPdf';
import HeroAxios from '../helpers/HeroAxios.js';

function parseFloatOrNull(number) {
  const parsedFloat = parseFloat(number);
  if (!isNaN(parsedFloat)) {
    return parsedFloat.toFixed(2);
  } else {
    return null;
  }
}

function parseFloatOrCero(number) {
  const parsedFloat = parseFloat(number);
  if (!isNaN(parsedFloat)) {
    return parsedFloat.toFixed(2);
  } else {
    return '0.00';
  }
}

const BotExpenseModal = (props) => {
  const {
    accounts,
    botexpense,
    clientId,
    visible,
    onCancel,
    onSubmit,
    API_domain,
    API_endPoint,
  } = props;

  const auth = useContext(authContext);
  const [expense, setExpense] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [amountWithoutItbms, setAmountWithoutItbms] = useState(0);
  const [itbms, setItbms] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [initialAmount, setInitialAmount] = useState(0);
  const [inputAmount, setInputAmount] = useState(0);
  const inputRef = useRef(null);
  const [commandPressed, setCommandPressed] = useState(false);
  const [showProviderFinder, setShowProviderFinder] = useState(false);

  // Initialize expense data from props.botexpense
  useEffect(() => {
    if (botexpense) {
      setExpense(botexpense);
      setImageUrl(botexpense.imageurl || '');
      setShowProviderFinder(auth.adminEmail && botexpense.team !== 'Hero DEMO');
      initializeForm(botexpense);
    }
  }, [botexpense, auth.email]);

  const initializeForm = (expenseData) => {
    const expense_date = moment(expenseData.receipt_date, 'YYYY-MM-DD');
    let initialValues = {};

    if (expenseData.processing) {
      const parsedItbms = parseFloatOrNull(expenseData.parsed_itbms);
      const total = parseFloatOrNull(expenseData.amount_total);
      const parsedAmount = parseFloatOrNull(expenseData.parsed_amount);

      setItbms(parsedItbms);
      setAmountWithoutItbms(
        total !== '0.00'
          ? parseFloatOrNull(
              parseFloat(total) - parseFloatOrCero(expenseData.parsed_itbms)
            )
          : parseFloatOrNull(
              parseFloat(parsedAmount) -
                parseFloatOrCero(expenseData.parsed_itbms)
            )
      );

      initialValues = {
        expenseName: expenseData.description || '',
        date: expense_date.isValid() ? expense_date : null,
        itbms: parsedItbms,
        amount:
          total !== '0.00'
            ? parseFloatOrNull(
                parseFloat(total) - parseFloatOrCero(expenseData.parsed_itbms)
              )
            : parseFloatOrNull(
                parseFloat(parsedAmount) -
                  parseFloatOrCero(expenseData.parsed_itbms)
              ),
        ruc: expenseData.ruc || '',
        provider: expenseData.provider || '',
        receipt_number: expenseData.receipt_number || '',
        category: expenseData.category_id || null,
      };
    } else {
      const itbmsValue = parseFloatOrNull(expenseData.itbms);
      const total = parseFloatOrNull(expenseData.amount_total);

      setItbms(itbmsValue);
      setAmountWithoutItbms(
        parseFloatOrNull(
          parseFloat(total) - parseFloatOrCero(expenseData.itbms)
        )
      );

      initialValues = {
        expenseName: expenseData.description || '',
        date: expense_date.isValid() ? expense_date : null,
        itbms: itbmsValue,
        amount: parseFloatOrNull(
          parseFloat(total) - parseFloatOrCero(expenseData.itbms)
        ),
        ruc: expenseData.ruc || '',
        provider: expenseData.provider || '',
        receipt_number: expenseData.receipt_number || '',
        category: expenseData.category_id || null,
      };
    }

    form.setFieldsValue(initialValues);
    setInitialAmount(initialValues.amount);
    setInputAmount(initialValues.amount);
  };

  const updateAmount = (newAmount) => {
    setInputAmount(newAmount); // Update the local state for InputNumber
    setAmountWithoutItbms(newAmount); // Update the state used in the total calculation
    form.setFieldsValue({ amount: newAmount }); // Sync form's amount field
  };

  const handleFocus = (event) => event.target.select();

  const handleItbmsFocus = () => {
    const calculatedItbms = parseFloatOrNull(amountWithoutItbms * 0.07);
    setItbms(calculatedItbms);
    form.setFieldsValue({
      itbms: calculatedItbms,
    });
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    }, 1);
  };

  const handleProveedorFocus = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[.,!_?'?-]/g, '');
    inputValue = inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    inputValue = inputValue.toUpperCase();

    form.setFieldsValue({
      provider: inputValue,
    });

    setTimeout(() => {
      event.target.select();
    }, 0);
  };

  const handleBlur = (event) => {
    const expression = event.target.value;
    try {
      // Validate and sanitize the expression to prevent malicious code execution
      // This regex allows only numbers, arithmetic operators, and spaces
      if (/^[0-9+\-*/.()\s]*$/.test(expression)) {
        const result = new Function(`return ${expression}`)();
        const formattedResult = parseFloat(result).toFixed(2); // Format to two decimal places
        updateAmount(formattedResult); // Update state and form
      } else {
        throw new Error('Invalid input');
      }
    } catch (error) {
      console.error('Error evaluating the expression: ', error);
      message.error('Entrada inválida en el monto.');
    }
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      botexpense_id: expense.id,
      description: values.expenseName,
      provider: values.provider,
      receipt_date: values.date.format('YYYY-MM-DD'),
      receipt_number: values.receipt_number,
      amount_total: parseFloat(values.amount) + parseFloat(values.itbms),
      itbms: parseFloat(values.itbms),
      imageUrl: imageUrl,
      expenseName: values.expenseName,
      //   category: values.category,
      id: expense.id,
      ruc: values.ruc,
    };
    HeroAxios({
      method: 'post',
      url: `${API_domain}${'updateBotExpense'}`,
      data: data,
    })
      .then((response) => {
        message.success('Gasto actualizado');
        if (commandPressed) {
          props.nextExpense();
          setSubmitting(false);
        } else {
          onSubmit();
          setSubmitting(false);
        }
      })
      .catch((error) => {
        console.error('Error al actualizar el gasto:', error);
        message.error('Error al actualizar el gasto');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const findRUC = () => {
    const provider = form.getFieldValue('provider');
    if (!provider) {
      message.warning('Por favor ingrese el proveedor primero.');
      return;
    }

    HeroAxios({
      method: 'get',
      url: `getRucFromProvider?provider=${encodeURIComponent(provider)}`,
    })
      .then((response) => {
        if (response.data.ruc) {
          message.success('RUC encontrado');
          form.setFieldsValue({
            ruc: response.data.ruc,
          });
        } else {
          message.warning('RUC no encontrado para el proveedor ingresado.');
        }
      })
      .catch((error) => {
        console.error('Error al buscar el RUC:', error);
        message.error('Error al buscar el RUC');
      });
  };

  const disabledBoolean = expense && expense.report_id ? true : false;

  const reportCardTransaction = () => {
    // Implement this function if needed
    message.info('Función de reportar problema no implementada.');
  };

  const handleKey = (event) => {
    setCommandPressed(event.metaKey || event.altKey);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKey);
    window.addEventListener('keyup', handleKey);
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'Enter') {
        form.submit();
      }
      if (event.altKey && event.key === 'ArrowRight') {
        props.nextExpense();
      }
      if (event.altKey && event.key === 'ArrowLeft') {
        props.previousExpense();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKey);
      window.removeEventListener('keyup', handleKey);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [botexpense, form]);

  if (!expense) {
    return (
      <Modal visible={visible} onCancel={onCancel} footer={null}>
        <Spin />
      </Modal>
    );
  }

  // Function to handle account change
  const handleAccountChange = (record, value) => {
    // Call the backend to update the record
    HeroAxios({
      method: 'post',
      url: 'updateBotExpenseAccount',
      data: {
        expense_id: record.id, // record id
        account_id: value, // new account_id
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Cuenta actualizada correctamente');
          //   update the record in the table
          record.account_id = value;
          setExpense({ ...record });
        } else {
          message.error('Error al actualizar la cuenta');
        }
      })
      .catch((error) => {
        console.error('Error updating account:', error);
        message.error('Error al actualizar la cuenta');
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1000} // Increased width to accommodate two columns
      title='Editar Gasto'
    >
      <Form
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        layout='vertical'
      >
        <Row gutter={16}>
          {/* Left Column: ImgOrPdf and ImageLoader */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label='Factura:'>
              <ImageLoader
                onImageSubmit={setImageUrl}
                urlToUpload={`${API_domain}uploadImages`}
                disabledBoolean={disabledBoolean}
                existingImageUrl={imageUrl}
              />
            </Form.Item>
            {imageUrl && (
              <>
                <ImgOrPdf src={{ imageSource: imageUrl }} expense={expense} />
                <Space style={{ marginTop: 10 }}>
                  <Popconfirm
                    title='¿Estás seguro de que quieres borrar la imagen adjunta?'
                    onConfirm={() => {
                      setImageUrl('');
                      form.submit();
                    }}
                    okText='Borrar'
                    cancelText='Cancelar'
                    placement='topRight'
                  >
                    <Tooltip placement='bottom' title='Borrar imagen'>
                      <Button
                        type='default'
                        icon={<DeleteOutlined />}
                        disabled={disabledBoolean}
                      />
                    </Tooltip>
                  </Popconfirm>
                  <Tooltip placement='bottom' title='Descargar archivo'>
                    <Button
                      type='default'
                      icon={<DownloadOutlined />}
                      style={{ marginLeft: '6px' }}
                      onClick={async (e) => {
                        e.stopPropagation();
                        try {
                          const response = await fetch(
                            `${API_domain}downloadImage?url=${encodeURIComponent(
                              imageUrl
                            )}`
                          );
                          if (!response.ok) {
                            throw new Error('Network response was not ok');
                          }
                          const blob = await response.blob();
                          let fileExtension = 'jpg'; // default
                          if (blob.type === 'application/pdf') {
                            fileExtension = 'pdf';
                          } else if (blob.type === 'image/png') {
                            fileExtension = 'png';
                          }
                          const objectUrl = URL.createObjectURL(blob);
                          const a = document.createElement('a');
                          a.href = objectUrl;
                          a.download = `factura${expense.id}.${fileExtension}`;
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                          URL.revokeObjectURL(objectUrl);
                        } catch (error) {
                          console.error('Error al descargar la imagen:', error);
                          message.error('Error al descargar la imagen');
                        }
                      }}
                      disabled={disabledBoolean}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          </Col>

          {/* Right Column: Other Form Items */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Descripción'
              name='expenseName'
              rules={[
                {
                  required: true,
                  message: 'Por favor escriba el nombre del gasto',
                },
              ]}
            >
              {expense.report_id ? (
                <Tooltip placement='topRight' title={expense.description || ''}>
                  <Input
                    placeholder='Gasolina'
                    disabled={disabledBoolean}
                    style={{ color: 'black' }}
                  />
                </Tooltip>
              ) : (
                <Input
                  placeholder='Gasolina'
                  disabled={disabledBoolean}
                  style={{ color: 'black' }}
                />
              )}
            </Form.Item>

            <Form.Item
              name='date'
              label='Fecha:'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione una fecha',
                },
              ]}
            >
              <DatePicker
                locale={locale}
                style={{ width: '100%' }}
                disabled={disabledBoolean}
                format='YYYY-MM-DD'
              />
            </Form.Item>

            <Form.Item label='Proveedor' name='provider'>
              <Input
                placeholder='Farmacias Arrocha'
                disabled={disabledBoolean}
                style={{ color: 'black' }}
                onFocus={
                  showProviderFinder ? handleProveedorFocus : handleFocus
                }
              />
            </Form.Item>

            {showProviderFinder && (
              <Form.Item label='Admin'>
                <Button
                  type='secondary'
                  onClick={(e) => {
                    findRUC();
                    e.stopPropagation();
                  }}
                  disabled={disabledBoolean}
                >
                  Buscar RUC
                </Button>
              </Form.Item>
            )}

            <Form.Item label='RUC' name='ruc'>
              <Input
                placeholder='12345-123-45678'
                disabled={disabledBoolean}
                style={{ color: 'black' }}
                onFocus={handleFocus}
              />
            </Form.Item>

            <Form.Item label='Número de Factura' name='receipt_number'>
              <Input
                placeholder='012345'
                disabled={disabledBoolean}
                style={{ color: 'black' }}
                onFocus={handleFocus}
              />
            </Form.Item>

            <Form.Item label='Cuenta'>
              <AccountingAccountSelect
                value={botexpense.account_id}
                onChange={(value) => handleAccountChange(botexpense, value)}
                clientId={clientId}
                API_domain={API_domain}
                auth={auth}
                accounts={accounts}
              />
            </Form.Item>

            <Form.Item
              label='Monto sin impuestos'
              name='amount'
              rules={[
                {
                  required: true,
                  message: 'Por favor escriba el monto (número) sin impuestos',
                },
              ]}
            >
              <Space>
                <InputNumber
                  style={{ width: 120, color: 'black' }}
                  placeholder='10.00'
                  step={0.01}
                  value={inputAmount}
                  onChange={updateAmount}
                  disabled={disabledBoolean}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <Button
                  type='default'
                  onClick={() =>
                    updateAmount(parseFloatOrNull(initialAmount * 1000))
                  }
                  tabIndex={-1}
                  style={{
                    backgroundColor: 'white',
                    color: 'rgba(0, 160, 0, 1)',
                    borderColor: 'green',
                    border: '0.5px solid rgba(0, 160, 0, 1)',
                    fontSize: 10.5,
                    fontWeight: 300,
                    padding: '5px',
                  }}
                  disabled={disabledBoolean}
                >
                  <FormattedUSD
                    total={parseFloatOrNull(initialAmount * 1000)}
                  />
                </Button>
                <Button
                  type='default'
                  onClick={() =>
                    updateAmount(parseFloatOrNull(initialAmount * 100))
                  }
                  tabIndex={-1}
                  style={{
                    backgroundColor: 'white',
                    color: 'rgba(0, 160, 0, 1)',
                    borderColor: 'green',
                    border: '0.5px solid rgba(0, 160, 0, 1)',
                    fontSize: 10.5,
                    fontWeight: 300,
                    padding: '5px',
                  }}
                  disabled={disabledBoolean}
                >
                  <FormattedUSD total={parseFloatOrNull(initialAmount * 100)} />
                </Button>
                <Button
                  type='default'
                  onClick={() =>
                    updateAmount(parseFloatOrNull(initialAmount * 0.01))
                  }
                  tabIndex={-1}
                  style={{
                    backgroundColor: 'white',
                    color: 'rgba(0, 160, 0, 1)',
                    borderColor: 'green',
                    border: '0.5px solid rgba(0, 160, 0, 1)',
                    fontSize: 10.5,
                    fontWeight: 300,
                    padding: '5px',
                  }}
                  disabled={disabledBoolean}
                >
                  <FormattedUSD
                    total={parseFloatOrNull(initialAmount * 0.01)}
                  />
                </Button>
                <Button
                  type='default'
                  tabIndex={-1}
                  onClick={() => updateAmount(parseFloatOrNull(initialAmount))}
                  style={{
                    backgroundColor: 'white',
                    color: 'rgba(0, 160, 0, 1)',
                    borderColor: 'green',
                    border: '0.5px dashed rgba(0, 160, 0, 1)',
                    fontSize: 10.5,
                    fontWeight: 300,
                    padding: '5px',
                  }}
                  disabled={disabledBoolean}
                >
                  <FormattedUSD total={initialAmount} />
                </Button>
              </Space>
            </Form.Item>

            <Form.Item
              label='Impuestos'
              name='itbms'
              onFocus={handleItbmsFocus}
              rules={[
                {
                  required: true,
                  message:
                    'Por favor escriba la cantidad (número) del impuesto',
                },
              ]}
            >
              <InputNumber
                ref={inputRef}
                style={{ width: 120, color: 'black' }}
                placeholder='0.70'
                step={0.01}
                value={itbms}
                onChange={(value) => setItbms(value)}
                disabled={disabledBoolean}
              />
            </Form.Item>

            <Form.Item label='Total'>
              <FormattedUSD
                total={parseFloatOrNull(+amountWithoutItbms + +itbms)}
              />
              {expense.card_atm_withdrawal ? (
                <span style={{ marginLeft: 6 }}>🏧</span>
              ) : expense.card_transaction ? (
                <span style={{ marginLeft: 6 }}>💳</span>
              ) : null}
            </Form.Item>

            <Form.Item>
              <Row gutter={16}>
                <Col span={18}>
                  {expense.report_id ? (
                    <Tooltip
                      placement='topRight'
                      title='No puedes editar un gasto reportado'
                    >
                      <Button
                        type='primary'
                        htmlType='submit'
                        disabled
                        block
                        style={{ fontWeight: 500 }}
                      >
                        Actualizar gasto
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement='top'
                      title={
                        <div>
                          <span
                            style={{
                              display: 'inline-block',
                              padding: '2px 5px',
                              backgroundColor: '#f0f0f0',
                              borderRadius: '3px',
                              fontWeight: 'bold',
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '0.9em',
                              marginRight: '5px',
                              color: 'black',
                            }}
                          >
                            ⌘ / control
                          </span>
                          <span>
                            + click para actualizar y continuar al próximo gasto
                          </span>
                        </div>
                      }
                    >
                      <Button
                        type='primary'
                        htmlType='submit'
                        block
                        style={{ fontWeight: 500 }}
                        disabled={disabledBoolean}
                      >
                        {isSubmitting ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          'Actualizar gasto'
                        )}
                      </Button>
                    </Tooltip>
                  )}
                </Col>
                <Col span={6}>
                  {expense.card_transaction ? (
                    <Tooltip placement='topRight' title='Reportar problema 💳'>
                      <Button
                        style={{ margin: '0 4px', width: '100%' }}
                        onClick={reportCardTransaction}
                        disabled={disabledBoolean}
                      >
                        🙋
                      </Button>
                    </Tooltip>
                  ) : (
                    <Popconfirm
                      title='¿Estás seguro de que quieres borrar este gasto?'
                      onConfirm={() => {
                        HeroAxios({
                          method: 'post',
                          url: `${API_domain}${'deleteBotExpense'}`,
                          data: { botexpense_id: expense.id },
                        })
                          .then((response) => {
                            message.success('Gasto borrado');
                            onSubmit(); // Callback to parent component
                          })
                          .catch((error) => {
                            console.error('Error al borrar el gasto:', error);
                            message.error('Error al borrar el gasto');
                          });
                      }}
                      okText='Borrar'
                      cancelText='Cancelar'
                      placement='topRight'
                    >
                      <Tooltip placement='right' title='Borrar gasto'>
                        <Button
                          danger
                          style={{ margin: '0 4px', width: '100%' }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  )}
                </Col>
              </Row>
            </Form.Item>

            {showProviderFinder && (
              <div style={{ color: 'grey' }}>
                <div>
                  Subida a Hero:{' '}
                  <b>
                    {moment(expense.created_at).format('YYYY-MM-DD HH:mm:ss')}
                  </b>
                </div>
                <div>
                  Extra_info:<b> {expense.extra_info}</b>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { BotExpenseModal };
