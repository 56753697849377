import React, {
  useContext,
  useEffect,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  DownOutlined,
  MinusCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Table,
  Input,
  message,
  Menu,
  Space,
  Checkbox,
  Tooltip,
  Tag,
} from 'antd';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import { FormattedUSD } from '../FormattedUSD';
import { calculateISRforThisPayroll } from '../../utils';

function NewPayrollTable(props, ref) {
  const auth = useContext(authContext);
  const [employeeData, setEmployeeData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [payrollData, setPayrollData] = useState([]);
  const [salarioPorHoraManual, setSalarioPorHoraManual] = useState(false);

  // Expose payrollData to the parent via useImperativeHandle
  useImperativeHandle(ref, () => ({
    getPayrollData: () => payrollData,
    getColumns: () => columns,
  }));

  useEffect(() => {
    if (employeeData.length === 0) return;

    const fetchCurrentPayroll = async () => {
      setTableLoading(true);
      try {
        const response = await axios.post(
          `${props.API_domain}getPayroll`,
          { accountingClientsId: props.selectedAccountingClientId },
          { auth: { username: auth.email, password: auth.token } }
        );

        if (response.data && Array.isArray(response.data.payroll)) {
          // Process each payroll item individually
          const formattedData = response.data.payroll.map((payroll) => {
            let updatedPayrollData = {
              ...payroll,
              biweekly_salary: parseFloat(payroll.biweekly_salary) || 0,
              hasDeductions: true,
            };

            // Find the corresponding employee data
            const employee = employeeData.find(
              (employeeItem) =>
                employeeItem.employee_id === updatedPayrollData.employee_id
            );

            // Calculate payroll components for the updated payroll data
            updatedPayrollData = calculatePayrollComponents(
              updatedPayrollData,
              employee,
              salarioPorHoraManual
            );

            return {
              ...updatedPayrollData,
              key: payroll.id,
            };
          });

          // Update the state with the formatted data
          setPayrollData(formattedData);
          setTableLoading(false);
        }
      } catch (error) {
        setTableLoading(false);
        console.error('Error fetching current payroll:', error);
      }
    };

    fetchCurrentPayroll();
  }, [employeeData, props.selectedAccountingClientId]);

  const calculatePayrollComponents = (
    updatedItem,
    employee,
    salarioPorHoraManual,
    isr_changed = false
  ) => {
    // Helper function to round numbers to two decimal places
    const roundToTwo = (num) => {
      return Math.round(num * 100) / 100;
    };

    // Ensure all numerical fields are initialized to 0 if they are falsy
    const numericalFields = [
      'biweekly_salary',
      'advance_deductions',
      'benefit_production',
      'benefit_fuel',
      'benefit_representation_expenses',
      'overtime', // this column is useless when horas breakdown is active. the name might be confusing. overtime might be 0 when pago_total_extras is non-zero
      'deduction_attendance',
      'deduction_direct_discount',
      'salarioporhora',
      'horas_extradiurnas',
      'horas_extranocturnas',
      'horas_domingos',
      'horas_dialibre',
      'horas_recargo',
      'propinas',
      'horas_no_trabajadas',
    ];

    numericalFields.forEach((field) => {
      updatedItem[field] = parseFloat(updatedItem[field]) || 0;
    });

    // Initialize payment variables as properties of updatedItem
    updatedItem.pago_extradiurnas = 0;
    updatedItem.pago_extranocturnas = 0;
    updatedItem.pago_domingos = 0;
    updatedItem.pago_dialibre = 0;
    updatedItem.pago_recargo = 0;
    updatedItem.pago_total_extras = 0;

    if (salarioPorHoraManual) {
      updatedItem.salarioporhora = roundToTwo(updatedItem.salarioporhora);
    } else {
      updatedItem.salarioporhora = roundToTwo(
        (2 * updatedItem.biweekly_salary) / (4.333 * 48)
      );
    }
    if (updatedItem.salarioporhora < 0) {
      updatedItem.salarioporhora = 0;
    }

    if (props.hourBreakdownBoolean) {
      // Compute extra hours payments
      updatedItem.pago_extradiurnas =
        updatedItem.horas_extradiurnas * updatedItem.salarioporhora * 1.25;
      updatedItem.pago_extranocturnas =
        updatedItem.horas_extranocturnas * updatedItem.salarioporhora * 1.5;
      updatedItem.pago_domingos =
        updatedItem.horas_domingos * updatedItem.salarioporhora * 0.5;
      updatedItem.pago_dialibre =
        updatedItem.horas_dialibre * updatedItem.salarioporhora * 1.5;
      updatedItem.pago_recargo =
        updatedItem.horas_recargo * updatedItem.salarioporhora * 1.75;
      updatedItem.propinas = updatedItem.propinas;

      // Sum up extra hours payments
      updatedItem.pago_total_extras = roundToTwo(
        updatedItem.pago_extradiurnas +
          updatedItem.pago_extranocturnas +
          updatedItem.pago_domingos +
          updatedItem.pago_dialibre +
          updatedItem.pago_recargo +
          updatedItem.propinas
      );

      // Compute payroll_gross
      updatedItem.payroll_gross = roundToTwo(
        updatedItem.biweekly_salary +
          updatedItem.benefit_production +
          updatedItem.benefit_fuel +
          updatedItem.benefit_representation_expenses +
          updatedItem.pago_total_extras
      );

      // Compute deduction_attendance as horas_no_trabajadas * salarioporhora
      updatedItem.deduction_attendance = roundToTwo(
        updatedItem.horas_no_trabajadas * updatedItem.salarioporhora
      );

      // Set overtime to 0
      updatedItem.overtime = 0;
    } else {
      updatedItem.payroll_gross = roundToTwo(
        updatedItem.biweekly_salary +
          updatedItem.benefit_production +
          updatedItem.benefit_fuel +
          updatedItem.benefit_representation_expenses +
          updatedItem.overtime
      );
    }

    // Calculate benefits subject to social security
    const productionBenefitSS = Math.max(
      roundToTwo(
        updatedItem.benefit_production - updatedItem.biweekly_salary * 0.5
      ),
      0
    );
    updatedItem.benefit_production_subject_to_social_security =
      productionBenefitSS;

    const totalSalaryAndProduction = roundToTwo(
      productionBenefitSS + updatedItem.biweekly_salary
    );
    const fuelBenefitLimit = roundToTwo(totalSalaryAndProduction * 0.2);
    const fuelBenefitSS = Math.max(
      roundToTwo(updatedItem.benefit_fuel - fuelBenefitLimit),
      0
    );
    updatedItem.benefit_fuel_subject_to_social_security = fuelBenefitSS;

    // Calculate payroll subject to social security
    if (props.hourBreakdownBoolean) {
      updatedItem.payroll_subject_to_ss = roundToTwo(
        updatedItem.biweekly_salary +
          updatedItem.benefit_representation_expenses +
          updatedItem.pago_extradiurnas +
          updatedItem.pago_extranocturnas +
          updatedItem.pago_domingos +
          updatedItem.pago_dialibre +
          updatedItem.pago_recargo +
          productionBenefitSS +
          fuelBenefitSS -
          updatedItem.deduction_attendance
      );
    } else {
      updatedItem.payroll_subject_to_ss = roundToTwo(
        updatedItem.biweekly_salary +
          updatedItem.benefit_representation_expenses +
          updatedItem.overtime +
          productionBenefitSS +
          fuelBenefitSS -
          updatedItem.deduction_attendance
      );
    }

    // Calculate social security deduction
    updatedItem.deduction_ss = roundToTwo(
      updatedItem.payroll_subject_to_ss *
        (props.decimoTercerMesBoolean ? 0.0725 : 0.0975)
    );

    // Calculate payroll subject to SE
    if (props.hourBreakdownBoolean) {
      updatedItem.payroll_subject_to_se = props.decimoTercerMesBoolean
        ? 0
        : roundToTwo(
            updatedItem.biweekly_salary +
              updatedItem.benefit_fuel +
              updatedItem.pago_extradiurnas +
              updatedItem.pago_extranocturnas +
              updatedItem.pago_domingos +
              updatedItem.pago_dialibre +
              updatedItem.pago_recargo -
              updatedItem.deduction_attendance
          );
    } else {
      updatedItem.payroll_subject_to_se = props.decimoTercerMesBoolean
        ? 0
        : roundToTwo(
            updatedItem.biweekly_salary +
              updatedItem.overtime +
              updatedItem.benefit_fuel -
              updatedItem.deduction_attendance
          );
    }

    // Calculate SE deduction
    updatedItem.deduction_se = roundToTwo(
      updatedItem.payroll_subject_to_se * 0.0125
    );

    // Calculate ISR deduction if not changed
    if (!isr_changed) {
      const total_comp_subject_to_isr_this_year_so_far =
        parseFloat(employee.total_comp_subject_to_isr_this_year_so_far) || 0;
      const total_isr_deducted_this_year_so_far =
        parseFloat(employee.total_isr_deducted_this_year_so_far) || 0;

      let isrDetails = calculateISRforThisPayroll(
        total_comp_subject_to_isr_this_year_so_far,
        total_isr_deducted_this_year_so_far,
        props.payableDate,
        updatedItem.payroll_gross,
        updatedItem.payroll_subject_to_ss,
        updatedItem.benefit_representation_expenses
      );

      updatedItem.deduction_isr = roundToTwo(isrDetails.isrForThisPayroll);
      updatedItem.isrDetails = isrDetails;
    }

    // Calculate total deductions
    updatedItem.deduction_total = roundToTwo(
      updatedItem.deduction_ss +
        updatedItem.deduction_se +
        (updatedItem.deduction_isr || 0) + // Ensure deduction_isr is defined
        updatedItem.deduction_direct_discount +
        updatedItem.advance_deductions +
        updatedItem.deduction_attendance
    );

    // Calculate net payroll
    updatedItem.payroll_net = roundToTwo(
      updatedItem.payroll_gross - updatedItem.deduction_total
    );

    // Handle case when there are no deductions
    if (!updatedItem.hasDeductions) {
      updatedItem.payroll_subject_to_se = 0;
      updatedItem.payroll_subject_to_ss = 0;
      updatedItem.deduction_ss = 0;
      updatedItem.deduction_se = 0;
      updatedItem.deduction_isr = 0;
      updatedItem.deduction_total = roundToTwo(
        updatedItem.deduction_attendance +
          updatedItem.advance_deductions +
          updatedItem.deduction_direct_discount
      );
      updatedItem.payroll_net = roundToTwo(
        updatedItem.payroll_gross -
          updatedItem.deduction_attendance -
          updatedItem.advance_deductions -
          updatedItem.deduction_direct_discount
      );
    }
    return updatedItem;
  };

  useEffect(() => {
    // Recalculate payroll components when props.decimoTercerMesBoolean changes
    const updatedPayrollData = payrollData.map((item) => {
      const employee = employeeData.find(
        (emp) => emp.employee_id == item.employee_id
      );
      return calculatePayrollComponents(
        item,
        employee,
        salarioPorHoraManual,
        true
      );
    });
    setPayrollData(updatedPayrollData);
  }, [
    props.decimoTercerMesBoolean,
    props.hourBreakdownBoolean,
    salarioPorHoraManual,
  ]);

  const handleEditChange = (value, key, column) => {
    const newData = payrollData.map((item) => {
      if (item.key === key) {
        const updatedValue = value !== '' ? parseFloat(value) : parseFloat(0);
        const updatedItem = { ...item, [column]: updatedValue };
        const employee = employeeData.find(
          (emp) => emp.employee_id == item.employee_id
        );
        return calculatePayrollComponents(
          updatedItem,
          employee,
          salarioPorHoraManual,
          column === 'deduction_isr'
        );
      }
      return item;
    });

    setPayrollData(newData);
  };

  const handleDeductionsCheckboxChange = (e, key) => {
    const { checked } = e.target;
    const newData = payrollData.map((item) => {
      if (item.key === key) {
        const employee = employeeData.find(
          (emp) => emp.employee_id == item.employee_id
        );
        const updatedItem = { ...item, hasDeductions: checked };
        return calculatePayrollComponents(
          updatedItem,
          employee,
          salarioPorHoraManual
        );
      }
      return item;
    });
    setPayrollData(newData);
  };

  const columns = [
    {
      title: 'Persona ',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <div>
            <b>{record.name}</b>
          </div>
          <div>{record.gov_id}</div>
        </>
      ),
    },
    {
      title: 'Aplicar deducciones',
      dataIndex: 'hasDeductions',
      key: 'hasDeductions',
      width: 80,
      render: (text, record) => (
        <Checkbox
          style={{ marginLeft: 20 }}
          checked={record.hasDeductions}
          onChange={(e) => handleDeductionsCheckboxChange(e, record.key)}
        />
      ),
    },
    {
      title: 'Salario Quincenal',
      dataIndex: 'biweekly_salary',
      key: 'biweekly_salary',
      editable: true,
    },
    {
      title: 'Prima de Prod.',
      dataIndex: 'benefit_production',
      key: 'benefit_production',
      editable: true,
    },
    {
      title: 'Prima de Comb.',
      dataIndex: 'benefit_fuel',
      key: 'benefit_fuel',
      editable: true,
    },
    {
      title: 'Gastos de Rep.',
      dataIndex: 'benefit_representation_expenses',
      key: 'benefit_representation_expenses',
      editable: true,
    },
    ...(props.hourBreakdownBoolean
      ? [
          {
            title: 'Salario por Hora',
            dataIndex: 'salarioporhora',
            key: 'salarioporhora',
            editable: true,
          },
          {
            title: 'Horas Extra Diurnas',
            dataIndex: 'horas_extradiurnas',
            key: 'horas_extradiurnas',
            editable: true,
          },
          {
            title: 'Pago Extra Diurnas',
            dataIndex: 'pago_extradiurnas',
            key: 'pago_extradiurnas',
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
          {
            title: 'Horas Extra Nocturnas',
            dataIndex: 'horas_extranocturnas',
            key: 'horas_extranocturnas',
            editable: true,
          },
          {
            title: 'Pago Extra Nocturnas',
            dataIndex: 'pago_extranocturnas',
            key: 'pago_extranocturnas',
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
          {
            title: 'Horas Domingos',
            dataIndex: 'horas_domingos',
            key: 'horas_domingos',
            editable: true,
          },
          {
            title: 'Pago Domingos',
            dataIndex: 'pago_domingos',
            key: 'pago_domingos',
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
          {
            title: 'Horas Día Libre',
            dataIndex: 'horas_dialibre',
            key: 'horas_dialibre',
            editable: true,
          },
          {
            title: 'Pago Día Libre',
            dataIndex: 'pago_dialibre',
            key: 'pago_dialibre',
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
          {
            title: 'Horas Recargo',
            dataIndex: 'horas_recargo',
            key: 'horas_recargo',
            editable: true,
          },
          {
            title: 'Pago Recargo',
            dataIndex: 'pago_recargo',
            key: 'pago_recargo',
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
          {
            title: 'Propinas',
            dataIndex: 'propinas',
            key: 'propinas',
            editable: true,
          },
          {
            title: 'Total de Extras',
            dataIndex: 'pago_total_extras',
            key: 'pago_total_extras',
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
          {
            title: 'Horas no trabajadas',
            dataIndex: 'horas_no_trabajadas',
            key: 'horas_no_trabajadas',
            editable: true,
          },
        ]
      : [
          {
            title: 'Tiempo Extra',
            dataIndex: 'overtime', // this column is useless when horas breakdown is active. the name might be confusing. overtime might be 0 when pago_total_extras is non-zero
            key: 'overtime',
            editable: true,
          },
          {
            title: 'Menos Ausencias',
            dataIndex: 'deduction_attendance',
            key: 'deduction_attendance',
            editable: true,
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
        ]),
    {
      title: 'Total de Ingresos',
      dataIndex: 'payroll_gross',
      key: 'payroll_gross',
      render: (text) => (
        <b style={{ color: 'green' }}>
          <FormattedUSD total={parseFloat(text).toFixed(2)} />
        </b>
      ),
    },
    {
      title: 'Prima de Prod. sujeta a SS',
      dataIndex: 'benefit_production_subject_to_social_security',
      key: 'benefit_production_subject_to_social_security',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Prima de Comb. sujeta a SS',
      dataIndex: 'benefit_fuel_subject_to_social_security',
      key: 'benefit_fuel_subject_to_social_security',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    ...(props.hourBreakdownBoolean
      ? [
          {
            title: 'Menos horas no trabajadas',
            dataIndex: 'deduction_attendance',
            key: 'deduction_attendance',
            render: (text) => (
              <FormattedUSD total={parseFloat(text).toFixed(2)} />
            ),
          },
        ]
      : []),
    {
      title: 'Ingresos sujetos a SS',
      dataIndex: 'payroll_subject_to_ss',
      key: 'payroll_subject_to_ss',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: props.decimoTercerMesBoolean
        ? 'Seguro Social 7.25%'
        : 'Seguro Social 9.75%',
      dataIndex: 'deduction_ss',
      key: 'deduction_ss',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Ingresos sujetos a SE',
      dataIndex: 'payroll_subject_to_se',
      key: 'payroll_subject_to_se',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Seguro Educativo 1.25%',
      dataIndex: 'deduction_se',
      key: 'deduction_se',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Descuento Directo',
      dataIndex: 'deduction_direct_discount',
      key: 'deduction_direct_discount',
      editable: true,
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'ISR',
      dataIndex: 'deduction_isr',
      key: 'deduction_isr',
      editable: true,
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Descuento de Adelanto',
      dataIndex: 'advance_deductions',
      key: 'advance_deductions',
      editable: true,
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Adelantos pendientes por descontar',
      dataIndex: 'pending_deductions',
      key: 'pending_deductions',
      render: (pending_deductions, record) => {
        const isZero = record.pending_deductions === 0;
        const isAdvanceLarger =
          record.advance_deductions > record.pending_deductions;
        return isZero && isAdvanceLarger ? (
          <span style={{ color: 'red', fontSize: '24px', fontWeight: 'bold' }}>
            $0.00
          </span>
        ) : isAdvanceLarger ? (
          <Tag color='red' style={{ fontSize: '24px', padding: 10 }}>
            <FormattedUSD total={record.pending_deductions} />
          </Tag>
        ) : record.pending_deductions > 0 ? (
          <div style={{ marginTop: 8 }}>
            <Tooltip
              title='Adelantos pendientes por descontar'
              placement='bottomLeft'
            >
              <Tag color='green' style={{ padding: 5 }}>
                <FormattedUSD total={record.pending_deductions} />
              </Tag>
            </Tooltip>
          </div>
        ) : (
          <span>$0.00</span>
        );
      },
    },
    {
      title: 'Total de Deducciones',
      key: 'deduction_total',
      render: (_, record) => (
        <b style={{ color: 'red' }}>
          <FormattedUSD total={parseFloat(record.deduction_total).toFixed(2)} />
        </b>
      ),
    },
    {
      title: 'Salario Neto a Recibir',
      dataIndex: 'payroll_net',
      key: 'payroll_net',
      render: (text) => (
        <b style={{ color: 'rgb(65,130,239)' }}>
          <FormattedUSD total={parseFloat(text).toFixed(2)} />
        </b>
      ),
    },
    {
      title: 'Notas',
      dataIndex: 'notes',
      key: 'notes',
      // editable: true,
      render: (text, record) => (
        <Input
          placeholder={text ? text : 'Ejemplo: Días trabajados 12-18'}
          style={{ width: 240 }}
          defaultValue={record.notes}
          onBlur={(e) => {
            const newData = payrollData.map((item) => {
              if (item.key === record.key) {
                return { ...item, notes: e.target.value };
              }
              return item;
            });
            setPayrollData(newData);
          }}
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type='secondary'
            style={{ border: 'none' }}
            onClick={() => {
              setPayrollData(
                payrollData.filter((emp) => emp.key !== record.key)
              );
            }}
          >
            <MinusCircleOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const EditableCell = ({
    editable,
    dataIndex,
    record,
    handleEditChange,
    isrDetails,
    children,
    ...restProps
  }) => {
    const [inputValue, setInputValue] = useState(
      record && record[dataIndex] ? record[dataIndex] : ''
    );
    const inputRef = useRef(null);

    useEffect(() => {
      if (record && record[dataIndex] !== undefined) {
        setInputValue(record[dataIndex]);
      }
    }, [record, dataIndex]);

    const handleBlur = () => {
      if (record) {
        handleEditChange(inputValue, record.key, dataIndex);
      }
    };

    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    };

    const formatISRDetails = (details) => {
      const labels = {
        isrForThisPayroll: 'ISR para esta nómina',
        estimatedTotalCompensationThisYear:
          'Compensación total estimada este año',
        estimatedTotalAnnualISR: 'ISR total anual estimado',
        totalRemainingISR: 'ISR total restante',
        remainingPayrollsThisYear: 'Nóminas restantes este año',
        total_isr_deducted_this_year_so_far:
          'ISR total deducido este año hasta antes de esta planilla',
        total_comp_subject_to_isr_this_year_so_far:
          'Compensación total sujeta a ISR este año hasta antes de esta planilla',
        isrForThisPayrollFromRepresentationExpenses:
          'ISR para esta nómina de Gastos de Representación',
      };

      return (
        <>
          <h3 style={{ color: 'white' }}>Cómo se calcula el ISR?</h3>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tbody>
              {Object.entries(details).map(([key, value]) => {
                return (
                  <tr
                    key={key}
                    style={{
                      padding: '8px',
                    }}
                  >
                    <td
                      style={{
                        padding: '8px',
                        fontWeight:
                          key === 'isrForThisPayroll' ? 'bold' : 'normal',
                        fontSize: key === 'isrForThisPayroll' ? '16px' : '14px',
                      }}
                    >
                      {labels[key]}:
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        textAlign: 'right',
                        fontWeight:
                          key === 'isrForThisPayroll' ? 'bold' : 'normal',
                        fontSize: key === 'isrForThisPayroll' ? '16px' : '14px',
                      }}
                    >
                      {key === 'remainingPayrollsThisYear' ? (
                        details[key]
                      ) : (
                        <FormattedUSD total={details[key]} />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    };

    return (
      <td {...restProps}>
        {editable ? (
          dataIndex === 'deduction_isr' ? (
            <Tooltip
              overlayStyle={{ maxWidth: '400px' }}
              title={
                <div style={{ maxWidth: '100%' }}>
                  {formatISRDetails(isrDetails)}
                </div>
              }
              placement='topLeft'
            >
              <Input
                ref={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                style={{ padding: 4, width: 75, textAlign: 'center' }}
              />
            </Tooltip>
          ) : (
            <Input
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              style={{ padding: 4, width: 75, textAlign: 'center' }}
            />
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const filteredColumns = columns.filter((col) => {
    if (props.isCondensed) {
      return [
        'name',
        'hasDeductions',
        'biweekly_salary',
        'benefit_production',
        'benefit_fuel',
        'overtime', // simple overtime
        'pago_total_extras',
        'payroll_gross',
        'deduction_attendance',
        'advance_deductions',
        'deduction_total',
        'payroll_net',
        'notes',
        'actions',
      ].includes(col.key);
    }
    return true;
  });

  const mergedColumns = filteredColumns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      handleEditChange,
      isrDetails: record.isrDetails,
    }),
  }));

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const selectedAccountingClientId = props.selectedAccountingClientId;
      if (!selectedAccountingClientId) {
        return;
      }
      try {
        const response = await axios.post(
          `${props.API_domain}getAccountingClientEmployees`,
          { accountingClientsId: selectedAccountingClientId },
          { auth: { username: auth.email, password: auth.token } }
        );
        // console.log('Employee data:', response.data);
        setEmployeeData(response.data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeData();
  }, [props.selectedAccountingClientId]);

  const handleAddPayrollRow = (e) => {
    const employeeId = e.key;
    // console.log('Adding Payroll Row for Employee:', employeeId);
    // console.log('Employee Data:', employeeData);
    const employee = employeeData.find((emp) => emp.employee_id == employeeId);
    // console.log(employee);
    const newPayrollRow = {
      employee_id: employeeId,
      key: payrollData.length + 1,
      name: employee.name,
      gov_id: employee.gov_id,
      biweekly_salary: 0,
      benefit_production: 0,
      benefit_fuel: 0,
      benefit_representation_expenses: 0,
      overtime: 0, // this column is useless when horas breakdown is active. the name might be confusing. overtime might be 0 when pago_total_extras is non-zero
      payroll_gross: 0,
      benefit_production_subject_to_social_security: 0,
      benefit_fuel_subject_to_social_security: 0,
      deduction_attendance: 0,
      payroll_subject_to_ss: 0,
      deduction_ss: 0,
      payroll_subject_to_se: 0,
      deduction_se: 0,
      deduction_isr: 0,
      deduction_direct_discount: 0,
      pending_deductions: employee.pending_deductions || 0,
      advance_deductions: 0,
      deduction_total: 0,
      payroll_net: 0,
      hasDeductions: true,
      isrDetails: {},
      salarioporhora: 0,
      horas_extradiurnas: 0,
      horas_extranocturnas: 0,
      horas_domingos: 0,
      horas_dialibre: 0,
      horas_no_trabajadas: 0,
      horas_recargo: 0,
      pago_extradiurnas: 0,
      pago_extranocturnas: 0,
      pago_domingos: 0,
      pago_dialibre: 0,
      pago_recargo: 0,
      pago_total_extras: 0, // this is the sum of all extra hours payments. not necessary in db
    };

    setPayrollData([...payrollData, newPayrollRow]);
  };

  const menu = (
    <Menu onClick={handleAddPayrollRow}>
      {employeeData
        .filter((emp) => emp.end_date === null)
        .map((emp) => (
          <Menu.Item key={emp.employee_id}>
            <Space>
              <UserOutlined />
              {emp.name}
            </Space>
          </Menu.Item>
        ))}
    </Menu>
  );

  const getMostRecentEntries = (data) => {
    // Step 1: Sort the data by 'payable_date' in descending order
    const sortedData = data.sort(
      (a, b) => new Date(b.payable_date) - new Date(a.payable_date)
    );

    // Step 2: Select the 8 most recent entries
    const mostRecentEntries = sortedData.slice(0, 8);

    // Step 3: Format the output
    return {
      name: mostRecentEntries[0]?.name || '', // Get the name from the first entry, fallback to an empty string if the array is empty
      employeeId: mostRecentEntries[0]?.employee_id || '', // Get the employee_id from the first entry, fallback to an empty string if the array is empty
      entries: mostRecentEntries.map((entry) => ({
        gov_id: entry.gov_id,
        payroll_gross: entry.payroll_gross,
        payable_date: entry.payable_date,
      })),
    };
  };

  const getEmployeePaymentHistory = async (employeeId) => {
    if (!employeeId) return;

    try {
      const response = await axios.post(
        `${props.API_domain}getPayroll`,
        {
          accountingClientsId: props.selectedAccountingClientId,
          employeeId,
        },
        { auth: { username: auth.email, password: auth.token } }
      );

      if (response.data && Array.isArray(response.data.payroll)) {
        // add index to each record
        response.data.payroll.forEach((record, index) => {
          record.index = index + 1;
        });
        // setPayrollData(response.data.payroll); // Uncomment this to set the payroll data
        return getMostRecentEntries(response.data.payroll);
      }
    } catch (error) {
      console.error('Error fetching payroll data:', error);
      message.error(
        'Error al obtener historial de planillas. Posiblemente no tiene planillas.'
      );
    }
  };

  const calculateDecimoTercerMes = async (employeeData) => {
    // TODO:FIX. broken before refactor!
    let employeeIdsList = [];
    payrollData.forEach((emp) => {
      employeeIdsList.push(emp.employee_id);
    });
    let decimoTercerMesData = []; // should be a list of dictionaries. each dictionary shall have the name of the employee, the payable_date, and the biweekly salary from the last 8 payrolls
    // run getEmplpoyeePaymentHistory for each employee in order to populate decimoTercerMesData
    for (let i = 0; i < employeeIdsList.length; i++) {
      const employeeId = employeeIdsList[i];
      const response = await getEmployeePaymentHistory(employeeId);
      decimoTercerMesData.push(response);
    }

    // clean up
    setPayrollData([]);
    // populate payrollData with the data from decimoTercerMesData
    const updatedPayrollData = decimoTercerMesData.map((emp, index) => {
      return {
        employee_id: emp.employeeId,
        key: index + 1,
        name: emp.name,
        gov_id: emp.entries[0]?.gov_id || '',
        // biweekly_salary should be the sum of all of the biweekly salaries from the last 8 payrolls
        biweekly_salary:
          (8.333 / 100) *
          emp.entries.reduce(
            (acc, curr) => acc + curr.payroll_subject_to_ss,
            0
          ),
        // biweekly_salary: emp.entries[0]?.payroll_gross || 0,
        benefit_production: 0,
        benefit_fuel: 0,
        benefit_representation_expenses: 0,
        overtime: 0, // this column is useless when horas breakdown is active. the name might be confusing. overtime might be 0 when pago_total_extras is non-zero
        payroll_gross: 0,
        benefit_production_subject_to_social_security: 0,
        benefit_fuel_subject_to_social_security: 0,
        deduction_attendance: 0,
        payroll_subject_to_ss: 0,
        deduction_ss: 0,
        payroll_subject_to_se: 0,
        deduction_se: 0,
        deduction_isr: 0,
        deduction_direct_discount: 0,
        pending_deductions: emp.pending_deductions || 0,
        advance_deductions: 0,
        deduction_total: 0,
        payroll_net: 0,
        hasDeductions: true,
        isrDetails: {},
        // lets include the last 8 payrolls in the notes
        notes: `${emp.entries
          .map((entry) => entry.payable_date + ' : ' + entry.payroll_gross)
          .join(' | ')}`,
      };
    });
    setPayrollData(updatedPayrollData);
    // to do: calculate other fields
  };

  return (
    <>
      <Table
        id='payrollTable'
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={payrollData}
        rowClassName='editable-row'
        pagination={false}
        loading={tableLoading}
        footer={() => (
          <>
            <Dropdown overlay={menu}>
              <Button>
                <Space>
                  Agregar fila
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                message.info('Borrador limpiado');
                setPayrollData([]);
              }}
            >
              Limpiar borrador
            </Button>
            {props.decimoTercerMesBoolean && (
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => {
                  message.info('Calculando Décimo Tercer Mes');
                  calculateDecimoTercerMes(employeeData);
                }}
              >
                Calcular Décimo Tercer Mes
              </Button>
            )}
            {props.hourBreakdownBoolean && (
              <Checkbox
                style={{ marginTop: 8, marginLeft: 16 }}
                onChange={() => setSalarioPorHoraManual(!salarioPorHoraManual)}
              >
                Salario por hora manual 🕐
              </Checkbox>
            )}
            <div style={{ textAlign: 'right' }}>
              Total a Pagar:{' '}
              <b style={{ color: 'rgb(65,130,239)' }}>
                <FormattedUSD
                  total={payrollData.reduce(
                    (acc, curr) => acc + curr.payroll_net,
                    0
                  )}
                />
              </b>
            </div>
          </>
        )}
      />
    </>
  );
}

// Key Changes:
// NewPayrollTable (Child Component):

// We wrapped the component with forwardRef to allow the parent to get a reference to it.
// Inside the child component, useImperativeHandle exposes the getPayrollData function, which allows the parent to access payrollData.
// NewPayrollTableContainer (Parent Component):

// We created a ref (childRef) using useRef and passed it to the child (NewPayrollTable).
// Inside handleCreatePayroll, we used childRef.current.getPayrollData() to fetch the payrollData from the child component right before creating the payroll.

export default forwardRef(NewPayrollTable);
