import React, { useState } from 'react';
import { Form, Input, message, Button, Row, Col } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';

const BGIntegration = (props) => {
  const [account, setAccount] = useState(
    props.bgCredentials.login_info.account || ''
  );
  const [password, setPassword] = useState(
    props.bgCredentials.login_info.password || ''
  );
  const [username, setUsername] = useState(
    props.bgCredentials.login_info.username || ''
  );

  // Convert question_map object to an array of key-value pairs
  const [questionMapArray, setQuestionMapArray] = useState(
    Object.entries(props.bgCredentials.login_info.question_map || {}).map(
      ([key, value]) => ({ key, value })
    )
  );
  const [loading, setLoading] = useState(false);

  // Handle changes in question keys
  const handleQuestionKeyChange = (index, newKey) => {
    setQuestionMapArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = { ...newArray[index], key: newKey };
      return newArray;
    });
  };

  // Handle changes in question values
  const handleQuestionValueChange = (index, newValue) => {
    setQuestionMapArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = { ...newArray[index], value: newValue };
      return newArray;
    });
  };

  // Add a new question-answer pair
  const handleAddQuestion = () => {
    setQuestionMapArray((prevArray) => [...prevArray, { key: '', value: '' }]);
  };

  // Delete a question-answer pair
  const handleDeleteQuestion = (index) => {
    setQuestionMapArray((prevArray) => prevArray.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const updatedCredentials = {
        accounting_client_id: props.bgCredentials.accounting_client_id,
        id: props.bgCredentials.id,
        site: 'bg',
        login_info: {
          account,
          password,
          username,
          question_map: Object.fromEntries(
            questionMapArray.map((item) => [item.key, item.value])
          ),
        },
      };

      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Actualización exitosa');
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error actualizando');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <h3 style={{ marginLeft: 4, textAlign: 'left' }}>BANCO GENERAL 🦸‍♂️</h3>
      <Form.Item label='Tipo de cuenta' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(account)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label='Contraseña' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(password)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label='Usuario' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(username)}
            />
          </Col>
        </Row>
      </Form.Item>
      {questionMapArray.map((item, index) => (
        <Form.Item key={index} style={{ marginLeft: 96 }}>
          <Row gutter={16}>
            <Col span={10}>
              <Input
                value={item.key}
                onChange={(e) => handleQuestionKeyChange(index, e.target.value)}
                placeholder='Pregunta'
              />
            </Col>
            <Col span={10}>
              <Input
                value={item.value}
                onChange={(e) =>
                  handleQuestionValueChange(index, e.target.value)
                }
                placeholder='Respuesta'
              />
            </Col>
            <Col span={2}>
              <Button
                icon={<CopyOutlined />}
                type='text'
                style={{ color: 'grey' }}
                onClick={() => props.copyToClipboard(item.value)}
              />
            </Col>
            <Col span={2}>
              <Button
                icon={<DeleteOutlined />}
                type='text'
                style={{ color: 'red' }}
                onClick={() => handleDeleteQuestion(index)}
              />
            </Col>
          </Row>
        </Form.Item>
      ))}
      <Button
        type='dashed'
        onClick={handleAddQuestion}
        style={{ marginLeft: 96, width: '60%' }}
      >
        + Añadir Pregunta
      </Button>
      <div style={{ textAlign: 'left', marginLeft: 96, marginTop: 16 }}>
        <Button
          type='primary'
          onClick={handleSave}
          loading={loading}
          style={{ marginRight: 16 }}
        >
          Guardar
        </Button>
        <Button
          type='secondary'
          onClick={() => console.log('validating')}
          style={{
            backgroundColor: 'hsl(0, 0%, 95%)',
            borderColor: 'hsl(0, 0%, 95%)',
          }}
          disabled
        >
          Validar Banco General ❌
        </Button>
      </div>
    </div>
  );
};

export default BGIntegration;
