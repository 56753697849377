import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Spin,
  Row,
  message,
  Tooltip,
} from 'antd';
import {
  CopyOutlined,
  DollarCircleOutlined,
  EyeOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  StopOutlined,
} from '@ant-design/icons';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { FormattedUSD } from './FormattedUSD.js';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { downloadBlob, get_account_statement_blob } from '../utils.js';
import { UserFromATeamSelect } from './UserFromATeamSelect.js';

function CardModal(props) {
  const auth = useContext(authContext);
  const [cardPan, setPan] = useState('');
  const [cardBalance, setBalance] = useState('');
  const [spentThisMonth, setSpentThisMonth] = useState('');
  const [blockedBoolean, setBlockedBoolean] = useState(false);
  const [team, setTeam] = useState('');
  const [pinCopy, setPinCopy] = useState('Ver PIN');
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [lowBalanceAmountToNotify, setLowBalanceAmountToNotify] = useState('');
  const [modalContent, setModalContent] = useState({});
  const [amount, setAmount] = useState('');
  const [isBlockingToggle, setIsBlockingToggle] = useState(false);
  const [cardInfo, setCardInfo] = useState({});

  const getPaycaddyCardInfos = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getPaycaddyCardInfos',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { card_id: props.selectedCard.id },
    })
      .then((response) => {
        setPan(response.data.pan);
        setBalance(response.data.balance);
        setSpentThisMonth(response.data.spent_this_month);
        setTeam(response.data.team);
        setLowBalanceAmountToNotify(response.data.low_balance_amount);
        setSelectedMemberId(response.data.low_balance_notification_user_id);
        setBlockedBoolean(response.data.blocked_boolean);
        setCardInfo(response.data);
      })
      .catch((response) => {
        console.log('Error is ', response);
      });
  };
  useEffect(() => {
    getPaycaddyCardInfos();
  }, [isBlockingToggle]);

  function downloadAccountStatement() {
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getCardAccountStatement?card_id=' +
        props.selectedCard.id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const gastos_blob = get_account_statement_blob(result.data);
        downloadBlob(gastos_blob, 'Estado de cuenta.csv');
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
  }

  const copyLink = (text) => {
    message.info('Info copiada  ✅', 3);
    navigator.clipboard.writeText(text);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // for How to depositar
  const showModal = (typeOfMovement) => {
    if (typeOfMovement === 'depositMoney') {
      setModalContent({
        title: 'Depositar dinero',
        endPoint: 'depositMoney',
        submitButtonCopy: 'Depositar',
      });
    } else if (typeOfMovement === 'withdrawMoney') {
      setModalContent({
        title: 'Sacar dinero',
        endPoint: 'withdrawMoney',
        submitButtonCopy: 'Sacar',
      });
    } else {
      setModalContent({
        title: 'Cómo depositar dinero',
      });
    }

    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const storeNotificationSettings = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'updateLowBalanceNotification',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        card_db_id: props.selectedCard.id,
        low_balance_amount: lowBalanceAmountToNotify,
        recepient_user_id: selectedMemberId,
      },
    })
      .then((response) => {
        message.success('Configuración guardada');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (values) => {
    setIsLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + modalContent.endPoint,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        card_id: props.selectedCard.id,
        amount: values.amount,
      },
    })
      .then((response) => {
        setIsLoading(false);
        message.success('Operación exitosa');
        getPaycaddyCardInfos();
        props.setRefreshingBalance(true);
        props.onSubmit();
        setIsModalVisible(false);
      })
      .catch((error) => {
        message.error('Ocurrió un error al realizar la operación');
        console.log(error);
      });
  };

  const fetchCardPin = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getPaycaddyCardPin',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        card_id: props.selectedCard.id,
      },
    }).then((response) => {
      setPinCopy(response.data.pin);
    });
  };

  const blockCard = () => {
    setIsBlockingToggle(true); // Assuming you want to show loading state
    axios({
      method: 'post',
      url: props.API_domain + 'blockCard',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        card_id: props.selectedCard.id,
      },
    })
      .then((response) => {
        setIsBlockingToggle(false);
        props.onSubmit();
        message.success('Tarjeta desactivada con éxito');
      })
      .catch((error) => {
        console.log(error);
        setIsBlockingToggle(false);
        message.error('Error al desactivar la tarjeta');
      });
  };

  const unblockCard = () => {
    setIsBlockingToggle(true); // Assuming you want to show loading state
    axios({
      method: 'post',
      url: props.API_domain + 'unblockCard',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        card_id: props.selectedCard.id,
      },
    })
      .then((response) => {
        setIsBlockingToggle(false);
        props.onSubmit();
        message.success('Tarjeta activada con éxito');
        // You might want to refresh the card info or handle UI changes here
      })
      .catch((error) => {
        console.log(error);
        setIsBlockingToggle(false);
        message.error('Error al desactivar la tarjeta');
      });
  };

  return (
    <Modal
      title={<>Tarjeta de {props.selectedCard.name} </>}
      visible={true}
      footer={null}
      width={1100}
      onCancel={props.handleCancel}
      //   bodyStyle={{ height: 'calc(100vh - 420px)', overflow: 'auto' }}
      centered
    >
      <Row style={{ overflow: 'auto' }}>
        <Col span={10}>
          <Row>
            <Col span={21}>
              <Cards
                name={props.selectedCard.name}
                number={cardPan}
                expiry='**/**'
                cvc='***'
              />
            </Col>
            <Col span={1} style={{ textAlign: 'left', paddingTop: 87 }}>
              <Tooltip title='Copiar número de tarjeta'>
                <CopyOutlined
                  style={{ cursor: 'pointer', marginLeft: 8, color: 'grey' }}
                  onClick={() => copyLink(cardPan)}
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <h2 style={{ textAlign: 'left', paddingBottom: 16 }}>
            Tarjeta de débito de {props.selectedCard.name}
          </h2>
          <Row style={{ width: 500 }}>
            <Col span={12} style={{ textAlign: 'left', marginLeft: 0 }}>
              Estado:
            </Col>
            <Col span={12} style={{ textAlign: 'left' }}>
              {blockedBoolean ? (
                <span style={{ color: 'red' }}>Desactivada</span>
              ) : (
                <span style={{ color: 'green' }}>Activada</span>
              )}
            </Col>
          </Row>
          <Row style={{ width: 500 }}>
            <Col span={12} style={{ textAlign: 'left', marginLeft: 0 }}>
              Equipo predeterminado:
            </Col>
            <Col span={12} style={{ textAlign: 'left' }}>
              {cardInfo.default_team}
            </Col>
          </Row>
          <Row style={{ width: 500, marginTop: 16 }}>
            <Col span={12} style={{ textAlign: 'left', marginLeft: 0 }}>
              Disponible:
            </Col>
            <Col span={12} style={{ textAlign: 'left' }}>
              <FormattedUSD total={cardBalance} />
            </Col>
          </Row>
          <Row style={{ width: 500 }}>
            <Col span={12} style={{ textAlign: 'left', marginLeft: 0 }}>
              Gastado este mes:
            </Col>
            <Col span={12} style={{ textAlign: 'left' }}>
              <FormattedUSD total={spentThisMonth} />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <span style={{ paddingTop: 4 }}>Notificar saldo menor a $</span>
            <InputNumber
              value={lowBalanceAmountToNotify}
              style={{ width: 80, height: 32, color: 'black', marginLeft: 4 }}
              placeholder='100.00'
              step='0.01'
              onChange={(value) => setLowBalanceAmountToNotify(value)}
              // disabled={disabledBoolean}
            />
            <span style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 4 }}>
              a:
            </span>
            <span style={{ marginBottom: 10 }}>
              <UserFromATeamSelect
                API_domain={props.API_domain}
                setSelectedMemberId={setSelectedMemberId}
                team={team}
                selectedMemberId={selectedMemberId}
              />
            </span>
            <Button
              style={{ marginLeft: 5 }}
              onClick={storeNotificationSettings}
            >
              Guardar
            </Button>
          </Row>
          <Divider />
          <Row style={{ width: 600 }}>
            {/* <Link to={'/expenses?card=true&user=' + props.selectedCard.name}>
              <Button style={{ border: 'none' }}>
                <UnorderedListOutlined />
                Transacciones
              </Button>
            </Link> */}
            <Button
              style={{ border: 'none' }}
              onClick={() => showModal('depositMoney')}
            >
              <DollarCircleOutlined />
              Depositar
            </Button>
            {props.isAdmin && (
              <Button
                style={{ border: 'none' }}
                onClick={() => showModal('withdrawMoney')}
              >
                <DollarCircleOutlined />
                Sacar
              </Button>
            )}
            <Button style={{ border: 'none' }} onClick={fetchCardPin}>
              <EyeOutlined />
              {pinCopy}
            </Button>
            <Button
              style={{ border: 'none' }}
              onClick={downloadAccountStatement}
            >
              <FileExcelOutlined />
              Estado de cuenta
            </Button>
            {blockedBoolean ? (
              <Tooltip
                placement='bottom'
                title='Inmediatamente se podrá hacer pagos con esta tarjeta.'
              >
                <Button
                  style={{ border: 'none', fontWeight: 500, color: 'green' }}
                  onClick={unblockCard}
                >
                  {isBlockingToggle ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{ color: 'black', marginLeft: 12 }}
                        />
                      }
                    />
                  ) : (
                    <>✅ Activar</>
                  )}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                placement='bottom'
                title='No se podrá hacer pagos con esta tarjeta.'
              >
                <Button
                  style={{ border: 'none', fontWeight: 500, color: 'red' }}
                  onClick={blockCard}
                >
                  {isBlockingToggle ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{ color: 'black', marginLeft: 12 }}
                        />
                      }
                    />
                  ) : (
                    <>
                      <StopOutlined /> Desactivar
                    </>
                  )}
                </Button>
              </Tooltip>
            )}
            <Modal
              title={modalContent.title}
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              width={400}
            >
              {props.isAdmin ? (
                <Form name='basic' onFinish={onSubmit}>
                  <div style={{ marginBottom: 16 }}>
                    Disponible en Wallet:
                    <span style={{ marginLeft: 4, fontWeight: 'bold' }}>
                      <FormattedUSD total={props.companyWalletBalance} />
                    </span>
                  </div>
                  <Form.Item
                    label='Monto'
                    name='amount'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingresa un monto',
                      },
                    ]}
                  >
                    <InputNumber
                      // style={{ width: 200 }}
                      placeholder='100.00'
                      step='0.01'
                      onChange={(value) => setAmount(value)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{ width: '100%' }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              spin
                              style={{ color: 'white', marginLeft: 12 }}
                            />
                          }
                        />
                      ) : (
                        <>
                          <span style={{ marginRight: 3 }}>
                            {modalContent.submitButtonCopy}
                          </span>
                          {amount > 0 && <FormattedUSD total={amount} />}
                        </>
                      )}
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <img
                  src='Como recargar CORRIENTE.png'
                  style={{ width: '100%', borderRadius: '10px' }}
                ></img>
              )}
            </Modal>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

export { CardModal };
