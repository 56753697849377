import React, { useState } from 'react';
import { Modal, Button, Input, Row, Col, message } from 'antd';
import { FormattedUSD } from '../FormattedUSD.js';
import HeroAxios from '../../helpers/HeroAxios.js';

export function CommentModal({ visible, sotTxn, onClose, onSubmit }) {
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmitComment = () => {
    setLoading(true);
    HeroAxios({
      method: 'post',
      url: 'sendCommentNotification',
      data: {
        sotTxn: sotTxn,
        comment: commentText,
      },
    })
      .then(() => {
        setLoading(false);
        setCommentText('');
        message.success('Comentario enviado');
        onSubmit();
      })
      .catch((error) => {
        setLoading(false);
        message.error('Error al enviar comentario');
        console.error('Error submitting comment:', error);
      });
  };

  return (
    <Modal
      visible={visible}
      title='Comentario de transacción'
      width={800}
      onCancel={onClose}
      footer={[
        <Button key='submit' type='primary' onClick={handleSubmitComment} loading={loading}>
          Enviar
        </Button>,
        <Button key='back' onClick={onClose}>
          Cancelar
        </Button>,
      ]}
    >
      <h3>Detalles de transacción:</h3>
      {sotTxn && sotTxn.sotTxnId && (
        <>
          <Row>
            <Col span={6}><strong>Fecha:</strong></Col>
            <Col span={18}>{sotTxn.fechaFormatted}</Col>
          </Row>
          <Row>
            <Col span={6}><strong>Descripcion:</strong></Col>
            <Col span={18}>{sotTxn.Descripcion}</Col>
          </Row>
          <Row>
            <Col span={6}><strong>Monto:</strong></Col>
            <Col span={18}>
              <FormattedUSD total={Math.abs(sotTxn.amount)} />
            </Col>
          </Row>
          <Input.TextArea
            rows={3}
            placeholder='Escribe tu comentario aquí'
            style={{ marginTop: 20 }}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </>
      )}
    </Modal>
  );
}