import { Modal } from 'antd';
import { ConfigRenameCategoryForm } from './ConfigRenameCategoryForm.js';

function ConfigRenameCategoryModal(props) {
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      footer={false}
      onCancel={props.handleCancel}
    >
      <ConfigRenameCategoryForm
        API_domain={props.API_domain}
        API_endPoint={props.API_endPoint}
        handleOk={props.handleOk}
        category={props.category}
      />
    </Modal>
  );
}

export { ConfigRenameCategoryModal };
