import { message, Select, Tag } from 'antd';
import axios from 'axios';
import React, { useContext } from 'react';
import { authContext } from '../../ProvideAuth.js';

function InvoiceStateSelect(props) {
  const auth = useContext(authContext);

  const updateState = (value) => {
    axios({
      method: 'post',
      url: props.API_domain + 'updateInvoiceState',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        secondary_doc_id: props.secondaryDoc.id,
        new_state: value,
      },
    })
      .then(function (response) {
        props.onSubmit();
        message.success('Estado actualizado');
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  const handleChange = (value) => {
    updateState(value);
  };
  return (
    <Select
      defaultValue={props.secondaryDoc.state}
      style={{
        width: 145,
      }}
      className='my-select'
      ellipsis={false}
      onChange={handleChange}
      bordered={false}
      options={[
        {
          value: 'pending',
          label: (
            <Tag
              color={
                props.invoiceStateMapper.find((x) => x.value === 'pending')
                  .color
              }
            >
              {
                props.invoiceStateMapper.find((x) => x.value === 'pending')
                  .title
              }
            </Tag>
          ),
        },
        {
          value: 'approved',
          label: (
            <Tag
              color={
                props.invoiceStateMapper.find((x) => x.value === 'approved')
                  .color
              }
            >
              {
                props.invoiceStateMapper.find((x) => x.value === 'approved')
                  .title
              }
            </Tag>
          ),
        },
        {
          value: 'registered',
          label: (
            <Tag
              color={
                props.invoiceStateMapper.find((x) => x.value === 'registered')
                  .color
              }
            >
              {
                props.invoiceStateMapper.find((x) => x.value === 'registered')
                  .title
              }
            </Tag>
          ),
        },
      ]}
    />
  );
}

export { InvoiceStateSelect };
