import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { authContext } from "../ProvideAuth.js";

function CookieRoute(props) {
  const auth = useContext(authContext);
  auth.signin(() => {
    return false;
  });
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let onboarding = "";
  let endpoint = "expenses";
  params.forEach(function (value, name) {
    if(name == 'onboarding'){
      onboarding = "?onboarding=true"
    }
    if(name == 'endpoint'){
      endpoint = value
    }
  });
  return (
    <Redirect
      to={"/" + endpoint + onboarding}
    />
  );
}

export { CookieRoute };
