import { Select, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function ConfigCreateTeamFromExistingSelector(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState([]);

  function fetchTeamOptions() {
    axios({
      method: 'get',
      url: props.API_domain + 'getTeams',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        let selectOptions = response.data.map((team) => ({
          label: team,
          value: team,
        }));
        setOptions(selectOptions);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchTeamOptions();
  }, []);
  return (
    <>
      <span
        style={{
          padding: 12,
          borderRadius: 4,
        }}
      >
        <Select
          showSearch
          options={options}
          onChange={props.onChange}
          placeholder='Selecciona equipo a copiar'
          style={{ width: '300px' }}
        />
      </span>
    </>
  );
}

export { ConfigCreateTeamFromExistingSelector };
