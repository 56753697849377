import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Select } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function MassEditButtonAndModalForSotTxns(props) {
  const auth = useContext(authContext);
  const [accountSubmitting, setAccountSubmitting] =
    useState(false);
  const [visible, setVisible] = useState(false);
  const [updateTableOnModalClose, setUpdateTableOnModalClose] = useState(false);
  const [form] = Form.useForm();
  const handleOk = () => {
    setVisible(false);
    // props.setSelectedSotTxnsInMassEdit([]);
  };
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    if (updateTableOnModalClose) {
      props.setSubmittedToggle(!props.submittedToggle);
      // props.setSelectedSotTxnsInMassEdit([]);
    }
  };

  const bulkUpdateSotTxnAccount = (new_account_id) => {
    setUpdateTableOnModalClose(true);
    if (!new_account_id) {
      message.error('Por favor seleccione una cuenta contable');
      return;
    }
    setAccountSubmitting(true);
    const data = {
      new_account_id: new_account_id,
      sotTxnsToUpdate: props.selectedSotTxnsInMassEdit
        ? props.selectedSotTxnsInMassEdit.map((x) => x.id)
        : '',
    };
    // console.log(data);
    // setAccountSubmitting(false);
    axios({
      method: 'post',
      url: props.API_domain + 'bulkUpdateSotTxnAccount',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setAccountSubmitting(false);
        message.success('Cuenta contable actualizada exitosamente');
        props.setSelectedSotTxnsInMassEdit([]);
      })
      .catch((response) => {
        console.log(response);
        setAccountSubmitting(false);
        message.error('Error actualizando las cuentas contables');
      });
  };

  return (
    <div>
      {props.selectedSotTxnsInMassEdit &&
        props.selectedSotTxnsInMassEdit.length > 1 && (
          <Button
            onClick={() => {
              setVisible(true);
            }}
          >
            <EditOutlined />
            Editar en masa ({props.selectedSotTxnsInMassEdit.length})
          </Button>
        )}
      <Modal
        title='Editar en masa'
        visible={visible}
        onOk={() => {
          setVisible(false);
          handleOk();
        }}
        onCancel={() => {
          setVisible(false);
          handleCancel();
        }}
        footer={null}
      >
        <Form form={form}>
          <Form.Item
            name='new_account'
            label='Nueva Cuenta Contable:'
            rules={[
              {
                required: true,
                message: 'Por favor seleccione una fecha',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder='Elegir cuenta contable'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                form.setFieldsValue({ new_account: value });
              }}
            >
              {props.accountOptions.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type='primary'
            block
            onClick={() =>
              bulkUpdateSotTxnAccount(
                form.getFieldValue('new_account')
              )
            }
          >
            {accountSubmitting ? (
              <LoadingOutlined />
            ) : (
              <>Actualizar Cuentas Contables</>
            )}
          </Button>
          {/* <Divider />
          <Form.Item label='Categoría' name='category'>
            <CategorySelect
              API_domain={props.API_domain}
              onChange={(value) => {
                form.setFieldsValue({ category: value });
              }}
              defaultCategory={null}
              disabledBoolean={false}
              team={props.team}
            />
          </Form.Item>
          <Button
            onClick={() => updateCategory(form.getFieldValue('category'))}
            type='primary'
            block
          >
            {categorySubmitting ? (
              <LoadingOutlined />
            ) : (
              <>Actualizar Categoría</>
            )}
          </Button>
          <Divider />
          <Form.Item name='team' label='Equipo:'>
            <TeamSelect
              API_domain={props.API_domain}
              onTeamSelect={(value) => {
                form.setFieldsValue({ team: value });
                setSelectedTeam(value);
              }}
              team={selectedTeam || form.getFieldValue('team')}
            />
          </Form.Item>
          <Button
            // onClick={() => updateTeam(form.getFieldValue('team'))}
            onClick={() => {
              updateTeam(form.getFieldValue('team'));
            }}
            type='primary'
            block
          >
            {teamSubmitting ? <LoadingOutlined /> : <>Actualizar Equipo</>}
          </Button>
          <Divider />
          <Button
            type='danger'
            block
            onClick={() => {
              deleteExpenses();
            }}
          >
            {deletionSubmitting ? (
              <LoadingOutlined />
            ) : (
              <>Borrar {props.selectedSotTxnsInMassEdit.length} gastos</>
            )}
          </Button> */}
        </Form>
      </Modal>
    </div>
  );
}

export { MassEditButtonAndModalForSotTxns };
