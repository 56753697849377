import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { ConfigAddExistingMemberSelect } from '../ConfigAddExistingMemberSelect.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminAutomaticReceiptMatcher(props) {
  const auth = useContext(authContext);

  function getUserTeams(value) {
    axios({
      method: 'post',
      url: props.API_domain + 'automaticallyMatchReceipts',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        user_id: value,
      },
    })
      .then(function (response) {
        // var teamsList = response.data.map((team) => team.name);
        // alert(teamsList);

        alert(response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  return (
    <div style={{ width: '200px' }}>
      <ConfigAddExistingMemberSelect
        API_domain={props.API_domain}
        setSelectedMemberId={getUserTeams}
        team={'all'}
      />
    </div>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminAutomaticReceiptMatcher };
