import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import HeroAxios from '../../helpers/HeroAxios.js';
import './../styles.css';

ChartJS.register(
  LineElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartDataLabels // Register the plugin
);

const getCSSVariable = (variable) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

function SalesLineChart(props) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    HeroAxios.post('getAccoutingAccountBalaceForEachOfLast30days', {
      accountingClientId: props.accountingClientId,
    })
      .then((response) => {
        // Process the response data
        const labels = response.data.map((item) => {
          const date = new Date(item.date);
          const options = { month: 'short', day: 'numeric' };
          return date.toLocaleDateString('es-ES', options);
        });
        const data = response.data.map((item) => item.balance);

        const borderColor = getCSSVariable('--purple-light');
        const backgroundColor = getCSSVariable('--purple-light');
        const pointBackgroundColor = getCSSVariable('--purple-normal');
        const pointBorderColor = getCSSVariable('--purple-normal');
        const hoverBackgroundColor = getCSSVariable('--purple-dark');

        setChartData({
          labels,
          datasets: [
            {
              data,
              borderColor: borderColor,
              backgroundColor: backgroundColor,
              fill: true,
              tension: 0.4,
              pointBackgroundColor: pointBackgroundColor,
              pointBorderColor: pointBorderColor,
              hoverBackgroundColor: hoverBackgroundColor,
            },
          ],
        });
      })
      .catch((error) => {
        console.error(error);
        setChartData({
          labels: [],
          datasets: [],
        });
      });
  }, []);

  // if (chartData.labels.length === 0) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className='infographic-card' style={{ position: 'relative' }}>
      <div style={{ opacity: 0.16 }}>
        <h2 style={{ color: 'var(--purple-darkest)' }}>Ventas</h2>
        <Line
          data={chartData}
          options={{
            layout: {
              padding: {
                top: 8, // Add padding to the top
                bottom: 8, // Add padding to the bottom
              },
            },
            plugins: {
              legend: {
                display: false, // Disable the legend
              },
              datalabels: {
                anchor: 'end', // Positions the label away from the point
                align: 'top', // Aligns the label above the point
                offset: 0, // Adjust the distance of the label from the point
                formatter: (value) => `$${value}`, // Format the label as USD
                color: getCSSVariable('--purple-darkest'),
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    // Format the tooltip label as USD
                    const value = tooltipItem.raw;
                    return `$${value.toFixed(2)}`;
                  },
                },
              },
            },
            elements: {
              point: {
                radius: 5,
                hoverRadius: 7,
                pointStyle: 'circle',
                hitRadius: 10,
                hoverBorderWidth: 2,
              },
            },
            scales: {
              x: {
                beginAtZero: false,
                ticks: {
                  padding: 8, // Add padding to the x-axis labels
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  padding: 8, // Add padding to the y-axis labels
                  callback: function (value) {
                    return `$${value.toLocaleString()}`; // Format y-axis labels as USD with commas
                  },
                },
                // suggestedMax: Math.max(...chartData.datasets[0].data) + 100, // Add padding to the top of y-axis
              },
            },
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'rgba(128, 128, 128, 0.9)',
          fontSize: '18px',
          fontWeight: 'bold',
          pointerEvents: 'none',
        }}
      >
        Coming soon ⚡🙂
      </div>
    </div>
  );
}

export { SalesLineChart };
