import { useContext } from 'react';
import { AccountingClientSelect } from '../Accounting/AccountingClientSelect.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';

function AdminGetAccountingClientUserLoginLink(props) {
  const auth = useContext(authContext);

  function getUserLoginLink(value) {
    axios({
      method: 'post',
      url: props.API_domain + 'adminGetAccountingClientUserLoginLink',
      auth: {
        username: auth.adminEmail ? auth.adminEmail : auth.email,
        password: auth.adminToken ? auth.adminToken : auth.token,
      },
      data: {
        accounting_client_id: value,
      },
    })
      .then(function (response) {
        let loginLink = response.data;
        const currentPath = window.location.pathname; // Get the current path
        const endpoint = currentPath.split('/').pop(); // Extract the last part of the path

        // Append the endpoint to the loginLink
        loginLink += `&endpoint=${endpoint}`;
        window.location.href = loginLink;
        // const newWindow = window.open(
        //   loginLink,
        //   '_blank',
        //   'noopener,noreferrer'
        // );

        // // Make it incognito by setting the newWindow to null
        // if (newWindow) newWindow.opener = null;
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        console.log(response);
      });
  }

  return (
    <AccountingClientSelect
      API_domain={props.API_domain}
      onAccountingClient={getUserLoginLink}
      noPadding={true}
      noWidth={true}
      placement='topLeft'
    />
  );
}

export { AdminGetAccountingClientUserLoginLink };
