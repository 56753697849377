import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, message } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { useAnalytics } from 'use-analytics';

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

function ConfigNewCategoryForm(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const { track } = useAnalytics();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      categoryName: values.categoryName,
      team: props.team,
    };
    // track('addCategory', {
    //   API_domain: props.API_domain,
    //   email: auth.email,
    // });
    axios({
      method: 'post',
      url: props.API_domain + 'createCategory',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.setCategoryCreated(true);
        props.handleOk();
        // clear form
        form.resetFields();
        message.success('Categoría creada exitosamente');
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        console.log(response);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        {...layout}
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name='categoryName'
          label='Nombre de categoría:'
          rules={[
            {
              required: true,
              message: 'Por favor escriba el nombre de la categoría nueva',
            },
          ]}
        >
          <Input placeholder='Aseo' />
        </Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          block
          style={{ fontWeight: 500 }}
        >
          {isSubmitting ? <Spin indicator={antIcon} /> : <>Agregar Categoría</>}
        </Button>
      </Form>
    </>
  );
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigNewCategoryForm };
