import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './../styles.css';

ChartJS.register(
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

const getCSSVariable = (variable) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

function ProfitBarChart(props) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const incomeStatementData = props.incomeStatementData;

    // Extract Income, Expenses, and Net Income categories
    const incomeCategory = incomeStatementData.find(
      (item) => item.category === 'Income'
    );
    const expensesCategory = incomeStatementData.find(
      (item) => item.category === 'Expenses'
    );
    const netIncomeCategory = incomeStatementData.find(
      (item) => item.category === 'Net Income'
    );

    const incomeData = incomeCategory ? incomeCategory.values : [];
    const expensesData = expensesCategory
      ? expensesCategory.values.map((val) => val * -1)
      : [];
    const netData = netIncomeCategory ? netIncomeCategory.values : [];
    const backgroundColorSales = getCSSVariable('--green-light');
    const backgroundColorExpenses = getCSSVariable('--red-light');
    const backgroundColorProfit = getCSSVariable('--purple-normal');

    setChartData({
      labels: props.incomeStatementRecentTimePeriods,
      datasets: [
        {
          label: 'Ingresos',
          data: incomeData,
          backgroundColor: backgroundColorSales,
          stack: 'stack1',
          datalabels: {
            display: false,
          },
          borderRadius: 2,
        },
        {
          label: 'Gastos',
          data: expensesData,
          backgroundColor: backgroundColorExpenses,
          stack: 'stack1',
          datalabels: {
            display: false,
          },
          borderRadius: 2,
        },
        {
          label: 'Ganancia Neta',
          data: netData,
          backgroundColor: backgroundColorProfit,
          stack: 'stack2',
          datalabels: {
            display: true,
            color: 'black',
            font: {
              size: 12,
            },
            anchor: 'end',
            align: 'top',
            formatter: (value) => `$${value}`,
          },
          datalabels: {
            display: false,
          },
          borderRadius: 2,
        },
      ],
    });
  }, [props.incomeStatementData]);

  return (
    <div className='infographic-card' style={{ position: 'relative' }}>
      <div style={{ opacity: 0.16 }}>
        <h2 style={{ color: getCSSVariable('--purple-darkest') }}>
          Ganancias Mensuales
        </h2>
        <Bar
          data={chartData}
          options={{
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value) {
                    return `$${value.toLocaleString()}`; // Format y-axis labels as USD with commas
                  },
                },
              },
              x: {
                stacked: true,
              },
            },
            plugins: {
              datalabels: {
                display: false,
              },
            },
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'rgba(128, 128, 128, 0.9)',
          fontSize: '18px',
          fontWeight: 'bold',
          pointerEvents: 'none',
        }}
      >
        Coming soon ⚡🙂
      </div>
    </div>
  );
}

export { ProfitBarChart };
