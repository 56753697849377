import React from 'react';
import { Card, Typography, Tabs } from 'antd';
import styled from 'styled-components/macro';
import { ConfigObligationsTable } from './ConfigObligationsTable';
import { ConfigCompaniesTable } from './ConfigCompaniesTable';
import { ConfigClientConfigsTable } from './ConfigClientConfigsTable';
import { ConfigAccountingClientsInfoTable } from './ConfigAccountingClientsInfoTable';
import CeleryScheduleViewer from './Admin/RunnerViews/CelerySchedulerViewer';

const { Title } = Typography;
const { TabPane } = Tabs;

function AccountingClientConfigsView() {
  return (
    <CardContainer>
      <Card>
        <StyledTitle level={3}>Administración de Clientes</StyledTitle>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Obligations" key="1">
            <ConfigObligationsTable />
          </TabPane>
          <TabPane tab="Companies" key="2">
            <ConfigCompaniesTable />
          </TabPane>
          <TabPane tab="Client Configs" key="3">
            <ConfigClientConfigsTable />
          </TabPane>
          <TabPane tab="Accounting Client Info" key="4">
            <ConfigAccountingClientsInfoTable />
          </TabPane>
          <TabPane tab="Runners" key="5">
            <CeleryScheduleViewer />
          </TabPane>
        </Tabs>
      </Card>
    </CardContainer>
  );
}

const CardContainer = styled.div`
  margin: 20px;
  padding: 20px;
`;

const StyledTitle = styled(Title)`
  text-align: left;
  margin-bottom: 20px;
`;

export { AccountingClientConfigsView };
