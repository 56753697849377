import { Modal } from 'antd';
import { ConfigEditUserNamePhoneEmailForm } from './ConfigEditUserNamePhoneEmailForm.js';
import { ConfigCreateNewUserForm } from './ConfigCreateNewUserForm.js';

function ConfigUserModal(props) {
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      footer={false}
      onCancel={props.handleCancel}
      width={550}
    >
      {props.API_endPoint === 'createNewUser' ? (
        <ConfigCreateNewUserForm
          API_domain={props.API_domain}
          onSubmit={props.onSubmit}
          handleOk={props.handleOk}
          isOwner={props.isOwner}
          teamOptions={props.teamOptions}
          subscriptionId={props.subscriptionId}
          quantityOfAvailableUsers={props.quantityOfAvailableUsers}
        />
      ) : (
        <ConfigEditUserNamePhoneEmailForm
          API_domain={props.API_domain}
          API_endPoint={props.API_endPoint}
          onSubmit={props.onSubmit}
          handleOk={props.handleOk}
          member={props.member}
          team={props.team}
          isOwner={props.isOwner}
          subscriptionId={props.subscriptionId}
        />
      )}
    </Modal>
  );
}

export { ConfigUserModal };
