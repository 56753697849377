import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Affix, Badge, Button, Col, Layout, Row, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from '../TeamSelect.js';
import { Refresh } from '../Refresh.js';
import { FacturasTable } from './FacturasTable.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { InvoicesNewModal } from './InvoicesNewModal.js';
import { InvoiceModal } from './InvoiceModal.js';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

function FacturasView(props) {
  const [isLoaded, setLoaded] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [showNewInvoiceModal, setShowNewInvoiceModal] = useState(false);

  const location = useLocation();
  const assigneeFilter = new URLSearchParams(location.search).get('assigneeFilter')
      ? new URLSearchParams(location.search).get('assigneeFilter')
      : [];

  const onSubmit = () => {
    setShowNewInvoiceModal(false);
    setSubmittedToggle(!submittedToggle);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const showModalParam = params.get('showNewInvoiceModal');
    setShowNewInvoiceModal(showModalParam === 'true');
  }, [location.search]);

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <InvoicesNewModal
        showNewInvoiceModal={showNewInvoiceModal}
        setShowNewInvoiceModal={setShowNewInvoiceModal}
        handleOk={onSubmit}
        API_domain={props.API_domain}
      />
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Refresh onClick={onSubmit} spin={!isLoaded} />
              <TeamSelect
                API_domain={props.API_domain}
                onTeamSelect={props.onTeamSelect}
                team={props.team}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <ButtonDiv>
                <Button
                  type='primary'
                  onClick={() => setShowNewInvoiceModal(true)}
                >
                  <ButtonSpan>
                    <>
                      <PlusOutlined /> Crear Factura
                    </>
                  </ButtonSpan>
                </Button>
              </ButtonDiv>
            </Col>
          </Row>
          {selectedInvoice && (
            <InvoiceModal
              API_domain={props.API_domain}
              invoice={selectedInvoice}
              submittedToggle={submittedToggle}
              setSubmittedToggle={setSubmittedToggle}
              setLoaded={setLoaded}
              setSelectedInvoice={setSelectedInvoice}
              handleOk={() => {
                setSelectedInvoice(null);
                setLoaded(false);
                setSubmittedToggle(!submittedToggle);
              }}
            />
          )}
        </AffixDiv>
      </Affix>
      <BeautifulDiv>
        <FacturasTable
          API_domain={props.API_domain}
          isLoaded={isLoaded}
          setLoaded={setLoaded}
          submittedToggle={submittedToggle}
          assigneeFilter={assigneeFilter}
          openModal={setSelectedInvoice}
          team={props.team}
        />
      </BeautifulDiv>
    </Content>
  );
}
const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 15px;
`;
const ButtonSpan = styled.span`
  font-weight: 500;
`;
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;
const BeautifulDiv = styled.div`
  width: min(calc(100vw - 300px), 1200px);
  margin: auto;
`;

export { FacturasView };
