import { CreditCardOutlined, WarningTwoTone } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from 'react';
import {
  Image,
  Modal,
  Table,
  Tabs,
  Tag,
  Tooltip as ANTDTooltip,
  Button,
} from 'antd';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import { VisualizationsBarChart } from './VisualizationsBarChart.js';
import { FormattedUSD } from './FormattedUSD.js';
import styled from 'styled-components/macro';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { tenColorArray, get_last_12_months_strings } from '../utils.js';
import { twentyTwoColorArray } from '../utils.js';
import { fourtySixColorArray } from '../utils.js';
import { VisualizationsTable } from './VisualizationsTable.js';
import { useHistory } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const months = get_last_12_months_strings();
const { TabPane } = Tabs;

let backgroundColorArray = [];
let borderColorArray = [];

function VisualizationsComponents(props) {
  const [chartObj, setChartObj] = useState({});
  const auth = useContext(authContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [datasetLabel, setDatasetLabel] = useState(null);
  const [monthLabel, setMonthLabel] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();

  const colors = ['orange', 'green', 'cyan', 'blue', 'purple'];
  let category_to_color = {};
  let counter = 0;
  let index = 1;
  items.forEach(function (item) {
    item.index = index;
    index += 1;
    if (!(item.category in category_to_color)) {
      category_to_color[item.category] = colors[counter];
      counter += 1;
    }
    if (item.fecha) {
      item.fechaFormatted = new Intl.DateTimeFormat('es-US', {
        dateStyle: 'medium',
      }).format(
        new Date(
          item.fecha.split('/')[0],
          item.fecha.split('/')[1] - 1,
          item.fecha.split('/')[2]
        )
      );
    }
    item.fechaFormattedShort = new Intl.DateTimeFormat('es-US', {
      day: 'numeric',
      month: 'short',
    }).format(
      new Date(
        item.fecha.split('/')[0],
        item.fecha.split('/')[1] - 1,
        item.fecha.split('/')[2]
      )
    );
  });

  let gastosColumns = [];
  gastosColumns = [
    // DESKTOP)
    // {
    //   title: '',
    //   dataIndex: 'index',
    //   key: 'id',
    //   width: 35,
    // },
    {
      dataIndex: 'card_transaction',
      key: 'card_transaction',
      width: 30,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) =>
        record.card_transaction ? (
          <ANTDTooltip placement='right' title='Pagado con tarjeta'>
            <CreditCardOutlined style={{ fontSize: '12px', color: 'grey' }} />
          </ANTDTooltip>
        ) : (
          ' '
        ),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ellipsis: {
        showTitle: false,
      },
      render: (expenseName, record) =>
        expenseName == 'SIN DESCRIPCION' ? (
          <ANTDTooltip placement='topLeft' title={'SIN DESCRIPCION'}>
            <span style={{ color: 'gray' }}>
              {'SIN DESCRIPCION'}
              <i>{record.provider ? ' - ' + record.provider : ''} </i>
            </span>
          </ANTDTooltip>
        ) : (
          <ANTDTooltip placement='topLeft' title={expenseName}>
            {expenseName}
          </ANTDTooltip>
        ),
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      width: 180,
      ellipsis: true,
      render: (category) => {
        if (category == null) {
          return <Tag>N/A</Tag>;
        }
        return <Tag color={category_to_color[category]}>{category}</Tag>;
      },
    },
    {
      title: 'Gastante',
      dataIndex: 'gastante',
      key: 'gastante',
      width: 160,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fecha',
      width: 120,
    },
    {
      title: 'Impuesto',
      dataIndex: 'itbms',
      key: 'itbms',
      align: 'right',
      width: 100,
      render: (itbms) => {
        return <FormattedUSD total={itbms} />;
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 120,
      render: (total) => {
        return <FormattedUSD total={total} />;
      },
    },
    // {
    //   title: 'Factura',
    //   dataIndex: 'factura',
    //   key: 'factura',
    //   align: 'center',
    //   width: 80,
    //   render: (blank, record) => {
    //     return <ImgOrPDF record={record} />;
    //   },
    // },
    {
      dataIndex: ['duplicate'],
      key: 'duplicate',
      align: 'center',
      width: 25,
      render: (id, expense) => {
        return expense.duplicate ? (
          <ANTDTooltip
            placement='topLeft'
            title={
              <>
                {'Posible factura duplicada. Presente en:'}
                {expense.duplicate_locations_list.map((location, index) => (
                  <div key={index}>
                    {index + 1}. {location}
                  </div>
                ))}
              </>
            }
          >
            <WarningTwoTone twoToneColor='#ffcc00' />
          </ANTDTooltip>
        ) : (
          ''
        );
      },
    },
  ];

  const showModal = (datasetLabel, monthLabel) => {
    setDatasetLabel(datasetLabel);
    setMonthLabel(monthLabel);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setDatasetLabel(null);
    setMonthLabel(null);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (datasetLabel && monthLabel) {
      getExpenses(datasetLabel, monthLabel);
    }
  }, [datasetLabel, monthLabel]);

  const getExpenses = (datasetLabel, monthLabel) => {
    axios({
      method: 'post',
      url: `${props.API_domain}getExpensesByCell`,
      data: {
        category: datasetLabel,
        month: monthLabel,
        team: props.team,
      },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setIsLoaded(true);
        setItems(response.data);
      })
      .catch((response) => {
        setIsLoaded(true);
        setError(true);
        console.error('Error:', response); // Debugging line
      });
  };

  function fetchVisualizationData() {
    axios({
      method: 'post',
      url: props.API_domain + 'getVisualizationLineChartData',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { team: props.team },
    })
      .then((response) => {
        let chart_obj = {};
        let labels = months.slice(-1 * response.data['length']);
        let datasets = [];
        if (response.data.data.length < 11) {
          backgroundColorArray = tenColorArray;
          borderColorArray = tenColorArray;
        } else if (response.data.data.length < 23) {
          backgroundColorArray = twentyTwoColorArray;
          borderColorArray = twentyTwoColorArray;
        } else {
          backgroundColorArray = fourtySixColorArray;
          borderColorArray = fourtySixColorArray;
        }
        response.data['data'].forEach(([key, value], index) => {
          datasets.push({
            label: key,
            data: value,
            borderColor: borderColorArray[index],
            backgroundColor: backgroundColorArray[index],
          });
        });

        chart_obj = {
          labels: labels,
          datasets: datasets,
        };
        setChartObj(chart_obj);
      })
      .catch(function (response) {
        props.setVisualizationsEmpty(true);
        console.log(response);
      });
  }

  useEffect(() => {
    fetchVisualizationData();
  }, [props.team]);
  return (
    <>
      {Object.keys(chartObj).length == 0 ? (
        <> </>
      ) : (
        <>
          <Modal
            // title='Desglose'
            visible={isModalVisible}
            onCancel={closeModal}
            onOk={closeModal}
            width={1400}
            loading={!isLoaded}
            centered
            footer={
              <div style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    history.push(
                      '/expenses?all=true&category=' +
                        datasetLabel +
                        '&month=' +
                        monthLabel.split(' ')[0]
                    );
                  }}
                >
                  Ver detalles en tabla de Gastos
                </Button>
              </div>
            }
          >
            <>
              <Table
                columns={gastosColumns}
                dataSource={items}
                pagination={false}
                size='middle'
                scroll={{ y: 'overflow' }}
                loading={!isLoaded}
                // rowClassName={(record, index) => {
                //   return 'cursor-pointer';
                // }}
                // id='table-to-print'
              />
            </>
          </Modal>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxWidth: '90%', // Optional, you can adjust this to control the component width
              }}
            >
              <h3 style={{ padding: 0, margin: 0 }}>{props.team}</h3>
              <div style={{ flex: 1 }}>
                <Tabs centered defaultActiveKey='1' style={{ height: '100%' }}>
                  <TabPane tab='Barras' key='1'>
                    <div style={{ height: '70vh' }}>
                      <VisualizationsBarChart
                        chartObj={chartObj}
                        showModal={showModal}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab='Tabla' key='2'>
                    <div>
                      <VisualizationsTable
                        chartObj={chartObj}
                        showModal={showModal}
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export { VisualizationsComponents };
