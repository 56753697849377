import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Spin, Row, Col } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

function AdminPayinHistory(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [payinHistory, setPayinHistory] = useState([]);
  const [payoutHistory, setPayoutHistory] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [canalTotal, setCanalTotal] = useState(0);
  const [mercantilTotal, setMercantilTotal] = useState(0);
  const auth = useContext(authContext);

  const onFinish = () => {
    setSubmitting(true);
    axios({
      method: 'get',
      url: props.API_domain + 'adminPayinHistory',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setPayinHistory(response.data.payins);
        setPayoutHistory(response.data.payouts);
        setModalVisible(true);
        setSubmitting(false);
      })
      .catch((error) => {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCheckboxChange = (isChecked, amount, isCanal) => {
    if (isChecked) {
      if (isCanal) {
        setCanalTotal(canalTotal + amount);
      } else {
        setMercantilTotal(mercantilTotal + amount);
      }
    } else {
      if (isCanal) {
        setCanalTotal(canalTotal - amount);
      } else {
        setMercantilTotal(mercantilTotal - amount);
      }
    }
  };

  const filteredPayins = payinHistory
    ? payinHistory.filter((item) =>
        item.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.amount.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const filteredPayouts = payoutHistory
    ? payoutHistory.filter((item) =>
        item.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.amount.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <>
      <Button type='secondary' onClick={onFinish} loading={isSubmitting}>
        <ButtonSpan>Fetch Payin History</ButtonSpan>
      </Button>
      <Modal
        title='Admin Payin and Payout History'
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={700}
      >
        {isSubmitting ? (
          <Spin />
        ) : (
          <div>
            <div>
              <h4>Canal Total: ${canalTotal.toFixed(2)}</h4>
              <h4>Mercantil Total: ${mercantilTotal.toFixed(2)}</h4>
            </div>
            <Input
              placeholder='Search Payin History'
              value={searchQuery}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
            />
            <h3>Payins</h3>
            {filteredPayins.length > 0 ? (
              <ul>
                {filteredPayins.map((item, index) => (
                  <li key={index}>
                    <Row align="middle">
                      <Col span={16}>
                        <span>{item.date} - {item.amount} - {item.user_name}</span>
                      </Col>
                      <Col span={4}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxChange(e.target.checked, item.amount_value, true)
                          }
                        >
                          Canal
                        </Checkbox>
                      </Col>
                      <Col span={4}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxChange(e.target.checked, item.amount_value, false)
                          }
                        >
                          Mercantil
                        </Checkbox>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No data available</p>
            )}

            <h3>Payouts</h3>
            {filteredPayouts.length > 0 ? (
              <ul>
                {filteredPayouts.map((item, index) => (
                  <li key={index}>
                    <Row align="middle">
                      <Col span={16}>
                        <span>{item.date} - {item.amount} - {item.user_name}</span>
                      </Col>
                      <Col span={4}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxChange(e.target.checked, item.amount_value, true)
                          }
                        >
                          Canal
                        </Checkbox>
                      </Col>
                      <Col span={4}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxChange(e.target.checked, item.amount_value, false)
                          }
                        >
                          Mercantil
                        </Checkbox>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No data available</p>
            )}
          </div>
        )}
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminPayinHistory };
