import { Avatar, Button, Col, Modal, Row } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState, useContext } from 'react';
import { SecondaryDocModal } from './SecondaryDocModal.js';
import { OrderTable } from './OrderTable.js';
// import { authContext } from '../../ProvideAuth.js';

function SharedBoxModal(props) {
  const [selectedSecondaryDoc, setSelectedSecondaryDoc] = useState();
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);

  return (
    <>
      {selectedSecondaryDoc && (
        <SecondaryDocModal
          API_domain={props.API_domain}
          visible={true}
          selectedSecondaryDoc={selectedSecondaryDoc}
          setRefreshToggle={setRefreshToggle}
          hideModal={() => {
            setSelectedSecondaryDoc(null);
            if (refreshToggle) {
              setSubmittedToggle(!submittedToggle);
            }
          }}
          //   invoiceStateMapper={props.invoiceStateMapper}
        />
      )}
      <Modal
        visible={true}
        // onCancel={() => {
        //   if (refreshToggle) {
        //     props.setSelectedOrder(null);
        //     props.setLoaded(false);
        //     props.setSubmittedToggle(!props.submittedToggle);
        //   } else {
        //     props.setSelectedOrder(null);
        //   }
        // }}
        onCancel={props.handleCancel}
        // onOk={props.handleOk}
        width={1200}
        centered
        // style={{ paddingBottom: 0 }}
        bodyStyle={{
          height: 'calc(100vh - 150px)',
          overflow: 'auto',
          paddingTop: 5,
          paddingBottom: 5,
        }}
        title={
          <Row>
            <Col span={12}>
              <div>
                {props.order.number}
                {/* <Button
                  style={{ border: 'none', color: 'grey' }}
                  onClick={() => {
                    setRenameOrder(true);
                  }}
                >
                  <EditOutlined />
                </Button> */}
              </div>

              <span style={{ color: 'grey', fontSize: '14px' }}>
                {props.order.team}
              </span>
            </Col>

            <Col span={12}></Col>
          </Row>
        }
        // footer={
        //   <>
        //     <Row>
        //       <Col span={8}></Col>
        //       <Col span={8} style={{ textAlign: 'center' }}>
        //         <Button
        //           type='primary'
        //           style={{
        //             marginLeft: 10,
        //             backgroundColor: 'rgb(26,190,43)',
        //             border: 'rgb(26,190,43)',
        //           }}
        //         >
        //           <a
        //             target='_blank'
        //             href='https://api.whatsapp.com/send?phone=50766181519&text=Quiero%20probar%20Hero!'
        //           >
        //             Quiero probar Hero
        //           </a>
        //         </Button>
        //       </Col>
        //       <Col span={8} style={{ textAlign: 'right' }}>
        //         {/* {props.mobile ? (
        //             <></>
        //           ) : (
        //             <Dropdown overlay={menu} placement='bottomRight' arrow>
        //               <Button>Descargar</Button>
        //             </Dropdown>
        //           )} */}
        //       </Col>
        //     </Row>
        //   </>
        // }
        footer={null}
      >
        <Row style={{ height: '100%' }}>
          {/* <Col span={16} style={{ overflow: 'auto', height: '100%' }}> */}
          <OrderTable
            submittedToggle={submittedToggle}
            API_domain={props.API_domain}
            orderId={props.order.id}
            setSelectedSecondaryDoc={setSelectedSecondaryDoc}
            invoiceStateMapper={props.invoiceStateMapper}
          />
          {/* </Col>

          <Col
            span={8}
            style={{
              overflow: 'auto',
              padding: 8,
              height: '100%',
              backgroundColor: 'rgb(245, 245, 245)',
              borderRadius: 8,
            }}
          >
            <h3 style={{ color: 'grey', padding: 3, paddingBottom: 6 }}>
              Chat
            </h3>
            <OrderChat
              API_domain={props.API_domain}
              order={props.order}
              invoiceStateMapper={props.invoiceStateMapper}
              submittedToggle={submittedToggle}
            />
          </Col> */}
        </Row>
      </Modal>
    </>
  );
}

export { SharedBoxModal };
