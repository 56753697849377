import React, { useContext, useState } from 'react';
import {
  Form,
  Input,
  message,
  Button,
  Card,
  Tag,
  Divider,
  Tooltip,
} from 'antd';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';

import {
  CopyOutlined,
  PlusOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SaveOutlined,
  CloseOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import '../styles.css';

const InvuIntegration = ({
  invuCredentials,
  copyToClipboard,
  accountingClientId,
  API_Domain,
}) => {
  const [credentials, setCredentials] = useState(
    invuCredentials.length > 0
      ? invuCredentials.map((cred) => ({
          id: cred.id,
          username: cred.login_info.username || '',
          password: cred.login_info.password || '',
          verificationStatus: null,
          showPassword: false,
          isDirty: false,
          isNew: false,
          original: { ...cred.login_info },
          savedMessage: null,
        }))
      : []
  );
  const auth = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState({});
  const [isAddingNew, setIsAddingNew] = useState(false);

  const handleInputChange = (index, field, value) => {
    const newCredentials = [...credentials];
    newCredentials[index] = {
      ...newCredentials[index],
      [field]: value,
      isDirty: true,
      savedMessage: null,
    };
    setCredentials(newCredentials);
  };

  const handleSave = async (index) => {
    setLoading(true);
    try {
      const credToUpdate = credentials[index];
      const updatedCredentials = {
        accounting_client_id: accountingClientId,
        id: credToUpdate.id,
        site: 'invu',
        login_info: {
          username: credToUpdate.username,
          password: credToUpdate.password,
        },
      };

      const response = await axios.post(
        `${API_Domain}updateAccountingClientConfig`,
        updatedCredentials,
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );

      message.success('Actualización exitosa');
      setCredentials((prev) => {
        const newCreds = [...prev];
        newCreds[index] = {
          ...newCreds[index],
          id: response.data.config_id,
          isDirty: false,
          isNew: false,
          original: {
            username: credToUpdate.username,
            password: credToUpdate.password,
          },
          savedMessage: 'Guardado exitosamente',
        };
        return newCreds;
      });
      setTimeout(() => {
        setCredentials((prev) => {
          const newCreds = [...prev];
          newCreds[index] = { ...newCreds[index], savedMessage: null };
          return newCreds;
        });
      }, 3000);
      setIsAddingNew(false);
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error actualizando');
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (index) => {
    setVerifying((prev) => ({ ...prev, [index]: true }));
    try {
      const credToVerify = credentials[index];
      const response = await axios.post(
        `${API_Domain}invu/verify-credentials`,
        {
          accounting_client_id: accountingClientId,
          username: credToVerify.username,
          password: credToVerify.password,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );

      if (response.data.success) {
        setCredentials((prev) => {
          const newCreds = [...prev];
          newCreds[index] = {
            ...newCreds[index],
            verificationStatus: 'success',
            validationMessage: response.data.message,
          };
          return newCreds;
        });
        message.success('Verificación exitosa');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error during verification:', error);
      setCredentials((prev) => {
        const newCreds = [...prev];
        newCreds[index] = {
          ...newCreds[index],
          verificationStatus: 'error',
          validationMessage:
            error.response?.data?.message ||
            error.message ||
            'Error durante la verificación',
        };
        return newCreds;
      });
      message.error('Error de verificación');
    } finally {
      setVerifying((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleAddNew = () => {
    if (!isAddingNew) {
      setCredentials((prev) => [
        ...prev,
        {
          id: 'new',
          username: '',
          password: '',
          verificationStatus: null,
          showPassword: false,
          isDirty: true, // Set to true for new rows
          isNew: true,
          original: {},
          savedMessage: null,
        },
      ]);
      setIsAddingNew(true);
    }
  };

  const handleCancel = (index) => {
    if (credentials[index].isNew) {
      handleDeleteNew(index);
    } else {
      setCredentials((prev) => {
        const newCreds = [...prev];
        newCreds[index] = {
          ...newCreds[index],
          username: newCreds[index].original.username,
          password: newCreds[index].original.password,
          isDirty: false,
          showPassword: false,
        };
        return newCreds;
      });
    }
  };

  const handleDeleteNew = (index) => {
    setCredentials((prev) => prev.filter((_, i) => i !== index));
    setIsAddingNew(false);
  };

  const togglePasswordVisibility = (index) => {
    setCredentials((prev) => {
      const newCreds = [...prev];
      newCreds[index] = {
        ...newCreds[index],
        showPassword: !newCreds[index].showPassword,
      };
      return newCreds;
    });
  };

  const renderCredentialForm = (cred, index) => (
    <Card
      key={cred.id}
      size='small'
      bordered={false}
      style={{ width: '80%', maxWidth: 600, marginLeft: 60, marginBottom: 16 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color:
                cred.verificationStatus === 'success'
                  ? 'green'
                  : cred.verificationStatus === 'error'
                  ? 'red'
                  : undefined,
            }}
          >
            <CheckCircleOutlined /> Invu Config #{index + 1}
          </span>
        </div>
      }
    >
      <Form layout='vertical'>
        <Form.Item label='Username'>
          <Input
            value={cred.username}
            onChange={(e) =>
              handleInputChange(index, 'username', e.target.value)
            }
            suffix={
              <Tooltip title='Copy username'>
                <Button
                  icon={<CopyOutlined />}
                  type='text'
                  onClick={() => copyToClipboard(cred.username)}
                />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item label='Contraseña'>
          <Input
            value={cred.password}
            onChange={(e) =>
              handleInputChange(index, 'password', e.target.value)
            }
            type={cred.showPassword ? 'text' : 'password'}
            suffix={
              <>
                <Tooltip
                  title={cred.showPassword ? 'Hide Password' : 'Show Password'}
                >
                  <Button
                    icon={
                      cred.showPassword ? (
                        <EyeOutlined />
                      ) : (
                        <EyeInvisibleOutlined />
                      )
                    }
                    type='text'
                    onClick={() => togglePasswordVisibility(index)}
                  />
                </Tooltip>
                <Tooltip title='Copy password'>
                  <Button
                    icon={<CopyOutlined />}
                    type='text'
                    onClick={() => copyToClipboard(cred.password)}
                  />
                </Tooltip>
              </>
            }
          />
        </Form.Item>
        {cred.verificationStatus && (
          <div
            style={{
              color: cred.verificationStatus === 'success' ? 'green' : 'red',
              marginBottom: 10,
            }}
          >
            {cred.validationMessage}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <Tooltip title='Save changes'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => handleSave(index)}
              loading={loading}
              disabled={!cred.isDirty}
              className='animated-button'
            >
              Guardar
            </Button>
          </Tooltip>
          {(cred.isDirty || cred.isNew) && (
            <Tooltip title='Cancel'>
              <Button
                icon={<CloseOutlined />}
                onClick={() => handleCancel(index)}
                className='animated-button'
              >
                Cancelar
              </Button>
            </Tooltip>
          )}
          <Tooltip title='Validate credentials'>
            <Button
              icon={<CheckCircleOutlined />}
              onClick={() => handleVerify(index)}
              loading={verifying[index]}
              className='animated-button'
            >
              Validar
            </Button>
          </Tooltip>
          {cred.savedMessage && (
            <span style={{ marginLeft: '10px', color: 'green' }}>
              {cred.savedMessage}
            </span>
          )}
        </div>
      </Form>
    </Card>
  );

  return (
    <div style={{ marginBottom: 32 }}>
      <h3 style={{ marginLeft: 4, textAlign: 'left', marginTop: 30 }}>
        INVU
        {credentials.length === 0 && (
          <Tag color='yellow' style={{ marginLeft: 8 }}>
            NO CONFIGURADO
          </Tag>
        )}
      </h3>
      {credentials.map((cred, index) => renderCredentialForm(cred, index))}
      {!isAddingNew && (
        <div style={{ textAlign: 'left', marginLeft: 4, marginTop: 16 }}>
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddNew}
            className='animated-button'
          >
            {credentials.length === 0
              ? 'Configurar acceso a INVU'
              : 'Añadir otro acceso a INVU'}
          </Button>
        </div>
      )}
      <Divider />
    </div>
  );
};

export default InvuIntegration;
