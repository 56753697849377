import {
  CaretLeftOutlined,
  CaretRightOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useRef } from 'react';
import { authContext } from '../ProvideAuth.js';
import { NewAndImprovedExpenseForm } from './NewAndImprovedExpenseForm.js';
import { ExpenseModalNewTeamSelector } from './ExpenseModalNewTeamSelector.js';
import { ImgOrPdf } from './ImgOrPdf.js';

function ExpenseModal(props) {
  const auth = useContext(authContext);
  function deleteExpense(expenseId) {
    axios({
      method: 'post',
      url:
        props.API_domain +
        'deleteExpense?id=' +
        expenseId +
        '&duplicate=' +
        props.expense.duplicate,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.handleOk();
        props.onSubmit();
      })
      .catch(function (response) {
        console.log('Error is ', response);
      });
  }
  let expenseIndex = props.expense.index ? ' #' + props.expense.index : '';

  useEffect(() => {
    window.addEventListener('keydown', handleKey);
  }, []);

  const nextButton = useRef();
  const prevButton = useRef();

  const handleKey = (event) => {
    if (event.keyCode == '37') {
      if (document.activeElement.tagName != 'INPUT') {
        prevButton.current.click();
      }
    }
    if (event.keyCode == '39') {
      if (document.activeElement.tagName != 'INPUT') {
        nextButton.current.click();
      }
    }
  };
  console.log('cashflow id:', props.expense.id);
  // console.log(
  //   'paycaddy_transactions_id',
  //   props.expense.paycaddy_transactions_id
  // );
  return (
    <>
      <Modal
        title={
          <Row>
            <Col span={7}>
              <>
                <span style={{ marginRight: 6 }}>
                  {props.title + expenseIndex}
                </span>
                {props.expense.report_id ? (
                  'Rep. #' + props.expense.report_id
                ) : props.expense.processing ? (
                  <Tooltip
                    placement='bottomLeft'
                    title={'Estamos extrayendo los datos de tu factura'}
                  >
                    <img src='processing.png' width='18px'></img>
                  </Tooltip>
                ) : props.expense.duplicate ? (
                  <Tooltip
                    placement='bottomLeft'
                    title={
                      'Posible factura duplicada. Presente en:' +
                      props.expense.duplicate_locations_list
                    }
                  >
                    <WarningTwoTone twoToneColor='#ffcc00' />
                  </Tooltip>
                ) : (
                  ''
                )}
              </>
            </Col>
            <Col span={7}>
              <ExpenseModalNewTeamSelector
                API_domain={props.API_domain}
                expense={props.expense}
                handleOk={props.handleOk}
                onSubmit={props.onSubmit}
              />
            </Col>
            <Col span={10} style={{ textAlign: 'right', paddingRight: 25 }}>
              <span style={{ paddingRight: 12 }}>
                <Tooltip placement='left' title={props.team}>
                  {props.expense.gastante}
                </Tooltip>
              </span>
              <Tooltip
                placement='bottomRight'
                title={[
                  'Presiona ',
                  <kbd
                    style={{
                      backgroundColor: 'grey',
                      padding: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      borderRadius: 4,
                    }}
                  >
                    ←
                  </kbd>,
                ]}
              >
                <Button
                  type='secondary'
                  style={{ marginRight: 3 }}
                  ref={prevButton}
                  onClick={props.previousExpense}
                >
                  <CaretLeftOutlined />
                </Button>
              </Tooltip>
              <Tooltip
                placement='bottomLeft'
                title={[
                  'Presiona ',
                  <kbd
                    style={{
                      backgroundColor: 'grey',
                      padding: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      borderRadius: 4,
                    }}
                  >
                    →
                  </kbd>,
                ]}
              >
                <Button
                  type='secondary'
                  style={{ marginRight: 3 }}
                  ref={nextButton}
                  onClick={props.nextExpense}
                >
                  <CaretRightOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        }
        visible={true}
        style={{ top: 20 }}
        footer={null}
        onCancel={props.handleCancel}
        width={1050}
      >
        <Row gutter={13}>
          <Col span={12}>
            <ImgOrPdf src={props} expense={props.expense} />
          </Col>
          <Col span={12}>
            <NewAndImprovedExpenseForm
              API_domain={props.API_domain}
              API_endPoint='updateExpense'
              onSubmit={props.onSubmit}
              handleOk={props.handleOk}
              expense={props.expense}
              deleteExpense={deleteExpense}
              team={props.team}
              nextExpense={props.nextExpense}
              previousExpense={props.previousExpense}
              tableExpenses={props.tableExpenses}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export { ExpenseModal };
