import React, { useContext, useState } from 'react';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, message, Row, Upload } from 'antd';
import { authContext } from '../../ProvideAuth.js';
const { Dragger } = Upload;

function BoxUploader(props) {
  const auth = useContext(authContext);
  const { onFileSubmit, endpointToUpload, API_domain } = props;
  const [uploading, setUploading] = useState(false);

  function onFileChange(event) {
    if (event.file.status === 'uploading') {
      setUploading(true);
    }
    if (event.file.status !== 'uploading') {
      console.log(event.file, event.fileList);
    }
    if (event.file.status === 'done') {
      onFileSubmit(event.file.response.file_url);
      message.success('Documento subido exitosamente');
      setUploading(false);
    } else if (event.file.status === 'error') {
      setUploading(false);
      message.error(`${event.file.name} file upload failed.`);
    }
  }
  return (
    <Dragger
      name='file'
      action={API_domain + endpointToUpload}
      showUploadList={false}
      headers={{
        Authorization: `Basic ${btoa(`${auth.email}:${auth.token}`)}`,
      }}
      onChange={onFileChange}
      onDrop={(e) => {
        console.log('Dropped files', e.dataTransfer.files);
      }}
      style={{
        backgroundColor: 'rgba(116, 116, 205, 0.15)',
        width: props.width ? props.width : 215,
      }}
    >
      {uploading ? (
        <LoadingOutlined style={{ fontSize: 50 }} spin />
      ) : (
        <Row align='center'>
          <Col style={{ paddingRight: 5 }}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined style={{ fontSize: 35 }} />
            </p>
          </Col>
          <Col align='left' style={{ paddingLeft: 5 }}>
            <p className='ant-upload-text'>{props.uploaderTitle}</p>
            <p className='ant-upload-hint'>Clickea o arrastra doc</p>
          </Col>
        </Row>
      )}
    </Dragger>
  );
}

export { BoxUploader };
