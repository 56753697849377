import { LoadingOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Table } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { ImgOrPdf } from './../ImgOrPdf.js';
import { ImgOrPdfProcurement } from './ImgOrPdfProcurement.js';
import { DeleteDocumentButton } from './DeleteDocumentButton.js';

function QuotesTable(props) {
  const [selectedQuote, setSelectedQuote] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const auth = useContext(authContext);
  // let requisicionStateMapper = [
  //   { value: 'pending', title: 'PENDIENTE', color: 'yellow' },
  //   { value: 'approved', title: 'APROBADA', color: 'blue' },
  //   { value: 'completed', title: 'COMPLETADA', color: 'green' },
  // ];
  useEffect(() => {
    props.setLoaded(false);
    getAllQuotes();
  }, [props.submittedToggle, props.team]);

  const getAllQuotes = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getAllQuotes',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then((response) => {
        setItems(response.data);
        props.setLoaded(true);
      })
      .catch((response) => {
        props.setLoaded(true);
        setError(response);
      });
  };

  items.forEach(function (item) {
    if (item.created_at) {
      item.createdAtFormatted = new Intl.DateTimeFormat('es-US', {
        dateStyle: 'medium',
      }).format(
        new Date(
          item.created_at.split('/')[0],
          item.created_at.split('/')[1] - 1,
          item.created_at.split('/')[2]
        )
      );
      item.createdAtFormattedShort = new Intl.DateTimeFormat('es-US', {
        day: 'numeric',
        month: 'short',
      }).format(
        new Date(
          item.created_at.split('/')[0],
          item.created_at.split('/')[1] - 1,
          item.created_at.split('/')[2]
        )
      );
    }
  });

  const columns = [
    {
      title: 'Número',
      dataIndex: 'id',
      key: 'id',
      width: 20,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at_with_hour',
      key: 'created_at_with_hour',
      width: 160,
    },
    {
      title: 'Requisición',
      dataIndex: 'requisition_id',
      key: 'requisition_id',
      width: 160,
      render: (text, record) => {
        return (
          <a
            href={'/requisitions?openModalRequisition=' + text}
            style={{ textDecoration: 'underline' }}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: 'Departamento',
      dataIndex: 'team',
      key: 'team',
      render: (team, record) => (
        <>
          <b>{record.creator_name}</b>
          <br></br>
          <span style={{ color: 'grey', size: '10px' }}>{team}</span>
        </>
      ),
    },
    {
      title: 'Provedor',
      dataIndex: 'legit_provider',
      key: 'legit_provider',
      render: (text, record) => (
        <>
          {record.estado == 'completed' ? (
            <span style={{ fontWeight: 600 }}>{text}</span>
          ) : (
            // <span style={{ color: 'grey' }}>hi</span>
            <span style={{ color: 'grey' }}>{text}</span>
          )}
        </>
      ),
    },
  ];
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!props.isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <>
        <Modal
          visible={selectedQuote ? true : false}
          onCancel={() => {
            setSelectedQuote(null);
          }}
          width={800}
          style={{ top: 20 }}
          // bodyStyle={{ height: 'calc(100vh - 320px)' }}
          footer={
            <DeleteDocumentButton
              API_domain={props.API_domain}
              document={selectedQuote}
              onSubmit={() => {setSelectedQuote(null); props.onSubmit()}}
              API_endPoint={'deleteQuote'}
            />}
        >
          <Row gutter={13}>
            <Col span={11}>
              <ImgOrPdfProcurement
                key={selectedQuote?.id}
                src={{
                  imageSource: selectedQuote?.image_url,
                }}
                isThumbnail={false}
              />
            </Col>
            <Col span={13}>
              <h2>Cotización {selectedQuote?.id}</h2>
              <div>
                Proveedor: <b>{selectedQuote?.legit_provider}</b>
              </div>
              <div>
                Fecha: <b>{selectedQuote?.createdAtFormatted}</b>
              </div>
              <div>
                Departamento: <b>{selectedQuote?.team}</b>
              </div>
              <div>
                Requsición: <b>{selectedQuote?.requisition_id}</b>
              </div>
            </Col>
          </Row>
        </Modal>
        <Table
          dataSource={items}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (event.target.tagName !== 'A') {
                  setSelectedQuote(record);
                }
              },
            };
          }}
        />
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { QuotesTable };
