import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { Button, Card, Timeline, Table, Tag, Typography, Skeleton, notification } from 'antd';
import { Link } from 'react-router-dom';
import { FileTextOutlined, CloseOutlined, CheckOutlined, ClockCircleOutlined, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { FormattedUSD } from '../FormattedUSD';
import { authContext } from '../../ProvideAuth.js';
import InfoCard from '../ui/InfoCard';
import './../styles.css';
import HeroAxios from '../../helpers/HeroAxios.js';

const { Text, Paragraph } = Typography;

const AccountingObligationsTimeline = (props) => {
  const [loading, setLoading] = useState(true);
  const auth = useContext(authContext);
  const [fullData, setFullData] = useState(props.data);
  const [data, setData] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [selectedItemDueDate, setSelectedItemDueDate] = useState(null);

  const [showInfoCard, setShowInfoCard] = useState(false); // State to control InfoCard visibility

  const detailsRef = useRef(null);
  const timelineContainerRef = useRef(null);

  const convertToIntDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  useEffect(() => {
    if (fullData) {
      const organizedData = organizeData(fullData);
      setData(organizedData);
      setLoading(false);
    }
  }, [fullData]);

  const getUniqueSources = (dataArray) => {
    const sources = new Set();
    dataArray.forEach(item => {
      if (item.source) {
        sources.add(item.source);
      }
      // Also check details for sources
      if (item.details && item.details.length > 0) {
        item.details.forEach(detail => {
          if (detail.source) {
            sources.add(detail.source);
          }
        });
      }
    });
    return Array.from(sources);
  };

  const memoizedUniqueSources = useMemo(() =>
    getUniqueSources(data),
    [data]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDetails, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideDetails, true);
    };
  }, []);

  const handleClickOutsideDetails = (event) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      closeDetails();
    }
  };

  const organizeData = (responseData) => {
    const clientIdStr = String(props.clientId);
    const obligationsData = responseData && responseData.obligations;
    const clientObligations =
      (obligationsData && obligationsData[clientIdStr]) || [];

    return clientObligations.map((obligation) => ({
      groupKey: obligation.group_key,
      dueDate: obligation.due_date,
      status: obligation.status,
      totalAmount: obligation.total_amount,
      source: obligation.source,
      details: obligation.details,
      last_updated: obligation.last_updated,
    }));
  };

  useEffect(() => {
    if (timelineContainerRef.current && !loading) {
      // Get current date
      const currentDate = new Date();

      // Sort the data by date
      const sortedData = [...data].sort(
        (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
      );

      // Find index of first pending OR current date, whichever comes first
      const targetIndex = sortedData.findIndex(
        item => item.status === 'pendiente' || new Date(item.dueDate) >= currentDate
      );

      if (targetIndex !== -1) {
        // Calculate approximate position
        const itemHeight = timelineContainerRef.current.scrollHeight / sortedData.length;
        const scrollPosition = itemHeight * targetIndex;

        // Scroll immediately without animation
        timelineContainerRef.current.scrollTop = scrollPosition;
      }
    }
  }, [loading, data]);

  const [selectedObligation, setSelectedObligation] = useState(null);

  const renderTimelineItems = () => {
    const sortedData = [...data].sort(
      (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
    );

    return sortedData.map((item, index) => {
      const formattedDate = new Date(item.dueDate).toLocaleDateString(
        'es-ES',
        {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }
      );
      const isPastDue =
        new Date() > new Date(item.dueDate) && item.status !== 'completado';
      let color = 'blue';

      if (item.status === 'completado') {
        color = '#22ce07'; // Green for paid
      } else if (isPastDue) {
        color = 'red'; // Red for past due items not paid
      } else if (
        item.status === 'pending' ||
        item.status === 'pendiente'
      ) {
        color = 'var(--yellow-normal)'; // Yellow for pending items not past due
      }

      const isSelected = selectedItemIndex === index;
      const isOtherSelected = selectedItemIndex !== null && !isSelected;

      const itemStyle = {
        color: isSelected
          ? 'var(--purple-darkest)'
          : isOtherSelected
            ? '#a6a6a6'
            : '#4f4f4f',
        cursor: 'pointer',
        fontSize: '12px',
      };

      const amountStyle = {
        fontSize: '10px',
        color: isSelected ? 'var(--purple-darkest)' : '#a6a6a6',
        textAlign: 'left',
      };

      const handleMouseEnter = (e) => {
        e.currentTarget.style.color = '#000';
      };

      const handleMouseLeave = (e) => {
        e.currentTarget.style.color = isSelected
          ? 'var(--purple-darkest)'
          : isOtherSelected
            ? '#a6a6a6'
            : '#4f4f4f';
      };

      return (
        <Timeline.Item color={color} key={index} label={formattedDate}>
          <div>
            <div >
              <div
                style={itemStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  showDetails(item.details, index, item.dueDate);
                  setSelectedObligation(item);
                }}
              >
                {item.groupKey}

                <Button
                  type="text"
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    showDetails(item.details, index, item.dueDate);
                    setSelectedObligation(item);
                  }}
                  style={{ ...itemStyle, minWidth: '20px', height: '20px' }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />

              </div>
            </div>
            {item.source && (
              <div style={{ ...amountStyle }}>{item.source}</div>
            )}
            {item.totalAmount && item.totalAmount !== "0.00" && (
              <div style={{ ...amountStyle }}>
                <FormattedUSD total={item.totalAmount} />
              </div>
            )}
          </div>
        </Timeline.Item>
      );
    });
  };

  const showDetails = (details, index, dueDate) => {
    setSelectedItemDetails(details);
    setSelectedItemIndex(index);
    setSelectedItemDueDate(dueDate);
  };

  const closeDetails = () => {
    setSelectedItemDetails([]);
    setSelectedItemIndex(null);
    setSelectedItemDueDate(null);
  };

  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <div style={{ fontSize: '12px', textAlign: 'left' }}>{text}</div>,
      sorter: (a, b) => a.description.localeCompare(b.description),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (text) =>
        text !== '0.00' && text !== '0.0' ? (
          <div style={{ textAlign: 'right' }}>
            <FormattedUSD total={text} />
          </div>
        ) : null,
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => (
        <Tag
          color={status === 'completado' ? 'green' : 'orange'}
          icon={status === 'completado' ? <CheckOutlined /> : <ClockCircleOutlined />}
          style={{ fontSize: '0.85em' }}
        >
          {status === 'completado' ? 'completado' : 'pendiente'}
        </Tag>
      ),
    },
  ];

  const formattedDueDate = selectedItemDueDate
    ? convertToIntDateFormat(selectedItemDueDate)
    : '';

  const clientId = props.clientId;
  const clientName = props.data.names[clientId]; // Extract clientName from data.names

  // Function to handle closing the InfoCard
  const handleInfoCardClose = () => {
    setShowInfoCard(false);
  };

  // The content to be passed to InfoCard
  const infoCardContent = (
    <>
      <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
        En esta sección puedes ver las diferentes obligaciones legales que tienes pendientes o completadas.
      </Paragraph>
      {memoizedUniqueSources.length > 0 ? (
        <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
          Tienes habilitadas las siguientes obligaciones automáticas: <Text strong>{memoizedUniqueSources.join(', ')}</Text>.
        </Paragraph>
      ) : (
        <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
          No tienes obligaciones automáticas actualmente.
        </Paragraph>
      )}
      <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
        Esta información se carga automáticamente con las credenciales cargadas en{' '}
        <Link to='/config'>Configuración</Link>.
      </Paragraph>
    </>
  );

  const TriggerObligationRunner = async (accounting_client_id, source) => {
    try {
      await HeroAxios.post(`obligations/update-obligations-by-source/${accounting_client_id}`, {
        source: source
      });

      if (source === 'MUPA') {
        setTimeout(async () => {
          try {
            const response = await HeroAxios.post('getAccountingObligations', {
              group_by: 'source'
            });
            setFullData(response.data);

            if (selectedItemIndex !== null) {
              const organizedData = organizeData(response.data);
              const updatedDetails = organizedData[selectedItemIndex]?.details || [];
              setSelectedItemDetails(updatedDetails);
            }
          } catch (error) {
            console.error('Error refreshing obligations:', error);
            notification.error({
              message: 'Error',
              description: 'No se pudieron actualizar las obligaciones.',
              placement: 'topRight',
            });
          }
        }, 5000);
      }
    } catch (error) {
      throw error;
    }
  };

  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);

  const handleUpdateClick = async (source) => {
    setIsUpdateDisabled(true);

    // Show immediate notification for sync start
    notification.info({
      message: 'Sincronización Iniciada',
      description: `Actualizando obligaciones de ${clientName} para ${source}`,
      placement: 'topRight',
    });

    try {
      await TriggerObligationRunner(props.clientId, source);

      if (source === 'MUPA') {
        setTimeout(async () => {
          try {
            const response = await HeroAxios.post('getAccountingObligations', {
              group_by: 'source'
            });
            setFullData(response.data);

            if (selectedItemIndex !== null) {
              const organizedData = organizeData(response.data);
              const updatedDetails = organizedData[selectedItemIndex]?.details || [];
              setSelectedItemDetails(updatedDetails);
            }
          } catch (error) {
            console.error('Error refreshing obligations:', error);
            notification.error({
              message: 'Error',
              description: `No se pudieron actualizar las obligaciones de ${clientName} para ${source}`,
              placement: 'topRight',
            });
          }
        }, 5000);
      }
    } catch (error) {
      console.error('Error triggering obligation runner:', error);
      notification.error({
        message: 'Error',
        description: `No se pudo iniciar la actualización de obligaciones de ${clientName} para ${source}`,
        placement: 'topRight',
      });
    } finally {
      setTimeout(() => {
        setIsUpdateDisabled(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text style={{ fontSize: '14px', textTransform: 'uppercase' }}>
              Obligaciones de {clientName.toUpperCase()}
            </Text>
            {/* Info Icon Button */}
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => setShowInfoCard(!showInfoCard)}
            />
          </div>
        }
        headStyle={{ textAlign: 'left' }}
        size="small"
        bodyStyle={{ padding: '12px' }}
      >
        <div style={{ display: 'flex' }}>
          {/* Main Content */}
          <div style={{ flex: showInfoCard ? '1 1 70%' : '1 1 100%' }}>

            {/* Timeline and Details */}
            <div style={{ display: 'flex' }}>
              {/* Timeline Section */}
              <div
                ref={timelineContainerRef}
                style={{
                  maxHeight: 500,
                  overflowY: 'auto',
                  flex: '1 1 33%',
                  paddingRight: '2%',
                  paddingTop: '8px',
                }}
              >
                {loading ? (
                  <Skeleton active paragraph={{ rows: 6 }} />
                ) : (
                  <Timeline mode="left" style={{ marginLeft: 0 }}>
                    {renderTimelineItems()}
                  </Timeline>
                )}
              </div>

              {/* Details Section */}
              {selectedItemDetails.length > 0 && (
                <div
                  ref={detailsRef}
                  style={{
                    flex: '1 1 60%',
                    maxHeight: 400,
                    overflowY: 'auto',
                    paddingLeft: '2%',
                  }}
                >
                  <Card
                    size="small"
                    title={
                      <>
                        Detalles de la Obligación - {selectedItemDetails[0]?.source} - {formattedDueDate}
                        <div style={{ fontSize: '10px', color: '#8c8c8c', display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '4px' }}>
                          <SyncOutlined
                            style={{
                              fontSize: '10px',
                              cursor: isUpdateDisabled ? 'not-allowed' : 'pointer',
                              marginLeft: '4px',
                              opacity: isUpdateDisabled ? 0.5 : 1,
                              animation: isUpdateDisabled ? 'spin 1s linear infinite' : 'none'
                            }}
                            onClick={(e) => {
                              if (!isUpdateDisabled) {
                                handleUpdateClick(selectedItemDetails[0]?.source);
                              }
                            }}
                            title={isUpdateDisabled ? "Actualizando..." : "Actualizar obligaciones"}
                          />
                          Actualizado el {selectedObligation?.last_updated ?
                            new Date(selectedObligation.last_updated).toLocaleString('es-ES', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true
                            })
                            : 'N/A'}
                        </div>
                      </>
                    }
                    extra={
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={closeDetails}
                        style={{
                          fontSize: '10px',
                          height: 'auto',
                          padding: '2px 4px',
                        }}
                      />
                    }
                  >
                    <Table
                      className="small-table"
                      dataSource={selectedItemDetails}
                      columns={columns}
                      pagination={false}
                      size="small"
                      rowKey="id"
                      showHeader={true}
                      summary={(pageData) => {
                        const totalAmount = pageData.reduce(
                          (sum, record) => sum + (parseFloat(record.amount) || 0),
                          0
                        );
                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <strong>Total</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <strong>
                                <FormattedUSD total={totalAmount} />
                              </strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                          </Table.Summary.Row>
                        );
                      }}
                    />
                  </Card>
                </div>
              )}
            </div>
          </div>

          {/* InfoCard Section */}
          {showInfoCard && (
            <div style={{ flex: '1 1 30%', paddingLeft: '16px' }}>
              <InfoCard
                title="¿Qué es la vista de obligaciones?" // Passed the new title here
                onClose={handleInfoCardClose}
              >
                {infoCardContent}
              </InfoCard>
            </div>
          )}
        </div>
      </Card>

      {/* Add your styles at the bottom of the file */}
      <style>
        {`
          .small-table .ant-table-cell {
            padding: 4px 8px;
            font-size: 12px;
            text-align: left;
          }
          .small-table .ant-table-thead > tr > th {
            padding: 4px 8px;
            font-size: 12px;
            text-align: left;
          }
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
        `}
      </style>
    </>
  );
};

export { AccountingObligationsTimeline };
