import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, message } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { useAnalytics } from 'use-analytics';

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

function ConfigNewPaymentMethodForm(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      paymentMethodName: values.paymentMethodName,
      team: props.team,
    };

    axios({
      method: 'post',
      url: props.API_domain + 'createPaymentMethod',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.setCategoryCreated(true);
        props.handleOk();
        // clear form
        form.resetFields();
        message.success('Forma de pago creada exitosamente');
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        console.log(response);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        {...layout}
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name='paymentMethodName'
          label='Nombre de forma de pago:'
          rules={[
            {
              required: true,
              message: 'Por favor escriba el nombre de la forma de pago nueva',
            },
          ]}
        >
          <Input placeholder='ACH Banco General' />
        </Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          block
          style={{ fontWeight: 500 }}
        >
          {isSubmitting ? (
            <Spin indicator={antIcon} />
          ) : (
            <>Agregar Forma de Pago</>
          )}
        </Button>
      </Form>
    </>
  );
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigNewPaymentMethodForm };
