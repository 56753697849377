import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext } from 'react';
import { authContext } from '../ProvideAuth.js';
import { downloadBlob, get_informe43_blob } from '../utils.js';
import { useAnalytics } from 'use-analytics';

function ReportDownloaderInforme43(props) {
  const auth = useContext(authContext);
  const { track } = useAnalytics();

  function download() {
    // track('download43', {
    //   API_domain: props.API_domain,
    //   email: auth.email,
    //   misc: { report_id: props.report_id },
    // });
    axios({
      method: 'get',
      url: props.API_domain + 'getReportExpenses?id=' + props.report_id + "&download=Informe43",
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const gastos_blob = get_informe43_blob(result.data);

        downloadBlob(
          gastos_blob,
          'reporte#' + props.report_id + 'Informe43.csv'
        );
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
  }

  return (
    <p onClick={download}>
      <DownloadOutlined />
      Inf. 43
    </p>
  );
}

export { ReportDownloaderInforme43 };
