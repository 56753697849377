import {
  BankOutlined,
  EyeOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  message,
  Modal,
  Spin,
  Table,
  Tooltip,
  Upload,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedUSD } from './FormattedUSD.js';
import { read, utils } from 'xlsx';
import { authContext } from '../ProvideAuth.js';
import axios from 'axios';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CsvUploader(props) {
  const [files, setFiles] = useState([]);
  const [processingFile, setProcessingFile] = useState(false);

  const uploadChanged = (event) => {
    if (event.file) {
      if (event.file.status == 'removed') return;
    }
    if (processingFile) return;
    setProcessingFile(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      // Process the workbook data as needed
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      axios({
        method: 'post',
        url: props.API_domain + 'processAndUploadSotTxns',
        data: {
          SOTcsv: jsonData,
          actually_upload: false,
          selectedSot: props.selectedSot,
        },
        auth: {
          username: props.auth.email,
          password: props.auth.token,
        },
      })
        .then((response) => {
          props.setSubmitting(false);
          setProcessingFile(false);

          // Check if the response contains missing columns information
          if (response.data.error && response.data.missing_columns) {
            const missingCols = response.data.missing_columns.join(', ');
            message.error(`Faltan las siguientes columnas: ${missingCols}`);
          } else {
            const csvLength = response.data.length;
            message.success(csvLength + ' líneas revisadas');
            props.setCsvData(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          props.setSubmitting(false);
          message.error('Se produjo un error al procesar el archivo CSV.');
          fileRemoved();
        });
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  const fileRemoved = (event) => {
    setFiles([]);
    props.setTableColumns([]);
    props.setTableData([]);
    props.setCsvData([]);
    props.setHasErrors(false);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={(e) => uploadChanged(e)}
      onRemove={(e) => fileRemoved(e)}
      multiple={false}
    >
      <Button icon={<UploadOutlined />} style={{ height: 50 }} type='dashed'>
        Selecciona o arrastra
      </Button>
    </Upload>
  );
}

function SOTUploader(props) {
  const auth = useContext(authContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;
  const [showBankTable, setShowBankTable] = useState(true);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    setSubmitting(true);
    axios({
      method: 'post',
      url: props.API_domain + 'processAndUploadSotTxns',
      data: {
        SotTransactionslike_dictionaries: csvData,
        actually_upload: true,
        selectedSot: props.selectedSot,
      },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setSubmitting(false);
        setVisible(false);
        props.setUploadChanged(!props.uploadChanged);
        const amountOfNewSots = response.data.amount_of_new_sot_transactions;
        message.success(
          amountOfNewSots + ' transacciones subidas exitosamente'
        );
      })
      .catch((response) => {
        setSubmitting(false);
        message.error('Error ' + response.data.error ? response.data.error : '');
      });
  };
  const convertToIntDateFormat = (dateString) => {
    const [datePart] = dateString.split(' '); // Splitting and taking the date part
    const [year, month, day] = datePart
      .split('-')
      .map((part) => parseInt(part, 10)); // Converting parts to integers

    return new Intl.DateTimeFormat('es-US', {
      dateStyle: 'medium',
    }).format(new Date(year, month - 1, day)); // Month is 0-indexed
  };

  useEffect(() => {
    if (showBankTable) {
      let transformedData = csvData.map((item, index) => ({
        ...item,
        Descripcion: item.description,
        Fecha: item.txn_date,
        Date: new Date(item.txn_date),
        fechaFormatted: convertToIntDateFormat(item.txn_date),
        // fechaFormatted: item.txn_date,
        Credito: item.amount > 0 ? Math.abs(item.amount) : null,
        Debito: item.amount < 0 ? Math.abs(item.amount) : null,
        balance: item.extra_info.balance,
        sotTxnId: item.id,
        flagged: item.flagged,
        // flagged: Math.random() >= 0.5,
      }));
      transformedData.sort((a, b) => {
        // First compare by Date
        const dateDifference = b.Date - a.Date;
        if (dateDifference !== 0) {
          return dateDifference;
        }

        // If the Dates are equal, compare by id
        return b.sotTxnId - a.sotTxnId; // Assuming larger ids should come first
      });

      let columns = [
        {
          title: 'Descripción',
          dataIndex: 'Descripcion',
          key: 'Descripcion',
          width: 420,
          render: (descripcion) => (
            <Tooltip placement='topLeft' title={descripcion}>
              {descripcion}
            </Tooltip>
          ),
        },
        {
          title: 'Credito',
          dataIndex: 'Credito',
          key: 'Credito',
          width: 100,
          render: (credito) => {
            return Math.abs(credito) > 0 ? (
              <FormattedUSD total={credito} />
            ) : (
              ''
            );
          },
        },
        {
          title: 'Debito',
          dataIndex: 'Debito',
          key: 'Debito',
          width: 100,
          render: (debito) => {
            return Math.abs(debito) > 0 ? <FormattedUSD total={debito} /> : '';
          },
        },
        {
          title: 'Saldo',
          dataIndex: 'balance',
          key: 'balance',
          width: 100,
          render: (balance) => {
            return <FormattedUSD total={balance} />;
          },
        },
        {
          title: 'Fecha',
          dataIndex: 'fechaFormatted',
          key: 'fechaFormatted',
          width: 120,
        },
      ];
      setTableData(transformedData);
      setTableColumns(columns);
    } else {
      if (csvData.length > 0) {
        let columnsHeaders = ['Index', ...Object.keys(csvData[0])];

        let columns = columnsHeaders.map((header, index) => {
          return {
            title:
              header === 'Index'
                ? '#'
                : header === 'errors'
                ? 'Errores'
                : header,
            dataIndex: header !== 'Index' ? header : null,
            key: header,
            render: (text, record, index) => {
              if (header === 'Index') {
                return index + 1;
              }
              // Check if the text is "ERROR" and apply red and bold styling
              if (text === 'ERROR') {
                setHasErrors(true);
                return (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    {text}
                  </span>
                );
              }
              return text;
            },
            ...(header === 'Fecha' ? { width: 120 } : {}),
          };
        });

        let tableData = csvData
          .filter((row) =>
            Object.values(row).some((cell) => cell !== '' && cell != null)
          )
          .map((row, rowIndex) => {
            const transformedRow = Object.fromEntries(
              Object.entries(row).map(([key, value]) => {
                if (Array.isArray(value)) {
                  return [key, value.join(', ')];
                }
                return [key, value];
              })
            );

            // if columnHeader is credito, debito or saldo total, return formattedusd
            for (let [key, value] of Object.entries(transformedRow)) {
              if (
                key === 'Credito' ||
                key === 'Debito' ||
                key === 'Saldo total'
              ) {
                transformedRow[key] = (
                  <FormattedUSD total={parseFloat(value)} />
                );
              }
            }

            return {
              key: rowIndex,
              ...transformedRow,
            };
          });

        setTableColumns(columns);
        setTableData(tableData);
      }
    }
  }, [csvData, showBankTable]);

  return (
    <>
      <Button type='primary' onClick={showModal} style={{ fontWeight: 500 }}>
        <BankOutlined />
        Subir transacciones 🦸‍♂️
      </Button>
      <Modal
        title='Subir SOT'
        visible={visible}
        footer={
          <>
            {hasErrors && (
              <div
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 15,
                }}
              >
                Hay errores en el archivo.
              </div>
            )}
            <Button
              type='primary'
              htmlType='submit'
              block
              onClick={onFinish}
              disabled={hasErrors}
            >
              {isSubmitting ? (
                <Spin indicator={antIcon} />
              ) : (
                <div style={{ fontWeight: 500 }}>
                  Subir {tableData.length} transacciones
                </div>
              )}
            </Button>
          </>
        }
        onCancel={handleCancel}
        width={'95%'}
        style={{ maxHeight: '95vh', overflow: 'auto' }}
        centered
      >
        <h3 style={{ marginBottom: 24 }}>{props.selectedSot.label}</h3>
        <Form {...layout} onFinish={onFinish}>
          <Form.Item label='Archivo:'>
            <div style={{ width: 300 }}>
              <CsvUploader
                auth={auth}
                API_domain={props.API_domain}
                isSubmitting={isSubmitting}
                setSubmitting={setSubmitting}
                setCsvData={setCsvData}
                setTableColumns={setTableColumns}
                setTableData={setTableData}
                selectedSot={props.selectedSot}
                setHasErrors={setHasErrors}
              />
            </div>
          </Form.Item>
        </Form>
        <>
          {hasErrors ? (
            <div
              style={{
                color: 'red',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 15,
              }}
            >
              Hay errores en el archivo.
            </div>
          ) : (
            <div
              style={{
                textAlign: 'center',
                display: tableData.length > 0 ? 'block' : 'none',
              }}
            >
              <Button
                type='secondary'
                onClick={() => setShowBankTable(!showBankTable)}
                style={{
                  fontWeight: 500,
                  textAlign: 'center',
                  marginBottom: 15,
                }}
              >
                <EyeOutlined />
                Ver como tabla de {showBankTable ? 'base de datos' : 'Banco'}
              </Button>
            </div>
          )}

          <Table
            columns={tableColumns}
            dataSource={tableData}
            pagination={false}
          />
        </>
      </Modal>
    </>
  );
}

export { SOTUploader };
