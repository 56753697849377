import { Modal, Form, Select, Button, message, Spin } from 'antd';
import { useState, useEffect } from 'react';
import HeroAxios from '../../helpers/HeroAxios'; // Adjust the path as needed
import AccountingAccountSelect from '../Accounting/AccountingAccountSelect';
import { PlusOutlined } from '@ant-design/icons';

function ConfigAccountsModal(props) {
  const { API_domain, accountingClientId, onCancel, auth } = props;
  const [clientAccounts, setClientAccounts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [form] = Form.useForm();

  // Fetch client accounts and existing mappings when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true); // Start loading
    try {
      await Promise.all([fetchClientAccounts(), fetchAccountingClientsInfo()]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const fetchClientAccounts = async () => {
    try {
      const response = await HeroAxios.post(`${API_domain}/getClientAccounts`, {
        client_id: accountingClientId,
      });
      const accounts = response.data.accounts;
      setClientAccounts(accounts);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      message.error('Error al obtener las cuentas');
    }
  };

  const fetchAccountingClientsInfo = async () => {
    try {
      const response = await HeroAxios.get(`${API_domain}/getAccountingClientsInfo`, {
        params: {
          accounting_client_id: accountingClientId,
        },
      });
      const info = response.data;

      // Transform the data into the format expected by Form.List
    const mappings = info.map((item) => ({
      info_name: item.info_name,
      info_value: parseInt(item.info_value),
    }));

      // Set form values
      form.setFieldsValue({ mappings });
    } catch (error) {
      console.error('Error fetching accounting clients info:', error);
      message.error('Error al obtener la información del cliente');
    }
  };

  const handleSave = async () => {
    try {
      const values = form.getFieldsValue();
      const data = values.mappings.map((item) => ({
        accounting_client_id: accountingClientId,
        info_name: item.info_name,
        info_value: item.info_value,
      }));

      await HeroAxios.post(`${API_domain}/saveAccountingClientsInfoBulk`, {
        data,
      });
      message.success('Información guardada exitosamente');
      onCancel();
    } catch (error) {
      console.error('Error saving accounting clients info:', error);
      message.error('Error al guardar la información');
    }
  };

  const infoNames = [
    { name: 'cxc_empleados', label: 'Cuenta por cobrar empleados - Activo' },
    { name: 'salarios_por_pagar', label: 'Salarios por pagar - Pasivo' },
    { name: 'css_por_pagar', label: 'CSS por pagar - Pasivo' },
    { name: 'retenciones_empleados', label: 'Retenciones a empleados (descuento por pagar) - Pasivo' },
    { name: 'salarios', label: 'Salarios - Gasto' },
    { name: 'honorarios', label: 'Honorarios - Gasto' },
    { name: 'gastos_representacion', label: 'Gastos de Representación' },
    { name: 'prima_produccion', label: 'Prima de Producción - Gasto' },
    { name: 'combustible', label: 'Combustible - Gasto' },
    { name: 'css_patronal', label: 'CSS (patronal) - Gasto' },
  ];

  if (loading) {
    return (
      <Modal
        title="Configurar Cuentas"
        visible={true}
        onCancel={onCancel}
        footer={null}
        width={800}
      >
        <Spin tip="Cargando..." />
      </Modal>
    );
  }

  return (
    <Modal
      title="Configurar Cuentas"
      visible={true}
      onCancel={onCancel}
      onOk={handleSave}
      width={800}
    >
      <Form form={form} layout="vertical">
        <Form.List name="mappings">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'info_name']}
                    fieldKey={[field.fieldKey, 'info_name']}
                    style={{ flex: 1, marginRight: 8 }}
                    rules={[
                      { required: true, message: 'Seleccione un nombre de cuenta' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const mappings = getFieldValue('mappings') || [];
                          const names = mappings.map((mapping) => mapping.info_name);
                          const duplicates = names.filter((name) => name === value);
                          if (duplicates.length > 1) {
                            return Promise.reject(
                              new Error('Este nombre de cuenta ya ha sido seleccionado')
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select placeholder="Seleccione un tipo de cuenta">
                      {infoNames.map((item) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'info_value']}
                    fieldKey={[field.fieldKey, 'info_value']}
                    style={{ flex: 1, marginRight: 8 }}
                    rules={[{ required: true, message: 'Seleccione una cuenta' }]}
                  >
                    <AccountingAccountSelect
                      value={field.info_value}
                      clientId={accountingClientId}
                      API_domain={API_domain}
                      auth={auth}
                      accounts={clientAccounts}
                    />
                  </Form.Item>
                  <Button type="link" onClick={() => remove(field.name)}>
                    Eliminar
                  </Button>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar Cuenta
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export { ConfigAccountsModal };
