import React, { useState } from 'react';
import {
  Form,
  Input,
  Checkbox,
  DatePicker,
  Button,
  Radio,
  Row,
  Col,
  message,
  notification,
} from 'antd';
import moment from 'moment';
import styled from 'styled-components/macro';
import axios from 'axios';

function PricingForm(props) {
  const [form] = Form.useForm();
  const [price, setPrice] = useState(200); // Initial base price
  const [hasAsterisk, setHasAsterisk] = useState(false); // Track whether asterisk is needed
  const [formComplete, setFormComplete] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openPriceChangeNotification = (amount) => {
    api.open({
      message: (
        <span style={{ color: amount < 0 ? '#52c41a' : '#333333' }}>
          {amount > 0 ? '+' : '-'}${Math.abs(amount)}
        </span>
      ),
      description: '',
      placement: 'bottom',
      duration: 1, // Display for only 1 second
      style: {
        width: 160,
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        fontWeight: '500',
        padding: '8px 16px',
      },
      closeIcon: false, // This removes the close button (X)
    });
  };

  const calculatePrice = (values) => {
    let updatedPrice = 200; // Base price
    let asterisk = false; // Track if the asterisk is required
    const previousPrice = price;

    // Payroll calculation
    // Adds $10 + $5 per employee in payroll
    if (values.payroll && values.payroll > 0) {
      updatedPrice += 10 + 5 * values.payroll;
    }

    // ITBMS calculation
    // Adds $30 if the user selects "yes" for ITBMS
    if (values.itbms === 'yes') {
      updatedPrice += 30;
    }

    // Financial Statements calculation
    // Adds $30 if the most recent financial statements are older than 6 months
    if (values.financialStatements) {
      const sixMonthsAgo = moment().subtract(6, 'months');
      if (moment(values.financialStatements).isBefore(sixMonthsAgo)) {
        updatedPrice += 30;
      }
    }

    // Bank Accounts calculation
    // Adds $25 for BAC, $15 for Banco General, and $40 for other banks (also triggers asterisk for "Otras")
    if (values.bankAccounts) {
      if (values.bankAccounts.includes('BAC')) {
        updatedPrice += 25;
      }
      if (values.bankAccounts.includes('Banco General')) {
        updatedPrice += 20;
      }
      if (values.bankAccounts.includes('Otras')) {
        updatedPrice += 40;
        asterisk = true; // Mark for asterisk
      }
    }

    // POS Software calculation
    // Deducts $15 for 'invu', adds $10 for 'otros' (also triggers asterisk for "otros")
    if (values.posSoftware === 'invu') {
      updatedPrice -= 15;
    } else if (values.posSoftware === 'otros') {
      updatedPrice += 10;
      asterisk = true; // Mark for asterisk
    } else if (values.posSoftware === 'ninguno') {
      updatedPrice -= 0;
      asterisk = true; // Mark for asterisk
    }

    // Factura electrónica calculation
    // Deducts $15 if 'yes' for electronic invoicing
    if (values.facturaElectronica === 'yes') {
      updatedPrice -= 15;
    }

    // Facturación año anterior calculation
    // Adjusts price based on previous year's billing
    if (values.facturacionAnterior === '$0') {
      updatedPrice -= 5;
    } else if (values.facturacionAnterior === '<$100k') {
      updatedPrice += 25;
    } else if (values.facturacionAnterior === '$100k-$1M') {
      updatedPrice += 50;
    } else if (values.facturacionAnterior === '>$1M') {
      updatedPrice += 100;
    }

    if (updatedPrice !== previousPrice) {
      const priceChange = updatedPrice - previousPrice;
      openPriceChangeNotification(priceChange);
    }

    // Set the final calculated price
    setPrice(updatedPrice);

    // Set or unset the asterisk based on conditions
    setHasAsterisk(asterisk || values.neverHadFinancialStatements);
  };

  const onValuesChange = (changedValues, allValues) => {
    calculatePrice(allValues);

    // Check if all fields have been interacted with
    if (form.isFieldsTouched(true)) {
      setFormComplete(true);
    }
  };

  const handleStart = () => {
    const data = form.getFieldsValue(); // Collect form data
    data.price = price; // Add the calculated price to the data
    // console.log(data);

    axios({
      method: 'post',
      url: props.API_domain + 'bookMeetingFromLafise',
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Agendemos');
          const calendlyLink = response.data.calendly_link; // Backend returns Calendly link

          // Redirect user to the Calendly link
          window.location.href = calendlyLink;
        }
      })
      .catch((error) => {
        message.error('Error redirigiendo a Calendly');
      })
      .finally(() => {
        setFormComplete(false);
      });
  };

  return (
    <PageContainer>
      {contextHolder}
      <Row>
        <Col span={12}>
          <Logo src='./HERO_LOGO-03.png' alt='Hero' />
        </Col>
        <Col span={12}>
          <LogoLafise src='./lafise.png' alt='Lafise' />
        </Col>
      </Row>
      <FormContainer>
        <StyledForm
          form={form}
          layout='vertical'
          onValuesChange={onValuesChange}
          initialValues={{
            payroll: 0, // Default value for payroll
          }}
        >
          <Form.Item label='Hero entrega mensualmente'>
            <Row>
              <Col span={24}>
                <span style={{ fontSize: 16, marginRight: 8 }}>✅</span>
                Estado de resultados
              </Col>
              <Col span={24}>
                <span style={{ fontSize: 16, marginRight: 8 }}>✅</span>
                Balance de situación
              </Col>
              <Col span={24}>
                <span style={{ fontSize: 16, marginRight: 8 }}>✅</span>
                Gestión de cumplimiento de obligaciones fiscales
              </Col>
              <Col span={24}>
                <span style={{ fontSize: 16, marginRight: 8 }}>✅</span>
                CPA para consultas
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name='payroll'
            label='Cantidad de empleados en planilla mensual'
          >
            <Input
              type='number'
              placeholder='Cantidad de empleados'
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item name='itbms' label='ITBMS mensual'>
            <Radio.Group>
              <Radio value='yes'>Sí</Radio>
              <Radio value='no'>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name='financialStatements'
            label='Estados Financieros más recientes'
          >
            <DatePicker placeholder='Fecha' />
            <br />
            <Checkbox
              name='neverHadFinancialStatements'
              style={{ marginTop: 8 }}
            >
              Nunca he tenido estados financieros
            </Checkbox>
          </Form.Item>

          <Form.Item name='bankAccounts' label='Cuentas de banco comerciales'>
            <Checkbox.Group>
              <Row>
                <Col span={24}>
                  <span style={{ fontSize: 16, marginRight: 8 }}>✅</span>
                  LAFISE
                </Col>
                <Col span={24}>
                  <Checkbox value='BAC'>BAC</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value='Banco General'>Banco General</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value='Otras'>Otras</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name='posSoftware' label='Software de POS'>
            <Radio.Group>
              <Radio value='invu'>Invu</Radio>
              <Radio value='otros'>Otros</Radio>
              <Radio value='ninguno'>Ninguno</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name='facturaElectronica' label='Factura Electrónica'>
            <Radio.Group>
              <Radio value='yes'>Sí</Radio>
              <Radio value='no'>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name='facturacionAnterior'
            label='Facturación año anterior'
          >
            <Radio.Group>
              <Radio value='$0'>$0</Radio>
              <Radio value='<$100k'>Menos de $100,000</Radio>
              <Radio value='$100k-$1M'>$100,000 - $1,000,000</Radio>
              <Radio value='>$1M'>Más de $1,000,000</Radio>
            </Radio.Group>
          </Form.Item>

          <PriceDisplay>
            <h2>
              Precio mensual: ${price}
              {hasAsterisk && '*'}
            </h2>
          </PriceDisplay>
          <Form.Item>
            <CenteredButton type='primary' onClick={handleStart}>
              Empezar
            </CenteredButton>
          </Form.Item>
        </StyledForm>

        <Assumptions>
          <h4>Asumimos:</h4>
          <ul>
            <li>Tienes constituida una sociedad anónima o similar</li>
            <li>Tu cuenta de banco en Lafise tiene permisos de API</li>
          </ul>
        </Assumptions>
      </FormContainer>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
`;

const Logo = styled.img`
  width: 80px;
  margin-bottom: 20px;
`;

const LogoLafise = styled.img`
  width: 120px;
  margin-bottom: 20px;
  margin-top: 16px;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label > label {
    text-align: left; /* Ensure labels are left-aligned */
  }

  .ant-form-item-control {
    text-align: left; /* Ensure inputs are left-aligned */
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item:last-child {
    text-align: center;
  }
`;

const PriceDisplay = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 24px; /* Improved styling */
  font-weight: bold; /* Improved styling */
`;

const Assumptions = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const CenteredButton = styled(Button)`
  display: block;
  margin: 0 auto;
`;

export { PricingForm };
