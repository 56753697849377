import { Button, Col, Dropdown, Menu, Modal, Row, Typography } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState, useContext } from 'react';
import { ReportedExpenseModal } from './ReportedExpenseModal';
import { ExpenseModalMobile } from './ExpenseModalMobile';
import { SharedReportTable } from './SharedReportTable.js';
import { SharedReportDownloaderGeneral } from './SharedReportDownloaderGeneral';
import { SharedReportDownloaderImages } from './SharedReportDownloaderImages';
import { ReportDownloaderInforme43 } from './ReportDownloaderInforme43';
import { ReportDownloaderPeachtree } from './ReportDownloaderPeachtree';
import { authContext } from '../ProvideAuth.js';

function SharedReportModal(props) {
  const auth = useContext(authContext);
  const [selectedExpense, setSelectedExpense] = useState();
  const [reportExpensesList, setReportExpensesList] = useState();
  function getIndex(expenseObject) {
    return reportExpensesList.findIndex((obj) => expenseObject === obj);
  }

  function getExpenseObject(index) {
    return reportExpensesList[index];
  }

  function previousExpense() {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex > 0) {
      setSelectedExpense(getExpenseObject(currentIndex - 1));
    }
  }
  const nextExpense = () => {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex < reportExpensesList.length - 1) {
      setSelectedExpense(getExpenseObject(currentIndex + 1));
    }
  };
  const menu = (
    <>
      <Menu>
        <Menu.Item>
          <SharedReportDownloaderGeneral
            API_domain={props.API_domain}
            report_id={props.report_id}
            token={props.token}
          />
        </Menu.Item>
        <Menu.Item>
          <SharedReportDownloaderImages
            API_domain={props.API_domain}
            report_id={props.report_id}
            token={props.token}
          />
        </Menu.Item>
        <Menu.Item disabled>
          <DownloadOutlined />
          Dynamics
        </Menu.Item>
        <Menu.Item disabled>
          <DownloadOutlined />
          SAP
        </Menu.Item>
        <Menu.Item disabled>
          <DownloadOutlined />
          Quickbooks
        </Menu.Item>
        <Menu.Item disabled>
          <DownloadOutlined />
          Peachtree
        </Menu.Item>
        <Menu.Item disabled>
          <DownloadOutlined />
          Personalizado
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <Modal
      title={
        <Row>
          <Col span={props.mobile ? 12 : 4}>
            <a href='https://www.herofacturas.com' target='_blank'>
              <img
                src='/heroLogoSharedReports.png'
                style={{ width: 125 }}
              ></img>
            </a>
          </Col>
          <Col span={props.mobile ? 12 : 20}>
            <div>{props.report_title}</div>
            <span style={{ color: 'grey', fontSize: '14px' }}>
              {props.team}
            </span>
          </Col>
        </Row>
      }
      visible={true}
      footer={
        <>
          <Row>
            <Col span={8}></Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <Button
                type='primary'
                style={{
                  marginLeft: 10,
                  backgroundColor: 'rgb(26,190,43)',
                  border: 'rgb(26,190,43)',
                }}
              >
                <a
                  target='_blank'
                  href='https://api.whatsapp.com/send?phone=50766181519&text=Quiero%20probar%20Hero!'
                >
                  Quiero probar Hero
                </a>
              </Button>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              {props.mobile ? (
                <></>
              ) : (
                <Dropdown overlay={menu} placement='bottomRight' arrow>
                  <Button>Descargar</Button>
                </Dropdown>
              )}
            </Col>
          </Row>
        </>
      }
      onCancel={props.handleCancel}
      width={1100}
      centered
      bodyStyle={{ height: 'calc(100vh - 180px)' }}
      style={{ height: '95vh' }}
    >
      {selectedExpense && (
        <>
          {props.mobile ? (
            <ExpenseModalMobile
              record={selectedExpense}
              visible={true}
              title={'Gasto'}
              imageSource={selectedExpense.factura}
              handleCancel={() => setSelectedExpense(null)}
              handleOk={() => setSelectedExpense(null)}
              // onSubmit={onSubmit}
              expense={selectedExpense}
              API_domain={props.API_domain}
              team={props.team}
              previous={previousExpense}
              next={nextExpense}
            />
          ) : (
            <ReportedExpenseModal
              record={selectedExpense}
              visible={true}
              title={props.report_title}
              imageSource={selectedExpense.factura}
              handleCancel={() => setSelectedExpense(null)}
              handleOk={() => setSelectedExpense(null)}
              expense={selectedExpense}
              API_domain={props.API_domain}
              team={props.team}
              previousExpense={previousExpense}
              nextExpense={nextExpense}
            />
          )}
        </>
      )}
      <SharedReportTable
        API_domain={props.API_domain}
        report_id={props.report_id}
        setReportExpensesList={setReportExpensesList}
        openModal={setSelectedExpense}
        setSelectedExpense={setSelectedExpense}
        mobile={props.mobile}
        token={props.token}
      />
    </Modal>
  );
}

export { SharedReportModal };
