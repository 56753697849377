// RelationshipModal.js

import React, { useEffect, useState } from 'react';
import { Modal, Spin, message, Button, Divider } from 'antd';
import HeroAxios from '../../helpers/HeroAxios.js';
import { PdfViewer } from '../PdfViewer.js';
import { Image } from 'antd';
import { BankOutlined, CreditCardOutlined } from '@ant-design/icons';

const RelationshipModal = ({
  visible,
  onClose,
  movement,
  API_domain,
}) => {
  const [relationshipData, setRelationshipData] = useState(null);
  const [relationshipLoading, setRelationshipLoading] = useState(false);
  console.log('movement', movement);
  useEffect(() => {
    if (visible && movement) {
      fetchRelationshipData(movement);
    }
  }, [visible, movement]);

  const fetchRelationshipData = async (record) => {
    setRelationshipLoading(true);
    try {
      const { data } = await HeroAxios.post('/getRelationships', {
        movement_id: record.id,
        source_table_name: movement.source_table,
      });
      console.log('data', data);
      setRelationshipData(data);
    } catch (error) {
      message.error('Error fetching relationship data');
    } finally {
      setRelationshipLoading(false);
    }
  };

  const handleDeleteRelationship = async (relationship_id) => {
    try {
      const { data } = await HeroAxios.post('/deleteRelationship', {
        relationship_id,
      });
      if (data.status === 'success') {
        message.success('Relación eliminada exitosamente');
        // Refresh relationship data
        await fetchRelationshipData(movement);
        // Optionally, update the 'related' status of the movement
        if (
          relationshipData.rel_data.length === 1 &&
          movement.related === true
        ) {
          // This was the last relationship; update the movement's 'related' status
          movement.related = false;
          // If you have a method to update the parent component, call it here
          // For example: updateMovement(movement);
        }
      } else {
        throw new Error('Failed to delete relationship');
      }
    } catch (error) {
      message.error('Error eliminando la relación');
    }
  };

  return (
    <Modal
      title='Relaciones del movimiento'
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      {relationshipLoading ? (
        <Spin />
      ) : relationshipData && relationshipData.rel_data ? (
        <div>
          {relationshipData.rel_data.map((rel, index) => (
            <div key={index} style={{ marginBottom: '16px' }}>
              {/* Check if it's related to "Movimiento contable" */}
              {rel.movement_table_name === 'entries' && (
                <p><strong>Relacionado con:</strong> Movimiento contable</p>
              )}
              
              <div>
                {/* Display PdfViewer or image based on the URL */}
                {rel.movement_table_name.includes('dgi') && (
                  <>
                    <PdfViewer pdfUrl={relationshipData.cufe_url} />
                  </>
                )}
                {rel.movement_table_name == 'cashflow' && (
                  <Image width={24} src={`${relationshipData.cashflow_url}`} />
                )}
                {rel.movement_table_name.includes('sot_transactions') && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    {/* Display icons based on type_of_mm */}
                    {relationshipData.type_of_mm === 'bank' && <BankOutlined />}
                    {relationshipData.type_of_mm === 'card' && <CreditCardOutlined />}
                  </div>
                )}
              </div>
              
              {/* Delete relationship button */}
              <Button
                danger
                onClick={() => handleDeleteRelationship(rel.relationship_id)}
              >
                Eliminar relación
              </Button>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <p>No hay relaciones para este movimiento.</p>
      )}
    </Modal>
  );
};

export { RelationshipModal };
