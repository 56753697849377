import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { authContext } from "../ProvideAuth.js";
import { Steps } from "antd";

function ReportHistory(props) {
  const [error, setError] = useState(null);
  const [actions, setActions] = useState([]);
  const [reportStati, setReportStati] = useState(["Creación", "Aprobación", "Reembolso"]);
  const auth = useContext(authContext);

  useEffect(() => {
    getReportActions();
  }, [props.submittedToggle]);

  const getReportActions = () => {
    axios({
      method: "get",
      url: props.API_domain + "getReportActions?id=" + props.report_id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setActions(response.data.actions);
        if (response.data.how_many_actions == 4){
          setReportStati(["Creación", "Aprobación(1)", "Aprobación(2)", "Reembolso"]);
        }
        if (response.data.how_many_actions == 5){
          setReportStati(["Creación", "Aprobación(1)", "Aprobación(2)", "Aprobación(3)", "Reembolso"]);
        }
        if (response.data.how_many_actions == 6){
          setReportStati(["Creación", "Aprobación(1)", "Aprobación(2)", "Aprobación(3)","Aprobación(4)", "Reembolso"]);
        }
      })
      .catch((response) => {
        setError(true);
      });
  };
  actions.forEach(function (action) {
    if (action.date) {
      action.dateFormatted = new Intl.DateTimeFormat("es-US", {
        dateStyle: "medium",
      }).format(
        new Date(
          action.date.split("/")[0],
          action.date.split("/")[1] - 1,
          action.date.split("/")[2]
        )
      );
    }
  });
  const { Step } = Steps;
  const stepsList = [];
  var currentStep = actions.length;
  for (let index = 0; index < reportStati.length; index++) {
    if (actions[index]) {
      stepsList.push(
        <Step
          title={reportStati[index]}
          description={
            actions[index].dateFormatted +
            ", " +
            actions[index].time +
            " - " +
            actions[index].name
          }
        />
      );
    } else {
      stepsList.push(
        <Step title={reportStati[index]} description="Pendiente" />
      );
    }
  }
  return (
    <>
      <Steps size="small" current={currentStep} direction="vertical">
        {stepsList}
      </Steps>
    </>
  );
}

export { ReportHistory };
