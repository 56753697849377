import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { authContext } from '../ProvideAuth'; // Ensure this path is correct

function CardStatus(props) {
  const [response, setResponse] = useState(false);
  const auth = useContext(authContext);
  const [isLoaded, setLoaded] = useState(false);

  const getCardBlockedBoolean = () => {
    if (!props.shouldRefresh) {
      // Only make the API call if shouldRefresh is true
      setResponse(props.cardBlocked); // Use cardBlocked from props
      setLoaded(true);
      return;
    }

    axios({
      method: 'post',
      url: props.API_domain + 'GetCardBlockedBoolean',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { card_id: props.card_id },
    })
      .then((response) => {
        setResponse(response.data.card_blocked);
        setLoaded(true);
      })
      .catch((error) => {
        console.error('Error fetching card blocked status:', error);
        setLoaded(true);
      });
  };

  useEffect(() => {
    getCardBlockedBoolean();
  }, [props.shouldRefresh]); // Dependency array includes shouldRefresh to trigger re-fetch

  if (isLoaded) {
    if (response) {
      return (
        <Tooltip
          placement='right'
          title='Actualmente NO se puede hacer pagos con esta tarjeta.'
        >
          <span style={{ fontWeight: 500 }}>🚫 Desactivada</span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          placement='right'
          title='Actualmente se puede hacer pagos con esta tarjeta.'
        >
          <span style={{ fontWeight: 500 }}>✅ Activa</span>
        </Tooltip>
      );
    }
  } else {
    return <LoadingOutlined></LoadingOutlined>;
  }
}

export { CardStatus };
