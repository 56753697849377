import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Image,
  message,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { FormattedUSD } from './../FormattedUSD.js';
import { ImgOrPdf } from './../ImgOrPdf.js';
import { ImgOrPdfProcurement } from './ImgOrPdfProcurement.js';
import { DeleteDocumentButton } from './DeleteDocumentButton.js';

function RecibidosTable(props) {
  const [selectedReceipt, setSelectedReceipt] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const auth = useContext(authContext);
  // let requisicionStateMapper = [
  //   { value: 'pending', title: 'PENDIENTE', color: 'yellow' },
  //   { value: 'approved', title: 'APROBADA', color: 'blue' },
  //   { value: 'completed', title: 'COMPLETADA', color: 'green' },
  // ];

  useEffect(() => {
    props.setLoaded(false);
    getAllReceipts();
  }, [props.submittedToggle, props.team]);

  const getAllReceipts = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getAllReceipts',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then((response) => {
        props.setLoaded(true);
        const data = response.data;
        data.forEach(function (item) {
          if (item.created_at) {
            item.createdAtFormatted = new Intl.DateTimeFormat('es-US', {
              dateStyle: 'medium',
            }).format(
              new Date(
                item.created_at.split('/')[0],
                item.created_at.split('/')[1] - 1,
                item.created_at.split('/')[2]
              )
            );
            item.createdAtFormattedShort = new Intl.DateTimeFormat('es-US', {
              day: 'numeric',
              month: 'short',
            }).format(
              new Date(
                item.created_at.split('/')[0],
                item.created_at.split('/')[1] - 1,
                item.created_at.split('/')[2]
              )
            );
          }
        });
        data.sort((a, b) => {
          if (a.state > b.state) {
            return -1;
          } else if (a.state < b.state) {
            return 1;
          } else {
            return new Date(b.created) - new Date(a.created);
          }
        });
        setItems(data);
      })
      .catch((response) => {
        props.setLoaded(true);
        setError(response);
      });
  };

  const columns = [
    {
      title: 'Número',
      dataIndex: 'id',
      key: 'id',
      width: 20,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at_with_hour',
      key: 'created_at_with_hour',
      width: 160,
    },
    {
      title: 'Orden',
      dataIndex: 'purchase_order_id',
      key: 'purchase_order_id',
      width: 160,
      render: (text, record) => {
        return <a style={{ textDecoration: 'underline' }}>{text}</a>;
      },
    },
    {
      title: 'Departamento',
      dataIndex: 'team',
      key: 'team',
      render: (team, record) => (
        <>
          <b>{record.creator_name}</b>
          <br></br>
          <span style={{ color: 'grey', size: '10px' }}>{team}</span>
        </>
      ),
    },
    // {
    //   title: 'Provedor',
    //   dataIndex: 'proveedor',
    //   key: 'proveedor',
    //   render: (text, record) => (
    //     <>
    //       {record.estado == 'completed' ? (
    //         <span style={{ fontWeight: 600 }}>{text}</span>
    //       ) : (
    //         // <span style={{ color: 'grey' }}>hi</span>
    //         <span style={{ color: 'grey' }}>{text}</span>
    //       )}
    //     </>
    //   ),
    // },
  ];
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!props.isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <>
        <Modal
          visible={selectedReceipt ? true : false}
          onCancel={() => {
            setSelectedReceipt(null);
          }}
          width={800}
          style={{ top: 20 }}
          // bodyStyle={{ height: 'calc(100vh - 320px)' }}
          footer={
          <DeleteDocumentButton
            API_domain={props.API_domain}
            document={selectedReceipt}
            onSubmit={() => {setSelectedReceipt(null); props.onSubmit()}}
            API_endpoint={'deleteReceipt'}
          />}
        >
          <Row gutter={13}>
            <Col span={11}>
              <ImgOrPdfProcurement
                key={selectedReceipt?.id}
                src={{
                  imageSource: selectedReceipt?.image_url,
                }}
                isThumbnail={false}
              />
              {/* <ImgOrPdf
                // src={props}
                src={{
                  imageSource:
                  selectedReceipt?.image_url
                }}
              /> */}
            </Col>
            <Col span={13}>
              <h2>Recibido {selectedReceipt?.id}</h2>
              <div>
                Fecha: <b>{selectedReceipt?.createdAtFormatted}</b>
              </div>
              <div>
                Equipo: <b>{selectedReceipt?.team}</b>
              </div>
              <div>
                Orden: <b>{selectedReceipt?.purchase_order_id}</b>
              </div>
            </Col>
          </Row>
        </Modal>
        <Table
          dataSource={items}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (event.target.tagName !== 'A') {
                  setSelectedReceipt(record);
                }
              },
            };
          }}
        />
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { RecibidosTable };
