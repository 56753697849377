import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminPayinCompanyWallet(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [companyOptions, setCompanyOptions] = useState();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      company_id: values.company_wallet,
      amount_in_cents: values.amount_in_cents,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminPayinCompanyWallet',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function adminFetchCompanyOptions() {
    axios({
      method: 'post',
      url: props.API_domain + 'adminFetchCompanyOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setCompanyOptions(
          response.data.map((company) => ({
            label: company[0],
            value: company[1],
          }))
        );
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    if(visible){
      adminFetchCompanyOptions();
    }
  }, [visible]);

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Payin Company Wallet</ButtonSpan>
      </Button>
      <Modal
        title='Payin Company'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Payin</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            name='company_wallet'
            label='Compania'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Select
              showSearch
              options={companyOptions}
              placeholder='Compania'
              filterOption={(input, option) =>
                option.props.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            name='amount_in_cents'
            label='amount_in_cents:'
            rules={[
              {
                required: true,
                message: 'Por favor',
              },
            ]}
          >
            <Input placeholder='10000' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminPayinCompanyWallet };
