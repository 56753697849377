import React, { useState, useEffect } from 'react';
import { Table, Card, Statistic, Row, Col, Badge, Tabs, Tooltip, Tag } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import HeroAxios from '../../../helpers/HeroAxios.js';

const { TabPane } = Tabs;





const CeleryScheduleViewer = () => {

    const [schedules, setSchedules] = useState(null);

    useEffect(() => {
        HeroAxios.get('/celery/get-schedules')
            .then(res => setSchedules(res.data))
            .catch(error => console.error(error));
    }, []);

    if (!schedules) return <div>Loading...</div>;

    // Column definition for the task tables
    const columns = [
        {
            title: 'Time',
            key: 'time',
            width: 100,
            fixed: 'left',
            render: (_, record) => (
                <Tooltip title={record.schedule}>
                    <span>
                        {`${String(Array.from(record.schedule_hour)[0]).padStart(2, '0')}:${String(
                            Array.from(record.schedule_minute)[0]
                        ).padStart(2, '0')}`}
                    </span>
                </Tooltip>
            ),
            sorter: (a, b) => {
                const aHour = Array.from(a.schedule_hour)[0];
                const bHour = Array.from(b.schedule_hour)[0];
                const aMin = Array.from(a.schedule_minute)[0];
                const bMin = Array.from(b.schedule_minute)[0];
                return aHour * 60 + aMin - (bHour * 60 + bMin);
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            ellipsis: true,
        },
        {
            title: 'Task',
            dataIndex: 'task',
            key: 'task',
            width: 400,
            ellipsis: true,
            render: text => (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Arguments',
            key: 'args',
            width: 150,
            render: (_, record) => <span>{JSON.stringify(record.args)}</span>,
        },
        {
            title: 'Schedule',
            key: 'schedule_days',
            width: 200,
            render: (_, record) => {
                const days = new Set(record.schedule_day_of_week);
                const isWeekdays =
                    days.size === 5 && [1, 2, 3, 4, 5].every(d => days.has(d));
                const isDaily = days.size === 7;

                if (isDaily) {
                    return <Tag color="blue">Daily</Tag>;
                }
                if (isWeekdays) {
                    return <Tag color="green">Weekdays</Tag>;
                }
                return (
                    <span>
                        {[
                            ['Sun', 0],
                            ['Mon', 1],
                            ['Tue', 2],
                            ['Wed', 3],
                            ['Thu', 4],
                            ['Fri', 5],
                            ['Sat', 6],
                        ].map(
                            ([day, num]) =>
                                days.has(num) && (
                                    <Tag key={day} color="purple" style={{ marginRight: 4 }}>
                                        {day}
                                    </Tag>
                                )
                        )}
                    </span>
                );
            },
        },
    ];

    return (
        <div style={{ padding: 24 }}>
            {/* Summary Statistics */}
            <Card style={{ marginBottom: 24 }}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Statistic
                            title="Total Tasks"
                            value={schedules.summary.total_tasks}
                            prefix={<CalendarOutlined />}
                        />
                    </Col>
                    <Col span={5}>
                        <Statistic
                            title="DGI Tasks"
                            value={schedules.summary.dgi_count}
                            valueStyle={{ color: '#1890ff' }}
                        />
                    </Col>
                    <Col span={5}>
                        <Statistic
                            title="MUPA Tasks"
                            value={schedules.summary.mupa_count}
                            valueStyle={{ color: '#52c41a' }}
                        />
                    </Col>
                    <Col span={5}>
                        <Statistic
                            title="INVU Tasks"
                            value={schedules.summary.invu_count}
                            valueStyle={{ color: '#722ed1' }}
                        />
                    </Col>
                    <Col span={5}>
                        <Statistic
                            title="SIPE Tasks"
                            value={schedules.summary.sipe_count}
                            valueStyle={{ color: '#fa8c16' }}
                        />
                    </Col>
                </Row>
            </Card>

            {/* Task Tables */}
            <Tabs defaultActiveKey="dgi" size="large">
                <TabPane
                    tab={
                        <span>
                            <Badge color="#1890ff" />
                            DGI Tasks
                        </span>
                    }
                    key="dgi"
                >
                    <Table
                        columns={columns}
                        dataSource={schedules.dgi_tasks}
                        rowKey="name"
                        scroll={{ x: 1200 }}
                        pagination={{ pageSize: 10 }}
                    />
                </TabPane>

                <TabPane
                    tab={
                        <span>
                            <Badge color="#52c41a" />
                            MUPA Tasks
                        </span>
                    }
                    key="mupa"
                >
                    <Table
                        columns={columns}
                        dataSource={schedules.mupa_tasks}
                        rowKey="name"
                        scroll={{ x: 1200 }}
                        pagination={{ pageSize: 10 }}
                    />
                </TabPane>

                <TabPane
                    tab={
                        <span>
                            <Badge color="#722ed1" />
                            INVU Tasks
                        </span>
                    }
                    key="invu"
                >
                    <Table
                        columns={columns}
                        dataSource={schedules.invu_tasks}
                        rowKey="name"
                        scroll={{ x: 1200 }}
                        pagination={{ pageSize: 10 }}
                    />
                </TabPane>

                <TabPane
                    tab={
                        <span>
                            <Badge color="#fa8c16" />
                            SIPE Tasks
                        </span>
                    }
                    key="sipe"
                >
                    <Table
                        columns={columns}
                        dataSource={schedules.sipe_tasks}
                        rowKey="name"
                        scroll={{ x: 1200 }}
                        pagination={{ pageSize: 10 }}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default CeleryScheduleViewer;