import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  notification,
} from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useLocation, useHistory } from 'react-router-dom';

const { Option } = Select;

function OnboardingForm(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [stripePaymentLink, setStripePaymentLink] = useState('');
  const [plink, setPlink] = useState('');
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('');
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  // Extract the stripe_payment_link parameter from the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paymentLink = params.get('stripe_payment_link');
    const plink = params.get('plink');
    const stripeSubscriptionId = params.get('stripe_subscription_id');
    if (paymentLink) {
      setStripePaymentLink(paymentLink);
      // Remove the stripe_payment_link from the URL without reloading the page
      params.delete('stripe_payment_link');
      history.replace({ search: params.toString() });
    }
    if (plink) {
      setPlink(plink);
      params.delete('plink');
      history.replace({ search: params.toString() });
    }
    if (stripeSubscriptionId) {
      setStripeSubscriptionId(stripeSubscriptionId);
      params.delete('stripe_subscription_id');
      history.replace({ search: params.toString() });
    }
  }, [location.search, history]);

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      ...values,
      stripe_payment_link: stripePaymentLink,
      stripe_payment_link_id: plink,
      stripe_subscription_id: stripeSubscriptionId,
      phone: `${values.countryCode}${values.phone}`, // Combine country code with phone number
    };
    console.log(data);
    axios({
      method: 'post',
      url:
        props.API_domain +
        'createUserAndCreateCompanyAndCreateAccountingClient',
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Onboarding exitoso');
          const loginLink = response.data.login_link; // assuming response.data.login_link contains the full link

          // Redirect the current window to the login link to ensure cookies are set
          window.location.href = loginLink;
        }
      })
      .catch((error) => {
        message.error('Error en el onboarding');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <PageContainer>
      <Logo src='./HERO_LOGO-03.png' alt='Hero' />
      {/* {plink} {stripePaymentLink} */}
      <FormContainer>
        <Form
          form={form}
          name='onboarding'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout='vertical' // Labels are placed above the input fields
        >
          <Form.Item
            name='name'
            label='Nombre:'
            rules={[{ required: true, message: 'Por fvaor ingresa tu nombre' }]}
          >
            <Input placeholder='John Doe' />
          </Form.Item>

          <Form.Item
            name='email'
            label='Email:'
            rules={[
              { required: true, message: 'Por favor ingresa un email' },
              { type: 'email', message: 'Por favor ingresa un email válido' },
            ]}
          >
            <Input placeholder='john.doe@abc.com' />
          </Form.Item>

          <Form.Item label='Celular:' style={{ marginBottom: 0 }}>
            <Row style={{ marginBottom: 0 }}>
              <Col span={8}>
                <Form.Item
                  name='countryCode'
                  initialValue={'507'}
                  rules={[{ required: true, message: 'Selecciona' }]}
                  style={{ marginRight: 8, flex: '0 0 120px' }}
                >
                  <Select>
                    <Option value='1'>
                      <span role='img' aria-label='United States'>
                        🇺🇸
                      </span>{' '}
                      +1
                    </Option>
                    <Option value='57'>
                      <span role='img' aria-label='Colombia'>
                        🇨🇴
                      </span>{' '}
                      +57
                    </Option>
                    <Option value='507'>
                      <span role='img' aria-label='Panama'>
                        🇵🇦
                      </span>{' '}
                      +507
                    </Option>
                    <Option value='34'>
                      <span role='img' aria-label='Spain'>
                        🇪🇸
                      </span>{' '}
                      +34
                    </Option>
                    <Option value='58'>
                      <span role='img' aria-label='Venezuela'>
                        🇻🇪
                      </span>{' '}
                      +58
                    </Option>
                    <Option value='55'>
                      <span role='img' aria-label='Brazil'>
                        🇧🇷
                      </span>{' '}
                      +55
                    </Option>
                    <Option value='39'>
                      <span role='img' aria-label='Italy'>
                        🇮🇹
                      </span>{' '}
                      +39
                    </Option>
                    <Option value='44'>
                      <span role='img' aria-label='UK'>
                        🇬🇧
                      </span>{' '}
                      +44
                    </Option>
                    <Option value='971'>
                      <span role='img' aria-label='UAE'>
                        🇦🇪
                      </span>{' '}
                      +971
                    </Option>
                    {/* Add more options for other countries here */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name='phone'
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa tu celular',
                    },
                  ]}
                  style={{ flex: 1 }}
                >
                  <Input placeholder='66123456' />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            name='company'
            label='Nombre de Compañía:'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa el nombre de tu compañía',
              },
            ]}
          >
            <Input placeholder='ABC SA' />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              block
              style={{
                fontWeight: 500,
                marginTop: 10,
                marginBottom: 10,
              }}
              loading={isSubmitting}
            >
              Entrar a Hero
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
    </PageContainer>
  );
}

// Custom styling for the page container
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
`;

// Custom styling for the logo
const Logo = styled.img`
  width: 200px;
  margin-bottom: 20px;
`;

// Custom styling for the form container
const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export { OnboardingForm };
