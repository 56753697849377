import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message, Spin, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
const { TextArea } = Input;

function ReportActionButton(props) {
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [status, setStatus] = useState(false);
  const [reportCreatorEmail, setReportCreatorEmail] = useState('');
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);
  const [statusMap, setStatusMap] = useState(false);

  useEffect(() => {
    getStatus();
    getStatusMap();
  }, [props.submittedToggle]);

  const getStatus = () => {
    axios({
      method: 'get',
      url: props.API_domain + 'getReportStatus?id=' + props.report_id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setStatus(response.data.status);
        setReportCreatorEmail(response.data.report_creator_email);
      })
      .catch((response) => {
        setError(true);
      });
  };

  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const getStatusMap = () => {
    axios({
      method: 'get',
      url: props.API_domain + 'getReportStatusMap?id=' + props.report_id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setStatusMap(response.data);
      })
      .catch((response) => {
        setError(true);
      });
  };
  const deleteReport = (values) => {
    setDeleting(true);
    // track('deleteReport', {
    //   API_domain: props.API_domain,
    //   email: auth.email,
    //   misc: { report_id: props.report_id },
    // });
    axios({
      method: 'post',
      url: props.API_domain + 'deleteReport?id=' + props.report_id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data:
        reportCreatorEmail != auth.email
          ? {
              comment: values.comment ? values.comment : '',
              emailToNotify: reportCreatorEmail,
            }
          : {},
    })
      .then((response) => {
        props.handleOk();
        props.setSubmittedToggle(!props.submittedToggle);
        props.onSubmit();
        setTimeout(() => setDeleting(false), 2000);
      })
      .catch((response) => {
        setError(true);
      });
  };
  const updateStatus = () => {
    setSubmitting(true);
    const data = {
      status: statusMap[status],
      id: props.report_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'updateStatus',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.handleOk();
        props.setSubmittedToggle(!props.submittedToggle);
        props.onSubmit();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch((response) => {
        // TODO: Add if to not show mistakenly this error if there is another problem
        setSubmitting(false);
        message.error('No tienes permiso de aprobar este reporte');
        setError(true);
      });
  };

  if (status == false || !statusMap) {
    return <> </>;
  }
  if (status == 'reembolsado') {
    return <> </>;
  } else if (statusMap[status].includes('reembolsado')) {
    return (
      <GreenButton type='secondary' onClick={updateStatus}>
        {isSubmitting ? (
          <Spin indicator={antIcon} />
        ) : (
          <>
            Marcar <b> {statusMap[status]}</b>
          </>
        )}
      </GreenButton>
    );
  } else {
    return (
      <>
        <Modal
          visible={visible}
          onCancel={handleCancel}
          centered
          width={450}
          title='Comentario'
          footer={null}
          bodyStyle={{
            overflow: 'auto',
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Form
            form={form}
            onFinish={deleteReport}
            validateMessages={{
              required: 'Por favor déjale saber por qué le cancelas el reporte',
            }}
          >
            <Form.Item
              name='comment'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea
                showCount
                maxLength={140}
                rows={2}
                style={{
                  height: 60,
                  marginTop: 12,
                }}
                placeholder='Hay un gasto duplicado'
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                // type='danger'
                style={{ width: '100%', marginTop: 10 }}
              >
                {isDeleting ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <>Cancelar Reporte y Notificar</>
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div>
          <Tooltip
            placement='top'
            title={
              'Los gastos no se borrarán. Se deshará el reporte y los gastos regresarán a la sección de Gastos'
            }
          >
            <Button
              type='link'
              style={{ display: 'inline-block', color: 'grey' }}
              onClick={() => {
                if (auth.email == reportCreatorEmail) {
                  deleteReport();
                } else {
                  setVisible(true);
                }
              }}
            >
              {isDeleting ? (
                <Spin indicator={antIcon} />
              ) : (
                <>Cancelar Reporte</>
              )}
            </Button>
          </Tooltip>
          <BlueButton type='secondary' onClick={updateStatus}>
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>
                Marcar <b> {statusMap[status]}</b>
              </>
            )}
          </BlueButton>
        </div>
      </>
    );
  }
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

const GreenButton = styled(Button)`
  background-color: rgb(70, 180, 70);
  border-color: rgb(70, 180, 70);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  color: white;
  text-align: center;
  &:focus,
  &:hover {
    background-color: rgb(70, 190, 70);
    border-color: rgb(70, 190, 70);
    color: white;
  }
`;

const BlueButton = styled(Button)`
  margin-bottom: 5px;
  background-color: rgb(33, 137, 251);
  border-color: rgb(33, 137, 251);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  color: white;
  display: inline-block;
  &:focus,
  &:hover {
    background-color: rgb(33, 137, 255);
    border-color: rgb(33, 137, 255);
    color: white;
  }
`;

export { ReportActionButton };
