
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { HelpCard } from './HelpCard.js';
import { Typography } from 'antd';
import { useAnalytics } from 'use-analytics';
import { authContext } from '../ProvideAuth.js';

const { Title } = Typography;

function HelpView(props) {
  const auth = useContext(authContext);
  const { track } = useAnalytics();
  useEffect(() => {
    track('viewHelp', {
      API_domain: props.API_domain,
      email: auth.email,
    });
  }, [])
    
  return (
    <>
      <TitleDiv>
        <Title style={{color: "black"}}>¿Tienes alguna duda?</Title>
      </TitleDiv>
      <HelpCard/>
    </>
  );
}

// const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const TitleDiv = styled.div`
  margin-top: 20px;
`;

export { HelpView };
