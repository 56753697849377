import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin, Input, message } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { AdminCompanySelect } from './AdminCompanySelect.js';
import { ConfigAddExistingMemberSelect } from '../ConfigAddExistingMemberSelect.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminEditUserCompany(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      user_id: selectedUser,
      company_id: values.company_id,
      stripe_subscription_id: values.stripe_subscription_id,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminEditUserCompany',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success('Usuario editado con exito');
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        message.error('Error al editar usuario');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }
  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Edit User Company</ButtonSpan>
      </Button>
      <Modal
        title='Edit User Company'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={form.submit}
          >
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Edit User</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item name='usersfacturas_id' label='Usuario:'>
            <ConfigAddExistingMemberSelect
              API_domain={props.API_domain}
              setSelectedMemberId={setSelectedUser}
              team={'all'}
            />
          </Form.Item>
          <Form.Item
            name="company_id"
            label="Compañía"
          >
            <AdminCompanySelect 
              API_domain={props.API_domain}
              onCompanySelect={(company_id) => form.setFieldsValue({ company_id })}
            />
          </Form.Item>
          <Form.Item
            name='stripe_subscription_id'
            label='Stripe id:'
          >
            <Input placeholder='sub_XxxxxXXxxxX' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminEditUserCompany };
