import { Button, Form, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { ConfigCategoryModal } from './ConfigCategoryModal.js';
import { ConfigSingleCategory } from './ConfigSingleCategory.js';

function ConfigCategoryManager(props) {
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesChanged, setCategoriesChanged] = useState(false);
  const [form] = Form.useForm();

  function fetchCategoryOptions() {
    axios({
      method: 'post',
      url: props.API_domain + 'getCategories',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then(function (response) {
        setCategories(
          response.data.map((category) => ({
            label: category.category,
            value: category.id,
          }))
        );
        setCategoriesChanged(false);
        form.resetFields();
      })
      .catch(function (response) {
        console.log(response);
      });
  }
  useEffect(() => {
    fetchCategoryOptions();
  }, [categoriesChanged]);

  return (
    <div>
      <div>
        {/* if there are no categories, render a Empty from antd with text "helloo wold" */}
        {categories.length === 0 && (
          <>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <div>
                    Apenas crees categorías, el usuario podrá seleccionarlas al
                    crear gastos mediante el bot de WhatsApp
                  </div>
                </>
              }
              style={{ marginTop: 0 }}
            />
          </>
        )}
        {categories.map((category) => (
          <ConfigSingleCategory
            API_domain={props.API_domain}
            category={category}
            setCategoriesChanged={setCategoriesChanged}
          />
        ))}
      </div>
      <div style={{ paddingTop: categories.length === 0 ? 0 : 16 }}>
        <Button type='primary' onClick={() => setVisible(true)}>
          <PlusOutlined /> Crear Categoría
        </Button>
      </div>

      <ConfigCategoryModal
        visible={visible}
        title={'Crear categoría'}
        handleCancel={() => setVisible(false)}
        handleOk={() => setVisible(false)}
        API_domain={props.API_domain}
        team={props.team}
        setCategoryCreated={setCategoriesChanged}
      />
    </div>
  );
}

export { ConfigCategoryManager };
