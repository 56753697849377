// AccountingTransactionsTable.js

import React, { useState, useEffect, useContext } from 'react';
import { Table, Modal, Button, message, Popconfirm, Input, Space, Select, DatePicker } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import AccountingAccountSelect from './AccountingAccountSelect'; // Import the component
import AccountingTransactionsUploader from './AccountingTransactionsUploader'; // Import the uploader component
import { authContext } from '../../ProvideAuth.js';
import moment from 'moment';
import HeroAxios from '../../helpers/HeroAxios.js'; // Import your custom axios instance

const { RangePicker } = DatePicker;

function AccountingTransactionsTable({ API_domain, accountingClientCompany }) {
  const auth = useContext(authContext);
  const [transactions, setTransactions] = useState([]);
  const [entries, setEntries] = useState([]);
  const [clientAccounts, setClientAccounts] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // State for creating new transaction
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [newEntries, setNewEntries] = useState([]);
  const [newTransactionDescription, setNewTransactionDescription] = useState('');
  const [newTransactionDate, setNewTransactionDate] = useState(moment()); // Default to today
  const [totalDebits, setTotalDebits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);

  const [editingTransactionId, setEditingTransactionId] = useState(null);


  // Fetch transactions from the backend
  useEffect(() => {
    fetchTransactions();
    fetchClientAccounts();
  }, [API_domain, accountingClientCompany, auth]);

  const fetchClientAccounts = async () => {
    try {
      const response = await HeroAxios.post('getClientAccounts', {
        client_id: accountingClientCompany.accounting_client_id,
      });
      const accounts = response.data.accounts;
      setClientAccounts(accounts);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      message.error('Error al obtener las cuentas');
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await HeroAxios.post('getAccountingTransactions', {
        client_id: accountingClientCompany.accounting_client_id,
      });
      setTransactions(response.data.transactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      message.error('Error al obtener las transacciones');
    }
  };

  const handleEditTransaction = async (transaction) => {
    try {
      // Fetch entries for the selected transaction
      const response = await HeroAxios.post('getTransactionEntries', {
        transaction_id: transaction.transaction_id,
      });
      const entries = response.data.entries;
  
      // Map the entries to the format required by newEntries
      const editableEntries = entries.map((entry) => ({
        key: entry.entry_id.toString(), // Use entry_id as key
        entry_id: entry.entry_id,
        account_id: entry.account_id,
        amount: entry.amount.toString(),
        entry_type: entry.entry_type,
      }));
  
      // Prefill the transaction details and entries
      setNewTransactionDescription(transaction.description);
      setNewTransactionDate(moment(transaction.transaction_date));
      setNewEntries(editableEntries);
      setEditingTransactionId(transaction.transaction_id);
  
      // Open the modal
      setIsCreateModalVisible(true);
    } catch (error) {
      console.error('Error editing transaction:', error);
      message.error('Error al editar la transacción');
    }
  };

  // Handle row click to open modal
  const handleRowClick = (record) => {
    handleEditTransaction(record);
  };

  // Handle transaction deletion
  const handleDeleteTransaction = async (transaction_id) => {
    try {
      await HeroAxios.post('deleteTransaction', {
        transaction_id,
      });
      message.success('Transacción eliminada exitosamente');
      // Refresh transactions list
      setTransactions((prev) =>
        prev.filter((t) => t.transaction_id !== transaction_id)
      );
    } catch (error) {
      console.error('Error deleting transaction:', error);
      message.error('Error al eliminar la transacción');
    }
  };

  // Handle entry account update
  const handleAccountChange = (entry_id, new_account_id) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.entry_id === entry_id
          ? { ...entry, account_id: new_account_id }
          : entry
      )
    );
  };

  // Save updated entries
  const handleSaveEntries = async () => {
    try {
      await HeroAxios.post('updateEntries', {
        entries,
      });
      message.success('Entradas actualizadas exitosamente');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating entries:', error);
      message.error('Error al actualizar las entradas');
    }
  };

  // Handle adding new entry in create transaction modal
  const addNewEntry = () => {
    const newKey = `new-${Date.now()}`; // Use a string to distinguish from existing entries
    setNewEntries([
      ...newEntries,
      {
        key: newKey,
        entry_id: null, // New entry
        account_id: null,
        amount: '',
        entry_type: 'D', // default to Debit
      },
    ]);
  };

  // Handle changes in new entries
  const handleNewEntryChange = (key, field, value) => {
    setNewEntries((prevEntries) => {
      const updatedEntries = prevEntries.map((entry) => {
        if (entry.key === key) {
          return { ...entry, [field]: value };
        }
        return entry;
      });
      return updatedEntries;
    });
  };

  // Remove an entry from new entries
  const removeNewEntry = (key) => {
    setNewEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.key === key ? { ...entry, isDeleted: true } : entry
      )
    );
  };

  const roundToTwo = (num) => {
    return Math.round(num * 100) / 100;
  };
  // Calculate total debits and credits whenever newEntries change
  useEffect(() => {
    let debits = 0;
    let credits = 0;
    newEntries
      .filter((entry) => !entry.isDeleted) // Exclude deleted entries
      .forEach((entry) => {
        let amount = parseFloat(entry.amount);
        amount = isNaN(amount) ? 0 : roundToTwo(amount);
        if (entry.entry_type === 'D') {
          debits += amount;
        } else {
          credits += amount;
        }
      });
    // Round to two decimal places to avoid float errors
    setTotalDebits(parseFloat(debits.toFixed(2)));
    setTotalCredits(parseFloat(credits.toFixed(2)));
  }, [newEntries]);

  // Function to populate initial balances
  const populateInitialBalances = () => {
    const entries = clientAccounts.map((account) => ({
      key: `${account.account_id}-${Date.now()}`, // Ensure unique key
      account_id: account.account_id,
      amount: '',
      entry_type: 'D', // default to Debit
    }));
    setNewEntries(entries);
  };

  // Handle creating a new transaction
  const handleCreateTransaction = async () => {
    try {
      // Prepare data to send
      const transactionData = {
        description: newTransactionDescription,
        transaction_date: newTransactionDate.format('YYYY-MM-DD'),
        accounting_client_id: accountingClientCompany.accounting_client_id,
        entries: newEntries.map((entry) => ({
          account_id: entry.account_id,
          amount: parseFloat(entry.amount),
          entry_type: entry.entry_type,
        })),
      };
  
      // Call backend endpoint
      await HeroAxios.post('createTransaction', transactionData);
  
      message.success('Transacción creada exitosamente');
  
      // Refresh transactions list
      fetchTransactions();
  
      // Reset states
      resetNewTransactionState();
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error('Error creating transaction:', error);
      message.error('Error al crear la transacción');
    }
  };

  const handleUpdateTransaction = async () => {
    try {
      // Prepare data to send
      const transactionData = {
        transaction_id: editingTransactionId,
        description: newTransactionDescription,
        transaction_date: newTransactionDate.format('YYYY-MM-DD'),
        accounting_client_id: accountingClientCompany.accounting_client_id,
        entries: newEntries.map((entry) => ({
          entry_id: entry.entry_id,
          account_id: entry.account_id,
          amount: parseFloat(entry.amount),
          entry_type: entry.entry_type,
          isDeleted: entry.isDeleted || false,
        })),
      };
  
      // Call backend endpoint
      await HeroAxios.post('updateTransaction', transactionData);
  
      message.success('Transacción actualizada exitosamente');
  
      // Refresh transactions list
      fetchTransactions();
  
      // Reset states
      resetNewTransactionState();
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error('Error updating transaction:', error);
      message.error('Error al actualizar la transacción');
    }
  };
  
  

  const handleCloneTransaction = async (transaction) => {
    try {
      // Fetch entries for the transaction to clone
      const response = await HeroAxios.post('getTransactionEntries', {
        transaction_id: transaction.transaction_id,
      });
      const entries = response.data.entries;
  
      // Map the entries to the format required by newEntries
      const clonedEntries = entries.map((entry) => ({
        key: `${entry.entry_id}-${Date.now()}`, // Unique key
        account_id: entry.account_id,
        amount: entry.amount.toString(), // Ensure it's a string
        entry_type: entry.entry_type,
      }));
  
      // Prefill the transaction details and entries
      setNewTransactionDescription(transaction.description);
      setNewTransactionDate(moment(transaction.transaction_date));
      setNewEntries(clonedEntries);
  
      // Open the create transaction modal
      setIsCreateModalVisible(true);
    } catch (error) {
      console.error('Error cloning transaction:', error);
      message.error('Error al clonar la transacción');
    }
  };
  
  const resetNewTransactionState = () => {
    setNewTransactionDescription('');
    setNewTransactionDate(moment());
    setNewEntries([]);
    setEditingTransactionId(null);
  };

  const transactionColumns = [
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar descripción"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.description ? record.description.toString().toLowerCase().includes(value.toLowerCase()) : '',
    },
    {
      title: 'Fecha de Transacción',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      sorter: (a, b) =>
        moment(a.transaction_date).unix() - moment(b.transaction_date).unix(),
      render: (text) => moment(text).format('YYYY-MM-DD'),
      // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      //   <div style={{ padding: 8 }}>
      //     <RangePicker
      //       value={selectedKeys[0]}
      //       onChange={(dates) => setSelectedKeys(dates ? [dates] : [])}
      //       style={{ marginBottom: 8, display: 'block' }}
      //     />
      //     <Space>
      //       <Button
      //         type="primary"
      //         onClick={confirm}
      //         size="small"
      //         style={{ width: 90 }}
      //       >
      //         Buscar
      //       </Button>
      //       <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
      //         Reset
      //       </Button>
      //     </Space>
      //   </div>
      // ),
      // onFilter: (value, record) => {
      //   const recordDate = moment(record.transaction_date);
      //   const [start, end] = value;
      //   return recordDate.isSameOrAfter(start, 'day') && recordDate.isSameOrBefore(end, 'day');
      // },
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_time',
      key: 'created_time',
      sorter: (a, b) =>
        moment(a.created_time).unix() - moment(b.created_time).unix(),
      render: (text) => moment(text).format('YYYY-MM-DD'),
      // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      //   <div style={{ padding: 8 }}>
      //     <RangePicker
      //       value={selectedKeys[0]}
      //       onChange={(dates) => setSelectedKeys(dates ? [dates] : [])}
      //       style={{ marginBottom: 8, display: 'block' }}
      //     />
      //     <Space>
      //       <Button
      //         type="primary"
      //         onClick={confirm}
      //         size="small"
      //         style={{ width: 90 }}
      //       >
      //         Buscar
      //       </Button>
      //       <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
      //         Reset
      //       </Button>
      //     </Space>
      //   </div>
      // ),
      // onFilter: (value, record) => {
      //   const recordDate = moment(record.created_time);
      //   const [start, end] = value;
      //   return recordDate.isSameOrAfter(start, 'day') && recordDate.isSameOrBefore(end, 'day');
      // },
    },
    {
      title: 'Zoho Journal ID',
      dataIndex: 'zoho_journal_id',
      key: 'zoho_journal_id',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      sorter: (a, b) => a.total - b.total,
      render: (text, record) => {
        const total = record.total; // Already calculated on the backend
        return total.toFixed(2);
      },
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="¿Seguro que deseas eliminar esta transacción?"
            onConfirm={(e) => { e.stopPropagation(); handleDeleteTransaction(record.transaction_id); }}
            okText="Sí"
            cancelText="No"
            onClick={(e) => e.stopPropagation()} // Prevent row click
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={(e) => e.stopPropagation()} // Prevent row click
            />
          </Popconfirm>
          <Button
            type="secondary"
            onClick={(e) => { e.stopPropagation(); handleCloneTransaction(record); }}
          >
            Clonar
          </Button>
        </Space>
      ),
    },
  ];

  const entryColumns = [
    {
      title: 'Entry ID',
      dataIndex: 'entry_id',
      key: 'entry_id',
    },
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => (
        <AccountingAccountSelect
          value={record.account_id}
          onChange={(value) => handleAccountChange(record.entry_id, value)}
          clientId={accountingClientCompany.accounting_client_id}
          API_domain={API_domain}
          auth={auth}
          accounts={clientAccounts}
        />
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Tipo de Entrada',
      dataIndex: 'entry_type',
      key: 'entry_type',
    },
  ];

  const newEntryColumns = [
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      width: '200px',
      render: (text, record) => (
        <AccountingAccountSelect
          value={record.account_id}
          onChange={(value) => handleNewEntryChange(record.key, 'account_id', value)}
          clientId={accountingClientCompany.accounting_client_id}
          API_domain={API_domain}
          auth={auth}
        />
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <Input
          value={record.amount}
          onChange={(e) => handleNewEntryChange(record.key, 'amount', e.target.value)}
        />
      ),
    },
    {
      title: 'Tipo de Entrada',
      dataIndex: 'entry_type',
      key: 'entry_type',
      render: (text, record) => (
        <Select
          value={record.entry_type}
          onChange={(value) => handleNewEntryChange(record.key, 'entry_type', value)}
          style={{ width: 100 }}
        >
          <Select.Option value='D'>Débito</Select.Option>
          <Select.Option value='C'>Crédito</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => removeNewEntry(record.key)}
        />
      ),
    },
  ];

  return (
    <>
      <Button type='primary' onClick={() => setIsCreateModalVisible(true)} style={{ marginBottom: 16 }}>
        Crear Transacción
      </Button>
      <AccountingTransactionsUploader
        API_domain={API_domain}
        accountingClientCompany={accountingClientCompany}
        fetchTransactions={fetchTransactions}
      />
      <Table
        dataSource={transactions}
        columns={transactionColumns}
        rowKey='transaction_id'
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />

      {/* Modal for viewing and editing entries of a transaction */}
      {/* Unified Modal for Creating and Editing Transactions */}
      <Modal
        title={editingTransactionId ? `Editar Transacción` : "Crear Nueva Transacción"}
        visible={isCreateModalVisible}
        onCancel={() => {
          setIsCreateModalVisible(false);
          resetNewTransactionState();
        }}
        onOk={editingTransactionId ? handleUpdateTransaction : handleCreateTransaction}
        okButtonProps={{ disabled: totalDebits !== totalCredits || newEntries.length === 0 }}
        width={800}
      >
        <Input
          placeholder="Descripción de la Transacción"
          value={newTransactionDescription}
          onChange={(e) => setNewTransactionDescription(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <DatePicker
          value={newTransactionDate}
          onChange={(date) => setNewTransactionDate(date)}
          style={{ marginBottom: 16, marginLeft: 16 }}
        />
        <Table
          dataSource={newEntries.filter((entry) => !entry.isDeleted)}
          columns={newEntryColumns}
          rowKey='key'
          pagination={false}
        />
        <Button type='dashed' onClick={addNewEntry} style={{ marginTop: 16 }}>
          Añadir Entrada
        </Button>
        <Button type='primary' onClick={populateInitialBalances} style={{ marginTop: 16, marginLeft: 8 }}>
          Saldos Iniciales
        </Button>
        <div style={{ marginTop: 16 }}>
          <p>Total Débitos: {totalDebits.toFixed(2)}</p>
          <p>Total Créditos: {totalCredits.toFixed(2)}</p>
        </div>
      </Modal>

    </>
  );
}

export { AccountingTransactionsTable };
