import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin, Upload, message } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { read, utils } from 'xlsx';
import { TeamSelect } from '../TeamSelect.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CsvUploader(props) {
  const [files, setFiles] = useState([]);

  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      // Process the workbook data as needed
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      props.setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  const fileRemoved = (event) => {
    const filteredFiles = files.filter((file) => file !== event);
    setFiles(filteredFiles);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={(e) => uploadChanged(e)}
      onRemove={(e) => fileRemoved(e)}
      // capture={undefined}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

function AdminMassUploader(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [team, setTeam] = useState(null);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      team: team,
      csv_data: csvData,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'adminMassUpload',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success('Pocotón de gastos subidos!');
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onTeamSelect = (team) => {
    setTeam(team);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>CARGA MASIVA</ButtonSpan>
      </Button>
      <Modal
        title='Carga Masiva'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={onFinish}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Subir Gastos</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {/* <Form.Item
            name="finca"
            label="Finca:"
            rules={[
              {
                required: true,
                message: "Por favor escriba el nombre de la finca",
              },
            ]}
          > */}
          <TeamSelect
            API_domain={props.API_domain}
            onTeamSelect={onTeamSelect}
            team={team}
          />
          <br></br>
          <br></br>
          <div>COLUMNAS: amount*, itbms, date*, category, description</div>
          <div>amount debe ser el total incluyendo itbms (ejemplo: 1.07)</div>
          <div>itbms debe ser el itbms (ejemplo: 0.07)</div>
          <div>date debe ser algo como 3/12/23 (marzo)</div>
          <div>omitir título de columnas si van vacías</div>
          <div>orden de columnas no importa.</div>
          <br></br>
          Asterisco es que son obligatorias
          <br></br>
          <CsvUploader setCsvData={setCsvData} />
          {/* </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminMassUploader };
