import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';
const { TextArea } = Input;

function AssigneeSelect(props) {
  const auth = useContext(authContext);
  const [selectedAssignee, setSelectedAssignee] = useState();
  const [selectedAssigneeName, setSelectedAssigneeName] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState([]);

  const updateAssignee = (values) => {
    setSubmitting(true);
    if (props.setRefreshToggle) {
      // this is to refresh table after changing assignee and closing modal
      props.setRefreshToggle(true);
    }
    axios({
      method: 'post',
      url: props.API_domain + props.API_endPoint,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        id: props.document.id,
        new_assignee_id: selectedAssignee,
        comment: values.comment,
      },
    })
      .then((response) => {
        message.success('Asignado a ' + selectedAssigneeName);
        props.onSubmit();
        setTimeout(() => setSubmitting(false), 2000);
        setVisible(false);
      })
      .catch((response) => {
        message.error('Error al asignar');
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getUsersIdNameEmail',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.document.team,
      },
    })
      .then((response) => {
        let selectOptions = response.data.map((user) => ({
          label: user.name,
          value: user.id,
        }));
        setOptions(selectOptions);
        setSelectedAssignee(props.document.assignee_id);
      })
      .catch((response) => {
        console.log('Error: ' + response);
      });
  };
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    setSelectedAssignee(props.document.assignee_id);
    setVisible(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        centered
        width={450}
        title='Comentario'
        footer={null}
        bodyStyle={{
          overflow: 'auto',
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Form
          form={form}
          onFinish={updateAssignee}
          validateMessages={{
            required: 'Por favor déjale saber por qué le asignas esto',
          }}
        >
          <Form.Item
            name='comment'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={140}
              rows={2}
              style={{
                height: 60,
              }}
              placeholder='Comentario'
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType='submit'
              type='primary'
              style={{ width: '100%', marginTop: 10 }}
            >
              {isSubmitting ? (
                <Spin
                  indicator={
                    <LoadingOutlined spin style={{ color: 'white' }} />
                  }
                />
              ) : (
                <>Asignar a {selectedAssigneeName}</>
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Select
        value={selectedAssignee}
        labelInValue
        style={{
          width: 145,
        }}
        className='my-select'
        ellipsis={false}
        onChange={(value) => {
          setSelectedAssignee(value.value);
          setSelectedAssigneeName(value.label);
          setVisible(true);
        }}
        // bordered={false}
        options={options}
      />
    </>
  );
}

export { AssigneeSelect };
