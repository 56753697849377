import {
  DownloadOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Modal, Select, Space, Spin, Input, Checkbox } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { downloadBlob, tildeRemover } from '../utils.js';
import { useAnalytics } from 'use-analytics';

function ReportDownloaderCustomModal(props) {
  const [options, setOptions] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [savedColumns, setSavedColumns] = useState([{}]); // Array with one element so form defaults to 1 instead of 0 columns
  const [form] = Form.useForm();
  const auth = useContext(authContext);
  const { track } = useAnalytics();

  function fetchReportColumnOptions() {
    // track('downloadCustom', {
    //   API_domain: props.API_domain,
    //   email: auth.email,
    //   misc: { report_id: props.report_id },
    // });
    axios({
      method: 'get',
      url: props.API_domain + 'getReportExpenses?id=' + props.report_id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setOptions(optionsConstructor(response.data[0]));
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  function fetchDefaultColumns() {
    axios({
      method: 'get',
      url: props.API_domain + 'getPersonalizedSavedColumns?report_id=' + props.report_id,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        setSavedColumns(response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  function saveColumns(columns){
    axios({
      method: 'post',
      url: props.API_domain + 'savePersonalizedColumns',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        report_id: props.report_id,
        columns: columns,
      }
    })
      .then(function (response) {
        setSavedColumns(columns);
        console.log(response);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  function onSave(event){
    if (event.target.checked == false) {
      saveColumns([]);
    } else {
      saveColumns(form.getFieldValue('columns'));
    }
  }

  useEffect(() => {
    fetchReportColumnOptions();
    fetchDefaultColumns();
  }, []);

  const onFinish = (values) => {
    props.onSubmit(values.columns);
  };

  return (
    <Modal
      title='Configura las columnas'
      visible={props.visible}
      footer={
        <Button
          type='primary'
          htmlType='submit'
          block
          style={{
            fontWeight: 500,
            marginTop: 10,
            marginBottom: 10,
          }}
          onClick={form.submit}
        >
          {isSubmitting ? <Spin indicator={antIcon} /> : <>Descargar</>}
        </Button>
      }
      onCancel={() => props.setVisible(false)}
      // width={400}
    >
      <h4><Checkbox onChange={onSave} checked={savedColumns.length > 1}label=""/> Guardar Columnas</h4>
      <br></br>
      <Form onFinish={onFinish} form={form} initialValues={{columns: savedColumns}}>
        <Form.List name='columns'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align='baseline'
                >
                  <Form.Item key={key} name={[name, 'title']}>
                    <Input placeholder='Titulo de columna' />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'column']}
                    fieldKey={[fieldKey, 'column']}
                    rules={[{ required: true, message: 'Escoge una columna' }]}
                  >
                    <Select options={options} placeholder='Escoge la columna' />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar columna
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
      
    </Modal>
  );
}

const value_to_copy = {
  receipt_number: 'Numero de factura',
  descripcion: 'Descripcion',
  category: 'Categoria',
  gastante: 'Gastante',
  fecha: 'Fecha',
  total: 'Total con ITBMS',
  total_no_itbms: 'Total sin ITBMS',
  itbms: 'ITBMS',
  ruc: 'RUC',
  provider: 'Proveedor',
  dv: 'DV',
};

function optionsConstructor(gasto) {
  var options = [];
  Object.keys(gasto).forEach((column) => {
    if (column in value_to_copy) {
      options.push({
        label: value_to_copy[column],
        value: column,
      });
    }
  });
  options.push({
    label: "Vacía",
    value: " ",
  });
  return options;
}

function ReportDownloaderCustom(props) {
  const auth = useContext(authContext);
  const [visible, setVisible] = useState(false);

  function download(values) {
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getReportExpenses?id=' +
        props.report_id +
        '&download=Custom',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        // populate empty itbms
        for (var r = 0; r < result.data.length; r++) {
          if (isNaN(result.data[r]['itbms']) || result.data[r]['itbms'] <= 0) {
            result.data[r]['itbms'] = '0.00';
          }
          result.data[r]['fecha'] =
            result.data[r]['fecha'].split('/')[1] +
            '/' +
            result.data[r]['fecha'].split('/')[2] +
            '/' +
            result.data[r]['fecha'].split('/')[0];
        }
        var csvRows = [];
        result.data.forEach((gasto) => {
          csvRows.push(
            values
              .map((column) => {
                if (
                  ['descripcion', 'provider', 'category'].includes(
                    column.column
                  )
                ) {
                  return '"' + gasto[column.column] + '"';
                }
                return gasto[column.column];
              })
              .join(',')
          );
          return;
        });
        var gastos_csv ='SEP=,\n' +
          values
            .map((column) => {
              // return value_to_copy[column.column]; // column titles
              // return; // input fields for each column added
              return column.title;
            })
            .join(',') +
          '\n' +
          csvRows.join('\n');
        gastos_csv = tildeRemover(gastos_csv);
        const gastos_blob = new Blob(['\ufeff', gastos_csv], {
          type: 'text/csv;charset=UTF-8',
        });
        downloadBlob(
          gastos_blob,
          'reporte' + props.report_id + 'Personalizado.csv'
        );
      },
      (error) => {
        console.log('Error is ', error);
        // setLoading(false);
      }
    );
  }
  return (
    <>
      <p onClick={() => setVisible(true)}>
        <DownloadOutlined />
        Personalizado
      </p>
      <ReportDownloaderCustomModal
        API_domain={props.API_domain}
        width={props.width}
        report_id={props.report_id}
        visible={visible}
        setVisible={setVisible}
        onSubmit={download}
      />
    </>
  );
}

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ReportDownloaderCustom };
