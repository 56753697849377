import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, message, Spin, Input, Typography, Switch } from 'antd';
import HeroAxios from '../helpers/HeroAxios.js';
import styled from 'styled-components/macro';

const { Text } = Typography;

function ConfigClientConfigsTable() {
  const [clientConfigs, setClientConfigs] = useState([]);
  const [initialClientConfigs, setInitialClientConfigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await HeroAxios.get('getClientConfigs');
        const clientConfigsData = response.data.map(config => ({
          ...config,
          key: config.id,
        }));

        setClientConfigs(clientConfigsData);
        setInitialClientConfigs(JSON.parse(JSON.stringify(clientConfigsData)));
      } catch (error) {
        message.error('Error fetching client configs data');
        console.error('Error fetching client configs data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (value, recordKey, fieldName) => {
    setClientConfigs(prevData =>
      prevData.map(item => {
        if (item.key === recordKey) {
          return {
            ...item,
            [fieldName]: value,
          };
        }
        return item;
      })
    );
  };

  const handleLoginInfoChange = (value, field, recordKey) => {
    setClientConfigs(prevData =>
      prevData.map(item => {
        if (item.key === recordKey) {
          const newLoginInfo = { ...item.login_info, [field]: value };
          return {
            ...item,
            login_info: newLoginInfo,
          };
        }
        return item;
      })
    );
  };

  const handleQuestionMapChange = (value, question, recordKey) => {
    setClientConfigs(prevData =>
      prevData.map(item => {
        if (item.key === recordKey) {
          const newQuestionMap = { ...item.login_info.question_map, [question]: value };
          const newLoginInfo = { ...item.login_info, question_map: newQuestionMap };
          return {
            ...item,
            login_info: newLoginInfo,
          };
        }
        return item;
      })
    );
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const changedConfigs = clientConfigs.filter(config => {
        const initialConfig = initialClientConfigs.find(
          initConfig => initConfig.key === config.key
        );
        return JSON.stringify(config) !== JSON.stringify(initialConfig);
      });

      if (changedConfigs.length > 0) {
        await HeroAxios.post('saveClientConfigsBulk', { payload: changedConfigs });
        message.success('Client configurations updated successfully');
        setInitialClientConfigs(JSON.parse(JSON.stringify(clientConfigs)));
      } else {
        message.info('No changes to save');
      }
    } catch (error) {
      message.error('Error saving client configurations data');
      console.error('Error saving client configurations data:', error);
    } finally {
      setSaving(false);
    }
  };

  // Filter data based on search term
  const filteredData = clientConfigs.filter(config => {
    const term = searchTerm.toLowerCase();
    return (
      config.id.toString().includes(term) ||
      (config.accounting_client_id && 
       config.accounting_client_id.toString().toLowerCase().includes(term)) ||
      (config.accounting_client_name && 
       config.accounting_client_name.toLowerCase().includes(term)) ||
      (config.site && config.site.toLowerCase().includes(term))
    );
  });

  if (loading) {
    return (
      <Container>
        <Spin size="large" />
      </Container>
    );
  }

  return (
    <Container>
      <SearchContainer>
        <Input.Search
          placeholder="Search by ID, Client Name, or Site"
          onSearch={(value) => setSearchTerm(value)}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
      </SearchContainer>
      <StyledCardContainer>
        {filteredData.map(config => (
          <StyledCard
            key={config.key}
            title={
              <CardTitle>
                <span>ID: {config.id}</span>
                <span>{config.accounting_client_name}</span>
                <span>Client ID: {config.accounting_client_id}</span>
              </CardTitle>
            }
            extra={
              <Switch
                checked={config.enabled}
                onChange={(checked) => handleInputChange(checked, config.key, 'enabled')}
                size="small"
              />
            }
          >
            <Row gutter={16}>
              <Col span={4}>
                <InfoLabel>Site:</InfoLabel>
                <StyledInput
                  value={config.site}
                  onChange={(e) => handleInputChange(e.target.value, config.key, 'site')}
                />
              </Col>
              <Col span={20}>
                <InfoLabel>Login Info:</InfoLabel>
                <Row gutter={8}>
                  {Object.entries(config.login_info).map(([key, value]) => (
                    <Col span={8} key={key}>
                      <InfoLabel>{key}:</InfoLabel>
                      <StyledInput
                        value={value}
                        onChange={(e) => handleLoginInfoChange(e.target.value, key, config.key)}
                        type={key === 'password' ? 'password' : 'text'}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </StyledCard>
        ))}
      </StyledCardContainer>
      <SaveButtonContainer>
        <Button type="primary" onClick={handleSave} loading={saving}>
          Save Changes
        </Button>
      </SaveButtonContainer>
    </Container>
  );
}

// Styled components
const Container = styled.div`
  padding: 20px;
`;

const SearchContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledCardContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  .ant-card-head {
    min-height: 36px;
  }
  .ant-card-head-title {
    padding: 8px 0;
  }
  .ant-card-extra {
    padding: 8px 0;
  }
  .ant-card-body {
    padding: 12px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  
  span {
    margin-right: 16px;
  }
`;

const InfoLabel = styled(Text)`
  display: block;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
`;

const StyledInput = styled(Input)`
  font-size: 12px;
  padding: 4px 8px;
  height: 24px;
`;

const SaveButtonContainer = styled.div`
  text-align: right;
`;

export { ConfigClientConfigsTable };
