import { Form, Modal, Progress, Switch } from 'antd';

function DownloadModal(props) {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      visible={props.visible}
      title='Descargar Gastos'
      okText='Descargar'
      confirmLoading={props.isLoading}
      cancelText='Cancelar'
      onCancel={props.onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields();
            props.onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        {...layout}
        form={form}
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item name='with_images' label='Incluir fotos'>
          <Switch />
        </Form.Item>
      </Form>
      {props.percentImagesDone ? (
        <Progress
          percent={Math.round(props.percentImagesDone)}
          status='active'
        />
      ) : (
        <></>
      )}
    </Modal>
  );
}

export { DownloadModal };
