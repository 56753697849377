import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Typography,
  Button,
  Form,
  Modal,
  Spin,
  Upload,
  message,
  Select,
} from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { AccountingClientSelect } from '../Accounting/AccountingClientSelect.js';
import { authContext } from '../../ProvideAuth.js';
import { read, utils } from 'xlsx';

const { Link } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const columnMapping = [
  'nombre_del_local',
  'id_de_pedido',
  'id_del_restaurante',
  'metodo_de_entrega',
  'forma_de_pago',
  'medio_de_pago',
  'estado_del_pedido',
  'fecha_del_pedido',
  'aceptado_en',
  'lista_para_retiro_eta',
  'lista_para_retiro',
  'repartidor_en_local',
  'retirado',
  'entrega_eta',
  'entregado_en',
  'cancelado_en',
  'motivo_de_rechazo',
  'tipo_de_rechazo',
  'total_del_pedido',
  'costos_de_empaque',
  'tarifa_minima_por_pedido',
  'amount_field_v',
  'tax_charge',
  'payment_fee',
  'descuentos_subsidiados_por_la_tienda',
  'voucher_subsidiado_por_la_tienda',
  'servicio_pedidosya',
  'cargos',
  'cargos_por_descuentos_fugaces',
  'empty1',
  'empty2',
  'ingreso_estimado',
  'amount_field_ag',
  'monto_adeudado_a_pedidosya',
  'amount_field_ai',
  'amount_field_au',
  'amount_field_ak',
  'descuento_total',
  'total_del_voucher',
  'amount_field_an',
  'articulos',
];

const CsvUploader = ({ setCsvData }) => {
  const [files, setFiles] = useState([]);

  const uploadChanged = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, {
        type: 'array',
        cellText: false,
        cellDates: false,
        cellNF: false,
        raw: true,
      });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      // Map the uploaded data to the correct format based on column names
      const jsonData = utils.sheet_to_json(worksheet, {
        header: columnMapping,
        range: 1, // Add this line to skip the header row
        defval: '', // Set default value for empty cells
      });
      setCsvData(jsonData);
      console.log(jsonData);
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  const onRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
  };

  const onChange = ({ fileList }) => {
    setFiles(fileList);
  };

  return (
    <Upload
      accept='.csv,.xlsx,.xls'
      beforeUpload={uploadChanged}
      onRemove={onRemove}
      fileList={files}
      onChange={onChange}
      maxCount={1}
    >
      <Button icon={<UploadOutlined />}>Upload CSV</Button>
    </Upload>
  );
};

function AdminPedidosYaUpload(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectedAccountingClientId, setSelectedAccountingClientId] =
    useState(null);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    setSubmitting(true);
    const data = {
      accounting_client_id: selectedAccountingClientId,
      orders_data: csvData,
    };
    axios({
      method: 'post',
      url: `${props.API_domain}pedidosya/upload`,
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        const { inserted_count, failed_rows_count } = response.data.data;
        message.success(
          `PedidosYa data processed successfully. Inserted: ${inserted_count}, Failed: ${failed_rows_count}`
        );
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch((error) => {
        console.log(error);
        message.error(
          'There was an error processing the file, please check the columns and permissions.'
        );
        setSubmitting(false);
      });
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Upload PedidosYa Data</ButtonSpan>
      </Button>
      <Modal
        title='Upload PedidosYa Data'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={onFinish}
          >
            {isSubmitting ? (
              <Spin indicator={antIcon} />
            ) : (
              <>Process Document</>
            )}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form {...layout} name='basic' form={form}>
          <AccountingClientSelect
            API_domain={props.API_domain}
            onAccountingClient={(accountingClient) => {
              setSelectedAccountingClientId(accountingClient);
            }}
          />
          <br />
          <br />
          <CsvUploader setCsvData={setCsvData} />
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminPedidosYaUpload };
