import React, { useContext, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Image,
  message,
  Tag,
} from 'antd';
import { CopyOutlined, UploadOutlined } from '@ant-design/icons';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';

const CompanySection = (props) => {
  const auth = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    name: props.accountingClientCompany.name,
    logo_image_url: props.accountingClientCompany.logo_image_url,
    ruc: props.accountingClientCompany.ruc,
  });

  const handleInputChange = (field, value) => {
    setFormDataState({
      ...formDataState,
      [field]: value,
    });
  };
  const handleFileChange = (info) => {
    setFormDataState({
      ...formDataState,
      logo: info.fileList,
    });
  };

  const handleSave = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      'accountingClientCompanyId',
      props.accountingClientCompany.accounting_client_company_id
    );
    Object.keys(formDataState).forEach((key) => {
      if (key === 'logo' && formDataState[key]) {
        formDataState[key].forEach((file) => {
          formData.append('logo_image_url', file.originFileObj);
        });
      } else {
        formData.append(key, formDataState[key]);
      }
    });

    axios({
      method: 'post',
      url: props.API_domain + 'updateUserAccountingClientCompanyInfo',
      data: formData,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.data) {
          message.success('Compañía actualizada exitosamente');
          setFormDataState((prevState) => ({
            ...prevState,
            logo_image_url: response.data.logo_image_url, // Update with the returned URL
          }));
        } else {
          message.error(
            'Error updating the company information'
            // response.data.message || 'Error updating the company information'
          );
        }
      })
      .catch((error) => {
        console.error('Error updating company information:', error);
        message.error('Error actualizando la compañía');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <h3 style={{ textAlign: 'left', paddingLeft: 0 }}>Compañía</h3>

      {/* Razón Social */}
      <Form.Item label='Nombre' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={formDataState.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(formDataState.name || '')}
            />
          </Col>
        </Row>
      </Form.Item>

      {/* RUC */}
      <Form.Item label='RUC' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={formDataState.ruc}
              onChange={(e) => handleInputChange('ruc', e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(formDataState.ruc || '')}
            />
          </Col>
        </Row>
      </Form.Item>

      {/* Logo Upload */}
      <Form.Item
        name='document'
        valuePropName='fileList'
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
        style={{ marginLeft: 96, textAlign: 'left' }}
      >
        <div style={{ textAlign: 'left' }}>
          {formDataState.logo_image_url ? (
            <Image
              src={formDataState.logo_image_url}
              alt='logo'
              style={{ width: '200px', marginRight: 32 }}
            />
          ) : (
            <Tag style={{ fontSize: 20, padding: 12 }} color='yellow'>
              Logo pendiente
            </Tag>
          )}

          <Upload beforeUpload={() => false} onChange={handleFileChange}>
            <Button icon={<UploadOutlined />}>
              {formDataState.logo_image_url ? 'Cambiar Logo' : 'Subir Logo'}
            </Button>
          </Upload>
        </div>
      </Form.Item>

      {/* Save Button */}
      <Form.Item style={{ marginLeft: 96, textAlign: 'left' }}>
        <Button type='primary' onClick={handleSave} loading={loading}>
          Guardar
        </Button>
      </Form.Item>
    </>
  );
};

export default CompanySection;
