import { Affix, Col, Empty, Layout, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from './TeamSelect.js';
import { VisualizationsComponents } from './VisualizationsComponents.js';
import { authContext } from '../ProvideAuth.js';
import axios from 'axios';

const { Content } = Layout;

function VisualizationsView(props) {
  const [visualizationsEmpty, setVisualizationsEmpty] = useState(false);
  const auth = useContext(authContext);
  const [teamList, setTeamList] = useState([]);
  useEffect(() => {
    if (!props.team) {
      axios({
        method: 'get',
        url: props.API_domain + 'getTeamsVisualizations',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      })
        .then((response) => {
          setTeamList(response.data);
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  }, []);

  useEffect(() => {
    if (props.team == 'all') {
      axios({
        method: 'get',
        url: props.API_domain + 'getTeamsVisualizations',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      })
        .then((response) => {
          setTeamList(response.data);
        })
        .catch(function (response) {
          console.log(response);
        });
    } else if (props.team) {
      setTeamList([props.team]);
    }
  }, [props.team]);

  return (
    <Content
      style={{
        height: '100%', // <-- Ensure the container takes up full height
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <TeamSelect
                API_domain={props.API_domain}
                onTeamSelect={props.onTeamSelect}
                team={props.team}
              />
            </Col>
          </Row>
        </AffixDiv>
      </Affix>
      <AffixDiv
        style={{
          height: '100%', // <-- Same here
        }}
      >
        <Row
          style={{
            height: '100%', // <-- And here
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          {visualizationsEmpty ? (
            <div
              style={{
                margin: 'auto',
                width: '50%',
                paddingTop: '20vh',
              }}
            >
              <div style={{ textAlign: 'middle' }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            </div>
          ) : (
            <>
              {teamList.map((team) => (
                <>
                  <div
                    style={{
                      // height: '80vh',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '20px',
                    }}
                  >
                    <VisualizationsComponents
                      style={{ height: '100%' }}
                      team={team}
                      API_domain={props.API_domain}
                      setVisualizationsEmpty={setVisualizationsEmpty}
                    />
                  </div>
                  <br />
                </>
              ))}
            </>
          )}
        </Row>
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { VisualizationsView };
