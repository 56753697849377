import React, { useState } from 'react';
import { Button, Modal } from 'antd';

function PdfViewer({ pdfUrl }) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button type='link' onClick={handleOpenModal}>
        {`Ver PDF`}
      </Button>
      <Modal
        title='Factura '
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
      >
          <iframe
            src={pdfUrl}
            width='100%'
            height='600px'
            title='Factura PDF'
            frameBorder='0'
          />
      </Modal>
    </>
  );
}

export {PdfViewer};
