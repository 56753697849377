import {
  FileOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Image, Table, Tag, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { DateFilterDropdown } from '../DateFilterDropdown.js';
import { ImgOrPdf } from './../ImgOrPdf.js';

function ImgOrPDFlocal(props) {
  if (!props.record) {
    return (
      <Tooltip placement='topLeft' title={'Foto de factura pendiente!'}>
        <QuestionCircleOutlined />
      </Tooltip>
    );
  }
  return <ImgOrPdf src={{ imageSource: props.record }} height={'280'} />;
}

function OrderTable(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const auth = useContext(authContext);

  let index = 1;
  items.forEach(function (item) {
    item.index = index;
    index += 1;
    if (item.created) {
      item.fechaFormatted = new Intl.DateTimeFormat('es-US', {
        dateStyle: 'medium',
      }).format(
        new Date(
          item.created.split('/')[0],
          item.created.split('/')[1] - 1,
          item.created.split('/')[2]
        )
      );
    }
    // item.factura =
  });

  useEffect(() => {
    setLoaded(false);
    async function getSecondaryDocs() {
      axios({
        method: 'post',
        url: props.API_domain + 'getSecondaryDocs',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          order_id: props.orderId,
        },
      })
        .then((response) => {
          setLoaded(true);
          setItems(response.data);
        })
        .catch((response) => {
          setLoaded(true);
          setError(response);
        });
    }
    getSecondaryDocs();
  }, [props.submittedToggle]);

  let secundaryDocsColumns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'id',
      width: 10,
    },

    {
      title: 'Subido por',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 150,
    },
    {
      title: 'Estado',
      dataIndex: 'factura',
      key: 'factura',
      width: 80,
      render: (factura, order) => {
        {
          return (
            <>
              {order.is_invoice ? (
                <Tag
                  color={
                    props.invoiceStateMapper.find(
                      (x) => x.value === order.state
                    ).color
                  }
                >
                  {
                    props.invoiceStateMapper.find(
                      (x) => x.value === order.state
                    ).title
                  }
                </Tag>
              ) : null}
            </>
          );
        }
      },
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'created',
      width: 90,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const current_date = new Date(record.fecha);
        return current_date >= value[0] && current_date <= value[1];
      },
    },

    {
      title: 'Documento',
      dataIndex: 'file_url',
      key: 'file_url',
      align: 'center',
      width: 80,
      render: (file_url) => {
        return (
          <div
            style={{
              position: 'relative',
              height: '180px',
              border: '1px solid grey',
              borderRadius: 5,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <ImgOrPDFlocal record={file_url} />
            </div>
          </div>
        );
      },
    },
  ];
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <>
        <Table
          columns={secundaryDocsColumns}
          dataSource={items}
          pagination={false}
          size='middle'
          scroll={{ y: 'overflow' }}
          rowClassName={(record, index) => {
            return 'cursor-pointer';
          }}
          onRow={(row) => {
            return {
              onClick: () => props.setSelectedSecondaryDoc(row),
            };
          }}
        />
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
  text-align: center;
`;

export { OrderTable };
