import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import {
  Button,
  Form,
  Input,
  Select,
  Spin,
  message,
  Row,
  Col,
  Switch,
  Tooltip,
  Checkbox,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const ConfigCreateNewUserForm = (props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [isAdmin, setIsAdmin] = useState(false);
  const [gptEnabledBot, setGptEnabledBot] = useState(false);
  const [teams, setTeams] = useState([]); // State to hold teams fetched from backend

  useEffect(() => {
    // Function to fetch teams
    const fetchTeams = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: props.API_domain + '/getTeams',
          auth: {
            username: auth.email,
            password: auth.token,
          },
        });
        const fetchedTeams = response.data.map((team) => ({
          label: team, // Assuming each team is just a string; adjust if it's an object
          value: team,
        }));
        setTeams(fetchedTeams);
      } catch (error) {
        message.error('Failed to fetch teams');
      }
    };

    fetchTeams();
  }, []);

  const onFinish = (values) => {
    setSubmitting(true);
    // Include selected teams in the data to be submitted
    const data = {
      teams: values.teams,
      name: values.name,
      admin: isAdmin,
      subscriptionId: isAdmin ? props.subscriptionId : null,
      phone: `whatsapp:+${values.countryCode}${values.phone}`,
      email: values.email,
      gptEnabledBot: gptEnabledBot,
    };

    axios({
      method: 'post',
      url: props.API_domain + 'addSubscriptionUser',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.onSubmit();
        props.handleOk();
        message.success('Usuario creado exitosamente');
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch((error) => {
        message.error('Ese teléfono ya está en nuestra base de datos');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleAdminChange = (checked) => {
    setIsAdmin(checked);
    if (checked) {
      const allTeamValues = teams.map((team) => team.value);
      form.setFieldsValue({ teams: allTeamValues });
    } else {
      form.setFieldsValue({ teams: [] });
    }
  };

  return (
    <>
      <Form
        name='basic'
        {...layout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name='name'
          label='Nombre:'
          rules={[
            {
              required: true,
              message: 'Por favor escriba el nombre del colaborador',
            },
          ]}
        >
          <Input placeholder='Juan Pérez' />
        </Form.Item>
        <Form.Item label='WhatsApp (sin guiones)' style={{ marginBottom: 0 }}>
          <Row>
            <Col span={8}>
              <Form.Item
                name='countryCode'
                initialValue={'507'}
                rules={[
                  {
                    required: true,
                    message: 'Selecciona un código de país',
                  },
                ]}
                style={{ marginRight: 3 }}
              >
                <Select
                  // showSearch
                  defaultValue='507'
                >
                  <Option value='1'>
                    <span role='img' aria-label='United States'>
                      🇺🇸
                    </span>{' '}
                    +1
                  </Option>
                  <Option value='57'>
                    <span role='img' aria-label='Colombia'>
                      🇨🇴
                    </span>{' '}
                    +57
                  </Option>
                  <Option value='507'>
                    <span role='img' aria-label='Panama'>
                      🇵🇦
                    </span>{' '}
                    +507
                  </Option>
                  <Option value='34'>
                    <span role='img' aria-label='Espana'>
                      🇪🇸
                    </span>{' '}
                    +34
                  </Option>
                  <Option value='58'>
                    <span role='img' aria-label='Venezuela'>
                      🇻🇪
                    </span>{' '}
                    +58
                  </Option>
                  <Option value='55'>
                    <span role='img' aria-label='Brazil'>
                      🇧🇷
                    </span>{' '}
                    +55
                  </Option>
                  <Option value='39'>
                    <span role='img' aria-label='Italy'>
                      🇮🇹
                    </span>{' '}
                    +39
                  </Option>
                  <Option value='44'>
                    <span role='img' aria-label='UK'>
                      🇬🇧
                    </span>{' '}
                    +44
                  </Option>
                  <Option value='971'>
                    <span role='img' aria-label='UAE'>
                      🇦🇪
                    </span>{' '}
                    +971
                  </Option>
                  {/* Add more options for other countries here */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name='phone'
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa tu número de WhatsApp',
                  },
                ]}
              >
                <Input type='number' placeholder='66123476' />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              message: 'Por favor escriba el correo del colaborador',
            },
          ]}
        >
          <Input placeholder='juanperez@gmail.com' />
        </Form.Item>
        {/* add form item to either make the user an admin or not. it must use a Toggle to set the user as an admin or not */}
        <Form.Item name='admin' label='Administrador' initialValue={false}>
          <Tooltip
            title={
              <>
                Podrá<div> - Solicitar tarjetas a usuarios</div>
                <div>- Ver y editar todos los usuarios de la compañía</div>
              </>
            }
            placement='right'
          >
            <Switch onChange={handleAdminChange} />
          </Tooltip>
        </Form.Item>
        <Form.Item
          name='admin'
          label='Auto categorizador 🪄🦄'
          initialValue={false}
        >
          <Tooltip
            title={
              <>
                <div>
                  Al ingresar gastos por WhatsApp, el bot intentará adivinar el
                  equipo y categoría del gasto.
                </div>
                <br></br>
                <div>
                  Esto hará más rápido tu ingreso de gastos ⚡ y te ayudará a
                  mantener una clasificación consistente.
                </div>
              </>
            }
            placement='right'
          >
            <Switch
              onChange={(checked) => {
                setGptEnabledBot(checked);
              }}
            />
          </Tooltip>
        </Form.Item>
        <Form.Item 
          name='teams'
          label='Seleccionar Equipos'
          rules={[{ required: true, message: 'Por favor, seleccione al menos un equipo' }]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              {teams.map((team, index) => (
                <Col span={24} key={index}>
                  <Checkbox style={{ marginTop: 5 }} value={team.value}>
                    {team.label}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Button
          type='primary'
          htmlType='submit'
          block
          style={{ fontWeight: 500 }}
        >
          {isSubmitting ? (
            <Spin
              indicator={<LoadingOutlined spin style={{ color: 'white' }} />}
            />
          ) : (
            'Crear Usuario'
          )}
        </Button>
        <div style={{ color: 'grey', marginTop: 6 }}>
          {props.quantityOfAvailableUsers > 0 ? (
            <>
              <div>Usaremos uno de tus usuarios disponibles.</div>
              <div>
                Tu nuevo número de usuarios disponibles será:{' '}
                {props.quantityOfAvailableUsers - 1}
              </div>
            </>
          ) : (
            'Se agregará 1 usuario a tu suscripción.'
          )}
        </div>
      </Form>
    </>
  );
};

export { ConfigCreateNewUserForm };
