import { Modal } from 'antd';
import { ConfigRenameTeamForm } from './ConfigRenameTeamForm.js';

function ConfigRenameTeamModal(props) {
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      footer={false}
      onCancel={props.handleCancel}
    >
      <ConfigRenameTeamForm
        API_domain={props.API_domain}
        API_endPoint={props.API_endPoint}
        onSubmit={props.onSubmit}
        handleOk={props.handleOk}
        oldTeamName={props.team}
        isOwner={props.isOwner}
      />
    </Modal>
  );
}

export { ConfigRenameTeamModal };
