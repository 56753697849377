import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function UserFromATeamSelect(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState();

  function fetchTeamUsers() {
    const data = {
      team: props.team,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'getUsersIdNameEmail',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        let selectOptions = response.data.map((user) => ({
          label: user.name,
          value: user.id,
        }));
        setOptions(selectOptions);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchTeamUsers();
  }, [props.team]);

  return (
    <>
      <Select
        value={props.selectedMemberId}
        showSearch
        filterOption={(input, option) =>
          option.props.label
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => {
          props.setSelectedMemberId(value);
        }}
        options={options}
        placeholder='Selecciona Usuario'
        style={{
          width: '100%',
        }}
      />
    </>
  );
}

export { UserFromATeamSelect };
