import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import CompanyWalletEnabler from './CompanyWalletEnabler';
import { CardSelect } from '../CardSelect';
import { Button, message } from 'antd';

const ConfigCompanyMoneySettings = (props) => {
  const auth = useContext(authContext);
  const [defaultCardId, setDefaultCardId] = useState();

  const setDefaultCompanyCard = async (cardId) => {
    setDefaultCardId(cardId);
    try {
      const response = await axios.post(
        props.API_domain + 'setDefaultCompanyCard',
        {
          accountingClientCompany:
            props.accountingClientCompany.accounting_client_company_id,
          cardId: cardId,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      if (response.data) {
        message.success('Tarjeta predeterminada actualizada exitosamente.');
      } else {
        message.error('Error actualizando la tarjeta predeterminada.');
      }
    } catch (error) {
      console.error('Error updating default card:', error);
      message.error('Error actualizando la tarjeta predeterminada.');
    }
  };

  useEffect(() => {
    const fetchDefaultCompanyCard = async () => {
      try {
        const response = await axios.post(
          props.API_domain + 'fetchDefaultCompanyCard',
          {
            accountingClientCompany:
              props.accountingClientCompany.accounting_client_company_id,
          },
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        setDefaultCardId(response.data.default_card_id);
      } catch (error) {
        console.error('Error fetching default card:', error);
      }
    };
    fetchDefaultCompanyCard();
  }, [props.accountingClientCompany]);

  return (
    <>
      <div
        style={{
          color: 'hsl(0,0%,50%)',
          marginBottom: 8,
          marginLeft: 96,
          textAlign: 'left',
        }}
      >
        Wallet
      </div>
      <CompanyWalletEnabler
        API_domain={props.API_domain}
        copyToClipboard={props.copyToClipboard}
        accountingClientCompany={props.accountingClientCompany}
      />
      <div
        style={{
          color: 'hsl(0,0%,50%)',
          marginTop: 16,
          marginBottom: 8,
          marginLeft: 96,
          textAlign: 'left',
        }}
      >
        Tarjeta predeterminada
      </div>
      <div style={{ marginLeft: 84, textAlign: 'left' }}>
        <CardSelect
          API_domain={props.API_domain}
          copyToClipboard={props.copyToClipboard}
          selectedCard={defaultCardId}
          onCardSelect={(cardId) => setDefaultCompanyCard(cardId)}
        />
        {defaultCardId && (
          <Button type='secondary' onClick={() => setDefaultCompanyCard('')}>
            Apagar tarjeta
          </Button>
        )}
      </div>
    </>
  );
};

export { ConfigCompanyMoneySettings };
