import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatDateSpanishLong } from '../../utils';
import axios from 'axios';
import { createCoverPage } from './GeneratePDFCoverPage.js';

const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const generatePDF = (
  outputOnlyBlob,
  accountingClientCompany,
  incomeStatementRecentTimePeriods,
  balanceSheetRecentTimePeriods,
  balanceSheetData,
  incomeStatementData,
  API_domain,
  auth
) => {
  const doc = new jsPDF();

  // Function to load the Georgia font
  const loadFont = async (url) => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const addGeorgiaFont = (base64) => {
    doc.addFileToVFS('Georgia.ttf', base64);
    doc.addFont('Georgia.ttf', 'Georgia', 'normal');
  };

  const convertTableData = (data, timePeriods) => {
    const formattedData = [];

    const processRow = (row, level = 0) => {
      formattedData.push({
        subcategory: row.subcategory,
        values: row.values
          .slice(-timePeriods.length) // Ensure values correspond to the sliced time periods
          .map((val) => formatNumberWithCommas(parseFloat(val))), // Apply formatting here
        level: level,
        isParent: row.children.length > 0,
      });
      row.children.forEach((child) => processRow(child, level + 1));
    };

    data.forEach((row) => processRow(row));
    return formattedData;
  };

  const createTable = (doc, data, timePeriods, startY) => {
    doc.autoTable({
      head: [
        [
          { content: 'Cuenta', styles: { halign: 'left' } },
          ...timePeriods.map((period) => ({
            content: period,
            styles: { halign: 'right' },
          })),
        ],
      ],
      body: data.map((item) => [
        {
          content: item.subcategory,
          styles: {
            fontStyle: item.isParent ? 'bold' : 'normal',
            cellPadding: {
              left: item.level * 10,
              right: 0,
              top: 2,
              bottom: 2,
            },
            halign: 'left',
            fontSize: 10, // Set font size for subcategory cells
          },
        },
        ...item.values.map((val) => ({
          content: val,
          styles: {
            fontStyle: item.isParent ? 'bold' : 'normal',
            halign: 'right',
            fontSize: 10, // Set font size for value cells
          },
        })),
      ]),
      startY: startY,
      headStyles: { fillColor: [116, 116, 199] },
      bodyStyles: { fontSize: 10 }, // Set default font size for body rows
    });
    return doc.previousAutoTable.finalY;
  };

  // Define the maximum number of periods to include
  const maxPeriods = 6;

  // Slice the time periods to include only the last 5 months
  const recentIncomePeriods = incomeStatementRecentTimePeriods.slice(
    -maxPeriods
  );
  const recentBalancePeriods = balanceSheetRecentTimePeriods.slice(-maxPeriods);

  // Convert the data using the sliced time periods
  const balanceSheetDataForPDF = convertTableData(
    balanceSheetData,
    recentBalancePeriods
  );
  const incomeStatementDataForPDF = convertTableData(
    incomeStatementData,
    recentIncomePeriods
  );

  const addFooter = (doc) => {
    const exportDate = `Exportado el ${formatDateSpanishLong(new Date())}`;
    const pageCount = doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `${i}`,
        doc.internal.pageSize.width - 10,
        doc.internal.pageSize.height - 10,
        { align: 'right' }
      );
      doc.text(exportDate, 10, doc.internal.pageSize.height - 10);
    }
  };

  // Load the Georgia font and add the cover page
  return loadFont('/georgia.ttf') // Adjust the path if necessary
    .then((base64) => {
      addGeorgiaFont(base64); // Add the Georgia font
      return createCoverPage(
        doc,
        accountingClientCompany,
        recentIncomePeriods // Use the sliced time periods
      );
    })
    .then(() => {
      doc.addPage();
      doc.setFontSize(16);
      doc.text('Balance', 16, 20);

      let finalY = createTable(
        doc,
        balanceSheetDataForPDF,
        recentBalancePeriods, // Use the sliced time periods
        30
      );

      doc.addPage();
      doc.setFontSize(16);
      doc.text('Estado de Resultados', 16, 20);

      createTable(
        doc,
        incomeStatementDataForPDF,
        recentIncomePeriods, // Use the sliced time periods
        30
      );

      // Add page numbers
      addFooter(doc);

      // Get the last time period from the sliced income periods
      const lastTimePeriod =
        recentIncomePeriods[recentIncomePeriods.length - 1];
      const lastDayOfMonth = getLastDayOfMonth(lastTimePeriod);
      const file_name = `${lastDayOfMonth}_reporte_contable_${accountingClientCompany.name}.pdf`;

      if (outputOnlyBlob) {
        return doc.output('blob');
      } else {
        // Track the event
        const trackingData = {
          event: 'exportFinancialStatementsPDF',
          properties: {},
        };
        axios({
          method: 'post',
          url: `${API_domain}trackFrontEndEvent`,
          auth: {
            username: auth.email,
            password: auth.token,
          },
          data: trackingData,
        })
          .then((response) => {
            console.log('Event tracked:', response);
          })
          .catch((error) => {
            console.error('Error tracking event:', error);
          });
        console.log('Saving PDF Document:', file_name);
        doc.save(file_name);
        return null;
      }
    })
    .catch((error) => {
      console.error(
        'Error loading the font or creating the cover page:',
        error
      );
      return null;
    });
};

const getLastDayOfMonth = (yearMonth) => {
  const [year, month] = yearMonth.split('-').map(Number);
  const date = new Date(year, month, 1);
  date.setDate(date.getDate() - 1);
  return date.toISOString().split('T')[0];
};
