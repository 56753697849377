import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, message, Spin, Input, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import HeroAxios from '../helpers/HeroAxios.js';
import { authContext } from '../ProvideAuth.js';
import styled from 'styled-components/macro';

const { Text } = Typography;

function ConfigObligationsTable() {
  const auth = useContext(authContext);
  const [accountingClients, setAccountingClients] = useState([]);
  const [obligations, setObligations] = useState([]);
  const [enabledObligations, setEnabledObligations] = useState({});
  const [initialEnabledObligations, setInitialEnabledObligations] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [obligationsGroup, setObligationsGroup] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 200 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch Accounting Clients
        const clientsResponse = await HeroAxios.get('getAccountingClients');

        // Fetch Obligations Master
        const obligationsResponse = await HeroAxios.get('getObligationsMaster');

        // Fetch All Enabled Obligations
        const enabledObligationsResponse = await HeroAxios.get('getEnabledObligations');

        setAccountingClients(clientsResponse.data);
        setObligations(obligationsResponse.data);

        // Group obligations by 'source'
        const groupedObligations = obligationsResponse.data.reduce((acc, obligation) => {
          const source = obligation.source || 'Other'; // Default to 'Other' if source is empty
          if (!acc[source]) {
            acc[source] = [];
          }
          acc[source].push(obligation);
          return acc;
        }, {});
        setObligationsGroup(groupedObligations);

        // Initialize enabledObligations as an object mapping client IDs to their obligations
        const enabledMap = {};
        enabledObligationsResponse.data.forEach(ob => {
          const clientId = ob.accounting_client_id;
          if (!enabledMap[clientId]) {
            enabledMap[clientId] = {};
          }
          enabledMap[clientId][ob.obligation_id] = ob.enabled;
        });
        setEnabledObligations(enabledMap);

        // Deep copy to preserve initial state
        setInitialEnabledObligations(JSON.parse(JSON.stringify(enabledMap)));
      } catch (error) {
        message.error('Error fetching data');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth]);

  const handleCellClick = (clientId, obligationId) => {
    setEnabledObligations(prevState => {
      const currentStatus = prevState[clientId]?.[obligationId];
      const initialStatus = initialEnabledObligations[clientId]?.[obligationId];

      let newStatus;
      if (initialStatus === undefined) {
        // Cell was empty initially, can cycle through all statuses
        if (currentStatus === undefined) {
          newStatus = true;
        } else if (currentStatus === true) {
          newStatus = false;
        } else if (currentStatus === false) {
          newStatus = undefined;
        }
      } else {
        // Cell had an initial value, can only toggle between true and false
        if (currentStatus === true) {
          newStatus = false;
        } else {
          newStatus = true;
        }
      }

      return {
        ...prevState,
        [clientId]: {
          ...prevState[clientId],
          [obligationId]: newStatus,
        },
      };
    });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const payload = [];

      Object.keys(enabledObligations).forEach(clientId => {
        const clientObligations = enabledObligations[clientId] || {};
        const initialClientObligations = initialEnabledObligations[clientId] || {};

        // Find differences between current and initial obligations
        const changedObligations = Object.keys(clientObligations).filter(obId => {
          return clientObligations[obId] !== initialClientObligations[obId];
        }).map(obId => ({
          obligation_id: parseInt(obId),
          enabled: clientObligations[obId],
        }));

        if (changedObligations.length > 0) {
          payload.push({
            accounting_client_id: parseInt(clientId),
            obligations: changedObligations,
          });
        }
      });

      if (payload.length > 0) {
        await HeroAxios.post('saveEnabledObligationsBulk', { payload });
        message.success('Obligations settings saved successfully for all clients');
        // Update initialEnabledObligations to reflect saved state
        setInitialEnabledObligations(JSON.parse(JSON.stringify(enabledObligations)));
      } else {
        message.info('No changes to save');
      }
    } catch (error) {
      message.error('Error saving obligations settings');
      console.error('Error saving obligations:', error);
    } finally {
      setSaving(false);
    }
  };

  // Event handlers for cell hover
  const handleCellMouseEnter = (event) => {
    const cell = event.currentTarget;
    const row = cell.parentNode;
    const table = row.closest('table');

    // Get the data-column-key from the hovered cell
    const dataColumnKey = cell.getAttribute('data-column-key');

    // Highlight ID and Name cells in the same row
    row.querySelector('td[data-column-key="clientId"]')?.classList.add('highlight');
    row.querySelector('td[data-column-key="clientName"]')?.classList.add('highlight');

    // Highlight header cell with the same data-column-key
    const headerCell = table.querySelector(`th[data-column-key="${dataColumnKey}"]`);
    headerCell?.classList.add('highlight');
  };

  const handleCellMouseLeave = (event) => {
    const cell = event.currentTarget;
    const row = cell.parentNode;
    const table = row.closest('table');

    // Get the data-column-key from the hovered cell
    const dataColumnKey = cell.getAttribute('data-column-key');
    // Remove highlight from ID and Name cells
    row.querySelector('td[data-column-key="clientId"]')?.classList.remove('highlight');
    row.querySelector('td[data-column-key="clientName"]')?.classList.remove('highlight');

    // Remove highlight from header cell
    const headerCell = table.querySelector(`th[data-column-key="${dataColumnKey}"]`);
    headerCell?.classList.remove('highlight');
  };

  // Prepare grouped columns using the 'source' field
  const groupedColumns = [
    {
      title: 'ID',
      dataIndex: 'clientId',
      key: 'clientId',
      fixed: 'left',
      width: 60,
      sorter: (a, b) => a.clientId - b.clientId,
      filterSearch: true,
      onFilter: (value, record) => record.clientId.toString().includes(value),
      onCell: () => ({
        'data-column-key': 'clientId',
        onMouseEnter: handleCellMouseEnter,
        onMouseLeave: handleCellMouseLeave,
      }),
      onHeaderCell: () => ({
        'data-column-key': 'clientId',
      }),
      render: (text, record) => (
        <Text style={{ fontSize: '11px', paddingLeft: '8px' }}>{text}</Text>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'clientName',
      key: 'clientName',
      fixed: 'left',
      width: 140,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      filterSearch: true,
      onFilter: (value, record) => record.clientName.toLowerCase().includes(value.toLowerCase()),
      onCell: () => ({
        'data-column-key': 'clientName',
        onMouseEnter: handleCellMouseEnter,
        onMouseLeave: handleCellMouseLeave,
      }),
      onHeaderCell: () => ({
        'data-column-key': 'clientName',
      }),
      render: (text, record) => (
        <Text style={{ fontSize: '11px', paddingLeft: '8px' }}>{text}</Text>
      ),
    },
  ];

  // Loop through the grouped obligations to create column groups
  Object.keys(obligationsGroup).forEach(source => {
    const groupColumns = obligationsGroup[source].map(obligation => {
      const columnKey = `obligation_${obligation.id}`;

      return {
        title: obligation.name,
        dataIndex: columnKey,
        key: columnKey,
        width: Math.max(100, obligation.name.length),
        render: (text, record) => {
          const clientId = record.clientId;
          const isEnabled = enabledObligations[clientId]?.[obligation.id];
          let icon;
          if (isEnabled === true) {
            icon = <CheckCircleOutlined style={{ color: 'green', fontSize: '14px' }} />;
          } else if (isEnabled === false) {
            icon = <CloseCircleOutlined style={{ color: 'red', fontSize: '14px' }} />;
          } else {
            icon = <span style={{ display: 'inline-block', width: '14px', height: '14px' }} />;
          }
          return (
            <Cell
              onClick={() => handleCellClick(clientId, obligation.id)}
              data-column-key={columnKey}
              onMouseEnter={handleCellMouseEnter}
              onMouseLeave={handleCellMouseLeave}
            >
              {icon}
            </Cell>
          );
        },
        filters: [
          { text: 'Enabled', value: true },
          { text: 'Disabled', value: false },
          { text: 'Not Set', value: undefined },
        ],
        onFilter: (value, record) => record[columnKey] === value,
        onCell: () => ({
          'data-column-key': columnKey,
        }),
        onHeaderCell: () => ({
          'data-column-key': columnKey,
        }),
      };
    });

    groupedColumns.push({
      title: source,
      children: groupColumns,
    });
  });

  // Prepare table data for all clients
  const data = accountingClients
    .filter(client => {
      const term = searchTerm.toLowerCase();
      return (
        client.id.toString().includes(term) ||
        client.name.toLowerCase().includes(term)
      );
    })
    .map(client => ({
      key: client.id,
      clientId: client.id,
      clientName: client.name,
      ...obligations.reduce((acc, obligation) => {
        const obId = obligation.id;
        const isEnabled = enabledObligations[client.id]?.[obId];
        acc[`obligation_${obId}`] = isEnabled;
        return acc;
      }, {}),
    }));

  if (loading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  return (
    <>
      <SearchContainer>
        <Input.Search
          placeholder="Search by ID or Name"
          onSearch={(value) => setSearchTerm(value)}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
      </SearchContainer>
      <ExplanationText>
        Al guardar los cambios, se crearán obligaciones contables según las siguientes reglas:
        <ul>
          <li>Las obligaciones se generan para el año actual y el próximo.</li>
          <li>Cada tipo de obligación tiene reglas específicas de frecuencia y descripción:</li>
          <ul>
            <li>Obligaciones anuales (ej. Impuesto Complementario, Tasa única)</li>
            <li>Obligaciones mensuales (ej. SIPE, ITBMS)</li>
            <li>Obligaciones tres veces al año (ej. Estimada Impuesto Sobre la Renta)</li>
          </ul>
          <li>Las descripciones se ajustan según las fechas de vencimiento y las reglas de frecuencia.</li>
          <li>No se crean obligaciones duplicadas.</li>
        </ul>
        El sistema manejará automáticamente estas reglas al generar las obligaciones.
      </ExplanationText>
      <StyledTableContainer>
        <div style={{ overflowX: 'auto' }}>
          <StyledText>
            Selecciona todas las obligaciones que apliquen para cada cliente
          </StyledText>
          <StyledTable
            columns={groupedColumns}
            dataSource={data}
            pagination={{
              ...pagination,
              total: data.length,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (page, pageSize) =>
                setPagination({ current: page, pageSize }),
            }}
            bordered
            showHeader
            scroll={{ x: 'max-content', y: 400 }}
            sticky
          />
          <br />
          <StyledText>
            Al presionar "Guardar Obligaciones" se guardará esta configuración y se
            generarán las obligaciones para los clientes
          </StyledText>
        </div>
        <SaveButtonContainer>
          <Button type="primary" onClick={handleSave} loading={saving}>
            Guardar Obligaciones
          </Button>
        </SaveButtonContainer>
      </StyledTableContainer>
    </>
  );
}

// Styled components
const SearchContainer = styled.div`
  text-align: left;
  margin-bottom: 16px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 100px;
`;

const StyledTableContainer = styled.div`
  margin: 20px 0;
`;

const StyledTable = styled(Table)`
  // Your existing table styles here
  .ant-table-cell {
    padding: 0;
    font-size: 11px;
  }
  // ...
`;

const Cell = styled.div`
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 11px;

  &:hover {
    background-color: #e6f7ff;
  }
`;

const StyledText = styled(Text)`
  display: block;
  text-align: left;
  margin-bottom: 12px;
  font-size: 12px;
`;

const SaveButtonContainer = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const ExplanationText = styled.div`
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 1.4;
  color: #666;
  text-align: left;
  
  ul {
    margin-top: 4px;
    padding-left: 20px;
  }
  
  ul ul {
    margin-top: 2px;
  }

  li {
    margin-bottom: 2px;
  }
`;

export { ConfigObligationsTable };
