import React, { useState, useEffect } from 'react';
import { Form, Input, message, Button, Row, Col } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CSSIntegration = (props) => {
  const [elaboradorMap, setElaboradorMap] = useState(
    props.cssCredentials.login_info.elaborador_map || {}
  );
  const [representanteLegalMap, setRepresentanteLegalMap] = useState(
    props.cssCredentials.login_info.representante_legal_map || {}
  );
  const [loading, setLoading] = useState(false); // State to manage the loading status
  // Handle change for dynamic question map inputs
  const handleRepresentanteLegalChange = (key, value) => {
    setRepresentanteLegalMap((prevMap) => ({
      ...prevMap,
      [key]: value,
    }));
  };
  const handleElaboradorChange = (key, value) => {
    setElaboradorMap((prevMap) => ({
      ...prevMap,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true); // Start loading

    try {
      const updatedCredentials = {
        accounting_client_id: props.cssCredentials.accounting_client_id,
        id: props.cssCredentials.id, // Unique ID for the configuration
        site: 'css',
        login_info: {
          elaborador_map: elaboradorMap,
          representante_legal_map: representanteLegalMap,
        },
      };

      // Send only the relevant credentials to the backend
      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Actualización exitosa');
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error actualizando');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <h3 style={{ marginLeft: 4, textAlign: 'left' }}>
        CAJA DE SEGURO SOCIAL
      </h3>
      <div
        style={{
          textAlign: 'left',
          marginLeft: 72,
          marginTop: 16,
          marginBottom: 8,
        }}
      >
        Representante Legal
      </div>
      {Object.keys(representanteLegalMap).map((key) => (
        <Form.Item label={key} style={{ marginLeft: 96 }} key={key}>
          <Row>
            <Col span={22}>
              <Input
                value={representanteLegalMap[key]}
                onChange={(e) =>
                  handleRepresentanteLegalChange(key, e.target.value)
                }
              />
            </Col>
            <Col span={2}>
              <Button
                icon={<CopyOutlined />}
                type='text'
                style={{ color: 'grey' }}
                onClick={() =>
                  props.copyToClipboard(representanteLegalMap[key])
                }
              />
            </Col>
          </Row>
        </Form.Item>
      ))}
      {/* add a label from the elaboradormap */}
      <div
        style={{
          textAlign: 'left',
          marginLeft: 72,
          marginTop: 16,
          marginBottom: 8,
        }}
      >
        Elaborador
      </div>
      {Object.keys(elaboradorMap).map((key) => (
        <Form.Item label={key} style={{ marginLeft: 96 }} key={key}>
          <Row>
            <Col span={22}>
              <Input
                value={elaboradorMap[key]}
                onChange={(e) => handleElaboradorChange(key, e.target.value)}
              />
            </Col>
            <Col span={2}>
              <Button
                icon={<CopyOutlined />}
                type='text'
                style={{ color: 'grey' }}
                onClick={() => props.copyToClipboard(elaboradorMap[key])}
              />
            </Col>
          </Row>
        </Form.Item>
      ))}
      <div style={{ textAlign: 'left', marginLeft: 96 }}>
        <Button
          type='primary'
          onClick={handleSave}
          loading={loading} // Apply loading state to the button
          style={{ marginRight: 16 }}
        >
          Guardar
        </Button>
        <Button
          type='secondary'
          onClick={() => console.log('validating')}
          style={{
            backgroundColor: 'hsl(0, 0%, 95%)',
            borderColor: 'hsl(0, 0%, 95%)',
          }}
          disabled
        >
          Validar CSS
          {/* {props.renderValidationIcon(props.cssCredentials.dgi_last_verify)} */}{' '}
          ❌
        </Button>
      </div>
    </div>
  );
};

export default CSSIntegration;
