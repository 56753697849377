import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { SharedBoxModal } from './SharedBoxModal';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function SharedBoxView(props) {
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get('orderId', '');
  const token = new URLSearchParams(location.search).get('token', '');
  const [selectedOrder, setSelectedOrder] = useState();
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  let invoiceStateMapper = [
    { value: 'pending', title: 'PENDIENTE', color: 'yellow' },
    { value: 'approved', title: 'APROBADO', color: 'blue' },
    { value: 'registered', title: 'REGISTRADO', color: 'purple' },
  ];
  const getSharedBoxInfo = () => {
    axios({
      method: 'get',
      //   url:
      //     props.API_domain +
      //     'getSharedBox?order_id=' +
      //     orderId +
      //     '&token=' +
      //     token,
      url: props.API_domain + 'getSharedBox?order_id=' + orderId,
    })
      .then((response) => {
        setSelectedOrder(response.data);
        setLoaded(true);
      })
      .catch((response) => {
        setError(response);
      });
  };
  useEffect(() => {
    getSharedBoxInfo();
  }, []);
  return (
    <>
      {isLoaded ? (
        <SharedBoxModal
          order={selectedOrder}
          token={token}
          //   report_title={selectedReport.title}
          API_domain={props.API_domain}
          invoiceStateMapper={invoiceStateMapper}
          // onSubmit={onSubmit}
          handleCancel={() =>
            (window.location.href = 'https://www.herofacturas.com')
          }
          // handleOk={() => setSelectedOrder(null)}
          //   team={selectedReport.team}
          //   mobile={props.mobile}
        />
      ) : (
        <LoadingDiv>{antIcon}</LoadingDiv>
      )}
    </>
  );
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { SharedBoxView };
