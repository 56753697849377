import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';

function MovementSourceSelect({
  API_domain,
  onSourceSelect,
  highlightedColor,
  noPadding,
}) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);

  useEffect(() => {
    fetchMovementSources();
  }, []);

  function fetchMovementSources() {
    axios({
      method: 'POST',
      url: `${API_domain}/getMovementSources`,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        const selectOptions = response.data.map((source) => ({
          label: source.source_name,
          value: JSON.stringify(source), // Store the entire source object as a string
        }));
        setOptions(selectOptions);
      })
      .catch(function (error) {
        console.error('Error fetching movement sources:', error);
      });
  }

  const handleChange = (values) => {
    const selectedSourcesObjects = values.map((value) => JSON.parse(value));
    setSelectedSources(selectedSourcesObjects);
    onSourceSelect(selectedSourcesObjects);
  };

  return (
    <span
      style={{
        backgroundColor: highlightedColor,
        padding: noPadding ? 0 : 12,
        borderRadius: 4,
      }}
    >
      <Select
        mode='multiple'
        showSearch
        value={selectedSources.map((source) => JSON.stringify(source))}
        options={options}
        onChange={handleChange}
        placeholder='Seleccione fuentes de movimiento'
        style={{ width: '400px' }}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      />
    </span>
  );
}

export { MovementSourceSelect };
