import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { useAnalytics } from 'use-analytics';

function ReportDownloaderImages(props) {
  const auth = useContext(authContext);
  const { track } = useAnalytics();
  const [isLoading, setLoading] = useState(false);

  function download() {
    setLoading(true);
    axios({
      method: 'get',
      url:
        props.API_domain +
        'getReportExpenses?id=' +
        props.report_id +
        '&download=Images',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        var zip = new JSZip();
        var itemsProcessed = 0;
        result.data.forEach(function (item, index, array) {
          var urlToDownload;
          if (item['factura'] != null && item['factura'] != '') {
            urlToDownload = item['factura'];
          } else {
            urlToDownload =
              'https://www.dropbox.com/s/bfxy1pxjlmj8nke/gastoSinFactura.pdf?raw=1';
          }
          fetch(props.API_domain + 'downloadImage?url=' + urlToDownload).then(
            (r) => {
              var file_ending =
                r.headers.get('content-type') == 'application/pdf'
                  ? '.pdf'
                  : '.jpg';

              let filename =
                item['fecha'].split('/')[0] +
                '-' +
                item['fecha'].split('/')[1] +
                '-' +
                item['fecha'].split('/')[2] +
                ' ' +
                item['receipt_number'] +
                ' ' +
                item['id'] +
                ' ' +
                item['descripcion'].replaceAll('/', ' ') +
                file_ending;
              zip.file(filename, r.blob(), { binary: true });
              itemsProcessed++;
              if (itemsProcessed == array.length) {
                zip.generateAsync({ type: 'blob' }).then(
                  function (blob) {
                    setLoading(false);
                    saveAs(blob, 'heroFacturas.zip');
                  },
                  function (err) {
                    setLoading(false);
                    console.log('Error is ', err);
                  }
                );
              }
            }
          );
        });
      },
      (error) => {
        console.log('Error is ', error);
      }
    );
  }
  if (isLoading) {
    return <LoadingOutlined spin />;
  }
  return (
    <p onClick={download}>
      <DownloadOutlined />
      Imagenes
    </p>
  );
}

export { ReportDownloaderImages };
