import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Button, Select, Upload } from 'antd';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useContext, useState, useEffect } from 'react';
import { authContext } from '../ProvideAuth.js';
import { useAnalytics } from 'use-analytics';
import { TeamSelect } from './TeamSelect.js';
import { CategorySelect } from './CategorySelect.js';

function CreateExpenseButton(props) {
  const auth = useContext(authContext);
  const { track } = useAnalytics();
  const [visible, setVisible] = useState(false);
  const [percentImagesDone, setPercentImagesDone] = useState(null);

  const { API_domain } = props;

  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        buttontype='primary'
      >
        <PlusOutlined />
        Crear gasto
      </Button>
      <CreateExpenseModal
        API_domain={API_domain}
        onSubmit={props.onSubmit}
        visible={visible}
        team={props.team}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
}

function CreateExpenseModal(props) {
  const [form] = Form.useForm();
  const [team, setTeam] = useState(props.team);
  const auth = useContext(authContext);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setTeam(props.team);
    form.setFieldsValue({ team: props.team });
  }, [props.team]);

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
  );

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleFormSubmission = (values) => {
    setLoading(true);
    // Create an instance of FormData
    const formData = new FormData();

    // Append all form values to formData
    Object.keys(values).forEach((key) => {
      // For the file upload field
      if (key === 'image' && values[key]) {
        values[key].forEach((file) => {
          // Append file to formData; note that 'file.originFileObj' is used for Ant Design's Upload component
          formData.append('image', file.originFileObj);
        });
      } else {
        // Append other values to formData
        formData.append(key, values[key]);
      }
    });

    // Perform the Axios POST request
    axios
      .post(`${props.API_domain}/createExpense`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Basic ' + btoa(auth.email + ':' + auth.token),
        },
      })
      .then((response) => {
        // Handle success
        console.log('Expense created successfully:', response);
        setLoading(false);
        props.onSubmit(); // Call onSuccess prop if provided
        props.onCancel();
      })
      .catch((error) => {
        setLoading(false);
        // Handle error
        console.error('Error creating expense:', error);
        props.onError && props.onError(error); // Call onError prop if provided
      });
  };

  return (
    <Modal
      visible={props.visible}
      title='Crear Gasto'
      okText='Crear'
      confirmLoading={isLoading}
      cancelText='Cancelar'
      onCancel={props.onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleFormSubmission(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        {...layout}
        form={form}
        name='form_in_modal'
        initialValues={{ team: props.team }}
      >
        <Form.Item
          name='image'
          label='Imagen'
          valuePropName='fileList'
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
        >
          <Upload
            name='logo'
            listType='picture'
            beforeUpload={() => false} // Prevent automatic upload
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          name='description'
          label='Descripción'
          rules={[{ required: true, message: 'Por favor colocar descripción' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='team'
          label='Equipo'
          rules={[{ required: true, message: 'Por favor seleccionar equipo' }]}
        >
          <TeamSelect
            noPadding={true}
            team={team}
            API_domain={props.API_domain}
            onTeamSelect={(value) => {
              form.setFieldsValue({ team: value });
              form.resetFields(['category']);
              setTeam(value);
            }}
          />
        </Form.Item>
        <Form.Item label='Categoría' name='category'>
          <CategorySelect
            API_domain={props.API_domain}
            onChange={(value) => {
              form.setFieldsValue({ category: value });
            }}
            defaultCategory={null}
            disabledBoolean={false}
            team={team}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export { CreateExpenseButton };
