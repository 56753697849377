import { useContext } from 'react';
import { ConfigAddExistingMemberSelect } from '../ConfigAddExistingMemberSelect.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';

function AdminGetUserLoginLink(props) {
  const auth = useContext(authContext);

  function getUserLoginLink(value) {
    axios({
      method: 'post',
      url: props.API_domain + 'adminGetUserLoginLink',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        user_id: value,
      },
    })
      .then(function (response) {
        const loginLink = response.data; // assuming response.data contains the link
        const newWindow = window.open(
          loginLink,
          '_blank',
          'noopener,noreferrer'
        );

        // Make it incognito by setting the newWindow to null
        if (newWindow) newWindow.opener = null;
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        console.log(response);
      });
  }

  return (
    <div style={{ width: '200px' }}>
      <ConfigAddExistingMemberSelect
        API_domain={props.API_domain}
        setSelectedMemberId={getUserLoginLink}
        team={'all'}
      />
    </div>
  );
}

export { AdminGetUserLoginLink };
