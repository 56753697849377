import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Affix, Badge, Button, Col, Layout, Row, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from '../TeamSelect.js';
import { Refresh } from '../Refresh.js';
import { QuotesTable } from './QuotesTable.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { QuotesNewModal } from './QuotesNewModal.js';

const { Content } = Layout;

function QuotesView(props) {
  const [isLoaded, setLoaded] = useState(false);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [showNewQuoteModal, setShowNewQuoteModal] = useState(false);

  const onSubmit = () => {
    setShowNewQuoteModal(false);
    setSubmittedToggle(!submittedToggle);
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <QuotesNewModal
        showNewQuoteModal={showNewQuoteModal}
        setShowNewQuoteModal={setShowNewQuoteModal}
        handleOk={onSubmit}
        API_domain={props.API_domain}
      />
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Refresh onClick={onSubmit} spin={!isLoaded} />
              <TeamSelect
                API_domain={props.API_domain}
                onTeamSelect={props.onTeamSelect}
                team={props.team}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <ButtonDiv>
                <Button
                  type='primary'
                  onClick={() => setShowNewQuoteModal(true)}
                >
                  <ButtonSpan>
                    <>
                      <PlusOutlined /> Crear Cotización
                    </>
                  </ButtonSpan>
                </Button>
              </ButtonDiv>
            </Col>
          </Row>
        </AffixDiv>
      </Affix>
      <BeautifulDiv>
        <QuotesTable
          API_domain={props.API_domain}
          isLoaded={isLoaded}
          setLoaded={setLoaded}
          submittedToggle={submittedToggle}
          team={props.team}
          onSubmit={onSubmit}
        />
      </BeautifulDiv>
    </Content>
  );
}
const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 15px;
`;
const ButtonSpan = styled.span`
  font-weight: 500;
`;
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;
const BeautifulDiv = styled.div`
  width: min(calc(100vw - 300px), 1200px);
  margin: auto;
`;

export { QuotesView };
