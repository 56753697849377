// src/components/FrontChatInitializer.js
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';

const FrontChatInitializer = ({ API_domain }) => {
  const auth = useContext(authContext);
  const { email, token } = auth;

  useEffect(() => {
    const initializeFrontChat = async () => {
      // Fetch the userHash from your backend
      const response = await axios.post(
        `${API_domain}getFrontChatUserHash`,
        { email: email },
        {
          auth: {
            username: email,
            password: token,
          },
        }
      );

      const { userHash } = response.data;
      if (!userHash) {
        return;
      }

      // Initialize Front Chat with identity included
      window.FrontChat('init', {
        chatId: 'b2da01b99ec877d2afcb4f6c30340049',
        useDefaultLauncher: true,
        email: email,
        userHash: userHash,
        // Optionally include name and customFields
        // name: 'User Name',
        // customFields: { 'Custom Field': 'Value' },
      });
    };
    if (API_domain == 'https://www.herofacturas.com/'){
      initializeFrontChat();
    }
  }, [email, API_domain, token]);

  return null;
};

export default FrontChatInitializer;
