import React, { useContext, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import HeroAxios from '../../helpers/HeroAxios.js';
import { authContext } from '../../ProvideAuth.js';
import { AccountingObligationsTimeline } from './AccountingObligationsTimeline.js';
import '../styles.css';

const transformAndSortData = (transactions) => {
  return transactions;
};

const AccountingObligationsViewer = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [clientNames, setClientNames] = useState({});
  const auth = useContext(authContext);

  const getAccountingObligations = async () => {
    setLoading(true);
    try {
      const response = await HeroAxios.post('getAccountingObligations', {
        group_by: 'source'
      });
      setData(response.data.obligations);
      setClientNames(response.data.names);
    } catch (error) {
      console.error('Error fetching accounting obligations:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccountingObligations();
  }, []);

  // Now do a for loop over the data object and render a timeline for each client
  const renderData = () => {
    return Object.keys(data).map((clientId) => {
      const clientObligations = data[clientId];
      return (
        <div key={clientId}>
          <AccountingObligationsTimeline
            API_domain={props.API_domain}
            loading={loading}
            data={{ obligations: { [clientId]: clientObligations }, names: { [clientId]: clientNames[clientId] } }}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            clientId={clientId}
            setShowCommentModal={props.setShowCommentModal}
            setCommentModalSotTxn={props.setCommentModalSotTxn}
          />
        </div>
      );
    });
  };

  if (loading) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  }

  return <>{renderData()}</>;
};

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { AccountingObligationsViewer };
