import React from 'react';
import { Row, Card, Button, Tag } from 'antd';
import styled from 'styled-components/macro';
import axios from 'axios';

function OtherHeroTools(props) {
  const trackEvent = (action) => {
    const trackingData = {
      event: action,
      properties: {},
    };
    axios({
      method: 'post',
      url: props.API_domain + 'trackFrontEndEvent',
      auth: {
        username: '',
        password: 'auth.token',
      },
      data: trackingData,
    })
      .then((response) => {
        console.log('Event tracked:', response);
      })
      .catch((error) => {
        console.error('Error tracking event:', error);
      });
  };

  return (
    <StyledCard
      title='Otras Calculadoras de Hero'
      style={{
        marginBottom: 20,
        textAlign: 'left',
        height: window.innerWidth < 420 ? 'auto' : 304,
      }}
    >
      <Button
        type='secondary'
        style={{ marginBottom: 16, display: 'block' }}
        onClick={() => trackEvent('clickedOtherHeroToolsLiquidacion')}
      >
        <a
          href='https://api.whatsapp.com/send?phone=63642239&text=Quiero%20herramienta%20de%20liquidacion'
          target='_blank'
          rel='noopener noreferrer'
        >
          Liquidación
        </a>
      </Button>
      <Button
        type='secondary'
        style={{ marginBottom: 16, display: 'block' }}
        onClick={() => trackEvent('clickedOtherHeroToolsSalarioAvanzado')}
      >
        <a
          href='https://api.whatsapp.com/send?phone=63642239&text=Quiero%20herramienta%20de%20salario%20avanzado'
          target='_blank'
          rel='noopener noreferrer'
        >
          Salario (avanzado)
        </a>
      </Button>
      <Button
        type='secondary'
        style={{ marginBottom: 16, display: 'block' }}
        onClick={() => trackEvent('clickedOtherHeroToolsITBMS')}
      >
        <a
          href='https://api.whatsapp.com/send?phone=63642239&text=Quiero%20herramienta%20de%20ITBMS'
          target='_blank'
          rel='noopener noreferrer'
        >
          ITBMS (Form. 430)
        </a>
      </Button>
      <Button
        type='secondary'
        style={{ marginBottom: 16, display: 'block' }}
        onClick={() =>
          trackEvent('clickedOtherHeroToolsImpuestoComplementario')
        }
      >
        <a
          href='https://api.whatsapp.com/send?phone=63642239&text=Quiero%20herramienta%20de%20impuesto%20complementario'
          target='_blank'
          rel='noopener noreferrer'
        >
          Impuesto complementario
        </a>
      </Button>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  .ant-card-head {
    background-color: rgb(116, 116, 205);
    color: white;
  }
  // add radius
  border-radius: 10px;
`;

export default OtherHeroTools;
