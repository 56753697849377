import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Spin,
  Upload,
  message,
} from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { ImageLoader } from '../ImageLoader';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminGetExtractedText(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const showModal = () => {
    setVisible(true);
    setExtractedText('');
    setImageUrl('');
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      imageUrl: imageUrl,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'getExtractedText',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setExtractedText(response.data);
        console.log(response.data);
        // handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>OCR</ButtonSpan>
      </Button>
      <Modal
        title='OCR'
        visible={visible}
        width={1000}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={onFinish}
            disabled={imageUrl.length != '' ? false : true}
            // disabled={true}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Conseguir Texto</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <div style={{ marginBottom: 10 }}>
            Funciona imagen y pdf (solo extrae la primera página del PDF)
          </div>
          <ImageLoader
            onImageSubmit={setImageUrl}
            urlToUpload={props.API_domain + 'uploadImages'}
            // disabledBoolean={disabledBoolean}
            disabledBoolean={false}
          />
          {/* </Form.Item> */}
        </Form>
        {extractedText.length > 0 && (
          <>
            <Divider />
            <div style={{ marginTop: 10 }}>{extractedText}</div>
          </>
        )}
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminGetExtractedText };
