import { Button, Col, Input, Row, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';

function ChatBox(props) {
  const auth = useContext(authContext);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [text, setText] = useState('');
  const [chatSent, setChatSent] = useState(true);
  const buttonRef = useRef(null);
  const tableRef = useRef(null);

  const sendChat = () => {
    setChatSent(false);
    console.log('sending chat...');
    axios({
      method: 'post',
      url: props.API_domain + props.API_endPointCreateChat,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        document_id: props.document.id,
        document_type: props.documentType,
        text: text,
      },
    })
      .then((response) => {
        setChatSent(true);
      })
      .catch((response) => {
        setLoaded(true);
        setError(response);
      });
  };

  useEffect(() => {
    function getDocumentChats() {
      axios({
        method: 'post',
        url: props.API_domain + props.API_endPointGetChats,
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          document_id: props.document.id,
          document_type: props.documentType,
        },
      })
        .then((response) => {
          setLoaded(true);
          setData(response.data);
          tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        })
        .catch((response) => {
          setLoaded(true);
          setError(response);
        });
    }
    getDocumentChats();
  }, [props.document, chatSent, props.submittedToggle]);

  data.forEach(function (chat) {
    chat.unformattedTime = chat.created.split(' - ')[1];
    chat.unformattedDate = chat.created.split(' - ')[0];
    chat.formattedDate = new Intl.DateTimeFormat('es-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    }).format(
      new Date(
        chat.unformattedDate.split('/')[0],
        chat.unformattedDate.split('/')[1] - 1,
        chat.unformattedDate.split('/')[2],
        chat.unformattedTime.split(':')[0],
        chat.unformattedTime.split(':')[1]
      )
    );
  });
  const columns = [
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      render: (created, chat) => (
        <>
          <Row>
            <Col span={12}>
              <span style={{ fontWeight: 500 }}>{chat.chat_author}</span>
            </Col>
            <Col span={12}>
              <span style={{ color: 'grey' }}>{chat.formattedDate}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              {chat.text.includes('EVENTO') ? (
                <div style={{ marginLeft: 8, fontWeight: 200 }}>
                  {chat.text
                    .split('EVENTO:')[1]
                    .split(/<<(.*?)>>/)
                    .map((part, index) => {
                      return (
                        <React.Fragment key={index}>
                          {index % 2 === 0 ? (
                            part
                          ) : (
                            <span style={{ fontWeight: '400' }}>{part}</span>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
              ) : (
                <div style={{ marginLeft: 4, fontWeight: 300 }}>
                  {chat.text}
                </div>
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        buttonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!isLoaded) {
    return antIcon;
  } else {
    return (
      <>
        <Table
          class='naked-table'
          columns={columns}
          dataSource={data}
          pagination={false}
          showHeader={false}
          bordered={false}
          size='small'
          ref={tableRef}
          footer={() => (
            <Row>
              <Col span={22} style={{ padding: 4 }}>
                <Input value={text} onChange={(e) => setText(e.target.value)} />
              </Col>
              <Col style={{ padding: 4 }}>
                <Button
                  style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                  ref={buttonRef}
                  onClick={() => {
                    if (text !== '') {
                      sendChat();
                      //   setData([
                      //     ...data,
                      //     {
                      //       date: '24 dec 11:00 pm',
                      //       name: 'Alvaro Sosa',
                      //       text: text,
                      //     },
                      //     // todo: add date and name without hardcoding
                      //   ]);
                      setText('');
                    }
                  }}
                >
                  {chatSent ? (
                    <SendOutlined style={{ color: 'rgb(116, 116, 205)' }} />
                  ) : (
                    <LoadingOutlined spin style={{ color: 'black' }} />
                  )}
                </Button>
              </Col>
            </Row>
          )}
        />
      </>
    );
  }
}
const antIcon = (
  <div style={{ marginTop: '50%', textAlign: 'center' }}>
    <LoadingOutlined spin style={{ color: 'black', fontSize: 30 }} />
  </div>
);

export { ChatBox };
