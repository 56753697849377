import React, { useContext, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  message,
  Modal,
  Tooltip,
  Row,
  Popconfirm,
} from 'antd';
import {
  CopyOutlined,
  DollarCircleOutlined,
  FileExcelOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { authContext } from '../ProvideAuth.js';
import { FormattedUSD } from './FormattedUSD.js';
import axios from 'axios';
import { downloadBlob, get_account_statement_blob } from '../utils.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function CardsCompanyWallet(props) {
  const auth = useContext(authContext);
  const [isModalVisible, setIsModalVisible] = useState(false); // for How to depositar
  const accountRef = useRef(null);
  const emailRef = useRef(null);
  const [isLoading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const copyLink = (ref) => {
    const text = ref.current.innerText;
    message.info('Info copiada ✅', 3);
    navigator.clipboard.writeText(text);
  };

  const downloadWalletStatementExcel = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getWalletStatement',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const gastos_blob = get_account_statement_blob(result.data);
        downloadBlob(gastos_blob, 'Movimientos.csv');
        setLoading(false);
      },
      (error) => {
        console.log('Error is ', error);
        setLoading(false);
      }
    );
  };

  const downloadWalletStatementPDF = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getWalletStatement',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then(
      (result) => {
        const doc = new jsPDF({ compress: true });

        const logoImg = new Image();
        logoImg.src = 'heroLogoTransparent.png';
        logoImg.onload = function () {
          const imgWidth = 25;
          const aspectRatio = logoImg.width / logoImg.height;
          const imgHeight = imgWidth / aspectRatio;

          doc.addImage(logoImg, 'PNG', 10, 10, imgWidth, imgHeight);

          doc.setFontSize(18);
          doc.text('Estado de cuenta de Wallet', 40, 25);

          const tableHeader = [
            'Fecha',
            'Descripción',
            'Débito',
            'Crédito',
            'Balance',
          ];

          // Directly mapping over result.data, as it is an array of transactions
          const tableData = result.data.map((transaction) => [
            transaction.date,
            transaction.description,
            transaction.debit,
            transaction.credit,
            transaction.balance,
          ]);

          doc.autoTable({
            head: [tableHeader],
            body: tableData,
            startY: 30 + imgHeight,
            headStyles: {
              fillColor: [116, 116, 199],
            },
          });

          doc.save('Movimientos.pdf');
          setLoading(false);
        };
      },
      (error) => {
        console.log('Error is ', error);
        setLoading(false);
      }
    );
  };

  const confirmDownload = (format) => {
    if (format === 'excel') {
      downloadWalletStatementExcel();
    } else if (format === 'pdf') {
      downloadWalletStatementPDF();
    }
  };

  return (
    <Card
      title={<b style={{ fontSize: 28 }}>Wallet</b>}
      style={{
        width: 500,
        margin: 'auto',
        borderRadius: 10,
        paddingLeft: 20,
        paddingRight: 20,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.05)',
      }}
      size='small'
    >
      <Row>
        <Col span={12} style={{ textAlign: 'left' }}>
          <span>
            <b style={{ fontSize: 20 }}>Disponible: </b>
            <Tooltip title='Monto disponible para depositar a tarjetas'>
              <InfoCircleOutlined
                style={{ fontSize: 16, fontWeight: 300, color: 'grey' }}
              />
            </Tooltip>

            <p style={{ fontSize: 24, fontWeight: 400 }}>
              {props.refreshingBalance ? (
                <LoadingOutlined />
              ) : (
                <FormattedUSD total={props.amount} />
              )}
            </p>
          </span>
        </Col>
        <Col span={12} style={{ textAlign: 'left' }}>
          <Button style={{ border: 'none' }} onClick={showModal}>
            <DollarCircleOutlined />
            Depositar
          </Button>
          <br />
          <Popconfirm
            title='Selecciona el formato'
            okText='Excel'
            cancelText='PDF'
            onConfirm={() => confirmDownload('excel')}
            onCancel={() => confirmDownload('pdf')}
          >
            <Button style={{ border: 'none' }}>
              <FileExcelOutlined />
              {isLoading ? <LoadingOutlined /> : 'Ver movimientos'}
            </Button>
          </Popconfirm>
        </Col>
      </Row>
      <Modal
        title='Cómo recargar tu Wallet Empresarial'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ fontWeight: 'bold', fontSize: 16 }}>ACH</div>
        <div style={{ marginBottom: 2 }}>Banco General</div>
        <div style={{ marginBottom: 2 }}>Hero</div>
        <div style={{ marginBottom: 2 }}>
          <span ref={accountRef}>04-81-01-914262-9</span>
          <CopyOutlined
            style={{ cursor: 'pointer', marginLeft: 8, color: 'grey' }}
            onClick={() => copyLink(accountRef)}
          />
        </div>
        <div style={{ marginBottom: 2 }}>
          <span ref={emailRef}>founders@herofacturas.com</span>
          <CopyOutlined
            style={{ cursor: 'pointer', marginLeft: 8, color: 'grey' }}
            onClick={() => copyLink(emailRef)}
          />
        </div>
        <br />
        <div style={{ fontWeight: 'bold', fontSize: 16 }}>Descripción:</div>
        <i>Nombre de compañía</i>
      </Modal>
    </Card>
  );
}

export { CardsCompanyWallet };
