import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Form,
  InputNumber,
  Card,
  Typography,
  Row,
  Col,
  Divider,
  Slider,
  Tooltip,
  Space,
} from 'antd';
import styled from 'styled-components/macro';
import { FormattedUSD } from '../FormattedUSD';
import EmployeeDeductionsChart from './EmployeeDeductionsChart';
import EmployerContributionsChart from './EmployerContributionsChart';
import SIPECompositionChart from './SIPECompositionChart';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import OtherHeroTools from './OtherHeroTools.js';

const { Title, Text } = Typography;

const StyledCard = styled(Card)`
  .ant-card-head {
    background-color: rgb(116, 116, 205);
    color: white;
  }
  // add radius
  border-radius: 10px;
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label > label {
    text-align: left;
  }
`;

const GreyedOutDiv = styled.div`
  opacity: ${(props) => (props.greyedOut ? 0.2 : 1)};
`;

function EmployeeForm({ employeeData, onInputChange, API_domain }) {
  let auth = useContext(authContext);
  const [form] = Form.useForm();
  const [salary, setSalary] = useState(employeeData?.monthlySalary || 800);
  const [riskPremium, setRiskPremium] = useState(
    employeeData?.riskPremium || 0
  );
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    onInputChange({ monthlySalary: salary, riskPremium });
    const trackingData = {
      event: 'usedPayrollCalculator',
      properties: {},
    };
    if (!tracked && salary != 800) {
      axios({
        method: 'post',
        url: API_domain + 'trackFrontEndEvent',
        auth: {
          username: '',
          password: 'auth.token',
        },
        data: trackingData,
      })
        .then((response) => {
          console.log('Event tracked:', response);
        })
        .catch((error) => {
          console.error('Error tracking event:', error);
        });
      setTracked(true);
    }
  }, [salary, riskPremium]);

  return (
    <StyledCard
      title='Información de empleado'
      style={{
        marginBottom: 20,
        textAlign: 'left',
        height: window.innerWidth < 420 ? 'auto' : 304,
      }}
    >
      <StyledForm
        form={form}
        layout='vertical'
        initialValues={employeeData}
        // onValuesChange={(changedValues, allValues) => {
        //   onInputChange(changedValues);
        // }}
      >
        <Form.Item
          name='monthlySalary'
          label='Salario Mensual'
          rules={[
            { required: true, message: 'Por favor ingresa el salario mensual' },
          ]}
        >
          <div style={{ width: '160px' }}>
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              min={0}
              onClick={(e) => e.target.select()}
              addonAfter={<div style={{ width: 16 }}>$</div>}
              value={salary}
              onChange={(value) => {
                setSalary(value > 0 ? value : 0.0);
              }}
            />
            <Slider
              min={500}
              max={6000}
              step={10}
              value={salary}
              onChange={(value) => {
                setSalary(value > 0 ? value : 0.0);
              }}
            />
          </div>
        </Form.Item>

        {/* Uncomment the following fields if needed */}
        {/* <Form.Item
          name='benefitProduction'
          label='Beneficio - Producción (Opcional)'
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            min={0}
          />
        </Form.Item>

        <Form.Item
          name='benefitFuel'
          label='Beneficio - Combustible (Opcional)'
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            min={0}
          />
        </Form.Item>

        <Form.Item
          name='benefitRepresentationExpenses'
          label='Beneficio - Gastos de Representación (Opcional)'
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            min={0}
          />
        </Form.Item> */}

        <Form.Item
          name='riskPremium'
          label={
            <>
              Riesgo Profesional
              <Tooltip
                placement='top'
                title={
                  <>
                    <div>
                      Este porcentaje lo asigna la CSS a la empresa de acuerdo a
                      los riesgos del trabajo.
                    </div>
                    <br></br>
                    <div>Un riesgo típico es 2.1%</div>
                    <br></br>
                    <div>
                      Puedes encontrar el porcentaje de tu negocio en el Aviso
                      de Cobro (SIPE)
                    </div>
                    <br></br>
                    <div>
                      <Button>
                        <a
                          href='https://tramites.css.gob.pa/dialogo/doc/64.pdf'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {' '}
                          Legislación de la CSS
                        </a>
                      </Button>
                    </div>
                  </>
                }
              >
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            </>
          }
        >
          <div style={{ width: '160px' }}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              max={100}
              value={riskPremium}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              addonAfter={<div style={{ width: 16 }}>%</div>}
              onChange={(value) => setRiskPremium(value)}
            />
            <Slider
              min={0}
              max={8}
              // step changes must be .1
              step={0.1}
              value={riskPremium}
              onChange={(value) => setRiskPremium(value)}
            />
          </div>
        </Form.Item>
      </StyledForm>
    </StyledCard>
  );
}

function PayrollResults({ results, greyedOut }) {
  if (!results || !results.breakdown) {
    return null;
  }

  const { breakdown } = results;

  return (
    <StyledCard
      title='Resumen'
      style={{
        marginBottom: 20,
        textAlign: 'left',
        height: window.innerWidth < 420 ? 'auto' : 304,
      }}
    >
      <GreyedOutDiv greyedOut={greyedOut}>
        <Row gutter={[12, 6, 6]}>
          <LabelCol span={9}>{/* <Text strong>Resumen</Text> */}</LabelCol>
          <LabelCol span={7} style={{ textAlign: 'right' }}>
            <Text strong>Mensual</Text>
          </LabelCol>
          <LabelCol span={8} style={{ textAlign: 'right' }}>
            <Text strong>
              Anual
              <Tooltip
                title={
                  <>
                    <div>Incluye décimo tercer mes (XIII).</div>
                  </>
                }
                placement='right'
              >
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            </Text>
          </LabelCol>
          <LabelCol span={9}>
            <Text strong style={{ color: 'rgb(252, 98, 3)' }}>
              Salario bruto:
            </Text>
          </LabelCol>
          <ValueCol span={7}>
            <Text style={{ color: 'rgb(252, 98, 3)' }}>
              <FormattedUSD total={breakdown.monthlyGrossSalary.toFixed(2)} />
            </Text>
          </ValueCol>
          <ValueCol span={8}>
            <Text style={{ color: 'rgb(252, 98, 3)' }}>
              <FormattedUSD
                total={13 * breakdown.monthlyGrossSalary.toFixed(2)}
              />
            </Text>
          </ValueCol>

          <LabelCol span={9}>
            <Text strong style={{ color: 'rgb(40, 185, 110)' }}>
              Empleado recibe:
            </Text>
          </LabelCol>
          <ValueCol span={7}>
            <Text style={{ color: 'rgb(40, 185, 110)' }}>
              <FormattedUSD total={breakdown.monthlyNetSalary.toFixed(2)} />
            </Text>
          </ValueCol>
          <ValueCol span={8}>
            <Text style={{ color: 'rgb(40, 185, 110)' }}>
              <FormattedUSD total={results.totalCompensation.toFixed(2)} />
            </Text>
          </ValueCol>

          <LabelCol span={9}>
            <Text strong style={{ color: 'rgb(171, 53, 165)' }}>
              Costo total de empleador:
            </Text>
          </LabelCol>
          <ValueCol span={7}>
            <Text style={{ color: 'rgb(171, 53, 165)' }}>
              <FormattedUSD
                total={breakdown.monthlyTotalEmployerCost.toFixed(2)}
              />
            </Text>
          </ValueCol>
          <ValueCol span={8}>
            <Text style={{ color: 'rgb(171, 53, 165)' }}>
              <FormattedUSD total={results.totalEmployerCost.toFixed(2)} />
            </Text>
          </ValueCol>
          {/* <LabelCol span={16}>
            <Text strong>Múltiplo:</Text>
          </LabelCol>
          <ValueCol span={7}>
            <Text>
              {breakdown && breakdown.monthlyGrossSalary > 0
                ? (
                    results.totalEmployerCost /
                    (13 * breakdown.monthlyGrossSalary)
                  ).toFixed(2)
                : '0'}
            </Text>
          </ValueCol> */}
        </Row>
      </GreyedOutDiv>
    </StyledCard>
  );
}
function BreakdownResults({ results, greyedOut }) {
  if (!results || !results.breakdown) {
    return null;
  }

  const { breakdown } = results;

  // Helper function to format numbers as USD
  const formatUSD = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const linkAmounts = [
    formatUSD(breakdown.monthlyGrossSalary), // Empleador to Salario Bruto
    formatUSD(breakdown.monthlyTotalEmployerContributions), // Empleador to SIPE
    formatUSD(breakdown.monthlyNetSalary), // Salario Bruto to Empleado
    formatUSD(breakdown.monthlyTotalEmployeeDeductions), // Salario Bruto to SIPE
    formatUSD(breakdown.monthlyEmployeeSE + breakdown.monthlyEmployerSE), // SIPE to SE
    formatUSD(breakdown.monthlyISR), // SIPE to ISR
    formatUSD(
      breakdown.monthlyEmployeeCSS +
        breakdown.monthlyEmployerCSS +
        breakdown.monthlyEmployerRiskPremiumBenefit
    ), // SIPE to CSS
  ];

  const margins = {
    l: window.innerWidth < 420 ? 0 : 64,
    r: window.innerWidth < 420 ? 0 : 64,
  };

  const sankeyData = {
    type: 'sankey',
    orientation: 'h',
    domain: {
      x: [0, 1],
      y: [0, 1],
    },
    node: {
      pad: 20,
      thickness: 30,
      line: {
        color: 'rgba(116, 116, 205, 1)',
        width: 0.1,
      },
      label: [
        `Empleador<br>${formatUSD(breakdown.monthlyTotalEmployerCost)}`, // Node 0
        `Salario Bruto<br>${formatUSD(breakdown.monthlyGrossSalary)}`, // Node 1
        `SIPE<br>${formatUSD(breakdown.monthlyTotalSIPE)}`, // Node 2
        `Empleado<br>${formatUSD(breakdown.monthlyNetSalary)}`, // Node 3
        `Seguro Educativo (SE)<br>${formatUSD(
          breakdown.monthlyEmployerSE + breakdown.monthlyEmployeeSE
        )}`, // Node 4
        `ISR<br>${formatUSD(breakdown.monthlyISR)}`, // Node 5
        `CSS<br>${formatUSD(
          breakdown.monthlyEmployerCSS +
            breakdown.monthlyEmployeeCSS +
            breakdown.monthlyEmployerRiskPremiumBenefit
        )}`, // Node 6
      ],
      color: [
        'rgba(171, 53, 165,0.75)', // Empleador
        'rgba(252, 98, 3,0.75)', // Salario Bruto
        'rgba(117, 85, 56,0.75)', // SIPE
        'rgba(40, 185, 110,0.75)', // Empleado
        'rgba(116, 116, 205,0.85)', // SE
        'rgba(116, 116, 205,0.85)', // ISR
        'rgba(116, 116, 205,0.85)', // CSS
      ],
      x: [
        0.0, // Empleador
        0.3, // Salario Bruto
        0.6, // SIPE
        1, // Empleado
        1, // Seguro Educativo (SE)
        1, // ISR
        1, // CSS
      ],
      y: [
        0.5, // Empleador
        0.25, // Salario Bruto
        0.85, // SIPE
        0.1, // Empleado
        0.55, // Seguro Educativo (SE)
        0.9, // ISR
        0.7, // CSS
      ],
      textfont: {
        size: 14,
        color: 'white', // Labels font color set to white
      },
      hoverinfo: 'none', // Disable hover tooltips for links
    },
    link: {
      source: [
        0, // Empleador to Salario Bruto
        0, // Empleador to SIPE (employer contributions)
        1, // Salario Bruto to Empleado (Net Salary)
        1, // Salario Bruto to SIPE (employee deductions)
        2, // SIPE to SE
        2, // SIPE to ISR
        2, // SIPE to CSS
      ],
      target: [
        1, // Empleador to Salario Bruto
        2, // Empleador to SIPE
        3, // Salario Bruto to Empleado
        2, // Salario Bruto to SIPE
        4, // SIPE to SE
        5, // SIPE to ISR
        6, // SIPE to CSS
      ],
      value: [
        breakdown.monthlyGrossSalary, // Empleador to Salario Bruto
        breakdown.monthlyTotalEmployerContributions, // Empleador to SIPE
        breakdown.monthlyNetSalary, // Salario Bruto to Empleado
        breakdown.monthlyTotalEmployeeDeductions, // Salario Bruto to SIPE
        breakdown.monthlyEmployeeSE + breakdown.monthlyEmployerSE, // SIPE to SE
        breakdown.monthlyISR, // SIPE to ISR
        breakdown.monthlyEmployeeCSS +
          breakdown.monthlyEmployerCSS +
          breakdown.monthlyEmployerRiskPremiumBenefit, // SIPE to CSS
      ],
      color: [
        'rgba(116, 116, 205, 0.32)', // Empleador to Salario Bruto
        'rgba(74, 144, 226,0.9)', // Empleador to SIPE
        'rgba(116, 116, 205,0.32)', // Salario Bruto to Empleado
        'rgba(190,30,20,0.9)', // Salario Bruto to SIPE
        'rgba(116, 116, 205, 0.32)', // SIPE to SE
        'rgba(116, 116, 205, 0.32)', // SIPE to ISR
        'rgba(116, 116, 205, 0.32)', // SIPE to CSS
      ],
      label: [
        'Empleador a Salario Bruto', // Empleador to Salario Bruto
        'Empleador a SIPE', // Empleador to SIPE
        'Salario Bruto a Empleado', // Salario Bruto to Empleado
        'Salario Bruto a SIPE', // Salario Bruto to SIPE
        'SIPE a SE', // SIPE to SE
        'SIPE a ISR', // SIPE to ISR
        'SIPE a CSS', // SIPE to CSS
      ],
      customdata: linkAmounts,
      hovertemplate: '%{label}<br>%{customdata}<extra></extra>',
    },
  };

  const sankeyLayout = {
    font: {
      size: 12,
      color: 'black',
    },
    margin: margins,
    // width: '100%',
    // height: 600,
    // Optionally, adjust margins or add background colors if needed
    // margin: { t: 50, l: 50, r: 50, b: 50 },
    // automargin: true,
    // paper_bgcolor: 'black', // Background color outside the plotting area
    // plot_bgcolor: 'black', // Background color of the plotting area
  };

  return (
    <StyledCard
      title='Desglose'
      style={{ marginBottom: 20, textAlign: 'left' }}
    >
      <GreyedOutDiv greyedOut={greyedOut}>
        <Row gutter={[12, 6, 6]}></Row>

        <Title style={{ textAlign: 'left' }} level={4}>
          Flujo de dinero
        </Title>
        <div style={{ width: '100%' }}>
          <Plot
            data={[sankeyData]}
            layout={sankeyLayout}
            style={{ width: '100%' }}
          />
        </div>
        <Divider />

        <Title style={{ textAlign: 'left' }} level={4}>
          Empleado aporta al estado (deducciones)
          <Tooltip
            title='Lo que el empleador descuenta al empleado para luego dárselo al estado mediante el SIPE.'
            placement='right'
          >
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        </Title>
        <Row gutter={16}>
          <Col xs={24} md={12} xxl={12}>
            <div style={{ width: '100%', marginTop: 40 }}>
              <Row gutter={16}>
                <LabelCol span={16}>
                  <Text>
                    Seguro Social (CSS) <b>9.75%</b>:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text>
                    <FormattedUSD
                      total={breakdown.monthlyEmployeeCSS.toFixed(2)}
                    />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text>
                    Seguro Educativo <b>1.25%</b>:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text>
                    <FormattedUSD
                      total={breakdown.monthlyEmployeeSE.toFixed(2)}
                    />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text>Impuesto sobre la Renta (ISR):</Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text>
                    <FormattedUSD total={breakdown.monthlyISR.toFixed(2)} />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text strong style={{ color: 'rgb(190,30,20)' }}>
                    Total de Aportes (deducciones) del Empleado:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text strong style={{ color: 'rgb(190,30,20)' }}>
                    <FormattedUSD
                      total={breakdown.monthlyTotalEmployeeDeductions.toFixed(
                        2
                      )}
                    />
                  </Text>
                </ValueCol>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12} xxl={12} style={{ marginTop: 12 }}>
            <EmployeeDeductionsChart deductions={breakdown} />
          </Col>
        </Row>

        <Divider />

        <Title style={{ textAlign: 'left' }} level={4}>
          Empleador aporta al estado
          <Tooltip
            title='Lo que le cuesta al empleador, adicional al salario. Estos montos se pagan mediante el SIPE.'
            placement='right'
          >
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        </Title>

        <Row gutter={16}>
          <Col xs={24} md={12} xxl={12}>
            <div style={{ width: '100%', marginTop: 40 }}>
              <Row gutter={16}>
                <LabelCol span={16}>
                  <Text>
                    Seguro Social (CSS) <b>12.25%</b>:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text>
                    <FormattedUSD
                      total={breakdown.monthlyEmployerCSS.toFixed(2)}
                    />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text>
                    Riesgo Profesional (CSS) <b>{breakdown.riskPremium}%</b>:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text>
                    <FormattedUSD
                      total={breakdown.monthlyEmployerRiskPremiumBenefit.toFixed(
                        2
                      )}
                    />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text>
                    Seguro Educativo <b>1.5%</b>:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text>
                    <FormattedUSD
                      total={breakdown.monthlyEmployerSE.toFixed(2)}
                    />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text strong style={{ color: 'rgb(54,109,179)' }}>
                    Total Contribuciones del Empleador:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text strong style={{ color: 'rgb(54,109,179)' }}>
                    <FormattedUSD
                      total={breakdown.monthlyTotalEmployerContributions.toFixed(
                        2
                      )}
                    />
                  </Text>
                </ValueCol>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12} xxl={12} style={{ marginTop: 12 }}>
            <EmployerContributionsChart contributions={breakdown} />
          </Col>
        </Row>

        <Divider />

        <Title style={{ textAlign: 'left' }} level={4}>
          SIPE Mensual
          <Tooltip
            placement='right'
            title={
              <>
                <div>
                  Abajo puedes ver desglosado el monto de un mes que no lleva
                  décimo tercer mes (XIII).
                </div>
                <br />
                <div>
                  El SIPE de este empleado en un mes de XIII sería{' '}
                  <FormattedUSD
                    total={breakdown.monthlyTotalSIPEdtm.toFixed(2)}
                  />
                </div>
              </>
            }
          >
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        </Title>

        <Row gutter={16}>
          <Col xs={24} md={12} xxl={12}>
            <div style={{ width: '100%', marginTop: 40 }}>
              <Row gutter={16}>
                <LabelCol span={16}>
                  <Text strong style={{ color: 'rgb(190,30,20)' }}>
                    Aportes del Empleado:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text strong style={{ color: 'rgb(190,30,20)' }}>
                    <FormattedUSD
                      total={breakdown.monthlySIPEEmployee.toFixed(2)}
                    />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text strong style={{ color: 'rgb(54,109,179)' }}>
                    Aportes del Empleador:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text strong style={{ color: 'rgb(54,109,179)' }}>
                    <FormattedUSD
                      total={breakdown.monthlySIPEEmployer.toFixed(2)}
                    />
                  </Text>
                </ValueCol>

                <LabelCol span={16}>
                  <Text strong style={{ color: 'rgb(117, 85, 56)' }}>
                    Total SIPE:
                  </Text>
                </LabelCol>
                <ValueCol span={8}>
                  <Text strong style={{ color: 'rgb(117, 85, 56)' }}>
                    <FormattedUSD
                      total={breakdown.monthlyTotalSIPE.toFixed(2)}
                    />
                  </Text>
                </ValueCol>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12} xxl={12} style={{ marginTop: 12 }}>
            <SIPECompositionChart breakdown={breakdown} />
          </Col>
        </Row>
      </GreyedOutDiv>
    </StyledCard>
  );
}

function PayrollCalculatorView(props) {
  const [employeeData, setEmployeeData] = useState({
    monthlySalary: 800,
    // Uncomment the following fields if needed
    // benefitProduction: 0,
    // benefitFuel: 0,
    // benefitRepresentationExpenses: 0,
    riskPremium: 2.1,
  });

  const [results, setResults] = useState(null);

  const handleInputChange = (changedValues) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  useEffect(() => {
    const {
      monthlySalary,
      // Uncomment the following lines if needed
      // benefitProduction,
      // benefitFuel,
      // benefitRepresentationExpenses,
      riskPremium,
    } = employeeData;

    // Proceed with calculations even if monthlySalary is 0
    // Ensure that all calculations handle zero values appropriately

    // Use zero for optional benefits if not provided
    const benefitProduction = 0;
    const benefitFuel = 0;
    const benefitRepresentationExpenses = 0;

    // Calculate monthly gross salary
    const monthlyGrossSalary =
      monthlySalary +
      benefitProduction +
      benefitFuel +
      benefitRepresentationExpenses;

    // Calculate productionBenefitSS
    const productionBenefitSS = Math.max(
      benefitProduction - monthlySalary * 0.5,
      0
    );

    // Calculate fuelBenefitSS
    const fuelBenefitSS = Math.max(
      benefitFuel - (monthlySalary + productionBenefitSS) * 0.2,
      0
    );

    // Calculate payrollSubjectToSS
    const payrollSubjectToSS =
      monthlySalary +
      benefitRepresentationExpenses +
      productionBenefitSS +
      fuelBenefitSS;

    // Deductions per month
    const deductionSSRate = 0.0975;
    const employeeCSS = payrollSubjectToSS * deductionSSRate;

    // Calculate payrollSubjectToSE
    const payrollSubjectToSE = monthlySalary + benefitFuel;

    const deductionSERate = 0.0125;
    const employeeSE = payrollSubjectToSE * deductionSERate;

    // Monthly deductions for employee
    const monthlyTotalEmployeeDeductions = employeeCSS + employeeSE;

    // Employer contributions per month
    const employerCSSRate = 0.1225; // 12.25%
    const employerSERate = 0.015; // 1.5%
    const employerCSS = payrollSubjectToSS * employerCSSRate;
    const employerSE = payrollSubjectToSE * employerSERate;
    const employerRiskPremiumBenefit = payrollSubjectToSS * (riskPremium / 100);

    const monthlyTotalEmployerContributions =
      employerCSS + employerSE + employerRiskPremiumBenefit;

    // Annual calculations
    const monthsPerYear = 12;
    const annualGrossSalary = monthlyGrossSalary * monthsPerYear;

    // **Decimo Tercer Mes (13th Month)**
    const decimoTercerMes = annualGrossSalary / 12; // It's 1/12 of annual salary

    // Total annual gross compensation including decimo tercer mes
    const totalAnnualGrossCompensation = annualGrossSalary + decimoTercerMes;

    // **Adjust rates for Decimo Tercer Mes**
    const deductionSSRateDTM = 0.0725;
    const employerSSRateDTM = 0.1075;
    const deductionSERateDTM = 0.0; // No SE during Decimo Tercer Mes
    const employerSERateDTM = 0.0; // No SE during Decimo Tercer Mes

    // **Annual employee deductions**
    const annualEmployeeCSS =
      employeeCSS * monthsPerYear + decimoTercerMes * deductionSSRateDTM;

    const annualEmployeeSE =
      employeeSE * monthsPerYear + decimoTercerMes * deductionSERateDTM;

    const totalAnnualEmployeeDeductions = annualEmployeeCSS + annualEmployeeSE;

    // **Add 10% ISR on Benefit - Representation Expenses**
    const annualBenefitRepresentationExpenses =
      (benefitRepresentationExpenses || 0) * monthsPerYear;
    const isrOnRepresentationExpenses =
      annualBenefitRepresentationExpenses * 0.1;

    // Compensation subject to ISR
    const compensationSubjectToISR =
      // totalAnnualGrossCompensation - totalAnnualEmployeeDeductions;
      totalAnnualGrossCompensation - annualBenefitRepresentationExpenses;

    // **Annual ISR**
    let totalAnnualISR;
    totalAnnualISR += isrOnRepresentationExpenses;

    if (compensationSubjectToISR < 11000) {
      totalAnnualISR = 0;
    } else if (
      compensationSubjectToISR >= 11000 &&
      compensationSubjectToISR < 50000
    ) {
      totalAnnualISR = 0.15 * (compensationSubjectToISR - 11000);
    } else {
      totalAnnualISR = 0.15 * 39000 + 0.25 * (compensationSubjectToISR - 50000);
    }

    // Monthly ISR
    // const monthlyISR = totalAnnualISR / monthsPerYear;
    const monthlyISR = totalAnnualISR / 13;

    // SIPE monthly
    const monthlySIPEEmployee = employeeCSS + employeeSE + monthlyISR;
    const monthlySIPEEmployer =
      employerCSS + employerSE + employerRiskPremiumBenefit;

    const monthlyTotalSIPE = monthlySIPEEmployee + monthlySIPEEmployer;

    // For months with décimo tercer mes (13th month)
    const monthlyTotalSIPEdtm =
      monthlyTotalSIPE +
      (decimoTercerMes * employerSSRateDTM +
        decimoTercerMes * employerSERateDTM +
        decimoTercerMes * deductionSSRateDTM +
        decimoTercerMes * deductionSERateDTM) /
        3;

    // Total monthly deductions including ISR
    const monthlyTotalEmployeeDeductionsWithISR =
      monthlyTotalEmployeeDeductions + monthlyISR;

    // Monthly net salary
    const monthlyNetSalary =
      monthlyGrossSalary - monthlyTotalEmployeeDeductionsWithISR;

    // Employee's net annual compensation
    // const totalCompensation =
    //   totalAnnualGrossCompensation -
    //   totalAnnualEmployeeDeductions -
    //   totalAnnualISR;
    const totalCompensation =
      (21 * monthlyNetSalary) / 2 +
      3 *
        (monthlyNetSalary / 2 +
          (decimoTercerMes -
            decimoTercerMes * deductionSSRateDTM -
            decimoTercerMes * deductionSERateDTM -
            monthlyISR) /
            3);

    // Total cost for employer
    const monthlyTotalEmployerCost =
      monthlyGrossSalary + monthlyTotalEmployerContributions;

    // Total cost for employer
    const totalEmployerCost =
      13 * monthlyGrossSalary +
      12 * monthlyTotalEmployerContributions +
      decimoTercerMes * employerSSRateDTM +
      decimoTercerMes * employerSERateDTM;

    setResults({
      totalCompensation,
      totalEmployerCost,
      breakdown: {
        monthlyGrossSalary,
        monthlyNetSalary,
        monthlyEmployeeCSS: employeeCSS,
        monthlyEmployeeSE: employeeSE,
        monthlyISR,
        monthlyTotalEmployeeDeductions: monthlyTotalEmployeeDeductionsWithISR,
        monthlyEmployerCSS: employerCSS,
        monthlyEmployerSE: employerSE,
        monthlyEmployerRiskPremiumBenefit: employerRiskPremiumBenefit,
        monthlyTotalEmployerContributions,
        monthlyTotalEmployerCost,
        monthlySIPEEmployee,
        monthlySIPEEmployer,
        monthlyTotalSIPE,
        monthlyTotalSIPEdtm,
        riskPremium,
      },
    });
  }, [employeeData]);

  const greyedOut = employeeData.monthlySalary <= 0;

  return (
    <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <a
        href='https://herofacturas.com/qrLead?source=payroll-calculator-logo'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Logo src='./HERO_LOGO-03.png' alt='Hero' />
      </a>
      <PageContainer>
        <Title level={2}>Calculadora de Planilla de Panamá 🇵🇦 </Title>
        <div style={{ marginBottom: 16 }}>
          Calcula cuánto recibe un empleado y cuánto cuesta al empleador.
          Cortesía de{' '}
          <a
            href='https://herofacturas.com/qrLead?source=payroll-calculator-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            Hero.
          </a>
        </div>
        <Row gutter={16}>
          <Col xs={24} md={7} xxl={7}>
            <EmployeeForm
              employeeData={employeeData}
              onInputChange={handleInputChange}
              API_domain={props.API_domain}
            />
          </Col>
          <Col xs={24} md={10} xxl={10}>
            <PayrollResults results={results} greyedOut={greyedOut} />
          </Col>
          <Col xs={24} md={7} xxl={7}>
            <OtherHeroTools API_domain={props.API_domain} />
          </Col>
        </Row>
        <BreakdownResults results={results} greyedOut={greyedOut} />
      </PageContainer>
    </div>
  );
}

const PageContainer = styled.div`
  padding: 20px;
  max-width: 960px;
  margin: 0 auto;
`;

const InfoIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='grey'
    className='size-6'
    width={20}
    style={{ paddingLeft: 5 }}
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z'
    />
  </svg>
);

const LabelCol = styled(Col)`
  text-align: left;
`;

const ValueCol = styled(Col)`
  text-align: right;
`;

const Logo = styled.img`
  width: 96px;
`;

export { PayrollCalculatorView };
