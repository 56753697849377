import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, message, Modal } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { CategorySelect } from './CategorySelect.js';
import { TeamSelect } from './TeamSelect.js';
import { authContext } from '../ProvideAuth.js';

function MassEditButtonAndModalForExpenses(props) {
  const auth = useContext(authContext);
  const [dateSubmitting, setDateSubmitting] = useState(false);
  const [categorySubmitting, setCategorySubmitting] = useState(false);
  const [teamSubmitting, setTeamSubmitting] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [deletionSubmitting, setDeletionSubmitting] = useState(false);
  const [swappingDates, setSwappingDates] = useState(false);
  const [visible, setVisible] = useState(false);
  const [updateTableOnModalClose, setUpdateTableOnModalClose] = useState(false);
  const [form] = Form.useForm();
  const [heroUsers, setHeroUsers] = useState(
    auth.email === 'noreplyherofacturas@gmail.com' ||
      auth.email === 'jarol@herofacturas.com' ||
      auth.email === 'jorge@herofacturas.com' ||
      auth.email === 'alvarososa123@gmail.com'
  );
  const handleOk = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
    if (updateTableOnModalClose) {
      props.setSubmittedToggle(!props.submittedToggle);
      props.setSelectedExpensesInMassEdit([]);
    }
  };

  const updateDate = (date) => {
    setUpdateTableOnModalClose(true);
    if (!date) {
      message.error('Por favor seleccione una fecha');
      return;
    }
    setDateSubmitting(true);
    const data = {
      date: date,
      expense_ids: props.selectedExpensesInMassEdit.map((x) => x.id),
    };
    axios({
      method: 'post',
      url: props.API_domain + 'bulkUpdateExpenseDate',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setDateSubmitting(false);
        message.success('Fechas actualizadas exitosamente');
      })
      .catch((response) => {
        console.log(response);
        setDateSubmitting(false);
        message.error('Error actualizando las fechas');
      });
  };

  const updateCategory = (category) => {
    setUpdateTableOnModalClose(true);
    if (!category) {
      message.error('Por favor seleccione una categoría');
      return;
    }
    setCategorySubmitting(true);
    const data = {
      category: category,
      expense_ids: props.selectedExpensesInMassEdit.map((x) => x.id),
    };
    axios({
      method: 'post',
      url: props.API_domain + 'bulkUpdateExpenseCategory',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setCategorySubmitting(false);
        message.success('Categorías actualizadas exitosamente');
      })
      .catch((response) => {
        console.log(response);
        setCategorySubmitting(false);
        message.error('Error actualizando las categorías');
      });
  };

  const deleteExpenses = () => {
    setUpdateTableOnModalClose(true);
    setDeletionSubmitting(true);
    const data = {
      expense_ids: props.selectedExpensesInMassEdit.map((x) => x.id),
    };
    axios({
      method: 'post',
      url: props.API_domain + 'bulkDeleteExpenses',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        message.success('Gastos borrados exitosamente');
        setDeletionSubmitting(false);
        //sleep 2 seconds before closing the modal
        setTimeout(() => {
          handleCancel();
          props.setSubmittedToggle(!props.submittedToggle);
        }, 1000);
      })
      .catch((response) => {
        setDeletionSubmitting(false);
        console.log(response);
        message.error('Error borrando los gastos');
      });
  };

  const updateTeam = (team) => {
    setUpdateTableOnModalClose(true);
    setTeamSubmitting(true); // Set submitting to true to show the loader

    const data = {
      team: team, // the team value
      expense_ids: props.selectedExpensesInMassEdit.map((x) => x.id), // IDs of the expenses you wish to update
    };

    axios({
      method: 'post',
      url: props.API_domain + 'bulkUpdateExpenseTeam', // Replace this with the actual API endpoint for updating team
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setTeamSubmitting(false);
        message.success('Equipo actualizado exitosamente');
      })
      .catch((response) => {
        console.log(response);
        setTeamSubmitting(false);
        message.error('Escoge un equipo válido');
      });
  };

  const swapDates = (team) => {
    setUpdateTableOnModalClose(true);
    setSwappingDates(true); // Set submitting to true to show the loader

    const data = {
      expense_ids: props.selectedExpensesInMassEdit.map((x) => x.id), // IDs of the expenses you wish to update
    };

    axios({
      method: 'post',
      url: props.API_domain + 'bulkSwapDates', // Replace this with the actual API endpoint for updating team
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setSwappingDates(false);
        message.success('Fechas cambiadas exitosamente');
      })
      .catch((response) => {
        console.log(response);
        setSwappingDates(false);
        message.error('Posiblemente alguna fecha no es intercambiable');
      });
  };

  return (
    <div>
      {props.selectedExpensesInMassEdit.length > 1 && (
        <Button
          onClick={() => {
            setVisible(true);
          }}
        >
          <EditOutlined />
          Editar en masa ({props.selectedExpensesInMassEdit.length})
        </Button>
      )}
      <Modal
        title='Editar en masa'
        visible={visible}
        onOk={() => {
          setVisible(false);
          handleOk();
        }}
        onCancel={() => {
          setVisible(false);
          handleCancel();
        }}
        footer={null}
      >
        <Form form={form}>
          <Form.Item
            name='date'
            label='Nueva Fecha:'
            rules={[
              {
                required: true,
                message: 'Por favor seleccione una fecha',
              },
            ]}
          >
            <DatePicker locale={locale} style={{ width: 180 }} />
          </Form.Item>
          <Button
            type='primary'
            block
            onClick={() => updateDate(form.getFieldValue('date'))}
          >
            {dateSubmitting ? <LoadingOutlined /> : <>Actualizar Fecha</>}
          </Button>
          <Divider />
          <Form.Item label='Categoría' name='category'>
            <CategorySelect
              API_domain={props.API_domain}
              onChange={(value) => {
                form.setFieldsValue({ category: value });
              }}
              defaultCategory={null}
              disabledBoolean={false}
              team={props.team}
            />
          </Form.Item>
          <Button
            onClick={() => updateCategory(form.getFieldValue('category'))}
            type='primary'
            block
          >
            {categorySubmitting ? (
              <LoadingOutlined />
            ) : (
              <>Actualizar Categoría</>
            )}
          </Button>
          <Divider />
          <Form.Item name='team' label='Equipo:'>
            <TeamSelect
              API_domain={props.API_domain}
              onTeamSelect={(value) => {
                form.setFieldsValue({ team: value });
                setSelectedTeam(value);
              }}
              team={selectedTeam || form.getFieldValue('team')}
            />
          </Form.Item>
          <Button
            // onClick={() => updateTeam(form.getFieldValue('team'))}
            onClick={() => {
              updateTeam(form.getFieldValue('team'));
            }}
            type='primary'
            block
          >
            {teamSubmitting ? <LoadingOutlined /> : <>Actualizar Equipo</>}
          </Button>
          <Divider />
          <Button
            type='danger'
            block
            onClick={() => {
              deleteExpenses();
            }}
          >
            {deletionSubmitting ? (
              <LoadingOutlined />
            ) : (
              <>Borrar {props.selectedExpensesInMassEdit.length} gastos</>
            )}
          </Button>
          {heroUsers && (
            <>
              <Divider />
              <Button
                type='secondary'
                block
                onClick={() => {
                  swapDates();
                }}
              >
                {swappingDates ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    Intercambiar día por mes para{' '}
                    {props.selectedExpensesInMassEdit.length} gastos
                  </>
                )}
              </Button>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
}

export { MassEditButtonAndModalForExpenses };
