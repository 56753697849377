import { Avatar, Button, Col, message, Modal, Row } from 'antd';
import React, { useState, useContext, useEffect } from 'react';

import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
import { AssigneeSelect } from './AssigneeSelect.js';
import { ChatBox } from './ChatBox.js';

import { useHistory } from 'react-router-dom';
import { ImgOrPdfProcurement } from './ImgOrPdfProcurement.js';
import { DeleteDocumentButton } from './DeleteDocumentButton.js';
import { MarkAsCompletedButton } from './MarkAsCompletedButton.js';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function InvoiceModal(props) {
  const auth = useContext(authContext);
  const history = useHistory();
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [assignee, setAssignee] = useState(props.invoice.assignee);
  const [imageUrls, setImageUrls] = useState([props.invoice.image_url]);
  const onSecondaryDocChange = () => {
    setSubmittedToggle(!submittedToggle);
  };

  return (
    <>
      <Modal
        visible={true}
        onCancel={() => {
          if (refreshToggle) {
            props.setSelectedInvoice(null);
            props.setLoaded(false);
            props.setSubmittedToggle(!props.submittedToggle);
          } else {
            props.setSelectedInvoice(null);
          }
          history.push({
            search: '',
          });
        }}
        onOk={props.handleOk}
        width={1200}
        centered
        // style={{ paddingBottom: 0 }}
        bodyStyle={{
          height: 'calc(100vh - 320px)',
          overflow: 'auto',
          paddingTop: 5,
          paddingBottom: 5,
        }}
        title={
          <Row>
            <Col span={12}>
              <div>
                Factura {props.invoice.id}
                {/* <Button
                  style={{ border: 'none', color: 'grey' }}
                  onClick={() => {
                    setRenameOrder(true);
                  }}
                >
                  <EditOutlined />
                </Button> */}
              </div>

              <span style={{ color: 'grey', fontSize: '14px' }}>
                {props.invoice.team} - {props.invoice.createdAtFormatted}
              </span>
            </Col>

            <Col span={12}>
              <div
                style={{
                  paddingLeft: 20,
                  textAlign: 'right',
                  paddingRight: 30,
                }}
              >
                <>
                  Orden de Compra: &nbsp;
                  <a
                    href={'/ordenes?openModalOrder=' + props.invoice.purchase_order_id}
                    style={{ textDecoration: 'underline' }}
                  >
                    {props.invoice.purchase_order_id}
                  </a>{' '}
                </>
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row>
              <Col
                span={8}
                style={{
                  textAlign: 'center',
                  padding: '100 100 100 100',
                  marginTop: 15,
                }}
              >
                <MarkAsCompletedButton
                  API_domain={props.API_domain}
                  API_endPoint={'markInvoiceAsCompleted'}
                  document={props.invoice}
                  onSubmit={props.handleOk}
                />
                <DeleteDocumentButton
                  API_domain={props.API_domain}
                  API_endPoint={'deleteInvoice'}
                  document={props.invoice}
                  onSubmit={props.handleOk}
                />
                Asignado a: &nbsp;
                <AssigneeSelect
                  API_domain={props.API_domain}
                  API_endPoint={'setInvoiceAssignee'}
                  document={props.invoice}
                  onSubmit={onSecondaryDocChange}
                  setAssignee={setAssignee}
                />
              </Col>
            </Row>
          </>
        }
      >
        <Row style={{ height: '100%' }}>
          <Col
            span={16}
            style={{
              overflow: 'auto',
              height: '100%',
              // display: 'flex',
              // alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Carousel
              useKeyboardArrows={true}
              dynamicHeight={false}
              height='100%'
              showStatus={false}
              style={{ color: 'red' }}
              renderThumbs={() =>
                imageUrls.map((image, index) => (
                  <ImgOrPdfProcurement
                    key={index}
                    src={{
                      imageSource: image,
                    }}
                    isThumbnail={true}
                  />
                ))
              }
            >
              {imageUrls.map((image, index) => (
                // <div style={{ height: '100%' }}>
                //   <Row>
                //     <Col span={16}>
                //       <ImgOrPdfProcurement
                //         src={{
                //           imageSource: image.imageSource,
                //         }}
                //         width={'225px'}
                //         isThumbnail={false}
                //       />
                //     </Col>
                //     <Col span={8}>
                //       <p className='legend'>Legendario {index}</p>
                //     </Col>
                //   </Row>
                // </div>
                <ImgOrPdfProcurement
                  src={{
                    imageSource: image,
                  }}
                  width={'225px'}
                  isThumbnail={false}
                />
              ))}
            </Carousel>
          </Col>

          <Col
            span={8}
            style={{
              overflow: 'auto',
              padding: 8,
              height: '100%',
              backgroundColor: 'rgb(245, 245, 245)',
              borderRadius: 8,
            }}
          >
            <h3 style={{ color: 'grey', padding: 3, paddingBottom: 6 }}>
              Chat
            </h3>
            <ChatBox
              API_domain={props.API_domain}
              document={props.invoice}
              documentType='invoice'
              API_endPointCreateChat='createInvoiceChat'
              API_endPointGetChats='getInvoiceChats'
              submittedToggle={submittedToggle}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export { InvoiceModal };
