import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function SIPECompositionChart({ breakdown }) {
  const data = {
    labels: [
      'Empleado CSS',
      'Empleado Seguro Educativo',
      'Empleado ISR',
      'Empleador CSS',
      'Empleador Seguro Educativo',
      'Empleador Riesgo Profesional',
    ],
    datasets: [
      {
        data: [
          breakdown.monthlyEmployeeCSS,
          breakdown.monthlyEmployeeSE,
          breakdown.monthlyISR,
          breakdown.monthlyEmployerCSS,
          breakdown.monthlyEmployerSE,
          breakdown.monthlyEmployerRiskPremiumBenefit,
        ],
        backgroundColor: [
          // Employee Contributions (Red hues)
          '#FF9999',
          '#FF6666',
          '#FF3333',
          // Employer Contributions (Blue hues)
          '#4A90E2',
          '#50A6E2',
          '#5AB3E2',
        ],
        hoverBackgroundColor: [
          // Employee Contributions (Red hues)
          '#FF9999',
          '#FF6666',
          '#FF3333',
          // Employer Contributions (Blue hues)
          '#4A90E2',
          '#50A6E2',
          '#5AB3E2',
        ],
        borderColor: [
          // Employee Contributions
          '#CC0000',
          '#CC0000',
          '#CC0000',
          // Employer Contributions
          '#357ABD',
          '#357ABD',
          '#357ABD',
        ],
        borderWidth: 2,
      },
    ],
  };

  const formatUSD = (value) =>
    '$' +
    value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  let isMobile = window.innerWidth <= 500;

  const options = {
    layout: {
      padding: {
        // Add padding if needed
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
      },
      datalabels: {
        display: (context) => {
          if (isMobile) {
            return false;
          } else {
            return context.dataset.data[context.dataIndex] !== 0;
          }
        },
        formatter: (value, context) => {
          const amount = formatUSD(value);
          return `${amount}`;
        },
        color: '#fff',
        font: {
          size: 10,
        },
        align: 'end',
        padding: -6,
        clip: false,
      },
    },
  };

  return <Doughnut data={data} options={options} />;
}

export default SIPECompositionChart;
